import React from 'react';
import SearchStudentBox from './SearchStudentBox';
import QuickAddStudentBox from './QuickAddStudentBox';

const QuickAddStudentForm = (props) => {

  return (
    <>
      <SearchStudentBox searchTitle="Renew Student"  setSearchData={props.getSearchData}  />
      <QuickAddStudentBox studentData={props.studentData} allCourses={props.allCourses} isShowForm={props.isShowForm} courseTotalInstallment={props.courseTotalInstallment} setFormData={props.setFormData} getSingleCourseData={props.getSingleCourseData} />
    </>
  )
}

export default QuickAddStudentForm;
