import React, { useEffect, useRef, useState } from 'react';
import './AddNewStudentForm.css';
import './OtherPaymentForm.css';
import './Loader.css';

const EditOthersPaymentForm = (props) => {
  const purposeElem = useRef();
  const [stuRegNo, setStuRegNo] = useState('');
  const [amount, setAmount] = useState('');
  const [purpose, setPurpose] = useState('');
  const [payerName, setPayerName] = useState('');

  useEffect(()=>{
    setStuRegNo(props.fetchedData.reg_no);
    setPayerName(props.fetchedData.full_name);
    setAmount(props.fetchedData.amount);
    setPurpose(props.fetchedData.purpose);
    if(purposeElem.current.querySelector(`option[value="${props.fetchedData.purpose}"]`) !== null)
        purposeElem.current.querySelector(`option[value="${props.fetchedData.purpose}"]`).selected = true;
  },[props.fetchedData]);

  const getStudentName = () => {
    fetch(`${props.hostName}/redsky_api/getSingleStudentName.php?token=${props.token}&id=${stuRegNo}`)
      .then(resp => resp.json()).then(data => {
        if(data.code === 5){
          setPayerName(data.data);
        }else{
          alert(data.data);
        }
      }).catch(err => console.log(err));
  }
  const checkKeyPressHandler = (e) => {
    if(e.key === "Enter"){
      getStudentName();
    }
  }
  const onBlurHandler = (e) => {
    if(e.target.value.trim() !== ""){
      getStudentName();
    }
  }
  const resetFormFields = () => {
    setStuRegNo('');
    setAmount('');
    setPurpose('');
    document.querySelectorAll(".placeHolder")[0].selected = true;
  }

  const formValidate = (e) => {
    e.preventDefault();
    if (stuRegNo.trim() === '' || amount.trim() === '' || purpose.trim() === '') {
      alert("Please Fill Out All Fields!");
      return;
    }

    let formData = {
      reg_no: stuRegNo,
      amount: amount,
      purpose: purpose
    };

    // console.log(JSON.stringify(formData));
    props.setFormData(formData);
    resetFormFields();
  }

  return (
    <>
      <section className={`add-student-form-container other-payment`}>
        
        <header>Modify Others Payment</header>
        <form action="#" className="form" onSubmit={formValidate} >


          <div className="input-box">
            <label>ID:<span className='color-red'>*</span></label>
            <input type="text" placeholder="Enter student ID" value={stuRegNo} onChange={(e) => { setStuRegNo(e.target.value) }} onKeyPress={checkKeyPressHandler} onBlur={onBlurHandler} required />
            <p className='payer-name'>&nbsp;{payerName}</p>
            {/* <button className='payer-verify-btn'>Verify Student</button> */}
          </div>


          <div className="input-box">
            <label>Amount<span className='color-red'>*</span></label>
            <input type="number" placeholder="Enter Amount in Rupees" value={amount} onChange={(e) => { setAmount(e.target.value) }} required />
          </div>

          <div className="input-box">
            <label>Purpose<span className='color-red'>*</span></label>
            <div className="select-box">
              <select onChange={(e) => { setPurpose(e.target.value) }} className="purpose-options" ref={purposeElem}>
                <option hidden className='placeHolder' value=''>Select a purpose</option>
                <option value='RFID Card'>RFID Card</option>
                <option value='Notebook'>Notebook</option>
                <option value='Monthly Card'>Monthly Card</option>
                <option value='Olympiad Course'>Olympiad Course</option>
              </select>
            </div>
          </div>

          <button type='submit'>Modify</button>
        </form>
      </section>
    </>
  )
}

export default EditOthersPaymentForm;
