import React, { useEffect, useState } from 'react';
import Table from '../../components/admin_components/Table';
import './AllStudents.css';
import Loader from '../../components/admin_components/Loader';

const AllStudents = (props) => {
  // , 'Edit', 'Delete', 'Print', 'Share'
    let tableCols = ['ID', 'Name', 'Guardian', 'Course', 'DOB', 'Contact', 'Board', 'Class', 'DOA', 'Status', 'Action'];
    let [rowsData, setRowsData] = useState([]);
    const [loaderClasses, setLoaderClasses] = useState('loader-container d-none');
    const [allCourses, setAllCourses] = useState([]);
    const [tableClasses, setTableClasses] = useState('table-container');
    const [msgClasses, setMsgClasses] = useState('msg d-none');
    const [msgData, setMsgData] = useState('');
    const [rowLimit, setRowLimit] = useState(15);
    const [LimitRowsCountClasses, setLimitRowsCountClasses] = useState('row-limit-container d-none');
    const [stuListType, setStuListType] = useState(2);
   
    useEffect(()=> {props.setTitle(`Students List | ${parseInt(props.isHeadAdmin) ? "Admin Panel" : "User Panel"} | RedSky Educations | Educations for future`)}, [props]);
    
    const fetchAllStudent = (stuType = 2) => {

        setLoaderClasses('loader-container');
        
        fetch(`${props.hostName}/redsky_api/getAllStudentsList.php?token=${props.token}&stu_type=${stuType}&limit=${rowLimit}`)
            .then(resp => resp.json()).then(data => {
                setLoaderClasses('loader-container d-none');
              
                if(data.code === 5){
                    setRowsData(data.data);
                    setTableClasses('table-container');
                    setMsgClasses('msg d-none');
                    setLimitRowsCountClasses('row-limit-container');
                }
                else{
                    setTableClasses('table-container d-none');
                    setMsgData(data.data);
                    setMsgClasses('msg');
                    setLimitRowsCountClasses('row-limit-container d-none');
                }
                
            }).catch(err => console.log(err));
    };
    useEffect(fetchAllStudent,[props.token,props.hostName,rowLimit]);
    
    const getFilteredData = (filterObj) => {
        setLoaderClasses('loader-container');

        fetch(`${props.hostName}/redsky_api/getFilteredStudent.php?token=${props.token}&stu_type=${stuListType}`,
        {
            method: "POST",
            body: JSON.stringify(filterObj),
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            }
        })
            .then(resp => resp.json()).then(data => {
                setLoaderClasses('loader-container d-none');
          
                if(data.code === 5){
                    setRowsData(data.data);
                    setTableClasses('table-container');
                    setMsgClasses('msg d-none');
                    setLimitRowsCountClasses('row-limit-container');
                }
                else{
                    setTableClasses('table-container d-none');
                    setMsgData(data.data);
                    setMsgClasses('msg');
                    setLimitRowsCountClasses('row-limit-container d-none');
                }
                
            }).catch(err => console.log(err));
    };

    const fetchAllCourses = () => {
        setLoaderClasses('loader-container');

        fetch(`${props.hostName}/redsky_api/getAllCourses.php?token=${props.token}`)
          .then(resp => resp.json()).then(data => {
            setLoaderClasses('loader-container d-none');
            if (data.code === 5) {
              setAllCourses(data.data);
            }
            else {
                document.querySelector('.add-student-form-container .form').innerHTML = `<center>${data.data} <br> Please Try again!</center>`;
            }
    
          }).catch(err => console.log(err));
        };

      useEffect(fetchAllCourses, [props.token,props.hostName]);
    
      
      const deleteStudentHandeler = (stuDeletionData) => {
        setLoaderClasses('loader-container');
        
          //deletion code will go here
          fetch(`${props.hostName}/redsky_api/deleteStudent.php?token=${props.token}`,
          {
            method: "POST",
            body: JSON.stringify(stuDeletionData),
            headers: {
              'Content-Type': 'application/json;charset=UTF-8'
            }
          })
          .then(resp => resp.json()).then(data => {
            if(data.code === 12){
              fetchAllStudent(stuListType);
            }
            alert(data.data);
            setLoaderClasses('loader-container d-none');
            
          }).catch(err => console.log(err));
      };

      const getRowLimit = (limitVal) => {
        setRowLimit(limitVal);
        fetchAllStudent(stuListType);
      };
    
    return (
        <>
            <Table tableClasses={tableClasses} tableCols={tableCols} rowsData={rowsData} allCourses={allCourses} setEditStuId={props.setEditStuId} token={props.token} hostName={props.hostName} fetchAllStudent={fetchAllStudent} deleteStudentHandeler={deleteStudentHandeler} getFilteredData={getFilteredData} setStuListType={setStuListType} getRowLimit={getRowLimit} LimitRowsCountClasses={LimitRowsCountClasses} msgClasses={msgClasses} msgData={msgData} setLoaderClasses={setLoaderClasses} />
            <Loader loaderClasses={loaderClasses}/>
        </>
    );
};

export default AllStudents;
