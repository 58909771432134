import { useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './Calender.css';

const Calender = (props) => {
  const [value, onChange] = useState(new Date());
  // const [maxDate, setMaxDate] = useState(new Date());
  const [presentDates, setPresentDates] = useState([]);
  const [absentDates, setAbsentDates] = useState([]);
  const [knownAbsentDates, setKnownAbsentDates] = useState([]);
  

  useEffect(()=>{
    if(props.attnData.present_data){
      setPresentDates(props.attnData.present_data);
    }
    if(props.attnData.absent_data){
      setAbsentDates(props.attnData.absent_data);
    }
    if(props.attnData.known_absent_data){
      setKnownAbsentDates(props.attnData.known_absent_data);
    }
  },[props.attnData]);


  return (
    <div className='w-100'>
      <Calendar onChange={onChange} value={value} className='w-100' 

        minDate={new Date(props.basicData.doa_y,props.basicData.doa_m-1,props.basicData.doa_d)} 
        
        maxDate={new Date(props.basicData.cend_y,props.basicData.cend_m-1,props.basicData.cend_d)}

        tileClassName={({date})=>{
            let day = date.getDate();
            let month = date.getMonth()+1;
            if(date.getMonth()<10) month = '0'+month;
            if(date.getDate()<10) day = '0'+day;
            const realDate = day+'-'+month+'-'+date.getFullYear();
            if(presentDates.find(val=> val === realDate)){
                return 'attn-calen-present';
            }
            else if(absentDates.find(val=> val === realDate)){
                return 'attn-calen-absent';
            }
            else if(knownAbsentDates.find(val=> val === realDate)){
              return 'attn-calen-known-absent';
          }
        }}
      />
    </div>
  );
}
export default Calender;

// import React, { useEffect, useRef, useState } from 'react';
// import './Calender.css';

// const Calender = () => {
//     const [date, setDate] = useState(new Date());
//     // let date = new Date();
//     const [year, setYear] = useState(date.getFullYear());
//     // let year = date.getFullYear();
//     const [month, setMonth] = useState(date.getMonth());
//     // let month = date.getMonth();
 
//     const calenderDates = useRef();
//     // const day = document.querySelector(".calendar-dates");
 
   
//     // const currdate = document.querySelector(".calendar-current-date");

//     // const prenexIcons = document.querySelectorAll(".calendar-navigation span");
 
//     // Array of month names
// const months = ["January","February","March","April","May","June","July","August","September","October","November","December"];
 
// // Function to generate the calendar
// const manipulate = () => {
 
//     // Get the first day of the month
//     let dayone = new Date(year, month, 1).getDay();
 
//     // Get the last date of the month
//     let lastdate = new Date(year, month + 1, 0).getDate();
 
//     // Get the day of the last date of the month
//     let dayend = new Date(year, month, lastdate).getDay();
 
//     // Get the last date of the previous month
//     let monthlastdate = new Date(year, month, 0).getDate();
 
//     // Variable to store the generated calendar HTML
//     let lit = "";
 
//     // Loop to add the last dates of the previous month
//     for (let i = dayone; i > 0; i--) {
//         lit +=
//             `<li class="inactive">${monthlastdate - i + 1}</li>`;
//     }
 
//     // Loop to add the dates of the current month
//     for (let i = 1; i <= lastdate; i++) {
 
//         // Check if the current date is today
//         let isToday = i === date.getDate()
//             && month === new Date().getMonth()
//             && year === new Date().getFullYear()
//             ? "active"
//             : "";
//         lit += `<li class="${isToday}">${i}</li>`;
//     }
 
//     // Loop to add the first dates of the next month
//     for (let i = dayend; i < 6; i++) {
//         lit += `<li class="inactive">${i - dayend + 1}</li>`
//     }

 
//     // update the HTML of the dates element 
//     // with the generated calendar
//     calenderDates.current.innerHTML = lit;
//     console.log("hello");
// }

// useEffect(manipulate);

 
// // Attach a click event listener to each icon
//     // When an icon is clicked
//     const prevNextIconsHandler = (btnType) => {
//         setMonth((btnType === "prev") ? month - 1 : month + 1);
 
//         // Check if the month is out of range
//         if (month < 0 || month > 11) {
 
//             // Set the date to the first day of the 
//             // month with the new year
//             setDate(new Date(year, month, new Date().getDate()));
 
//             // Set the year to the new year
//             setYear(date.getFullYear());
 
//             // Set the month to the new month
//             setMonth(date.getMonth());
//         }
 
//         else {
 
//             // Set the date to the current date
//             setDate(new Date());
//         }
 
//         // Call the manipulate function to 
//         // update the calendar display
//         manipulate();
//     }
//   return (
//     <div className="calendar-container">
//         <header className="calendar-header">
//             <p className="calendar-current-date">{`${months[month]} ${year}`}</p>
//             <div className="calendar-navigation">
//                 <span className="material-icons material-symbols-rounded" onClick={prevNextIconsHandler('prev')}> chevron_left</span>
//                 <span className="material-icons material-symbols-rounded" onClick={prevNextIconsHandler('next')}>chevron_right</span>
//             </div>
//         </header>
 
//         <div className="calendar-body">
//             <ul className="calendar-weekdays">
//                 <li>Sun</li>
//                 <li>Mon</li>
//                 <li>Tue</li>
//                 <li>Wed</li>
//                 <li>Thu</li>
//                 <li>Fri</li>
//                 <li>Sat</li>
//             </ul>
//             <ul className="calendar-dates" ref={calenderDates}></ul>
//         </div>
//     </div>
//   )
// }

// export default Calender
