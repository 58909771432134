import React, { forwardRef, useImperativeHandle, useState } from 'react';
import './FilterStudentTask.css';


const FilterStudentTask = (props, ref) => {
    const [filterId, setfilterId] = useState('');
    const [filterName, setfilterName] = useState('');
    const [filterGender, setfilterGender] = useState('');
    const [filterCourse, setfilterCourse] = useState('');
    const [filterDOBFrom, setfilterDOBFrom] = useState('');
    const [filterDOBTo, setfilterDOBTo] = useState('');

    const filterFormHandler = (e) => {
        e.preventDefault();
        props.filterHandler();
        let filterObj = {
            filterId: filterId,
            filterName:filterName,
            filterGender:filterGender,
            filterCourse:filterCourse,
            filterDOBFrom:filterDOBFrom,
            filterDOBTo:filterDOBTo
        }
        props.getFilteredData(filterObj);
    };

    const resetFilterForm = () => {
        let filterForm = document.querySelector('.filter-box');
        // filterForm.querySelector(`.gender-option input[value='']`).checked = true;
        filterForm.querySelector(`.course-options option[value='']`).selected = true;
        setfilterId('');
        setfilterName('');
        setfilterGender('');
        setfilterCourse('');
        setfilterDOBFrom('');
        setfilterDOBTo('');
    };

    useImperativeHandle(ref, ()=>({
        resetFilterForm
    }));

    return (
        <div className='filter-box-task d-none'>
            
            <abbr title='Reset Filter'><span className="material-icons material-symbols-outlined reset-filter-btn" onClick={resetFilterForm}>filter_list_off</span></abbr>
        
            <div className="input-box">
                <label className='field'>ID :</label>
                <input type="number" placeholder='Filter By ID' value={filterId} onChange={(e)=>setfilterId(e.target.value)}/>
            </div>

            <div className="input-box">
                <label className='field'>Name :</label>
                <input type="text" placeholder='Filter By Name' value={filterName} onChange={(e)=>setfilterName(e.target.value)}/>
            </div>

            {/* <div className="input-box">
                <label className='field'>Gender : </label>
                <div className="gender-option">
                    <div className="gender">
                        <label><input type="radio" name="gender" value='' onChange={(e)=>setfilterGender(e.target.value)} /> ALL</label>
                    </div>
                    <div className="gender">
                        <label><input type="radio" name="gender" value='Male' onChange={(e)=>setfilterGender(e.target.value)}/> Male</label>
                    </div>
                    <div className="gender">
                        <label><input type="radio" name="gender" value='Female' onChange={(e)=>setfilterGender(e.target.value)} /> Female</label>
                    </div>
                    <div className="gender">
                        <label><input type="radio" name="gender" value='Others' onChange={(e)=>setfilterGender(e.target.value)} /> Prefer not to say</label>
                    </div>
                    
                </div>
            </div> */}

            <div className="input-box">
                <label className='field'>Course :</label>
                <div className="select-box">
                    <select className="course-options" onChange={(e)=>setfilterCourse(e.target.value)}>
                        
                        <option value=''>All Course</option>
                        {
                            props.allCourses.map((element) => {
                            return(<option key={element.c_id} value={element.c_id}>{element.c_name}</option>);
                            })
                        }
                    </select>
                </div>
            </div>

            {/* <div className="input-box">
                <label className='field'>Date Of Birth :</label>
                <div className='dob-filter'>
                    <label>From : <input type="date" value={filterDOBFrom} onChange={(e)=>setfilterDOBFrom(e.target.value)} /></label>
                    <label>To : <input type="date" value={filterDOBTo} onChange={(e)=>setfilterDOBTo(e.target.value)} /></label>
                </div>
            </div> */}

            <div className="input-box d-flex justify-center">
                {/* <input type="submit" className='apply-filter-btn' value="Apply Filter" onClick={()=> props.filterHandler()}/> */}
                <button className='apply-filter-btn btn btn-primary' onClick={filterFormHandler}>Apply Filter</button>
            </div>

        </div>

    )
}

export default forwardRef(FilterStudentTask);
