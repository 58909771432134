import React, { useEffect } from 'react';

const DailyClass = (props) => {
  useEffect(()=> {props.setTitle("Daily Class | Task Panel | RedSky Educations | Educations for future")}, [props]);
  return (
    <div className='helolo' style={{overflow:"scroll", maxHeight:"100vh", height:"100%", minHeight: "90vh"}}>
      <iframe title='Daily Class' src="https://docs.google.com/spreadsheets/d/e/2PACX-1vRubBevj7brmNNtz_rM_zKODuClyNFGwNCqjzrJe3bZCpX2DLK-Z81_2LVnLZWb7AI9m0xZo5NAwxWK/pubhtml" style={{ minHeight:"90vh", width: "100%"}}></iframe>
    </div>
  );
};

export default DailyClass;
