import React, { useEffect, useState } from 'react';
import EditOthersPaymentForm from '../../components/admin_components/EditOthersPaymentForm';
import Loader from '../../components/admin_components/Loader';
import { useNavigate, useParams } from 'react-router-dom';

const EditOthersPayment = (props) => {
    const navigate = useNavigate('');
    const param = useParams();
    const receiptNo = param.receipt_no;
    const [loaderClasses, setLoaderClasses] = useState('loader-container d-none');
    const [fetchedData, setFetchedData] = useState({});

    useEffect(()=> {props.setTitle("Edit Other Payment | Admin Panel | RedSky Educations | Educations for future")}, [props]);

    useEffect(()=>{
      if(props.isHeadAdmin === '0'){
        navigate('../view-all-others-payments');
      }
    });
    
    const getFormData = (formData) => {
        setLoaderClasses('loader-container');
        fetch(`${props.hostName}/redsky_api/modifyOtherPayments.php?token=${props.token}`,
          {
            method: "POST",
            body: JSON.stringify({...formData, receipt_no: receiptNo}),
            headers: {
              'Content-Type': 'application/json;charset=UTF-8'
            }
          })
          .then(resp => resp.json()).then(data => {
            setLoaderClasses('loader-container d-none');
            if(data.code === 8){
              navigate('../view-all-others-payments');
              
            }else{
              alert(data.data);
            }
          }).catch(err => console.log(err));
      };
    
      const getSingleOthersPaymentData = () => {
        setLoaderClasses('loader-container');
        fetch(`${props.hostName}/redsky_api/fetchSingleOthersPayment.php?token=${props.token}&receipt_no=${receiptNo}`)
          .then(resp => resp.json()).then(data => {
            setLoaderClasses('loader-container d-none');
            if(data.code === 5){
                setFetchedData(data.data);
            }else{
              alert(data.data);
            }
          }).catch(err => console.log(err));
      };
      useEffect(getSingleOthersPaymentData,[props.hostName, props.token, receiptNo, setFetchedData]);

  return (
    <div>
      <EditOthersPaymentForm  hostName={props.hostName} token={props.token} isHeadAdmin={props.isHeadAdmin} fetchedData={fetchedData} setFormData={getFormData} />
      <Loader loaderClasses={loaderClasses} />
    </div>
  )
}

export default EditOthersPayment;

