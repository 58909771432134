import React, { useRef, useState } from 'react';
import './Table.css';
import AllOthersPaymentTableRow from './AllOthersPaymentTableRow';
import FilterOtherPayments from './FilterOtherPayments';
import VerifyPinContainer from './VerifyPinContainer';
// import LimitRowsCount from './LimitRowsCount';

const ViewAllOthersPaymentsTable = (props) => {
    const resestFilterRef = useRef();
    const [enteredPin, setEnteredPin] = useState('');
	const [verifyPinClasses, setVerifyPinClasses] = useState('verify-pin-container d-none');
    const [receiptNo, setReceiptNo] = useState('');
    
    const printAll = () => {
        window.print();
    };
    const filterHandler = () => {
        document.querySelector(".filter-box").classList.toggle('d-none');
    };
    const cancelPinHandler = () => {
		setVerifyPinClasses('verify-pin-container d-none');
		setEnteredPin('');
	};

    const proceedPinHandler = () => {
		if (enteredPin.length !== 0) {
			let paymentDeleteData = {
				receipt_no: receiptNo,
                entered_verify_pin: enteredPin
			};

			setEnteredPin('');
			// setEnteredCatpcha('');
			setVerifyPinClasses('verify-pin-container d-none');
			props.setDeleteOthersPayment(paymentDeleteData);

		} else {
			alert("Please Enter Pin");
		}
	};

    return (
        <div>
            <header className='table-header'>
                <span>Other Payments</span>
                <div className='pos-rel'>
                    <div className='d-flex align-center'>
                        <span className="material-icons material-symbols-outlined" onClick={filterHandler}>tune</span>
                        <span className="material-icons material-symbols-rounded" onClick={printAll}>print</span>
                    </div>

                    <FilterOtherPayments ref={resestFilterRef} getFilteredData={props.getFilteredData} filterHandler={filterHandler} allCourses={props.allCourses} />
                </div>
            </header>

            {/* <div className='select-box-container'>
                <select className='select-box' onChange={typeOfStudentListHandler}>
                    <option value='1'>All</option>
                    <option value='2'>Active</option>
                    <option value='3'>Discontinue</option>
                    <option value='4'>Pass Out</option>
                </select>
            </div> */}

            <div className={props.tableClasses}>
                <table>
                    <thead>
                        <tr>
                            {
                                props.tableCols.map((element) => {
                                    return (<th scope="col" id={element} key={element}>{element}</th>);
                                })
                            }
                        </tr>
                    </thead>
                    <tbody>
                        <AllOthersPaymentTableRow rowsData={props.rowsData} setDeleteOthersPayment={props.setDeleteOthersPayment} setVerifyPinClasses={setVerifyPinClasses} setReceiptNo={setReceiptNo} />
                    </tbody>

                </table>
                {/* <LimitRowsCount getRowLimit={props.getRowLimit} LimitRowsCountClasses={props.LimitRowsCountClasses} resestFilterRef={resestFilterRef}/> */}
            </div>
            <center className={props.msgClasses}>{props.msgData}</center> 
            
            <VerifyPinContainer verifyPinClasses={verifyPinClasses} enteredPin={[enteredPin, setEnteredPin]} proceedPinHandler={proceedPinHandler}  cancelPinHandler={cancelPinHandler} />
        </div>
    )
}

export default ViewAllOthersPaymentsTable;

