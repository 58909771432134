import React, { useEffect, useState } from 'react';
import './PrintEnqueryBill.css';
import { useLocation } from 'react-router-dom';
import Loader from './Loader';

const PrintBookAdvanceBill = (props) => {
	const location = useLocation();
	const { bookAdvanceFormData } = location.state;
	const currentDate = new Date();
	const monthArr = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
	const [loaderClasses, setLoaderClasses] = useState('loader-container d-none');
	const [courseDetails, setCourseDetails] = useState({});

	props.setTitle(`Print Instant Bill | ${parseInt(props.isHeadAdmin) ? "Admin Panel" : "User Panel"} | RedSky Educations | Educations for future`);

	useEffect(()=>{
		setLoaderClasses('loader-container');
		fetch(`${props.hostName}/redsky_api/getSingleCourseData.php?token=${props.token}&course_id=${bookAdvanceFormData.course}`)
			.then(resp => resp.json()).then(data => {
				
			if (data.code === 5) {
				  setCourseDetails(data.data);
				}else{
				  alert(data.data);
				}
				setLoaderClasses('loader-container d-none');
			  }).catch(err => console.log(err));
	},[bookAdvanceFormData, props.hostName, props.token]);


	return (
		<>
			<div className={props.isShowBill}>
				<div id="Enquey-bill-container">
					<div id="top-border">
						<h3>MONEY RECEIPT [ADV.]</h3>
					</div>
					<div className='company-details-container'>
						<h2 className="company-name">Redsky educations</h2>
						<p>CIN: U80903WB2021PTC248059</p>
						<div className='company-meta-details-container'>
							<p>Krishnanagar, WB, IN</p>
							<p>Helpdesk: 94 74 89 71 92</p>
						</div>
					</div>

					<div className="student-data advance-bill-print">
						<div className='student-short-data'>
							<div className='divide-horion d-flex-us'>
								<div className='divide-vertical'>
									<p>Name:</p>
									<p className='course-para'>Course:</p>
									<p>Fees:</p>
									<p>Purpose:</p>
								</div>
								<div>
									<p>&nbsp;{bookAdvanceFormData.name}</p>
									<p className='course-para'><small>&nbsp;{courseDetails.course_name}</small></p>
									<p>&nbsp;{bookAdvanceFormData.advance_fees}/-</p>
									<p>&nbsp;Tution/ Admission Fee/ Seat Booking</p>
								</div>
							</div>
						</div>
					</div>
					<div className='book_advance_bill__extra-details'>
						<p>Please bring this receipt to get student kit.</p>
						<p>Date: {(currentDate.getDate() < 10 ? '0'+currentDate.getDate() : currentDate.getDate())}-{monthArr[currentDate.getMonth()]}-{currentDate.getFullYear()}</p>
						<p>Your Registration Details will send to your WhatsApp number soon.</p>
					</div>
					<div className="seal-container">
						<div>
							<p>For,</p>
							<p>RedSky Educations</p>
						</div>
					</div>
					<div style={{fontSize:'0.8rem'}}>
						<p style={{marginBottom:0, marginTop:'1rem'}}>Website: https://www.redskyeducations.in</p>
						<p>Help Desk: +91 9474897192 (Available on WhatsApp)</p>
					</div>

					{/* <div className='note-container'>
					<p className='text-note'>Note:</p>
					<p>1. Please bring monthly card during payment</p>
					<p>2. If attendace card lost fine Rs. 30/-</p>
				</div> */}
					<div className='printing-info'>
						<p className='heading-text'>Printing Info :</p>
						<div>
							<p>[{(currentDate.getDate() < 10 ? '0'+currentDate.getDate() : currentDate.getDate())}-{monthArr[currentDate.getMonth()]}-{currentDate.getFullYear()}]-[{currentDate.toLocaleTimeString()}]</p>
						</div>
					</div>
				</div>
				<button class="btn btn-primary print-btn" onClick={()=>window.print()} autoFocus>Print<span class="material-icons">print</span></button>
			</div>

			<Loader loaderClasses={loaderClasses} />
		</>
	)
}


export default PrintBookAdvanceBill;
