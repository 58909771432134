import React, { useEffect, useState } from 'react';
import Calender from '../../components/student_components/Calender';
import { useParams } from 'react-router';
import Loader from '../../components/admin_components/Loader';

const AttendanceDetails = (props) => {
  const param = useParams();
  const [loaderClasses, setLoaderClasses] = useState('loader-container d-none');
  const [basicData, setBasicData] = useState([]);
  const [attnData, setAttnData] = useState([]);
  useEffect(()=>{
    setLoaderClasses('loader-container');
    fetch(`${props.hostName}/redsky_api/student/get_attn_data.php?stu_reg_no=${param.reg_no}&contact=${param.contact}`)
    .then(resp => resp.json()).then(data => {
  
      if(data.code === 5){
        setBasicData(data.data.stu_data);
        setAttnData(data.data.attn_data);
      }else{
        alert(data.data);
      }
      setLoaderClasses('loader-container d-none');
    }).catch(err => console.log(err));
  }, [param, props]);

  return (
    <>
        <div className='container px-3 py-5'>
            <h3 className='text-center mb-3'>Attendance Data</h3>
            <p className='text-center fw-medium mb-5'>Course: {basicData.course_name}</p>
              <div>
                <div className='d-sm-flex justify-content-between'>
                  <p className='fw-medium d-flex align-items-center'><span className='color-box present-box'></span>Present : { attnData.present_data ? attnData.present_data.length : 'N/A'}</p>
                  <p className='fw-medium d-flex align-items-center'><span className='color-box known-absent-box'></span>Known Absent : { attnData.known_absent_data ? attnData.known_absent_data.length : 'N/A'}</p>
                  <p className='fw-medium d-flex align-items-center'><span className='color-box absent-box'></span>Absent : { attnData.absent_data ? attnData.absent_data.length : 'N/A'}</p>
                </div>
                <div className='d-flex justify-content-center'>
                  <Calender attnData={attnData} basicData={basicData} />
                </div>
              </div>
        </div>
        <Loader loaderClasses={loaderClasses} />
    </>
  )
}

export default AttendanceDetails
