import React from 'react';
import './AllStudentTableRow.css';
import { Link } from 'react-router-dom';

const DailyRegisterPaymentTableRow = (props) => {
  let slNo = 1;
  const deletePaidFeesHandler = (receiptNo) => {
    props.setReceiptNo(receiptNo);
    props.setTypeOfDeletion('P');
    props.setVerifyPinClasses('verify-pin-container');
  };
  
  const sharePaymentData = (fName, lName, receiptNo, installmentNo, courseFees, forMonth, mode, contact) => { 
    let wp_msg = `✅ *PAYMENT SUCCESSFUL*%0a%0aHello, ${fName} ${lName}%0aThank you for making payment of your TUTION FEE.%0a%0a*PAYMENT DETAILS* %0a%0a*Receipt No*: ${receiptNo}%0a*Installment No*: ${installmentNo}%0a*Amount*: Rs. ${courseFees}/-%0a*For Month*: ${forMonth}%0a*Payment Mode*: ${mode}%0a%0aFor any query, feel free to contact us...%0a📞 9474897192%0a%0aBest Wishes,%0aTeam RedSky`;

    if(props.deviceType === 'desktop'){
       window.open(`https://web.whatsapp.com/send?phone=+91 ${contact}&text=${wp_msg}&app_absent=0`, "blank");
    }else{
       window.open(` https://wa.me/+91 ${contact}?text=${wp_msg}`, "blank");
    }
  }

  return (
    <>
      {
        props.paymentRowsData.map((element) => {
          return (
            <tr key={slNo}>
              <td data-label="Sl.">{slNo++}</td>
              <td data-label="ID">{`${element.reg_no}`}</td>
              <td data-label="Name">{element.first_name + ' ' + element.last_name}</td>
              <td data-label="Course">{element.course_name}</td>
              <td data-label="Amount">{element.paid_amount}/-</td>
              <td data-label="For Month">{element.for_month}</td>
              <td data-label="Mode">{element.payment_mode}</td>
              <td data-label="Date">{element.date}</td>
              <td data-label="Edit" className='print-hide'>
                <Link to={`../modify-paid-fees/${element.receipt_no}`} >
                    <span className="material-icons material-symbols-rounded edit-btn prevent-select" >edit</span>
                </Link>
              </td>
              <td data-label="Delete" className='print-hide'>
                <span className="material-icons material-symbols-rounded edit-btn prevent-select" onClick={()=>{deletePaidFeesHandler(element.receipt_no)}} >delete</span>
              </td>
              <td data-label="Print" className='print-hide'>
                <Link to={`../Print-paid-Fees/${element.receipt_no}`} >
                  <span className="material-icons material-symbols-rounded edit-btn prevent-select" >print</span>
                </Link>
              </td>
              <td data-label="Share"><span className="material-icons material-symbols-outlined prevent-select" onClick={()=>{
                sharePaymentData(element.first_name, element.last_name, element.receipt_no, element.installment_no, element.paid_amount, element.for_month, element.payment_mode, element.contact)
              }}>share</span></td>
            </tr>
          )
        })
      }
    </>

  )
}

export default DailyRegisterPaymentTableRow;
