import React, {useEffect, useState} from 'react';
import './AddNewStudentForm.css';
import './Loader.css';

const ModifyCourseForm = (props) => {
  const [courseName, setCourseName] = useState('');
  const [courseDuration, setCourseDuration] = useState('');
  const [durationUnit, setDurationUnit] = useState('');
  const [noOfInstallment, setNoOfInstallment] = useState('');
  const [feesPerInstallment, setFeesPerInstallment] = useState('');
  const [courseModule, setCourseModule] = useState('');
  const [isProfCourse, setIsProfCourse] = useState(false);

  useEffect(()=>{
    if(Object.keys(props.courseData).length !== 0){
      setCourseName(props.courseData.course_name);
      setCourseDuration(props.courseData.course_duration);
      setDurationUnit(props.courseData.duration_unit);
      setNoOfInstallment(props.courseData.total_installment);
      setFeesPerInstallment(props.courseData.course_fees);
      setCourseModule(props.courseData.course_module);
      setIsProfCourse( parseInt(props.courseData.is_prof_course)? true : false);
      document.querySelector(`.duration-unit-options option[value='${props.courseData.duration_unit}']`).selected = true;
    }
  }, [props.courseData]);
  
  const resetFormFields = () =>{
    setCourseName('');
    setCourseDuration('');
    setDurationUnit('');
    setNoOfInstallment('');
    setFeesPerInstallment('');
    document.querySelectorAll(".placeHolder")[0].selected = true;
  }


  const formValidate = (e) =>{
    e.preventDefault();
    if(courseName.trim() === '' || courseDuration.trim() === '' || durationUnit.trim() === ''  || noOfInstallment.trim() === '' || feesPerInstallment.trim() === '' ){
      alert("Please Fill Out All Fields!");
      return;
    }

    let formData = {
      course_id: props.courseData.course_id,
      course_name: courseName,
      course_duration: courseDuration,
      duration_unit: durationUnit,
      no_of_installment: noOfInstallment,
      fees_per_installment: feesPerInstallment,
      course_module: courseModule,
      is_prof_course: isProfCourse
    };
    
    props.setModifyCourseForm(formData);
    resetFormFields();
  };

  return (
    <>
      <section className='add-student-form-container'>
        <header>Modify Course</header>
        <form action="#" className="form" onSubmit={formValidate}>
        <div className="input-box">
              <label>Course Name<span className='color-red'>*</span></label>
              <input type="text" placeholder="Enter Course Name" value={courseName} onChange={(e)=>setCourseName(e.target.value)} required />
        </div>
        <div className="column">
            <div className="input-box">
              <label>Course Duration<span className='color-red'>*</span></label>
              <input type="number" placeholder="Enter Course Duration" value={courseDuration} onChange={(e)=>setCourseDuration(e.target.value)} required />
            </div>
            <div className="input-box">
                <label>Duration Unit<span className='color-red'>*</span></label>
                <div className="select-box">
                      <select onChange={(e)=>setDurationUnit(e.target.value)} className="duration-unit-options">
                        <option hidden className='placeHolder' value=''>Duration Unit</option>
                        <option value='Month'>Months</option>
                        <option value='Week'>Weeks</option>
                        <option value='Day'>Days</option>
                      </select>
                </div>
            </div>
          </div>

          <div className="column">
            <div className="input-box field-disabled">
              <label>No of Installment<span className='color-red'>*</span></label>
              <input type="number" placeholder="Enter No of Installment [eg: 5]" value={noOfInstallment} onChange={(e)=>setNoOfInstallment(e.target.value)}  required disabled/>
            </div>
            <div className="input-box">
                <label>Fees Per Installment<span className='color-red'>*</span></label>
                <input type="number" placeholder="Enter Fees Per Installment [eg: 250]" value={feesPerInstallment} onChange={(e)=>setFeesPerInstallment(e.target.value)}  required />
            </div>
          </div>    

          <div className="input-box">
            <label>Course Module</label>
            <textarea placeholder='Enter topics separated by semi-colon(;)  eg: MS Excel; MS Powerpoint' value={courseModule} onChange={(e)=>setCourseModule(e.target.value)}></textarea>
          </div>

          <div className="input-box d-oflex align-center">
              <label style={{margin:0}} htmlFor='prof-course-check'>Professional Course : </label>&nbsp;
              <input type="checkbox" checked={isProfCourse} onChange={()=>setIsProfCourse(!isProfCourse)} id='prof-course-check' />
          </div>     

          <button type='submit'>Modify Course</button>
        </form>
      </section>
    </>
  )
}

export default ModifyCourseForm;
