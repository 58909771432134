import React from "react";
// import { Link } from 'react-router-dom';
import "./PaidFeesTableRow.css";
import { Link } from "react-router-dom";

const DueFeesTableRow = (props) => {
  // let monthNames = ['','JAN','FEB','MAR','APR','MAY','JUN','JUL','AUG','SEP','OCT','NOV','DEC'];
  return (
    <tr key={props.element.key}>
      <td data-label="ID">{`${props.element.reg_no}`}</td>
      <td data-label="Name">{props.element.full_name}</td>
      <td data-label="Contact">
        <Link
          to={`tel:+91 ${props.element.contact}`}
          className="text-dark"
          title="Click to Call"
        >
          {props.element.contact}
        </Link>
      </td>
      <td data-label="Course">{props.element.course_name}</td>
      <td data-label="Installment No">{props.element.installment_no}</td>
      {/* <td data-label="Due Month">{monthNames[props.element.due_month]}, {props.element.due_year}</td> */}
      <td data-label="Amount">{props.element.due_amount}</td>
      <td data-label="SMS">
        <button
          className="btn btn-primary"
          onClick={function () {
            props.sendPaymentDueReminder(props.element.contact);
          }}
        >
          Send
        </button>
      </td>
    </tr>
  );
};

export default DueFeesTableRow;
