import React, {useEffect, useState} from 'react';
import Loader from '../../components/admin_components/Loader';
import './common_task.css';
import './TaskLogin.css';
import { useNavigate } from 'react-router-dom';

const TaskLogin = (props) => {
    const navigate = useNavigate();
    localStorage.removeItem("task_user_data_redsky");
    const [loaderClasses, setLoaderClasses] = useState('loader-container d-none');
    const [taskUserName, setTaskUserName] = useState('');
    const [taskUserPassword, setTaskUserPassword] = useState('');
    const [isShowPassword, setIsShowPassword] = useState('password');
    const [isShowStatus, setIsShowStatus] = useState('hidden');
    const [statusMsg, setStatusMsg] = useState('');

    useEffect(()=> {props.setTitle("Login-Task Panel | RedSky Educations | Educations for future")}, [props]);

    const resetFormField = () => {
        setTaskUserName('');
        setTaskUserPassword('');
        setIsShowPassword('password');
    };

    const validateTaskUser = (taskUserDetails) => {
        fetch(`${props.hostName}/redsky_api/task_api/validateTaskUser.php`,
          {
            method: "POST",
            body: JSON.stringify(taskUserDetails),
            headers: {
              'Content-Type': 'application/json;charset=UTF-8'
            }
          })
          .then(resp => resp.json()).then(data => {
          
            setLoaderClasses('loader-container d-none');
            if (data.code === 5) {
              let localStorageData = { task_token: data.data.task_token, task_fname: data.data.task_fname, task_lname: data.data.task_lname };
              localStorage.setItem('task_user_data_redsky', JSON.stringify(localStorageData));
              
              navigate('/task_panel/verify/view-task');
            } else {
              setStatusMsg(data.data);
              setIsShowStatus('');
              setTaskUserName('');
              setTaskUserPassword('');
              // reloadCaptcha();
              localStorage.clear();
            }
            resetFormField();
          }).catch(err => console.log(err));
      };
    
    const taskLoginFormSubmit = (e) =>{
        e.preventDefault();

        setIsShowStatus('hidden');
        setLoaderClasses('loader-container');
        if (taskUserName === '' && taskUserPassword === '') {
            alert('Please Fill All Fields!');
        } else {
            let taskUserDetails = {
            task_username: taskUserName,
            task_user_password: taskUserPassword
            };

            validateTaskUser(taskUserDetails);
        }
    }

  return (
    <>
    <div className="task-container">
      <div className="task-login-form">
        <span className="title">Task Login</span>

        <form onSubmit={taskLoginFormSubmit}>
          <div className="input-field">
            <input type="text" placeholder="Enter your Login Id" value={taskUserName} onChange={(e) => setTaskUserName(e.target.value)} required />
            <span className="material-icons material-symbols-outlined">person</span>
          </div>
          <div className="input-field">
            <input type={isShowPassword} className="password" placeholder="Enter your password" value={taskUserPassword} onChange={(e) => setTaskUserPassword(e.target.value)} required />
            <span className="material-icons material-symbols-outlined">lock</span>
            {
              (isShowPassword === 'password') ?
                <span className="material-icons material-symbols-outlined showHidePw" onClick={() => setIsShowPassword('text')}>visibility</span>
                :
                <span className="material-icons material-symbols-outlined showHidePw" onClick={() => setIsShowPassword('password')}>visibility_off</span>
            }
          </div>
          {/* <CaptchaBox captchaText={captchaText} enteredCatpcha={[enteredCatpcha, setEnteredCatpcha]} reloadCaptcha={reloadCaptcha} /> */}
          <div className="input-field button">
            <input type="submit" value="Login" />
          </div>
          <div className='status-box'>
            <p className={isShowStatus}>{statusMsg}</p>
          </div>
        </form>
      </div>
    </div>

    <Loader loaderClasses={loaderClasses} />
  </>
  )
}

export default TaskLogin;
