import React, {useState, useEffect} from 'react';
import './TaskStudentShortData.css';

const TaskStudentShortData = (props) => {
  const [stuID, setstuID] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [dateOfAdmission, setDateOfAdmission] = useState('');
  const [gender, setGender] = useState('');
  const [course, setCourse] = useState('');

  // this form is also used for MODIFYING STUDENTS.... so if we get any student data from the parent component (API), then this code will run
  useEffect(() => {
    if(props.studentData.length !== 0){
        let singleStudentData = props.studentData;
        setstuID(singleStudentData.reg_no);
        setFirstName(props.studentData.first_name);
        setLastName(props.studentData.last_name);
        setDateOfAdmission(props.studentData.date_of_admission);
        setGender(props.studentData.gender);
        setCourse(props.studentData.course_name);
    }
  }, [props.studentData]);
  

  let classes = "form-container ";

  return (
    <>
      <section className={classes}>
        <header>Student Details</header>
        <form action="#" className="student-short-details-form task-student-short-data-form" >

            <div className="input-box field-disabled">
              <label>Student Id</label>
              <input type="text" placeholder="Enter student id" value={stuID} onChange={()=>{}} disabled  />
            </div>
          <div className="column">
            <div className="input-box field-disabled">
              <label>First Name</label>
              <input type="text" placeholder="Enter first name" value={firstName} onChange={()=>{}} disabled />
            </div>
            <div className="input-box field-disabled">
              <label>Last Name</label>
              <input type="text" placeholder="Enter last name" value={lastName} onChange={()=>{}} disabled />
            </div>
          </div>

          <div className="column">
            <div className="input-box field-disabled">
              <label>Gender</label>
              <input type="text" placeholder="Enter Gender" value={gender} onChange={()=>{}} disabled />
            </div>
            <div className="input-box field-disabled">
              <label>Admission Date</label>
              <input type="date" placeholder="Enter Admission date" value={dateOfAdmission} onChange={()=>{}} disabled/>
            </div>
          </div>

          <div className="column">
            
            <div className="input-box field-disabled">
              <label>Course</label>
              <input type="text" placeholder="Enter Course name" value={course} onChange={()=>{}} disabled />
            </div>
          </div>
        </form>
      </section>
    </>
  )
}

export default TaskStudentShortData;