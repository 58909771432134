import React, { useEffect, useState } from 'react'
import Loader from '../../components/admin_components/Loader';
import TaskTable from '../../components/task_components/TaskTable';

const AllTask = (props) => {
    let tableCols = ['ID', 'Heading', "Details", 'Type', 'For Date', 'Added Date', 'Action'];
    let [rowsData, setRowsData] = useState([]);
    const [loaderClasses, setLoaderClasses] = useState('loader-container d-none');
    const [tableClasses, setTableClasses] = useState('table-container');

    useEffect(()=> {props.setTitle("All Task | Task Panel | RedSky Educations | Educations for future")}, [props]);

    const fetchAllTask = () => {

        setLoaderClasses('loader-container');
        
        fetch(`${props.hostName}/redsky_api/task_api/getAllTaskList.php?token=${props.token}`)
            .then(resp => resp.json()).then(data => {
                setLoaderClasses('loader-container d-none');
              
                if(data.code === 5){
                    setRowsData(data.data);
                    setTableClasses('table-container');
                }
                else{
                    setTableClasses('table-container d-none');
                }
                
            }).catch(err => console.log(err));
    };
    useEffect(fetchAllTask,[props.token,props.hostName]);

    const deleteStudentHandler = (taskId) => {
      setLoaderClasses('loader-container');
        fetch(`${props.hostName}/redsky_api/task_api/task_data_delete.php?token=${props.token}`,
        {
        method: "POST",
        body: JSON.stringify({task_id : taskId}),
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
        })
        .then(resp => resp.json()).then(data => {
                
            setLoaderClasses('loader-container d-none');
            alert(data.data);
            fetchAllTask();
            
        }).catch(err => console.log(err));
    }

  return (
    <>
      <TaskTable tableClasses={tableClasses} tableCols={tableCols} rowsData={rowsData} setLoaderClasses={setLoaderClasses} deleteStudentHandler={deleteStudentHandler} />

      <Loader loaderClasses={loaderClasses}/>
    </>
  )
}

export default AllTask
