import React, { useEffect, useState } from 'react';
import mcaImg from '../../assets/img/m_c_a.png';
import classplus from '../../assets/img/Classplus.png';
import logo from '../../assets/img/redsky logo.jpeg';
// import airtel from '../../assets/img/Airtel_Logo.png';
import tataTele from '../../assets/img/tata_tele.jpeg';
import ScrollToTopAndPrint from './ScrollToTopAndPrint ';
// import award from '../../assets/img/award.png';
// import mctLogo from '../../assets/img/Ministry_of_Communications_India.png';
// import isoLogo from '../../assets/img/iso_logo.png';

const QuotationPrintForm = (props) => {
  const [name, setName] = useState();
  const [course, setCourse] = useState();
  const [feesDiscount, setFeesDiscount] = useState();
  const [contact, setContact] = useState();
  const [courseStartingMonth, setCourseStartingMonth] = useState();
  const [courseEndingMonth, setCourseEndingMonth] = useState();
  const [day1, setDay1] = useState();
  const [time1, setTime1] = useState();
  const [day2, setDay2] = useState();
  const [time2, setTime2] = useState();
  const [enqueryDate, setEnqueryDate] = useState();
  const [enqueryTime, setEnqueryTime] = useState();
  const [packageDuration, setPackageDuration] = useState();
  
  useEffect(() => {
    setName(props.enqueryPrintData.name);
    setContact(props.enqueryPrintData.contact);
    setCourse(props.enqueryPrintData.course_name);
    setFeesDiscount(props.enqueryPrintData.fees_discount);
    setCourseStartingMonth(props.enqueryPrintData.course_start_month);
    setCourseEndingMonth(props.enqueryPrintData.course_end_month);
    setDay1(props.enqueryPrintData.day1);
    setTime1(props.enqueryPrintData.time1);
    setDay2(props.enqueryPrintData.day2);
    setTime2(props.enqueryPrintData.time2);
    setEnqueryDate(props.enqueryPrintData.date);
    setEnqueryTime(props.enqueryPrintData.time);
    setPackageDuration(calculateDuration(props.enqueryPrintData.course_start_month, props.enqueryPrintData.course_end_month));
  }, [props.enqueryPrintData]);


  const calculateDuration = (d1, d2) => {
    if(!d1 && !d2){
      return null;
    }
    let d1Month = parseInt(d1.slice(5,7));
    let d1Year = parseInt(d1.slice(0,4));
    let d2Month = parseInt(d2.slice(5,7));
    let d2Year = parseInt(d2.slice(0,4));
    let months;

    months = ((d2Year - d1Year) * 12);
    months -= d1Month;
    months += d2Month;

    return ((months < 0) ? 0 : months+1);
  }

  

  return (
    <div className={`quotation-print  ${props.isFormVisible ? 'd-none' : ''}`}>
        <div className="quotation-print-container">
          <div className="quotation-header">
            <img src={logo} alt="Logo" id="company-logo" />
            <div className="company-details" style={{visibility:"hidden"}}>
              <p>Education For Future</p>
              <h1>RedSky Educations PVT. LTD.</h1>
              <p>Regd. Under Ministry Of Corporate Affairs, Govt. Of India</p>
              <p>CIN: U80903WB2021PTC248059</p>
            </div>
          </div>
          <div className="quotaion-date-time">
            {/* <p>Quotation date: {(currentDate.getDate() < 10 ? '0'+currentDate.getDate() : currentDate.getDate())}-{monthArr[currentDate.getMonth()]}-{currentDate.getFullYear()}</p>
            <p>Quotation time: {currentDate.toLocaleTimeString()}</p> */}
            <p>Quotation date: {enqueryDate}</p>
            <p>Quotation time: {enqueryTime}</p>
          </div>
          <h2 id="hero-text">QUOTATION AND COURSE PACKAGE DETAILS</h2>
          <p id="letter-head">
            <span>To,</span> <br />
            {name} <br />
            The followings are the course & package details, please go through the
            details and let us know if you have any doubt; our helpline number  
            <strong> 9474897192</strong> (Except Tuesday).
          </p>

          <div className="personal-package-emi-details-container">
            <div className="personal-details-container">
              <h3 className="details-heading">Personal Details:</h3>
              <div className="personal-details-container__details">
                <div>
                  <label>Name&nbsp;</label>
                  <label>Contact&nbsp;</label>
                  <label>Course&nbsp;</label>
                  <label>Class Mode&nbsp;</label>
                </div>
                <div>
                  <label>&nbsp;{name}</label>
                  <label>&nbsp;{contact}</label>
                  <label>&nbsp;{course}</label>
                  <label>&nbsp;Online/ Offline/ Hybrid</label>
                </div>
              </div>
            </div>
            <div className="package-emi-details-container">
              <h3 className="details-heading low-spacing">PACKAGE & EMI DETAILS:</h3>
              <div className="package-emi-details-container__details">
                <div> 
                  <label>Tenure&nbsp;</label>
                  <label>Package Price&nbsp;</label>
                  <label>Nos of EMI’s&nbsp;</label>
                  <label>EMI Amount&nbsp;</label>
                  <label>Start Month&nbsp;</label>
                  <label>End Month&nbsp;</label>
                </div>
                <div>
                  <label>&nbsp;{packageDuration} Months</label>
                  <label>&nbsp;{((props.enqueryPrintData.course_fees) - (feesDiscount))*(packageDuration)}/‐ inclusive all taxes</label>
                  {/* <label>&nbsp;{courseDetails.total_installment}</label> */}
                  <label>&nbsp;{packageDuration}</label>
                  <label>&nbsp;{((props.enqueryPrintData.course_fees) - (feesDiscount))}/-</label>
                  <label>&nbsp;{courseStartingMonth}</label>
                  <label>&nbsp;{courseEndingMonth}</label>
                </div>
              </div>
            </div>
          </div>

          <div className="class-dates-container">
            <h3 className="details-heading low-spacing">CLASS SCHEDULE:</h3>
            <div className="class-dates-container__details">
              <table>
                <thead>
                  <tr>
                    <td>Day 1</td>
                    <td>Day 2</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                    {day1} <br /> {time1}
                    </td>
                    <td>
                    {day2} <br /> {time2}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="document-details-container" >
            <h3 className="details-heading">
              ADMISSION NOTES:
            </h3>
            <div className="document-details-container__details">
              <ol>
                <li>Photo Of Candidate Will Be Taken For ID Card Purpose Only</li>
                <li>Due To Online Registration No Paper Document Is Needed</li>
                <li>1st EMI As Advance.</li>
                <li>For Any Query Please Call Helpdesk +91 9474897192</li>
              </ol>
              
              {/* <img src={award} id="award-img" alt="award" /> */}
              {/* <p id='award-img-details'>Awarded by Class plus <br/><b>STUDENTS' CHOICE AWARD 2023</b></p> */}
            </div>
          </div>
          <small><b>N.B:</b> Class dates are valid till no one gets admitted before your admission</small>

          <div className="accreditation-container"  >
            <h3 className="details-heading">ACCREDITATION:</h3>
            <div className="accreditation-container__details">
              {/* 1 */}
              <div className='accreditation-container__details__div'>
                <small>Institute Registered Under</small>
                <img src={mcaImg} alt="MCA" id="mca-img" />
              </div>
              {/* 2 */}
              <div className='accreditation-container__details__div'>
                <small>DLT Registered Under</small>
                {/* <img src={mctLogo} alt="MCT" id="mct-img"  /> */}
                <img src={tataTele} alt="tata_tele" style={{height:"50px", marginTop:"10px"}}  />
              </div>
              {/* <img src={isoLogo} alt="iso_logo" id="iso-img" /> */}
              {/* <img src={classplus} alt="classplus" id="classplus-img" /> */}
              {/* 3 */}
              <div className='accreditation-container__details__div'>
                <small>Trusted Partner</small>
                <div className='d-flex' style={{gridGap:"0.7rem", justifyContent:"flex-end"}}>
                  <img src={classplus} alt="classplus" id="classplus-img" />
                  {/* <img src={airtel} alt="airtel" id="airtel-img" /> */}
                </div>
              </div>
              {/* <p id="awarded-by-classplus-text">
                <small>Awarded by Class plus</small> <br />
                <b>
                  STUDENTS' CHOICE AWARD
                  <br />
                  2023
                </b>
              </p> */}
            </div>
          </div>
        </div>
      
        {/* <button className="btn btn-primary print-btn"  autoFocus onClick={()=>window.print()}>Print&nbsp;<span className="material-icons">print</span></button> */}
        <ScrollToTopAndPrint btnText="Print" icon="print" />
      </div>
  )
}

export default QuotationPrintForm
