import React from 'react';
import { Link } from 'react-router-dom';
import './AllStudentTableRow.css';

const AllCoursesTableRow = (props) => {

  return (
    <>
    {
      props.allCourses.map((element) => {
      
        return (
          
          <tr key={element.course_id}>
          <td data-label="ID">{element.course_id}</td>
          <td data-label="Name">{element.course_name}</td>
          <td data-label="Duration">{element.course_duration + ' ' + element.duration_unit}(s)</td>
          <td data-label="Installment">{element.total_no_of_installment}</td>
          <td data-label="Fees">{element.course_fees}/-</td>
          <td data-label="Enrolled">{element.total_student}</td>

          <td data-label="Edit">
            <Link to={`../Modify-Course/${element.course_id}`} >
              <span className="material-icons material-symbols-rounded edit-btn">edit</span>
            </Link>
          </td>

          <td data-label="Delete"><span className="material-icons material-symbols-rounded del-btn" onClick={()=>props.deleteCourseHandeler(element.course_id)}>delete</span></td>
        </tr>
        )
      })
    }
    </>

  )
}

export default AllCoursesTableRow;
