import React, {useState} from 'react';

const UnpaidFeesTableRow = (props) => {
    const [currentDate, setCurrentDate] = useState(new Date().toJSON().slice(0, 10));
    let unpaidFeesRowData = props.element;
    const [courseFees , setCourseFees] = useState(unpaidFeesRowData.course_fees);
    let paymentData = {
      installment_no: unpaidFeesRowData.installment_no, 
      course_fees: courseFees, 
      payment_date: currentDate, 
      for_month: unpaidFeesRowData.for_month, 
      for_year: unpaidFeesRowData.for_year
    };
    
  return (
      <tr>
          <td data-label="No" /*className='fit'*/>{unpaidFeesRowData.installment_no}</td>
          <td data-label="Due Month">{unpaidFeesRowData.for_month_year_text}</td>
          <td data-label="Amount">
            <div className="input-group">
                <span className="input-group-text" id="basic-addon1">₹</span>
                <input type="number" className="form-control amount_input" placeholder="Amount" value={courseFees}  onChange={(e)=>setCourseFees(e.target.value)} ></input>
            </div>
            
            </td>
          <td data-label="Date"><input type="date" className="form-control py-2" defaultValue={currentDate} onChange={(e) => setCurrentDate(e.target.value)}/></td>
          <td data-label="Action">
            <div className='gap-3 d-flex'>
              {
                props.type === 'def' ?
                  <button  className='btn btn-danger' onClick={()=>props.saveDefPayment(
                  {
                    payment_mode: 'N/A',
                    ...paymentData
                  }  
                  )}>Defferal</button>

                  :
                  <>
                    <button  className='btn btn-primary' onClick={()=>props.saveNewPaymentDetails(
                    {
                      payment_mode: 'Online',
                      ...paymentData
                    }  
                    )}>Online</button>
                    <button className='btn btn-success' onClick={()=>props.saveNewPaymentDetails(
                    {
                      payment_mode: 'Cash',
                      ...paymentData
                    }  
                    )}>Cash</button>
                  </>

              }
              

            </div>
          </td>
      </tr>
  );
}

export default UnpaidFeesTableRow;
