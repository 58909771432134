import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import EnqueryModifyForm from '../../components/admin_components/EnqueryModifyForm';
import Loader from '../../components/admin_components/Loader';

const EnqueryModify = (props) => {
  const param = useParams();
  const navigate = useNavigate();
  const [enqueryData, setEnqueryData] = useState({});

  const [loaderClasses, setLoaderClasses] = useState('loader-container d-none');

  useEffect(()=> {props.setTitle(`Modify Enquiry | ${parseInt(props.isHeadAdmin) ? "Admin Panel" : "User Panel"} | RedSky Educations | Educations for future`)}, [props]);

  useEffect(() => {
    setLoaderClasses('loader-container');
  
    fetch(`${props.hostName}/redsky_api/get_single_enquery.php?token=${props.token}&enquery_id=${param.enquery_id}`)
        .then(resp => resp.json()).then(data => {
    
          if(data.code === 5){
            setEnqueryData(data.data);
          }
          else{
            alert(data.data);
            navigate('../all-enquery-list');
          }
          setLoaderClasses('loader-container d-none');
            
        }).catch(err => console.log(err));
  }, [props.hostName, props.token, param.enquery_id, navigate]);
  /*const resetFormFields = () => {
    setName('');
    setCourse('');
    setFeesDiscount(0);
    setSchool('');
    setContact('');
    setDay1('');
    setTime1('');
    setDay2('');
    setTime2('');
    setAdvanceFees('');
    // document.querySelectorAll(".placeHolder")[0].selected = true;
    // document.querySelectorAll(".placeHolder")[1].selected = true;
    // document.querySelectorAll(".placeHolder")[2].selected = true;
    // document.querySelectorAll(".placeHolder")[3].selected = true;
    // document.querySelectorAll(".placeHolder")[4].selected = true;
  };*/

  const enqueryModify = (enqueryFormDataObj) => {
    
    setLoaderClasses('loader-container');
      
		fetch(`${props.hostName}/redsky_api/modifyEnquery.php?token=${props.token}`,
		{
			method: "POST",
			body: JSON.stringify(enqueryFormDataObj),
			headers: {
			'Content-Type': 'application/json;charset=UTF-8'
			}
		})
		.then(resp => resp.json()).then(data => {
			if(data.code === 5){
				navigate(`../print-enquery/${enqueryFormDataObj.enquery_id}`);
			}else{
				alert(data.data);
			}
			setLoaderClasses('loader-container d-none');
		}).catch(err => console.log(err));
  };


  return (
    <>
      <EnqueryModifyForm enqueryData={enqueryData} enqueryModify={enqueryModify} allCourses={props.allCourses} />
      <Loader loaderClasses={loaderClasses}/>
    </>
  )
}

export default EnqueryModify;