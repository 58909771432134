import React, {useState} from 'react';
// import CaptchaBox from './CaptchaBox';

const VerifyPinContainer = (props) => {
    const [enteredPin, setEnteredPin] = props.enteredPin;
    const [isShowPassword, setIsShowPassword] = useState('password');

    const verifyPinFormSubmitHandler = (e) => {
        e.preventDefault();
        props.proceedPinHandler();
    }

    return (
        <div className={props.verifyPinClasses}>
            <form className='verify-pin-form' onSubmit={verifyPinFormSubmitHandler}>
                <header>Verify Pin</header>
                <div className="input-group">
                    <label>Enter Password</label>
                    <div className='input-box'>
                        <input type={isShowPassword} placeholder="Enter Password" value={enteredPin} onChange={(e) => setEnteredPin(e.target.value)} required />
                        {
                            (isShowPassword === 'password') ?
                                <span className="material-icons material-symbols-outlined showHidePw" onClick={() => setIsShowPassword('text')}>visibility</span>
                                :
                                <span className="material-icons material-symbols-outlined showHidePw" onClick={() => setIsShowPassword('password')}>visibility_off</span>
                        }
                    </div>
                </div>
                {/* <CaptchaBox captchaText={props.captchaText} enteredCatpcha={props.enteredCatpcha} reloadCaptcha={props.reloadCaptcha} /> */}
                <div className='verify-btn-container'>
                    <input type='submit' value='Verify' className='verify-btn verify-proceed-btn' />
                    <button className='verify-btn verify-cancel-btn' onClick={props.cancelPinHandler}>Cancel</button>
                </div>
            </form>
        </div>
    )
}

export default VerifyPinContainer
