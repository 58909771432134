import React from 'react';
import './Loader.css';
import './MarksUploadForm.css';
import MarksModifyFormRow from './MarksModifyFormRow';
import ScrollToTopAndPrint from './ScrollToTopAndPrint ';

const MarksModifyForm = (props) => {

  return (
    <>
      <section className='bg-white p-5 mx-4 rounded shadow-sm' id='marks-upload-table-container'>
        <h5 className='mb-2 text-center'>{props.yearOfExam} <br/>Document Acknowledgement Sheet</h5>
        <p className='text-center'>{props.courseName} | {props.yearOfExam}</p>
          <div className='overflowY-scroll' id='marks-upload-table-sub-container'>
              <table id='marks-upload-table'>
                <thead>
                  <tr>
                    <th rowSpan={2} className='printable'>Id</th>
                    <th rowSpan={2} className='printable'>Name</th>
                    <th rowSpan={2}>Exam Date</th>
                    <th colSpan={2}>Theory</th>
                    <th colSpan={2}>Practical</th>
                    <th colSpan={2}>Viva</th>
                    <th rowSpan={2}>Rank</th>
                    <th colSpan={2}>Action</th>

                    <th className='printable d-none'>Theory</th>
                    <th className='printable d-none'>Practical</th>
                    <th className='printable d-none'>Viva</th>
                    <th className='printable d-none'>Total</th>
                    <th className='printable d-none' style={{minWidth:'120px'}}>Remarks</th>
                  </tr>
                  <tr>
                    <th>Obtained</th>
                    <th>Total</th>
                    <th>Obtained</th>
                    <th>Total</th>
                    <th>Obtained</th>
                    <th>Total</th>
                    <th>Edit</th>
                    <th>Del</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    props.marksData.map((elem) => {
                      return(
                        <MarksModifyFormRow key={elem.marks_id} elem={elem} modifyMarksData={props.modifyMarksData} setVerifyPinClasses={props.setVerifyPinClasses} setDeleteMarksId={props.setDeleteMarksId} />
                      );
                    })
                  }                 
                </tbody>
                
              </table>
            </div>

            {/* <div className='d-flex justify-content-end '>
              <button className='btn print-btn btn-success position-static mt-4' onClick={()=>window.print()}>Print<span className='material-icons'>print</span></button>
            </div> */}
            {/* <button class="btn btn-primary print-btn" onClick={printList}>Print&nbsp;<span class="material-icons">print</span></button> */}
            <ScrollToTopAndPrint btnText="Print" icon="print" />
      </section>
    </>
  )
}

export default MarksModifyForm;