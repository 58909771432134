import React, { useEffect, useState } from 'react';
import AddNewCourseForm from '../../components/admin_components/AddNewCourseForm';
import Loader from '../../components/admin_components/Loader';
import { useNavigate } from 'react-router-dom';


const AddNewCourse = (props) => {
    const navigate = useNavigate('');
    
    useEffect(()=>{
      if(props.isHeadAdmin === '0'){
        navigate('../all-students');
      }
    });
    const [loaderClasses, setLoaderClasses] = useState('loader-container d-none');
    
    useEffect(()=> {props.setTitle("Add Course | Admin Panel | RedSky Educations | Educations for future")}, [props]);

    const setAddCourseForm = (courseData) => {
        setLoaderClasses('loader-container');
        fetch(`${props.hostName}/redsky_api/addNewCourse.php?token=${props.token}`,
          {
            method: "POST",
            body: JSON.stringify(courseData),
            headers: {
              'Content-Type': 'application/json;charset=UTF-8'
            }
          })
          .then(resp => resp.json()).then(data => {
            alert(data.data);
            setLoaderClasses('loader-container d-none');
          }).catch(err => console.log(err));
    };

  return (
    <>
        <AddNewCourseForm setLoaderClasses={setLoaderClasses} setAddCourseForm={setAddCourseForm} />
        <Loader loaderClasses={loaderClasses}/>
    </>
  );
};

export default AddNewCourse;
