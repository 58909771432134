import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../admin_components/AllStudentTableRow.css";

const TaskTableRow = (props) => {
  const [isActionULShow, setIsActionULShow] = useState(false);

  const deleteStudentHandler = (taskId) => {
    if (
      window.confirm(
        "Want to Delete? All data wil be removed associated with this task"
      )
    )
      props.deleteStudentHandler(taskId);
    else {
      setIsActionULShow(false);
    }
  };

  return (
    <>
      <tr>
        <td data-label="ID">{props.element.task_id}</td>
        <td data-label="Heading">{props.element.task_heading}</td>
        <td data-label="Details">{props.element.task_details}</td>
        <td data-label="Type">{props.element.task_type}</td>
        <td data-label="For Date">{props.element.task_for_date}</td>
        <td data-label="Added Date">{props.element.task_add_date}</td>

        <td data-label="ACTION" className="action-more-btn">
          <span
            className="material-icons material-symbols-rounded prevent-select"
            onClick={() => setIsActionULShow(!isActionULShow)}
          >
            more_vert
          </span>
          <ul className={`action-more-ul ${isActionULShow ? "" : "d-none"} `}>
            <li data-label="Edit">
              <Link to={`../Modify-task/${props.element.task_id}`}>
                <span className="material-icons material-symbols-rounded edit-btn">
                  edit
                </span>
              </Link>
            </li>
            <li data-label="Delete">
              <span
                className="material-icons material-symbols-rounded del-btn"
                onClick={() => deleteStudentHandler(props.element.task_id)}
              >
                delete
              </span>
            </li>
          </ul>
        </td>
      </tr>
    </>
  );
};

export default TaskTableRow;
