import React, { useEffect, useState } from 'react';
import Loader from '../../components/admin_components/Loader';
import AssignedClassListTable from '../../components/admin_components/AssignedClassListTable';

const AssignedClassList = (props) => {
    let tableCols = ['Sl.', 'Mentor', 'Course', 'Topic', 'Date', 'Done', 'Share'];
    let [rowsData, setRowsData] = useState([]);
    const [loaderClasses, setLoaderClasses] = useState('loader-container d-none');
    const [tableClasses, setTableClasses] = useState('table-container');
    const [msgClasses, setMsgClasses] = useState('msg d-none');
    const [msgData, setMsgData] = useState('');
    const [totalClassDone, setTotalClassDone] = useState(0);
    const [isAllDataShow, setIsAllDataShow] = useState(false);
  
    useEffect(()=> {props.setTitle(`Assigned Class List | ${parseInt(props.isHeadAdmin) ? "Admin Panel" : "User Panel"} | RedSky Educations | Educations for future`)}, [props]);
    const fetchEnqueryDetails = () => {
  
      setLoaderClasses('loader-container');
      setRowsData([]);
      fetch(`${props.hostName}/redsky_api/get_all_assigned_class_list.php?token=${props.token}&is_all_data_show=${isAllDataShow}`)
          .then(resp => resp.json()).then(data => {
              setLoaderClasses('loader-container d-none');
              if(data.code === 5){
                  setRowsData(data.data.records);
                  setTotalClassDone(data.data.total_class_done);
                  setTableClasses('table-container');
                  setMsgClasses('msg d-none');
              }
              else{
                  setTableClasses('table-container d-none');
                  setMsgData(data.data);
                  setMsgClasses('msg');
              }
              
          }).catch(err => console.log(err));
  };
  
    useEffect(fetchEnqueryDetails, [props.hostName, props.token, isAllDataShow]);

    const updateIsDone = (id, status) => {
      setLoaderClasses('loader-container');
      fetch(`${props.hostName}/redsky_api/modify_class_details_is_done.php?token=${props.token}&id=${id}&is_done_status=${status}`)
      .then(resp => resp.json()).then(data => {
          
          if (data.code === 5) {
            if(status){
              setTotalClassDone(totalClassDone+1);
            }else{
              setTotalClassDone(totalClassDone-1);
            }
          }else{
            alert(data.data);
          }
          setLoaderClasses('loader-container d-none');
      }).catch(err => console.log(err));
  }
  
    return (
      <>
        <AssignedClassListTable tableClasses={tableClasses} tableCols={tableCols} rowsData={rowsData} token={props.token}  msgClasses={msgClasses} msgData={msgData} setLoaderClasses={setLoaderClasses} updateIsDone={updateIsDone} totalClassDone={totalClassDone} setIsAllDataShow={setIsAllDataShow} />
        <Loader loaderClasses={loaderClasses}  />
      </>
    )
}

export default AssignedClassList;
