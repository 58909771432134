import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import './SideNav.css';

const SideNav = (props) => {
    const isExtraContentShowClass = (props.isHeadAdmin === '0') ? 'd-none' : '';
    const sideBar = useRef('');
    const toggleLinks = (index) => {
        let allSideBarElem = document.querySelectorAll('.open-able-links');
        allSideBarElem.forEach((elem, indx)=>{

            let nextIcon = elem.querySelector('.next-icon');
            let groupBtn = elem.querySelector('.group');
            let nestedLinks = elem.querySelector('.nested-links');

            if(index === indx){
                elem.classList.toggle('show');
                nextIcon.classList.toggle('rotate');
                groupBtn.classList.toggle('active');
                nestedLinks.classList.toggle('active');
            }else{
                elem.classList.remove('show');
                nextIcon.classList.remove('rotate');
                groupBtn.classList.remove('active');
                nestedLinks.classList.remove('active');
            }
        });
    }
    const closeSideMenu = () => {
        sideBar.current.classList.add("active");
    }
    const sideNaveMenuItemClickhandler = () => {
        closeSideMenu();
    }

    return (
        <div className="sidebar active" ref={sideBar}>
            <div>
                <button className='close-menu-btn' onClick={closeSideMenu}><span className="material-icons material-symbols-outlined">close</span></button>
                <div className="logo-details">
                    <i className='bx bxl-c-plus-plus'></i>
                    <span className="logo_name">RedSky</span>
                </div>
                <ul className="nav-links-us">
                    <li className={isExtraContentShowClass}>
                        <Link to="./dashboard" onClick={sideNaveMenuItemClickhandler} >
                            <span className="material-icons material-symbols-outlined">dashboard</span>
                            <span className="links_name">Dashboard</span>
                        </Link>
                    </li>
                    <li className={(props.isHeadAdmin === '1') ? 'd-none' : ''}>
                        <Link to="./user-dashboard" onClick={sideNaveMenuItemClickhandler} >
                            <span className="material-icons material-symbols-outlined">dashboard</span>
                            <span className="links_name">Dashboard</span>
                        </Link>
                    </li>
                    <li className='open-able-links'>
                        <span className='group' onClick={() => { toggleLinks(0) }}>
                            <span className="material-icons material-symbols-outlined">person</span>
                            <span className="links_name">Student</span>
                            <span className="material-icons material-symbols-outlined next-icon">navigate_next</span>
                        </span>
                        <ul className='nested-links'>
                            <li>
                                <Link to="./add-student" onClick={sideNaveMenuItemClickhandler}>
                                    <span className="material-icons material-symbols-outlined">person_add</span>
                                    <span className="links_name">Add Student</span>
                                </Link>
                            </li>
                            
                            <li>
                                <Link to="./all-students" onClick={sideNaveMenuItemClickhandler}>
                                    <span className="material-icons material-symbols-outlined">group</span>
                                    <span className="links_name">All Students</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="./certificate-download" onClick={sideNaveMenuItemClickhandler}>
                                    <span className="material-icons material-symbols-outlined">badge</span>
                                    <span className="links_name">Certificate</span>
                                </Link>
                            </li>
                        </ul>
                    </li>

                    <li className={`open-able-links ${isExtraContentShowClass}`}>
                        <span className='group' onClick={() => { toggleLinks(1) }}>
                            <span className="material-icons material-symbols-outlined">style</span>
                            <span className="links_name">Courses</span>
                            <span className="material-icons material-symbols-outlined next-icon">navigate_next</span>
                        </span>
                        <ul className='nested-links '>
                            <li>
                                <Link to="./add-course" onClick={sideNaveMenuItemClickhandler}>
                                    <span className="material-icons material-symbols-outlined">add</span>
                                    <span className="links_name">Add Course</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="./all-courses" onClick={sideNaveMenuItemClickhandler}>
                                    <span className="material-icons material-symbols-outlined">list_alt</span>
                                    <span className="links_name">All Courses</span>
                                </Link>
                            </li>
                            {/* <li>
                                <Link to="./upgrade-course" onClick={sideNaveMenuItemClickhandler}>
                                    <span className="material-icons material-symbols-outlined">keyboard_double_arrow_up</span>
                                    <span className="links_name">Upgrade</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="./renewal" onClick={sideNaveMenuItemClickhandler}>
                                    <span className="material-icons material-symbols-outlined">auto_mode</span>
                                    <span className="links_name">Renewal</span>
                                </Link>
                            </li> */}

                        </ul>
                    </li>
                    <li className={`open-able-links `}>
                        <span className='group' onClick={() => { toggleLinks(2) }}>
                            <span className="material-icons material-symbols-outlined">currency_rupee</span>
                            <span className="links_name">Payment</span>
                            <span className="material-icons material-symbols-outlined next-icon">navigate_next</span>
                        </span>
                        <ul className='nested-links '>
                            <li>
                                <Link to="./payment-student" onClick={sideNaveMenuItemClickhandler}>
                                    <span className="material-icons material-symbols-outlined">attach_money</span>
                                    <span className="links_name">New Payment</span>
                                </Link>
                            </li>
                            <li className={isExtraContentShowClass}>
                                <Link to="./deferral-payment" onClick={sideNaveMenuItemClickhandler}>
                                    <span className="material-icons material-symbols-outlined">do_not_disturb_off</span>
                                    <span className="links_name">New Deferral</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="./paid-fees" onClick={sideNaveMenuItemClickhandler}>
                                    <span className="material-icons material-symbols-outlined">drive_file_rename_outline</span>
                                    <span className="links_name">Paid Fees</span>
                                </Link>
                            </li>
                            <li className={isExtraContentShowClass}>
                                <Link to="./due-fees-list" onClick={sideNaveMenuItemClickhandler}>
                                    <span className="material-icons material-symbols-outlined">wallet</span>
                                    <span className="links_name">Due Fees List</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="./other-payment" onClick={sideNaveMenuItemClickhandler}>
                                    <span className="material-icons material-symbols-outlined">receipt_long</span>
                                    <span className="links_name">Others</span>
                                </Link>
                            </li>


                        </ul>
                    </li>

                    <li className='open-able-links'>
                        <span className='group' onClick={() => { toggleLinks(3) }}>
                            <span className="material-icons material-symbols-outlined">calendar_month</span>
                            <span className="links_name">Attendance</span>
                            <span className="material-icons material-symbols-outlined next-icon">navigate_next</span>
                        </span>
                        <ul className='nested-links '>
                            <li>
                                <Link to="./record-attendance" target='blank' onClick={sideNaveMenuItemClickhandler}>
                                    <span className="material-icons material-symbols-outlined">edit_calendar</span>
                                    <span className="links_name">Record</span>
                                </Link>
                            </li>
                        
                            <li>
                                <Link to="./view-attendance" onClick={sideNaveMenuItemClickhandler}>
                                    <span className="material-icons material-symbols-outlined">view_cozy</span>
                                    <span className="links_name">View Attendance</span>
                                </Link>
                            </li>
                            {/* <li>
                                <Link to="./filter-attendance" onClick={sideNaveMenuItemClickhandler}>
                                    <span className="material-icons material-symbols-outlined">style</span>
                                    <span className="links_name">Attendance Filter</span>
                                </Link>
                            </li>                             */}
                            {/* <li>
                                <Link to="./rfid-list" onClick={sideNaveMenuItemClickhandler}>
                                    <span className="material-icons material-symbols-outlined">format_list_bulleted</span>
                                    <span className="links_name">RFID List</span>
                                </Link>
                            </li> */}
                            {/* <li>
                                <Link to="./add-rfid" onClick={sideNaveMenuItemClickhandler}>
                                    <span className="material-icons material-symbols-outlined">qr_code</span>
                                    <span className="links_name">Add RFID</span>
                                </Link>
                            </li> */}
                            <li>
                                <Link to="./view-attendance-manual" onClick={sideNaveMenuItemClickhandler}>
                                    <span className="material-icons material-symbols-outlined">stars</span>
                                    <span className="links_name">Manual</span>
                                </Link>
                            </li>

                        </ul>
                    </li>
                    <li className='open-able-links'>
                        <span className='group' onClick={() => { toggleLinks(4) }}>
                            <span className="material-icons material-symbols-outlined">school</span>
                            <span className="links_name">Class</span>
                            <span className="material-icons material-symbols-outlined next-icon">navigate_next</span>
                        </span>
                        <ul className='nested-links '>
                            <li>
                                <Link to="./assign-class" onClick={sideNaveMenuItemClickhandler}>
                                    <span className="material-icons material-symbols-outlined">add</span>
                                    <span className="links_name">Assign Class</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="./assigned-class-list" onClick={sideNaveMenuItemClickhandler}>
                                    <span className="material-icons material-symbols-outlined">list</span>
                                    <span className="links_name">Class List</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="./class-graph" onClick={sideNaveMenuItemClickhandler}>
                                    <span className="material-icons material-symbols-outlined">area_chart</span>
                                    <span className="links_name">Class Graph</span>
                                </Link>
                            </li>
                        </ul>
                    </li>
                    <li className={`open-able-links`}>
                        <span className='group' onClick={() => { toggleLinks(5) }}>
                            <span className="material-icons material-symbols-outlined">token</span>
                            <span className="links_name">Marks</span>
                            <span className="material-icons material-symbols-outlined next-icon">navigate_next</span>
                        </span>
                        <ul className='nested-links'>
                            <li>
                                <Link to="./upload-marks" onClick={sideNaveMenuItemClickhandler}>
                                    <span className="material-icons material-symbols-outlined">add</span>
                                    <span className="links_name">Upload Marks</span>
                                </Link>
                            </li>
                            {/* <li>
                                <Link to="./view-marks-single" onClick={sideNaveMenuItemClickhandler}>
                                    <span className="material-icons material-symbols-outlined">grid_view</span>
                                    <span className="links_name">View Marks</span>
                                </Link>
                            </li> */}
                            <li>
                                <Link to="./view-marks" onClick={sideNaveMenuItemClickhandler}>
                                    <span className="material-icons material-symbols-outlined">grid_view</span>
                                    <span className="links_name">View Marks</span>
                                </Link>
                            </li>
                        </ul>
                    </li>
                    {/* <li className={`open-able-links `}>
                        <span className='group' onClick={() => { toggleLinks(6) }}>
                            <span className="material-icons material-symbols-outlined">draw</span>
                            <span className="links_name">Exam</span>
                            <span className="material-icons material-symbols-outlined next-icon">navigate_next</span>
                        </span>
                        <ul className='nested-links '>
                            <li>
                                <Link to="./add-exam-question" onClick={sideNaveMenuItemClickhandler}>
                                    <span className="material-icons material-symbols-outlined">add</span>
                                    <span className="links_name">Add Question</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="./set-exam" onClick={sideNaveMenuItemClickhandler}>
                                    <span className="material-icons material-symbols-outlined">double_arrow</span>
                                    <span className="links_name">Set Exam</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="./exam-list" onClick={sideNaveMenuItemClickhandler}>
                                    <span className="material-icons material-symbols-outlined">list_alt</span>
                                    <span className="links_name">Exam List</span>
                                </Link>
                            </li>

                        </ul>
                    </li> */}
                    <li className='open-able-links'>
                        <span className='group' onClick={() => { toggleLinks(6) }}>
                            <span className="material-icons material-symbols-outlined">dynamic_feed</span>
                            <span className="links_name">Extra</span>
                            <span className="material-icons material-symbols-outlined next-icon">navigate_next</span>
                        </span>
                        <ul className='nested-links '>
                            <li>
                                <Link to="./enquery" onClick={sideNaveMenuItemClickhandler}>
                                    <span className="material-icons material-symbols-outlined">question_mark</span>
                                    <span className="links_name">Enquiry</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="./enquery/all-enquery-list" onClick={sideNaveMenuItemClickhandler}>
                                    <span className="material-icons material-symbols-outlined">apps</span>
                                    <span className="links_name">All Enquiry</span>
                                </Link>
                            </li>
                        </ul>
                    </li>

                    <li className={isExtraContentShowClass}>
                        <Link to="./daily-register" onClick={sideNaveMenuItemClickhandler}>
                            <span className="material-icons material-symbols-outlined">query_stats</span>
                            <span className="links_name">Daily Register</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="./payment-student" onClick={sideNaveMenuItemClickhandler}>
                            <span className="material-icons material-symbols-outlined">attach_money</span>
                            <span className="links_name">New Payment</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="./instant-bill" onClick={sideNaveMenuItemClickhandler}>
                            <span className="material-icons material-symbols-outlined">receipt_long</span>
                            <span className="links_name">Instant Bill</span>
                        </Link>
                    </li>


                </ul>
            </div>
            <ul className="nav-links-us">
                <li className="log_out">
                    <Link to="/admin_panel/login" onClick={sideNaveMenuItemClickhandler}>
                        <span className="material-icons material-symbols-outlined">logout</span>
                        <span className="links_name">Log out</span>
                    </Link>
                </li>
            </ul>

        </div>
    );
};

export default SideNav;
