import React, { useEffect, useState } from "react";
import CertificateForMultiDownload from "./CertificateForMultiDownload";
import Loader from "../../components/admin_components/Loader";
import ScrollToTopAndPrint from "../../components/admin_components/ScrollToTopAndPrint ";
// import jsPDF from 'jspdf';
// import html2canvas from 'html2canvas';

const CertificateMulti = (props) => {
  const [downloadType, setDownloadType] = useState("by_student");
  const [courseId, setCourseId] = useState("");
  const [yearOfExam, setYearOfExam] = useState("");
  const [allCourses, setAllCourses] = useState([]);
  const [loaderClasses, setLoaderClasses] = useState("loader-container d-none");
  const [stuIdArr, setStuIdArr] = useState([]);
  const [stuRegNo, setStuRegNo] = useState("");

  const fetchAllCourses = () => {
    setLoaderClasses("loader-container");
    fetch(
      `${props.hostName}/redsky_api/getAllCoursesWithOutProfCourse.php?token=${props.token}`
    )
      .then((resp) => resp.json())
      .then((data) => {
        if (data.code === 5) {
          setAllCourses(data.data);
        } else {
          alert(data.data);
        }
        setLoaderClasses("loader-container d-none");
      })
      .catch((err) => console.log(err));
  };
  useEffect(fetchAllCourses, [props.token, props.hostName]);

  const fetchCertificateData = (path) => {
    setLoaderClasses("loader-container");
    fetch(path)
      .then((resp) => resp.json())
      .then((data) => {
        if (data.code === 5) {
          setStuIdArr(data.data);
        } else {
          alert(data.data);
        }
        setLoaderClasses("loader-container d-none");
      })
      .catch((err) => console.log(err));
  };

  const getCertificateDataByStudentId = (e) => {
    e.preventDefault();
    if (stuRegNo.trim() === "") {
      alert("Please fill out all fields");
      return;
    }
    fetchCertificateData(
      `${props.hostName}/redsky_api/getStudentIdFromMarksTable.php?token=${props.token}&stu_reg_no=${stuRegNo}`
    );
  };

  const getCertificateDataByCourse = (e) => {
    e.preventDefault();
    if (courseId.trim() === "" || yearOfExam.trim() === "") {
      alert("Please fill out all fields");
      return;
    }
    fetchCertificateData(
      `${props.hostName}/redsky_api/getStudentIdFromMarksTable.php?token=${props.token}&course_id=${courseId}&year_of_exam=${yearOfExam}`
    );
  };

  return (
    <>
      <div>
        <section className="search-container">
          <header>Download Certificate</header>
          <div className="input-box">
            <label>
              Download Type:<span className="color-red">*</span>
            </label>
            <div className="select-box">
              <select
                onChange={(e) => {
                  setDownloadType(e.target.value);
                  setStuRegNo("");
                  setYearOfExam("");
                }}
                className="course-options py-1"
                required
                defaultValue="by_student"
              >
                <option hidden className="placeHolder">
                  --Select Download Type--
                </option>
                <option value="by_course">By Course</option>
                <option value="by_student">By Student</option>
              </select>
            </div>
          </div>

          {downloadType === "by_student" ? (
            //by student form
            <form
              className="form"
              onSubmit={getCertificateDataByStudentId}
              style={{ margin: "1rem 0" }}
            >
              <div className="column">
                <div className="input-box mt-0">
                  <label>
                    Student ID:<span className="color-red">*</span>
                  </label>
                  <input
                    type="text"
                    value={stuRegNo}
                    onChange={(e) => {
                      setStuRegNo(e.target.value);
                      setStuIdArr([]);
                    }}
                    placeholder="Enter Student ID"
                    required
                  />
                </div>
              </div>
              <button type="submit">Generate</button>
            </form>
          ) : (
            //by course form
            <form
              className="form"
              onSubmit={getCertificateDataByCourse}
              style={{ margin: "1rem 0" }}
            >
              <div className="column">
                <div className="input-box mt-0">
                  <label>
                    Exam Year:<span className="color-red">*</span>
                  </label>
                  <input
                    type="text"
                    value={yearOfExam}
                    onChange={(e) => {
                      setYearOfExam(e.target.value);
                      setStuIdArr([]);
                    }}
                    placeholder="Enter Exam Year (eg. 2023)"
                    required
                  />
                </div>

                <div className="input-box mt-0">
                  <label>
                    Select Course:<span className="color-red">*</span>
                  </label>
                  <div className="select-box">
                    <select
                      onChange={(e) => {
                        setCourseId(e.target.value);
                        setStuIdArr([]);
                      }}
                      className="course-options"
                      required
                    >
                      <option hidden className="placeHolder">
                        --Select Course--
                      </option>
                      {allCourses.map((element) => {
                        return (
                          <option key={element.c_id} value={element.c_id}>
                            {element.c_name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <button type="submit">Generate</button>
            </form>
          )}
        </section>

        {stuIdArr.length
          ? stuIdArr.map((item, index) => {
              return (
                <CertificateForMultiDownload
                  key={index}
                  hostName={props.hostName}
                  token={props.token}
                  subId={item.sub_id}
                />
              );
            })
          : ""}
        {stuIdArr.length ? (
          <ScrollToTopAndPrint btnText="Download All" icon="download" />
        ) : (
          ""
        )}
      </div>
      <Loader loaderClasses={loaderClasses} />
    </>
  );
};

export default CertificateMulti;
