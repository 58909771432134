import React, { useEffect, useState } from 'react';
import './BasicStudentDetails.css';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../../components/admin_components/Loader';

const BasicStudentDetails = (props) => {
    const param = useParams();
    const [stuProfileData, setStuProfileData] = useState([]);
    const [baseImage, setBaseImage] = useState("");
    const [loaderClasses, setLoaderClasses] = useState('loader-container d-none');
    const navigate = useNavigate();
    
    const fetchStudentProfileData = () =>{
        setLoaderClasses('loader-container');
        fetch(`${props.hostName}/redsky_api/student/get_stu_profile_basic_data.php?stu_reg_no=${param.reg_no}&contact=${param.contact}`)
        .then(resp => resp.json()).then(data => {
            
            if(data.code === 5){
                setStuProfileData(data.data.stu_basic_data);
                setBaseImage(data.data.stu_img);
            }else{
                alert(data.data);
                navigate('../');
            }
            setLoaderClasses('loader-container d-none');
        }).catch(err => console.log(err));
    };

    useEffect(fetchStudentProfileData,[param.contact, props.hostName, param.reg_no, navigate]);

  return (  
    <>
    <div  className='min-vh-100 py-4 px-3'>
        <div className="container-lg student-profile">
            <form method="post">
                <div className="row">
                    <div className="col-md-4 d-flex">
                        <div className="profile-img">
                            {
                                baseImage ? 
                                    <img src={baseImage.file_name} alt="img"/>
                             :
                                    <div className='p-3 py-4  d-flex flex-col'>
                                        <span className="material-icons material-symbols-outlined profile-no-img" >no_photography</span>
                                        <span>No Image Found</span>
                                    </div> 
                            }
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="profile-head">
                                    <h5>
                                        {stuProfileData.first_name+' '+stuProfileData.last_name}
                                    </h5>
                                    <h6>
                                        {stuProfileData.course_name}
                                    </h6>
                                    <p className="profile-status">STATUS : <span className='profile-status-btn'>{stuProfileData.status}</span></p>
                                <ul className="nav nav-tabs" id="myTab" role="tablist">
                                    <li className="nav-item">
                                        {/* <a className="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Personal Details</a> */}
                                        <p  className="nav-link active">Personal Details</p>
                                    </li>
                            </ul>
                        </div>
                    </div>
                    {/* <div className="col-md-2">
                        <input type="submit" className="profile-edit-btn" name="btnAddMore" value="Edit Profile"/>
                    </div> */}
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <div className="profile-address">
                            <p>ADDRESS</p>
                            <span>{stuProfileData.address}, {stuProfileData.state}, {stuProfileData.country}, {stuProfileData.pin}</span>
                            <p>CONTACT</p>
                            <span>{stuProfileData.contact}</span>
                        </div>
                    </div>
                    <div className="col-md-8">
                        <div className="tab-content profile-tab" id="myTabContent">
                            <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                
                                <div className="row">
                                    <div className="col-3">
                                        <label>ID:</label>
                                    </div>
                                    <div className="col-9">
                                        <p>{stuProfileData.reg_no}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-3">
                                        <label>Name:</label>
                                    </div>
                                    <div className="col-9">
                                        <p>{stuProfileData.first_name + ' ' + stuProfileData.last_name}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-3">
                                        <label>Guardian:</label>
                                    </div>
                                    <div className="col-9">
                                        <p>{stuProfileData.guardian_name}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-3">
                                        <label>Gender</label>
                                    </div>
                                    <div className="col-9">
                                        <p>{stuProfileData.gender}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-3">
                                        <label>D.O.B</label>
                                    </div>
                                    <div className="col-9">
                                        <p>{stuProfileData.dob}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-3">
                                        <label>Email</label>
                                    </div>
                                    <div className="col-9">
                                        <p>{stuProfileData.email}</p>
                                    </div>
                                </div>
                                {/* <div className="row">
                                    <div className="col-3">
                                        <label>Phone</label>
                                    </div>
                                    <div className="col-9">
                                        <p>{stuProfileData.contact}</p>
                                    </div>
                                </div> */}
                                <div className="row">
                                    <div className="col-3">
                                        <label>Board</label>
                                    </div>
                                    <div className="col-9">
                                        <p>{stuProfileData.board}</p>
                                    </div>
                                </div>

                                
                            </div>

                            {/* <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label>Experience</label>
                                            </div>
                                            <div className="col-md-6">
                                                <p>Expert</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label>Hourly Rate</label>
                                            </div>
                                            <div className="col-md-6">
                                                <p>10$/hr</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label>Total Projects</label>
                                            </div>
                                            <div className="col-md-6">
                                                <p>230</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label>English Level</label>
                                            </div>
                                            <div className="col-md-6">
                                                <p>Expert</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label>Availability</label>
                                            </div>
                                            <div className="col-md-6">
                                                <p>6 months</p>
                                            </div>
                                        </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <label>Your Bio</label><br/>
                                        <p>Your detail description</p>
                                    </div>
                                </div>
                            </div> */}

                        </div>
                    </div>
                </div>
            </form>           
        </div>
    </div>

    <Loader loaderClasses={loaderClasses} />
    </>
  )
}

export default BasicStudentDetails
