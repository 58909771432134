import React, { useEffect, useState } from 'react';
import Chart from 'react-google-charts';
import Loader from './Loader';

const ClassDailyGraph = (props) => {
  const [graphData, setGraphData] = useState('');
  const [classCountPerDay, setClassCountPerDay] = useState([]);
  const [loaderClasses, setLoaderClasses] = useState('loader-container');

  useEffect(() => {

    setLoaderClasses("loader-container");

    fetch(`${props.hostName}/redsky_api/getClassCountPerDay.php?token=${props.token}`)
      .then(resp => resp.json()).then(data => {
       
        if (data.code === 5) {
            // setClassCountPerDay(data.data.reverse());
            setClassCountPerDay(data.data);
            setLoaderClasses("loader-container d-none");
        }

      }).catch(err => console.log(err));
      

  }, [props.token, props.hostName]);



  useEffect(() => {
    if (classCountPerDay.length !== 0) {
      let data = [];
      let classPerDay, day;

    classCountPerDay.forEach((elem, index) => {
        day = parseInt(elem.day);
        classPerDay = parseInt(elem.total_class_per_day);
        data[index] = [day, classPerDay];
      });


      setGraphData([
        ["Day", "Completed"], ...data
      ]);

    }
  }, [classCountPerDay]);


  return (
    <>
      <Chart className={props.className} chartType="Line" data={graphData}
        height="400px" width='1200px'
        options={{
            title: "Class Details (per Day)",
            curveType: "function",
            legend: { position: "top" },
            colors: ['#1e88e5','#ff9900'],  
            responsive: true,
            chart: {
                title: 'Class Data (per Day)',
                subtitle: 'Daily Conducted Class List',
                
              }, 
              vAxis: {                
                maxValue:31,
                minValue:1
                
            },      
        }} />

        <Loader loaderClasses={loaderClasses}/>
    </>
  )
}

export default ClassDailyGraph;