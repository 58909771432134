import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './AllStudentTableRow.css';

const EnqueryAllListTableRow = (props) => {
  const [isAdmitted, setIsAdmitted] = useState(parseInt(props.elem.is_admitted) ? true : false);
  
  const updateIsAdmitted = (id) => {
    props.updateAdmittedStatus(id, !isAdmitted);
    setIsAdmitted(!isAdmitted);
  }
  const deleteEnqueryHandler = (enqueryId) => {
    props.setEnqueryId(enqueryId);
    props.setVerifyPinClasses('verify-pin-container');
  }
  return (
      <tr>
        <td data-label="Sl.">&nbsp;{props.slNo}</td>
        <td data-label="Name">&nbsp;{props.elem.name}</td>
        <td data-label="Course">&nbsp;{props.elem.course_name}</td>
        <td data-label="Contact">&nbsp;{props.elem.contact}</td>
        <td data-label="Day">&nbsp;{props.elem.day1.slice(0,3)}-{props.elem.day2.slice(0,3)}</td>
        <td data-label="Date">&nbsp;{props.elem.date}</td>
        <td data-label="Admitted"><input type="checkbox" value={props.elem.enquery_id} checked={isAdmitted} onChange={(e)=>{updateIsAdmitted(e.target.value)}} /></td>
        <td data-label="Edit">
          <Link to={`../enquiry-modify/${props.elem.enquery_id}`} >
            <span className="material-icons material-symbols-rounded edit-btn" >edit</span>
          </Link>
        </td>
        <td data-label="Delete"><span className="material-icons material-symbols-rounded del-btn" onClick={ ()=> deleteEnqueryHandler(props.elem.enquery_id)} >delete</span></td>
        <td data-label="Print">
          <Link to={`../print-enquery/${props.elem.enquery_id}`}>
            <span className="material-icons material-symbols-rounded print-btn">print</span>
          </Link>
        </td>
      </tr>

  )
}

export default EnqueryAllListTableRow;

// import React from 'react';
// import { Link } from 'react-router-dom';
// import './AllStudentTableRow.css';

// const EnqueryAllListTableRow = (props) => {

//   return (
//     <>
//       {
//         props.rowsData.map((element) => {
          
//             let enquiryData = {
//                 enquery_id: element.enquery_id,
//                 name: element.name,
//                 course: element.course_id,
//                 fees_discount: element.fees_discount,
//                 contact: element.contact,
//                 course_starting_date: element.course_start_month,
//                 course_ending_date: element.course_end_month,
//                 day1: element.day1,
//                 time1: element.time1,
//                 day2: element.day2,
//                 time2: element.time2
//             }
//           return (

//             <tr>
//               <td data-label="Sl.">&nbsp;{props.slNo}</td>
//               <td data-label="Name">&nbsp;{element.name}</td>
//               {/* <td data-label="Course">&nbsp;{element.course_name}</td> */}
//               <td data-label="Course">&nbsp;{element.course_name}</td>
//               <td data-label="Contact">&nbsp;{element.contact}</td>
//               <td data-label="Date">&nbsp;{element.date}</td>
//               <td data-label="Admitted"><input type="checkbox" value={element.enquery_id} onChange={(e)=>setIsAdmitted(e)} checked={} /></td>

//               <td data-label="Edit">
//                 <Link to="../enquiry-modify" state={{enqueryFormData:enquiryData}}>
//                   <span className="material-icons material-symbols-rounded edit-btn" >edit</span>
//                 </Link>
//               </td>
//               {/* <td data-label="Delete"><span className="material-icons material-symbols-rounded del-btn" onClick={ ()=> deleteOthersPayment(element.receipt_no) } >delete</span></td> */}

//               <td data-label="Print">
//                 <Link to="../print-enquery" state={{enqueryFormData:enquiryData}}
//                  >
//                   <span className="material-icons material-symbols-rounded print-btn">print</span>
//                 </Link>
//               </td>
//             </tr>
//           )
//         })
//       }
//     </>

//   )
// }

// export default EnqueryAllListTableRow;

