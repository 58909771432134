import React, { useEffect, useState } from 'react';
import Loader from '../../components/admin_components/Loader';
import ModifyCourseForm from '../../components/admin_components/ModifyCourseForm';
import { useNavigate, useParams } from 'react-router-dom';


const ModifyCourse = (props) => {
  const navigate = useNavigate('');
    useEffect(()=>{
      if(props.isHeadAdmin === '0'){
        navigate('../all-students');
      }
    });
  const param = useParams();
  const [courseData, setCourseData] = useState({});
  const [loaderClasses, setLoaderClasses] = useState('loader-container d-none');

  useEffect(()=> {props.setTitle("Modify Course | Admin Panel | RedSky Educations | Educations for future")}, [props]);
    
  const getCourseData = () => {
      setLoaderClasses('loader-container');
      fetch(`${props.hostName}/redsky_api/getSingleCourseData.php?token=${props.token}&course_id=${param.course_id}`)
      .then(resp => resp.json()).then(data => {
          setLoaderClasses('loader-container d-none');
          if(data.code === 5){
            setCourseData(data.data);
          }else{
              alert(data.data);
          }
      }).catch(err => console.log(err));
  };

  const ModifyCourse = (courseData) => {
    setLoaderClasses('loader-container');
        fetch(`${props.hostName}/redsky_api/ModifyCourse.php?token=${props.token}`,
          {
            method: "POST",
            body: JSON.stringify(courseData),
            headers: {
              'Content-Type': 'application/json;charset=UTF-8'
            }
          })
          .then(resp => resp.json()).then(data => {
            setLoaderClasses('loader-container d-none');
            alert(data.data);
            if(data.code === 5){
              navigate('../All-Courses');
            }
          }).catch(err => console.log(err));
};

  useEffect(getCourseData, [param.course_id, props.token,props.hostName]);

  return (
    <>
      <ModifyCourseForm setLoaderClasses={setLoaderClasses} courseData={courseData} setModifyCourseForm={ModifyCourse}/>
      <Loader loaderClasses={loaderClasses}/>
    </>
  );
};

export default ModifyCourse;
