import React, { useEffect, useState } from "react";
import "../../components/admin_components/PrintOtherPaymentForm.css";
import { useParams } from "react-router-dom";
import Loader from "../../components/admin_components/Loader";
import ScrollToTopAndPrint from "../../components/admin_components/ScrollToTopAndPrint ";

const PrintOtherPaymentDirect = (props) => {
  const param = useParams();
  const receiptNo = param.receipt_no;
  const [otherPaymentBillData, setOtherPaymentBillData] = useState([]);
  const [loaderClasses, setLoaderClasses] = useState("loader-container");

  useEffect(() => {
    props.setTitle(
      `Print Other Payment | ${
        parseInt(props.isHeadAdmin) ? "Admin Panel" : "User Panel"
      } | RedSky Educations | Educations for future`
    );
  }, [props]);

  const fetchSingleOthersPayment = () => {
    setLoaderClasses("loader-container");

    fetch(
      `${props.hostName}/redsky_api/fetchSingleOthersPayment.php?token=${props.token}&receipt_no=${receiptNo}`
    )
      .then((resp) => resp.json())
      .then((data) => {
        setLoaderClasses("loader-container d-none");
        if (data.code === 5) {
          setOtherPaymentBillData(data.data);
        } else {
          alert(data.data);
        }
      })
      .catch((err) => console.log(err));
  };
  useEffect(fetchSingleOthersPayment, [props.token, props.hostName, receiptNo]);

  return (
    <>
      <div>
        <div id="Other-bill-container">
          <div id="top-border">
            <h3>MONEY RECEIPT</h3>
          </div>
          <div className="company-details-container">
            <h2 className="company-name">Redsky educations</h2>
            <p>CIN: U80903WB2021PTC248059</p>
            <div className="company-meta-details-container">
              <div>
                <p>Krishnanagar, WB, IN</p>
                <p>Helpdesk: 94 74 89 71 92</p>
              </div>
              <div>
                <p>Receipt No. {otherPaymentBillData.receipt_no}</p>
                <p>Date. {otherPaymentBillData.payment_day}</p>
              </div>
            </div>
          </div>

          <div className="student-data">
            <div className="student-short-data">
              <div className="divide-horion d-flex-us">
                <div className="divide-vertical">
                  <p>ID</p>
                  <p>Name</p>
                  <p>Amount</p>
                  <p>Purpose</p>
                </div>
                <div>
                  <p>&nbsp;{otherPaymentBillData.reg_no}</p>
                  <p>&nbsp;{otherPaymentBillData.full_name}</p>
                  <p>&nbsp;{otherPaymentBillData.amount}/-</p>
                  <p>
                    &nbsp;<b>{otherPaymentBillData.purpose}</b>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="seal-container">
            <div>
              <p>For,</p>
              <p>RedSky Educations</p>
            </div>
          </div>
          <div className="website-helpdesk-container">
            <p>Website: https://www.redskyeducations.in</p>
            <p>Help Desk: +91 9474897192 (Available on WhatsApp)</p>
          </div>

          <div className="note-container">
            <p className="text-note">Note:</p>
            <p>1. Please bring monthly card during payment</p>
            <p>2. If attendace card lost fine Rs. 30/-</p>
          </div>
          <div className="printing-info">
            <p className="heading-text">Printing Info :</p>
            <div>
              <p>
                [{otherPaymentBillData.printing_info_date}]-[
                {otherPaymentBillData.printing_info_time}]
              </p>
            </div>
          </div>
        </div>
        <ScrollToTopAndPrint btnText="Print" icon="print" />
      </div>
      <Loader loaderClasses={loaderClasses} />
    </>
  );
};

export default PrintOtherPaymentDirect;
