import React from "react";
import "./PaidPaymentBox.css";
import DueFeesTableRow from "./DueFeesTableRow";

const DueFeesTable = (props) => {
  return (
    <section className={props.isVisibleDueTable}>
      <header>Due Fees Details</header>
      <p className="all-total">Total Due: {props.totalDueAmount}/-</p>
      <table className="paid-table">
        <thead>
          <tr>
            <th scope="col">ID</th>
            <th scope="col">Name</th>
            <th scope="col">Contact</th>
            <th scope="col">Course</th>
            <th scope="col">Installment No</th>
            {/* <th scope="col">For Month</th> */}
            <th scope="col">Amount</th>
            <th scope="col">SMS</th>
          </tr>
        </thead>
        <tbody>
          {props.dueFeesListData.length > 0 ? (
            props.dueFeesListData.map((element) => {
              return (
                <DueFeesTableRow
                  key={element.sub_id}
                  element={element}
                  sendPaymentDueReminder={props.sendPaymentDueReminder}
                />
              );
            })
          ) : (
            <tr>
              <td colSpan="6">
                <center>No Due Data Found</center>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </section>
  );
};

export default DueFeesTable;
