import React, {useState, useEffect} from 'react';
import './ViewTask.css';
import ViewTaskTable from '../../components/task_components/ViewTaskTable';
import Loader from '../../components/admin_components/Loader';

const ViewTask = (props) => {

    let tableCols = ['ID', 'Name', 'Guardian', 'Course', 'DOA', 'View'];
    let [rowsData, setRowsData] = useState([]);
    const [loaderClasses, setLoaderClasses] = useState('loader-container d-none');
    const [allCourses, setAllCourses] = useState([]);
    const [tableClasses, setTableClasses] = useState('table-container');
    const [msgClasses, setMsgClasses] = useState('msg d-none');
    const [msgData, setMsgData] = useState('');
    const [rowLimit, setRowLimit] = useState(15);
    const [LimitRowsCountClasses, setLimitRowsCountClasses] = useState('row-limit-container d-none');
    const [stuListType, setStuListType] = useState(1);
   
    useEffect(()=> {props.setTitle("View Task | Task Panel | RedSky Educations | Educations for future")}, [props]);

    const fetchAllStudent = () => {

        setLoaderClasses('loader-container');
        
        fetch(`${props.hostName}/redsky_api/task_api/task_get_all_stu_list.php?token=${props.token}`)
            .then(resp => resp.json()).then(data => {
                setLoaderClasses('loader-container d-none');
              
                if(data.code === 5){
                    setRowsData(data.data);
                    setTableClasses('table-container');
                    setMsgClasses('msg d-none');
                    setLimitRowsCountClasses('row-limit-container');
                }
                else{
                    setTableClasses('table-container d-none');
                    setMsgData(data.data);
                    setMsgClasses('msg');
                    setLimitRowsCountClasses('row-limit-container d-none');
                }
                
            }).catch(err => console.log(err));
    };
    useEffect(fetchAllStudent,[props.token,props.hostName,rowLimit]);
    
    const getFilteredData = (filterObj) => {
        setLoaderClasses('loader-container');

        fetch(`${props.hostName}/redsky_api/task_api/task_get_filtered_active_student.php?token=${props.token}`,
        {
            method: "POST",
            body: JSON.stringify(filterObj),
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            }
        })
            .then(resp => resp.json()).then(data => {
                setLoaderClasses('loader-container d-none');
        
                if(data.code === 8){
                    setRowsData(data.data);
                    setTableClasses('table-container');
                    setMsgClasses('msg d-none');
                    setLimitRowsCountClasses('row-limit-container');
                }
                else{
                    setTableClasses('table-container d-none');
                    setMsgData(data.data);
                    setMsgClasses('msg');
                    setLimitRowsCountClasses('row-limit-container d-none');
                }
                
            }).catch(err => console.log(err));
    };

    const fetchAllCourses = () => {
        setLoaderClasses('loader-container');

        fetch(`${props.hostName}/redsky_api/task_api/task_get_all_courses.php?token=${props.token}`)
          .then(resp => resp.json()).then(data => {
            setLoaderClasses('loader-container d-none');
            if (data.code === 5) {
              setAllCourses(data.data);
            }
            else {
                document.querySelector('.add-student-form-container .form').innerHTML = `<center>${data.data} <br> Please Try again!</center>`;
            }
    
          }).catch(err => console.log(err));
        };

      useEffect(fetchAllCourses, [props.token,props.hostName]);
    

      const getRowLimit = (limitVal) => {
        setRowLimit(limitVal);
        fetchAllStudent(stuListType);
      };
    
   

    return(
        <>
            <ViewTaskTable tableClasses={tableClasses} tableCols={tableCols} rowsData={rowsData} allCourses={allCourses} setEditStuId={props.setEditStuId} token={props.token} fetchAllStudent={fetchAllStudent} getFilteredData={getFilteredData} setStuListType={setStuListType} getRowLimit={getRowLimit} LimitRowsCountClasses={LimitRowsCountClasses} msgClasses={msgClasses} msgData={msgData} />
            <Loader loaderClasses={loaderClasses}/>
        </>
    );
}

export default ViewTask;
