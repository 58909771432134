import React, { useState, useEffect } from "react";
import "./AddTask.css";
import Loader from "../../components/admin_components/Loader";
import { useNavigate, useParams } from "react-router-dom";

const ModifyTask = (props) => {
  const param = useParams();
  const navigate = useNavigate();
  const [taskHeading, setTaskHeading] = useState("");
  const [taskForDate, setTaskForDate] = useState("");
  const [taskDetails, setTaskDetails] = useState("");
  const [taskType, setTaskType] = useState(null);
  const [loaderClasses, setLoaderClasses] = useState("loader-container d-none");

  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);

  useEffect(() => {
    props.setTitle(
      "Modify Task | Task Panel | RedSky Educations | Educations for future"
    );
  }, [props]);

  const getCourseData = () => {
    setLoaderClasses("loader-container");
    fetch(
      `${props.hostName}/redsky_api/task_api/getSingleTaskData.php?token=${props.token}&task_id=${param.task_id}`
    )
      .then((resp) => resp.json())
      .then((data) => {
        setLoaderClasses("loader-container d-none");
        if (data.code === 5) {
          setTaskType(data.data.task_type);
          setTaskDetails(data.data.task_details);
          setTaskHeading(data.data.task_heading);
          setTaskForDate(data.data.task_for_date);
        } else {
          alert(data.data);
        }
      })
      .catch((err) => console.log(err));
  };
  useEffect(getCourseData, [param.task_id, props.token, props.hostName]);

  const validateAddTaskHandler = (e) => {
    e.preventDefault();

    if (
      taskHeading.trim() === "" ||
      taskDetails.trim() === "" ||
      taskType.trim() === ""
    ) {
      alert("Please Fill All The Field");
      return;
    }

    setLoaderClasses("loader-container");
    fetch(
      `${props.hostName}/redsky_api/task_api/modify_task.php?token=${props.token}`,
      {
        method: "POST",
        body: JSON.stringify({
          task_id: param.task_id,
          task_type: taskType,
          task_heading: taskHeading,
          task_for_date: taskForDate,
          task_details: taskDetails,
        }),
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    )
      .then((resp) => resp.json())
      .then((data) => {
        alert(data.data);
        if (data.code === 5) {
          navigate("../all-task");
        }
        setLoaderClasses("loader-container d-none");
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <div className="d-flex">
        <section className="add-task-form">
          <header>Modify Task</header>
          <form action="#" className="form" onSubmit={validateAddTaskHandler}>
            <div className="input-box">
              <label>
                Task Type:<span className="color-red">*</span>
              </label>
              <div className="select-box">
                <select onChange={(e) => setTaskType(e.target.value)} required>
                  <option hidden className="placeHolder" value="">
                    Practical / Theory
                  </option>
                  <option value="1" selected={taskType === "1" ? true : false}>
                    Practical
                  </option>
                  <option value="2" selected={taskType === "2" ? true : false}>
                    Theory
                  </option>
                  <option value="3" selected={taskType === "3" ? true : false}>
                    Test
                  </option>
                </select>
              </div>
            </div>
            <div className="input-box">
              <label>
                Task Heading:<span className="color-red">*</span>
              </label>
              <input
                type="text"
                placeholder="Enter Task Headline"
                value={taskHeading}
                onChange={(e) => setTaskHeading(e.target.value)}
                required
              />
            </div>

            <div className="input-box">
              <label>
                Task For Date:<span className="color-red">*</span>
              </label>
              <input
                type="date"
                placeholder="Enter Task For Date"
                value={taskForDate}
                onChange={(e) => setTaskForDate(e.target.value)}
                min={tomorrow.toJSON().slice(0, 10)}
                required
              />
            </div>

            <div className="input-box">
              <label>
                Task Details:<span className="color-red">*</span>
              </label>
              <textarea
                placeholder="Enter Task Details"
                value={taskDetails}
                onChange={(e) => setTaskDetails(e.target.value)}
                required
              ></textarea>
            </div>

            <input type="submit" value="Modify Task" />
          </form>
        </section>
      </div>

      <Loader loaderClasses={loaderClasses} />
    </>
  );
};

export default ModifyTask;
