import React, { useEffect, useState } from 'react';
import ActiveAllStudentGraph from './ActiveAllStudentGraph';
import CourseStudentGraph from './CourseStudentGraph';
import GenderRatio from './GenderRatio';
import './Dashboard.css';
import './DashBoardUser.css';
import Loader from './Loader';

const DashBoardUser = (props) => {
    const [totalNoOfStudent, setTotalNoOfStudent] = useState(0);
    const [newAdmission, setNewAdmission] = useState(0);
    const [hasBirthday, setHasBirthday] = useState(false);
    const [birthdayCount, setBirthdayCount] = useState(0);
    const [loaderClasses, setLoaderClasses] = useState('loader-container d-none');
 

    useEffect(()=> {props.setTitle("DashBoard | User Panel | RedSky Educations | Educations for future")}, [props]);

    useEffect(()=>{
        fetch(`${props.hostName}/redsky_api/getTotalNoOfActiveStudents.php?token=${props.token}`)
        .then(resp => resp.json()).then(data => {
            if (data.code === 5) {
                setTotalNoOfStudent(data.data.total_student);
            }

        }).catch(err => console.log(err));
    },[setTotalNoOfStudent,props.token,props.hostName]);

    useEffect(()=>{
        fetch(`${props.hostName}/redsky_api/getTotalNewAdmission.php?token=${props.token}`)
        .then(resp => resp.json()).then(data => {
            
            if (data.code === 5) {
                setNewAdmission(data.data.new_admission);
            }

        }).catch(err => console.log(err));
    },[props.token,props.hostName]);

    useEffect(()=>{
        fetch(`${props.hostName}/redsky_api/get_has_birthday.php?token=${props.token}`)
        .then(resp => resp.json()).then(data => {
            if (data.code === 5 && data.data.student_count > 0) {
                setHasBirthday(true);
                setBirthdayCount(data.data.student_count);
            }

        }).catch(err => console.log(err));
    },[props.token,props.hostName]);

    const sendBirthdayWish = () => {
        setLoaderClasses('loader-container');
        fetch(`${props.hostName}/redsky_api/send_birthday_wish.php?token=${props.token}`)
        .then(resp => resp.json()).then(data => {
            if (data.code === 5) {
                setHasBirthday(false);
            }
            setLoaderClasses('loader-container d-none');

        }).catch(err => console.log(err));
    }

    return (
        <>
            <section className="dashboard-container">

                <div className={`action-pop-up ${hasBirthday ? 'active' : ''} d-flex`}>
                    <p style={{whiteSpace:"nowrap", marginBottom:0}}>BirthDay: {birthdayCount}</p>
                    <span className="material-icons material-symbols-outlined send-sms text-white prevent-select" onClick={sendBirthdayWish}>send</span>
                    <span className="material-icons material-symbols-outlined prevent-select " style={{cursor:"pointer"}} onClick={()=>setHasBirthday(false)}>close</span>
                </div>

                <div className="dash-content dash-user-content">
                    <div className="overview">
                        <div className="title">
                            <span className="material-icons material-symbols-outlined">speed</span>
                            <span className="text">Overview</span>
                        </div>

                        <div className="boxes">
                            <div className="box box1">
                                <span className="material-icons material-symbols-outlined">person_4</span>
                                <span className="text">Active Student</span>
                                <span className="number">{totalNoOfStudent}</span>
                                <span className="sub-text">Life Time</span>
                            </div>
                            <div className="box box2">
                                <span className="material-icons material-symbols-outlined">person_add</span>
                                <span className="text">New Admission</span>
                                <span className="number">{newAdmission}</span>
                                <span className="sub-text">This Month</span>
                            </div>
                        </div>
                    </div>

                    <div className='chart-container'>
                        <ActiveAllStudentGraph className='charts' hostName={props.hostName} token={props.token}/>
                    </div>
                
                    <hr/>
                    <div className='chart-group'>
                        <GenderRatio className='charts' hostName={props.hostName} token={props.token}/>
                        <CourseStudentGraph className='charts' hostName={props.hostName} token={props.token}/>
                    </div>
                </div>
            </section>
            <Loader loaderClasses={loaderClasses} />
        </>

    )
};

export default DashBoardUser;
