import React, {useState} from 'react';
// import './AddNewStudentForm.css';
import './Loader.css';
import './MarksUploadForm.css';
import MarksUploadFormRow from './MarksUploadFormRow';

const MarksUploadForm = (props) => {
  const [dateOfExam, setDateOfExam] = useState('');

  const uploadMarks = (marksData, row) =>{
    // e.preventDefault();
    if(dateOfExam.trim() === '' ){
      alert("Please Fill Exam Date!");
      return;
    }
    
    props.storeMarks({date_of_exam: dateOfExam, ...marksData}, row);
  }

  return (
    <>
      <section className='add-student-form-container'>
        <header>Upload Marks</header>
        <div action="#" className="form" >

            <div className="input-box">
                <label htmlFor='date-of-exam'>Date Of Examination:<span className='color-red'>*</span></label>
                <input type="date" placeholder="Date Of Examination" id='date-of-exam' value={dateOfExam} onChange={(e)=>setDateOfExam(e.target.value)} required/>
            </div>

            <div style={{overflow:'scroll', marginTop:"1rem", maxHeight:"300px"}}>
              <table id='marks-upload-table'>
                <thead>
                  <tr>
                    <th rowSpan={2}>ID</th>
                    <th rowSpan={2}>Name</th>
                    <th colSpan={2}>Theory</th>
                    <th colSpan={2}>Practical</th>
                    <th colSpan={2}>Viva</th>
                    <th>Rank</th>
                    <th>Save</th>
                  </tr>
                  {/* <tr>
                    <th>Obtained</th>
                    <th>Full</th>
                    <th>Obtained</th>
                    <th>Full</th>
                    <th>Obtained</th>
                    <th>Full</th>
                  </tr> */}
                </thead>
                <tbody>
                  {
                    props.studentData.map((elem) => {
                      return(
                        <MarksUploadFormRow key={elem.sub_id} elem={elem} uploadMarks={uploadMarks} />
                      );
                    })
                  }                 
                </tbody>
                
              </table>
            </div>

          {/* <button type='submit'>Upload Marks</button> */}
        </div>
      </section>
    </>
  )
}

export default MarksUploadForm;