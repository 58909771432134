import React, {useEffect, useState} from 'react';
import StudentShortDetails from '../../components/admin_components/StudentShortDetails';
import DefferalBox from '../../components/admin_components/DefferalBox';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../../components/admin_components/Loader';
import SearchPaymentBox from './SearchPaymentBox';

// let stu_reg_no = '';

const DefferalStudent = (props) => {
  const navigate = useNavigate();
  const param = useParams();
  const stu_reg_no = param.reg_no;
    useEffect(()=>{
      if(props.isHeadAdmin === '0'){
        navigate('../all-students');
      }
    });


  const [singleStudentData, setSingleStudentData] = useState({});
  const [unpaidFeesLists, setUnpaidFeesLists] = useState([]);
  const [loaderClasses, setLoaderClasses] = useState('loader-container d-none');

  useEffect(()=> {props.setTitle("New Deferral | Admin Panel | RedSky Educations | Educations for future")}, [props]);

  useEffect(()=>{
    getSearchData({stu_reg_no: stu_reg_no});
  },[props.hostName, props.token, stu_reg_no]);

  const getSearchData = (searchVal) => {
    setLoaderClasses('loader-container');
   
    fetch(`${props.hostName}/redsky_api/unpaidFeesListWithStudentShortData.php?token=${props.token}`,
      {
        method: "POST",
        body: JSON.stringify(searchVal),
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        }
      })
      .then(resp => resp.json()).then(data => {
        
        
        if(data.code === 5){
          setSingleStudentData(data.data['stu_data']);
          setUnpaidFeesLists(data.data['unpaid_fees_data']);
          
        }else if(data.code === 6){
          setSingleStudentData(data.data['stu_data']);  
        }else{
          alert(data.data);
        }
        setLoaderClasses('loader-container d-none');
        
      }).catch(err => console.log(err));
  };

  const setNewDefferalPaymentDetails = (defPaymentData) => {
    
      setLoaderClasses('loader-container');
      fetch(`${props.hostName}/redsky_api/addNewDefferalPaymentDetails.php?token=${props.token}`,
      {
        method: "POST",
        body: JSON.stringify({...defPaymentData, reg_no: stu_reg_no}),
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        }
      })
      .then(resp => resp.json()).then(data => {
        if(data.code === 8){
          alert(data.data.msg);
        }else{
          alert(data.data.msg);
        }
        setLoaderClasses('loader-container d-none');
        getSearchData({stu_reg_no: data.data.stu_reg_no});
      }).catch(err => console.log(err));
  };

  return (
    <>
      <SearchPaymentBox setTitle={props.setTitle} heading="Deferral Payment" path="../deferral-payment" />
      {
        Object.keys(singleStudentData).length > 0 ?
          <StudentShortDetails studentData={singleStudentData} />
        :
        <></>
      }
      <DefferalBox  unpaidFeesLists={unpaidFeesLists} setNewDefferalPaymentDetails={setNewDefferalPaymentDetails} stu_reg_no={stu_reg_no} />
      <Loader loaderClasses={loaderClasses} />
    </>
  );
}

export default DefferalStudent;
