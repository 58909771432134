import React, {useRef} from 'react';
import ViewTaskTableRow from './ViewTaskTableRow';
import FilterStudentTask from './FilterStudentTask';
import './ViewTaskTable.css';


const ViewTaskTable = (props) => {

    const resestFilterRef = useRef();

    const printAll = ()=>{
        window.print();
    };
    const filterHandler = () => {
        document.querySelector(".filter-box-task").classList.toggle('d-none');
    };

    return (
        <div className='view-task-table-container'>
            <header className='table-header'>
                <span>{(props.tableName) ? props.tableName :'Student Lists'}</span>
                <div className='pos-rel'>
                    <div className='d-flex align-center'>
                        <span className="material-icons material-symbols-outlined" onClick={filterHandler}>tune</span>
                        <span className="material-icons material-symbols-rounded" onClick={printAll}>print</span>
                    </div>
                    <FilterStudentTask ref={resestFilterRef} getFilteredData={props.getFilteredData} filterHandler={filterHandler} allCourses={props.allCourses} />

                </div>
            </header>

            <div className={props.tableClasses}>
                <table>
                    <thead>
                        <tr>
                            {
                                props.tableCols.map((element)=>{
                                    return(<th scope="col" id={element} key={element}>{element}</th>);
                                })
                            }
                        </tr>
                    </thead>
                    <tbody>                    
                        <ViewTaskTableRow rowsData={props.rowsData} fetchAllStudent={props.fetchAllStudent} setEditStuId={props.setEditStuId}  token={props.token} />
                    </tbody>
                    
                </table>
            </div>
                <center className={props.msgClasses}>{props.msgData}</center>
        </div>
    )
}

export default ViewTaskTable;
