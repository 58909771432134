import React, { useEffect, useState } from "react";

const MarkTaskStatus = (props) => {
  const [taskType, setTaskType] = useState("N/A");
  const [selectRemark, setSelectRemark] = useState("");
  const [cancelReason, setCancelReason] = useState("");
  const [incompleteReason, setIncompleteReason] = useState("");
  const [isDateOver, setIsDateOver] = useState(props.taskData.is_date_over);

  if (isDateOver === 1) {
    setIsDateOver(3);
    props.setTaskAsIncomplete({
      incomplete_reason: "Date Over",
      stu_id: props.taskData.stu_id,
      task_id: props.taskData.task_id,
    });
  }

  useEffect(() => {
    if (props.taskData.task_type === "1") {
      setTaskType("Practical");
    } else if (props.taskData.task_type === "2") {
      setTaskType("Theory");
    } else if (props.taskData.task_type === "3") {
      setTaskType("Test");
    }
  }, [props.taskData.task_type]);

  let task_status = "undefined";
  let dateOf = "undefined";
  let remark = "",
    remarkStatus = "N/A";

  const deleteTaskHandler = (taskAssignId) => {
    if (window.confirm("Want to Delete ?"))
      props.deleteTask({ task_assign_id: taskAssignId });
  };

  const resumeTask = (taskId, stuId) => {
    if (!window.confirm("Are You sure to RESUME THE TASK?")) return;

    setIncompleteReason("");
    props.setResumeTask({ task_id: taskId, stu_id: stuId });
  };
  const expandTaskBox = (e) => {
    e.target.parentNode.parentNode.parentNode.style.maxHeight = "initial";
    e.target.style.display = "none";
    e.target.nextSibling.style.display = "block";
  };
  const closeTaskBox = (e) => {
    e.target.parentNode.parentNode.parentNode.style.maxHeight = "52px";
    e.target.style.display = "none";
    e.target.previousSibling.style.display = "block";
  };

  const saveRemarkHandler = (e, stu_id, task_id) => {
    e.preventDefault();

    if (selectRemark.trim() === "") {
      alert("Please Select Remark");
      return;
    }
    if (!window.confirm("Are You sure to MARK TASK AS COMPLETE?")) return;
    let obj = {
      remark: selectRemark,
      stu_id: stu_id,
      task_id: task_id,
    };
    props.setTaskAsComplete(obj);
  };

  const saveIncompleteReasonHandler = (e, stu_id, task_id) => {
    e.preventDefault();

    if (!window.confirm("Are You sure to MARK TASK AS INCOMPETE?")) return;

    let incompleteObj = {
      incomplete_reason: incompleteReason,
      stu_id: stu_id,
      task_id: task_id,
    };
    props.setTaskAsIncomplete(incompleteObj);
  };
  const saveCancelReasonHandler = (e, stu_id, task_id) => {
    e.preventDefault();

    if (!window.confirm("Are You sure to MARK TASK AS CANCELED?")) return;

    let cancelObj = {
      cancel_reason: cancelReason,
      stu_id: stu_id,
      task_id: task_id,
    };
    props.setTaskAsCancel(cancelObj);
  };

  remark = "Remark : ";
  if (props.taskData.completion_remark === "0") remarkStatus = "Low";
  else if (props.taskData.completion_remark === "1") remarkStatus = "Moderate";
  else if (props.taskData.completion_remark === "2") remarkStatus = "High";

  if (props.taskData.task_status === "0") {
    task_status = "pending";
  } else if (props.taskData.task_status === "1") {
    task_status = "completed";
    dateOf = "Complete ";
  } else if (props.taskData.task_status === "2") {
    task_status = "canceled";
    dateOf = "Cancellation ";
    remark = "";
    remarkStatus = "";
  } else if (props.taskData.task_status === "3") {
    task_status = "incomplete";
    dateOf = "Incompletion  ";
    remark = "";
    remarkStatus = "";
  } else {
    task_status = "undefined";
  }

  return (
    <div className="task-box" key={props.taskData.task_id}>
      <div className="task-box-hero">
        <h4 className="task-heading">
          [{props.taskData.task_id}]-{props.taskData.task_heading}
        </h4>

        <div className="d-flex">
          {parseInt(props.taskData.task_status) === 3 &&
          parseInt(props.taskData.is_date_over) === 2 ? (
            <span
              className="material-icons material-symbols-outlined resume-btn"
              onClick={() =>
                resumeTask(props.taskData.task_id, props.taskData.stu_id)
              }
            >
              fast_forward
            </span>
          ) : (
            <></>
          )}

          <span className={`task-status task-status-${task_status}`}>
            {task_status}
          </span>
          <span
            className="material-icons material-symbols-outlined down-arrow"
            onClick={expandTaskBox}
          >
            keyboard_arrow_down
          </span>
          <span
            className="material-icons material-symbols-outlined up-arrow"
            onClick={closeTaskBox}
          >
            keyboard_arrow_up
          </span>
        </div>
      </div>
      <div className="task-box-extra-info">
        <p>Task Type : {taskType}</p>
        <p>Task Details : {props.taskData.task_details}</p>
        <p>Assign Date : {props.taskData.assign_date}</p>
        <p>For Date : {props.taskData.for_date}</p>
        {props.taskData.task_status === "2" ? (
          <>
            <p>
              Cancelation Reason :{" "}
              {props.taskData.cancel_reason
                ? props.taskData.cancel_reason
                : "N/A"}
            </p>
          </>
        ) : (
          <></>
        )}
        {props.taskData.task_status === "3" ? (
          <>
            <p>
              Incompletion Reason :{" "}
              {props.taskData.incomplete_reason
                ? props.taskData.incomplete_reason
                : "N/A"}
            </p>
          </>
        ) : (
          <></>
        )}

        {props.taskData.task_status !== "0" ? (
          <p>
            {" "}
            {dateOf} Date :{" "}
            {props.taskData.status_date ? props.taskData.status_date : "N/A"}
          </p>
        ) : (
          ""
        )}

        <p>
          {remark} {remarkStatus}
        </p>
        {props.taskData.task_status === "0" ? (
          <>
            <form
              className="task-complete-form"
              onSubmit={(e) =>
                saveIncompleteReasonHandler(
                  e,
                  props.taskData.stu_id,
                  props.taskData.task_id
                )
              }
            >
              <div className="input-box">
                <label>Reason of Incomplete:</label>
                <input
                  type="text"
                  placeholder="Specify a reason"
                  value={incompleteReason}
                  onChange={(e) => setIncompleteReason(e.target.value)}
                />
              </div>
              <input type="submit" value="Incomplete" id="incomplete-btn" />
            </form>

            <p className="text-or">OR</p>

            <form
              className="task-complete-form"
              onSubmit={(e) =>
                saveRemarkHandler(
                  e,
                  props.taskData.stu_id,
                  props.taskData.task_id
                )
              }
            >
              <div className="input-box">
                <label>
                  Completion Remark:<span className="color-red">*</span>
                </label>
                <div className="select-box">
                  <select
                    required
                    value={selectRemark}
                    onChange={(e) => setSelectRemark(e.target.value)}
                  >
                    <option hidden="" className="placeHolder" value="">
                      --Select Remark--
                    </option>
                    <option value="2">High</option>
                    <option value="1">Moderate</option>
                    <option value="0">Low</option>
                  </select>
                </div>
              </div>
              <input type="submit" value="Complete" id="complete-btn" />
            </form>
            <p className="text-or">OR</p>
            <form
              className="task-complete-form"
              onSubmit={(e) =>
                saveCancelReasonHandler(
                  e,
                  props.taskData.stu_id,
                  props.taskData.task_id
                )
              }
            >
              <div className="input-box">
                <label>Reason of Cancelation:</label>
                <input
                  type="text"
                  placeholder="Specify a reason"
                  value={cancelReason}
                  onChange={(e) => setCancelReason(e.target.value)}
                />
              </div>
              <input type="submit" value="Cancel" id="cancel-btn" />
            </form>
          </>
        ) : (
          ""
        )}

        <div className="d-flex align-items-center mb-2 mt-4 justify-content-start">
          <p className="mb-0 me-2">Want to Delete? </p>
          <span
            className="material-icons material-symbols-outlined cursor-pointer"
            onClick={() => {
              deleteTaskHandler(props.taskData.task_assign_id);
            }}
          >
            delete
          </span>
        </div>
      </div>
    </div>
  );
};

export default MarkTaskStatus;
