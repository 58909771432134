import React, { useEffect, useState} from 'react';
import StudentShortDetails from '../../components/admin_components/StudentShortDetails';
import PaidPaymentBox from '../../components/admin_components/PaidPaymentBox';
import Loader from '../../components/admin_components/Loader';
import { useNavigate, useParams } from 'react-router-dom';
import SearchPaymentBox from './SearchPaymentBox';

const PaidFeesList = (props) => {
  const navigate = useNavigate();
  const param = useParams();
  const stu_reg_no = param.reg_no;

  const isExtraContentShow = (props.isHeadAdmin === '0') ? 'd-none' : '';
  
  const [singleStudentData, setSingleStudentData] = useState({});
  const [paidFeesLists, setPaidFeesLists] = useState([]);
  const [totalPaidAmount, setTotalPaidAmount] = useState(0);
  const [loaderClasses, setLoaderClasses] = useState('loader-container d-none');

  useEffect(()=> {props.setTitle(`Paid Fees List | ${parseInt(props.isHeadAdmin) ? "Admin Panel" : "User Panel"} | RedSky Educations | Educations for future`)}, [props]);

  useEffect(() => {
    setLoaderClasses('loader-container');
 
    fetch(`${props.hostName}/redsky_api/paidFeesListWithStudentShortData.php?token=${props.token}`,
      {
        method: "POST",
        body: JSON.stringify({stu_reg_no: stu_reg_no}),
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        }
      })
      .then(resp => resp.json()).then(data => {
  
        if(data.code === 5){
          setSingleStudentData(data.data['stu_data']);
          setPaidFeesLists(data.data['paid_fees_data']);
          setTotalPaidAmount(data.data.total_paid_amount)
      
        }else{
          alert(data.data);
        }
        setLoaderClasses('loader-container d-none');
        
      }).catch(err => console.log(err));
  },[props.hostName, props.token, stu_reg_no]);

  const deletePaymentHandler = (deleteData) => {
  
    if(props.isHeadAdmin === '0'){
        navigate('../user-dashboard');
    }
    setLoaderClasses('loader-container');
        
      //deletion code will go here
      fetch(`${props.hostName}/redsky_api/deletePaidFees.php?token=${props.token}`,
      {
        method: "POST",
        body: JSON.stringify(deleteData),
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        }
      })
      .then(resp => resp.json()).then(data => {
        if(data.code === 12){
            navigate(`../paid-fees-list-no-search/${stu_reg_no}`);
        }else{
            alert(data.data);
        }
        setLoaderClasses('loader-container d-none');
            
      }).catch(err => console.log(err));
  };

  return (
    <>
      <SearchPaymentBox setTitle={props.setTitle} heading="Paid Fees" path="../paid-fees" />
      {
        Object.keys(singleStudentData).length > 0 ?
          <StudentShortDetails studentData={singleStudentData} />
        :
        <></>
      }
      {
        paidFeesLists.length ? 
          <PaidPaymentBox paidFeesLists={paidFeesLists} totalPaidAmount={totalPaidAmount} deletePaymentHandler={deletePaymentHandler} isExtraContentShow={isExtraContentShow} />
        :
        <></>
      }
      <Loader loaderClasses={loaderClasses} />
    </>
  );
}

export default PaidFeesList;

