import React, {useEffect, useState} from 'react';
import StudentShortDetails from '../../components/admin_components/StudentShortDetails';
import PaidPaymentBox from '../../components/admin_components/PaidPaymentBox';
// import NoPaidFees from '../../components/admin_components/NoPaidFees';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../../components/admin_components/Loader';

let stu_reg_no = '';

const PaidFeesListNoSearch = (props) => {
  const param = useParams();
  const navigate = useNavigate();
  const [singleStudentData, setSingleStudentData] = useState([]);
  const [paidFeesLists, setPaidFeesLists] = useState([]);
  const [totalPaidAmount, setTotalPaidAmount] = useState([]);
  const [loaderClasses, setLoaderClasses] = useState('loader-container d-none');
  
  useEffect(()=> {props.setTitle("Paid Fees List | Admin Panel | RedSky Educations | Educations for future")}, [props]);
  window.onpopstate = ()=>{
  }

  const getPaymentData = () => {
    setLoaderClasses('loader-container');

    stu_reg_no = param.reg_no;

    fetch(`${props.hostName}/redsky_api/paidFeesListWithStudentShortData.php?token=${props.token}`,
      {
        method: "POST",
        body: JSON.stringify({stu_reg_no:param.reg_no}),
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        }
      })
      .then(resp => resp.json()).then(data => {
   
        if(data.code === 5){
          setSingleStudentData(data.data['stu_data']);
          setPaidFeesLists(data.data['paid_fees_data']);
          setTotalPaidAmount(data.data.total_paid_amount)
        }else{
          alert(data.data);
        }
        setLoaderClasses('loader-container d-none');
        
      }).catch(err => console.log(err));
  }
  useEffect( getPaymentData,[props.hostName,props.token,param.reg_no]);
 
  const deletePaymentHandler = (deleteData) => {
  
    if(props.isHeadAdmin === '0'){
        navigate('../user-dashboard');
    }
    setLoaderClasses('loader-container');
        
      //deletion code will go here
      fetch(`${props.hostName}/redsky_api/deletePaidFees.php?token=${props.token}`,
      {
        method: "POST",
        body: JSON.stringify(deleteData),
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        }
      })
          .then(resp => resp.json()).then(data => {
            if(data.code === 12){
              getPaymentData();
              navigate(`../paid-fees-list-no-search/${param.reg_no}`);
            }else{
              alert(data.data);
            }
            setLoaderClasses('loader-container d-none');
            
          }).catch(err => console.log(err));
  };

  return (
    <>
      <PaidPaymentBox paidFeesLists={paidFeesLists} totalPaidAmount={totalPaidAmount} stu_reg_no={stu_reg_no} deletePaymentHandler={deletePaymentHandler}/>
      <StudentShortDetails studentData={singleStudentData} />
      <Loader loaderClasses={loaderClasses}/>
    </>
  );
}

export default PaidFeesListNoSearch;

