import React, { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Dashboard from '../../components/admin_components/Dashboard';
import AddNewStudent from './AddNewStudent';
import ModifyStudent from './ModifyStudent';
import AllStudents from './AllStudents';
import PrintSingleStudent from './PrintSingleStudent';
import PaymentStudent from './PaymentStudent';
import DefferalStudent from './DefferalStudent';
import PaidFeesList from './PaidFeesList';
import DueFeesList from './DueFeesList';

import RightSection from '../../components/admin_components/RightSection';
import SideNav from '../../components/admin_components/SideNav';
import PrintPaidFees from './PrintPaidFees';
import AddAttendace from './AddAttendace';
import QuickAddStudent from './QuickAddStudent';
import PrintRegistration from './PrintRegistration';
import OtherPayment from './OtherPayment';
import AddNewCourse from './AddNewCourse';
import AllCourses from './AllCourses';
import ModifyCourse from './ModifyCourse';
import UpgradeCourse from './UpgradeCourse';
// import AddRFID from './AddRFID';
// import RfidList from './RfidList';
// import ModifyRFID from './ModifyRFID';
import Enquery from './Enquery';
import PageNotFound from './PageNotFound';
import PaidFeesListNoSearch from './PaidFeesListNoSearch';
import DailyRegister from './DailyRegister';
import InstantBill from './InstantBill';
import ViewAllAttendace from './ViewAllAttendace';
import ViewAllOthersPayments from './ViewAllOthersPayments';
import PrintOtherPaymentDirect from './PrintOtherPaymentDirect';
import EditOthersPayment from './EditOthersPayment';
import ModifyPaidFees from './ModifyPaidFees';
import DashBoardUser from '../../components/admin_components/DashBoardUser';
import AttendanceViewManual from './AttendanceViewManual';
import AssignOnlineClass from './AssignOnlineClass';
import AssignedClassList from './AssignedClassList';
import MarksUpload from './MarksUpload';
import ViewMarksList from './ViewMarksList';
// import AddExamQuestion from './AddExamQuestion';
// import SetExam from './SetExam';
// import ExamList from './ExamList';
import ClassGraph from './ClassGraph';
import Certificate from './Certificate';
import CertificateMulti from './CertificateMulti';
import SearchPaymentBox from './SearchPaymentBox';


const AdminIndex = (props) => {

  const navigate = useNavigate();

  const isLoggedIn = JSON.parse(localStorage.getItem("admin_data_redsky"));

  useEffect(()=>{
    if(isLoggedIn === null){
      navigate('/admin_panel/login');
    }
  });

  let token = '';
  let isHeadAdmin = '0';
  if(isLoggedIn != null){
    token=isLoggedIn.admin_token;
    isHeadAdmin = isLoggedIn.is_head;
  }

  return (
    <div className='admin'>
        <SideNav isHeadAdmin={isHeadAdmin}/>
        <RightSection adminFName={ (isLoggedIn) ? isLoggedIn.first_name : "Admin"}>
         <Routes>
            <Route path='/' element={<Dashboard setTitle={props.setTitle} hostName={props.hostName} token={token} isHeadAdmin={isHeadAdmin} />}/>
            <Route path='dashboard' element={<Dashboard setTitle={props.setTitle} hostName={props.hostName} token={token} isHeadAdmin={isHeadAdmin} />}/>
            <Route path='user-dashboard' element={<DashBoardUser setTitle={props.setTitle} hostName={props.hostName} token={token} isHeadAdmin={isHeadAdmin} />}/>

            <Route path='add-student' element={<AddNewStudent setTitle={props.setTitle} hostName={props.hostName} token={token} isHeadAdmin={isHeadAdmin}/>}/>        
            <Route path='quick-add-student' element={<QuickAddStudent setTitle={props.setTitle} hostName={props.hostName} token={token} isHeadAdmin={isHeadAdmin}/>}/>        
            <Route path='modify-student/:id' element={<ModifyStudent setTitle={props.setTitle} hostName={props.hostName} token={token} isHeadAdmin={isHeadAdmin} />}/>              
            <Route path='all-students' element={<AllStudents setTitle={props.setTitle} hostName={props.hostName} token={token} isHeadAdmin={isHeadAdmin}  />}/>           
            <Route path='print-single-student' element={<PrintSingleStudent setTitle={props.setTitle} hostName={props.hostName} token={token} isHeadAdmin={isHeadAdmin} />}/> 
            <Route path='certificate/:id' element={<Certificate setTitle={props.setTitle} hostName={props.hostName} token={token}  />}/> 
            <Route path='certificate-download' element={<CertificateMulti setTitle={props.setTitle} hostName={props.hostName} token={token}  />}/> 
            <Route path='print-registration/:id' element={<PrintRegistration setTitle={props.setTitle} hostName={props.hostName} token={token} isHeadAdmin={isHeadAdmin} />}/>        
            
            <Route path='add-course' element={<AddNewCourse setTitle={props.setTitle} hostName={props.hostName} token={token} isHeadAdmin={isHeadAdmin} />}/> 
            <Route path='modify-course/:course_id' element={<ModifyCourse setTitle={props.setTitle} hostName={props.hostName} token={token} isHeadAdmin={isHeadAdmin} />}/> 
            <Route path='all-courses' element={<AllCourses setTitle={props.setTitle} hostName={props.hostName} token={token} isHeadAdmin={isHeadAdmin} />} /> 
            <Route path='upgrade-course' element={<UpgradeCourse setTitle={props.setTitle} hostName={props.hostName} token={token} isHeadAdmin={isHeadAdmin} />}/> 
            <Route path='renewal' element={<QuickAddStudent setTitle={props.setTitle} hostName={props.hostName} token={token} isHeadAdmin={isHeadAdmin} />}/> 

            <Route path='payment-student' element={<SearchPaymentBox setTitle={props.setTitle} heading="Payment Student" path="../payment-student" />}/>        
            <Route path='payment-student/:reg_no' element={<PaymentStudent setTitle={props.setTitle} hostName={props.hostName} token={token} isHeadAdmin={isHeadAdmin}  />}/>        
            <Route path='deferral-payment' element={<SearchPaymentBox setTitle={props.setTitle} heading="Deferral Payment" path="../deferral-payment" />}/>        
            <Route path='deferral-payment/:reg_no' element={<DefferalStudent setTitle={props.setTitle} hostName={props.hostName} token={token} isHeadAdmin={isHeadAdmin} />}/>        
            <Route path='paid-fees' element={<SearchPaymentBox setTitle={props.setTitle} heading="Paid Fees" path="../paid-fees" />}/>        
            <Route path='paid-fees/:reg_no' element={<PaidFeesList setTitle={props.setTitle} hostName={props.hostName} token={token} isHeadAdmin={isHeadAdmin} />}/>        
            <Route path='paid-fees-list-no-search/:reg_no' element={<PaidFeesListNoSearch setTitle={props.setTitle} hostName={props.hostName} token={token} />}/>        
            <Route path='print-paid-fees/:receipt_no' element={<PrintPaidFees setTitle={props.setTitle} hostName={props.hostName} token={token} isHeadAdmin={isHeadAdmin} />}/>        
            <Route path='modify-paid-fees/:receipt_no' element={<ModifyPaidFees setTitle={props.setTitle} hostName={props.hostName} token={token} isHeadAdmin={isHeadAdmin} />}/>        
            <Route path='due-fees-list' element={<DueFeesList setTitle={props.setTitle} hostName={props.hostName} token={token} isHeadAdmin={isHeadAdmin} />}/>        
            <Route path='other-payment' element={<OtherPayment setTitle={props.setTitle} hostName={props.hostName} token={token} isHeadAdmin={isHeadAdmin} />}/>        
            <Route path='view-all-others-payments' element={<ViewAllOthersPayments setTitle={props.setTitle} hostName={props.hostName} token={token} isHeadAdmin={isHeadAdmin} />}/>        
            <Route path='Print-Other-Payment/:receipt_no' element={<PrintOtherPaymentDirect setTitle={props.setTitle} hostName={props.hostName} token={token} isHeadAdmin={isHeadAdmin} />}/>        
            <Route path='edit-other-payment/:receipt_no' element={<EditOthersPayment setTitle={props.setTitle} hostName={props.hostName} token={token} isHeadAdmin={isHeadAdmin} />}/>        

            <Route path='record-attendance' element={<AddAttendace setTitle={props.setTitle} hostName={props.hostName} token={token} isHeadAdmin={isHeadAdmin}/>}/>   
            <Route path='view-attendance' element={<ViewAllAttendace setTitle={props.setTitle} hostName={props.hostName} token={token} isHeadAdmin={isHeadAdmin}/>}/>   
            {/* <Route path='filter-attendance' element={<AttendanceSPFilter setTitle={props.setTitle} hostName={props.hostName} token={token} isHeadAdmin={isHeadAdmin} />}/>  */}
            {/* <Route path='rfid-list' element={<RfidList setTitle={props.setTitle} hostName={props.hostName} token={token} isHeadAdmin={isHeadAdmin}/>}/>    */}
            {/* <Route path='add-rfid' element={<AddRFID setTitle={props.setTitle} hostName={props.hostName} token={token} isHeadAdmin={isHeadAdmin}/>}/>    */}
            {/* <Route path='modify-rfid' element={<ModifyRFID setTitle={props.setTitle} hostName={props.hostName} token={token} isHeadAdmin={isHeadAdmin}/>}/>    */}
            <Route path='view-attendance-manual' element={<AttendanceViewManual setTitle={props.setTitle} hostName={props.hostName} token={token} isHeadAdmin={isHeadAdmin}/>}/> 
            
            <Route path='upload-marks' element={<MarksUpload setTitle={props.setTitle} hostName={props.hostName} token={token} isHeadAdmin={isHeadAdmin} />}/> 
            {/* <Route path='view-marks-single' element={<ViewMarksSingle setTitle={props.setTitle} hostName={props.hostName} token={token} isHeadAdmin={isHeadAdmin} />}/>  */}
            <Route path='view-marks' element={<ViewMarksList setTitle={props.setTitle} hostName={props.hostName} token={token} isHeadAdmin={isHeadAdmin} />}/> 

            {/* <Route path='add-exam-question' element={<AddExamQuestion setTitle={props.setTitle} hostName={props.hostName} token={token} />}/> 
            <Route path='set-exam' element={<SetExam setTitle={props.setTitle} hostName={props.hostName} token={token}  />}/> 
            <Route path='exam-list' element={<ExamList setTitle={props.setTitle} hostName={props.hostName} token={token}  />}/>  */}
            
            {/* <Route path='enquery/quotation-print' element={<QuotationPrint setTitle={props.setTitle} hostName={props.hostName} token={token}/>}/>   */}
            <Route path='enquery/*' element={<Enquery setTitle={props.setTitle} hostName={props.hostName} token={token} isHeadAdmin={isHeadAdmin}/>}/>  
            <Route path='instant-bill/*' element={<InstantBill setTitle={props.setTitle} hostName={props.hostName} token={token} isHeadAdmin={isHeadAdmin} />}/>  

            <Route path='daily-register' element={<DailyRegister setTitle={props.setTitle} hostName={props.hostName} token={token} isHeadAdmin={isHeadAdmin} />}/> 
            
            <Route path='assign-class' element={<AssignOnlineClass setTitle={props.setTitle} hostName={props.hostName} token={token} isHeadAdmin={isHeadAdmin} />}/> 
            <Route path='assigned-class-list' element={<AssignedClassList setTitle={props.setTitle} hostName={props.hostName} token={token} isHeadAdmin={isHeadAdmin} />}/> 
            <Route path='class-graph' element={<ClassGraph setTitle={props.setTitle} hostName={props.hostName} token={token} isHeadAdmin={isHeadAdmin} />}/>

            <Route path='*' element={<PageNotFound setTitle={props.setTitle} />}/>    
          </Routes> 
        </RightSection>
    </div>
  )
}

export default AdminIndex;