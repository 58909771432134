import React, { useRef } from 'react';
import './Table.css';
import FilterStudentForAttendance from './FilterStudentForAttendance';
import ViewAttendanceTableRow from './ViewAttendanceTableRow';

const ViewAttendanceTable = (props) => {
    const resestFilterRef = useRef();
    
    const printAll = () => {
        window.print();
    };
    const filterHandler = () => {
        document.querySelector(".filter-box").classList.toggle('d-none');
    };

    return (
        <div>
            <header className='table-header'>
                <span>Attendance Details</span>
                <div className='pos-rel'>
                    <div className='d-flex align-center'>
                        <span className="material-icons material-symbols-outlined prevent-select" onClick={filterHandler}>tune</span>
                        <span className="material-icons material-symbols-rounded prevent-select" onClick={printAll}>print</span>
                    </div>

                    <FilterStudentForAttendance ref={resestFilterRef} allCourses={props.allCourses} getFilteredAttendanceData={props.getFilteredAttendanceData} filterHandler={filterHandler} />
                </div>
            </header>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-12 d-flex align-items-center justify-content-end'>
                        
                            <input type='Checkbox' id='all-attendance-check' onChange={(e)=>{e.target.checked ? props.setIsAllDataShow(true) : props.setIsAllDataShow(false)}} />
                            <label htmlFor='all-attendance-check' className='ms-1'>All</label>
                        
                    </div>
                </div>
            </div>
            <div className={props.tableClasses}>
                <div className='color-identifier-checkbox-container d-oflex justify-between align-center'>
                    <div className='color-identifier-container d-oflex'>
                        <div className='color-identifier d-oflex align-center'>
                            <span className='absent'>&nbsp;</span>
                            Absent
                        </div>
                        <div className='color-identifier d-oflex align-center'>
                            <span className='absent-but-known'>&nbsp;</span>
                            Absent But Known
                        </div>
                    </div>                 
                </div>
                <table>
                    <thead>
                        <tr>
                            {
                                props.tableCols.map((element) => {
                                    return (<th scope="col" id={element} key={element}>{element}</th>);
                                })
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            props.rowsData.map((element) => {
                                return(
                                    <ViewAttendanceTableRow key={`${element.sub_id}-${element.date}`} element={element} setEditStuId={props.setEditStuId} setDeleteAttedance={props.setDeleteAttedance} />
                                );
                            })
                        }
                        
                    </tbody>

                </table>

            </div>
            <center className={props.msgClasses}>{props.msgData}</center> 
        </div>
    )
}

export default ViewAttendanceTable;

