import React, { useEffect, useState } from 'react';
import './PaidFeesListStudent.css';
import { useParams } from 'react-router-dom';
import Loader from '../../components/admin_components/Loader';
import PaidFeesTableRowStudent from '../../components/student_components/PaidFeesTableRowStudent';

const PaidFeesListStudent = (props) => {
    props.setTitle("Paid Fees List | Student Panel | RedSky Educations");
    const param = useParams();

    let monthNames = ['','JAN','FEB','MAR','APR','MAY','JUN','JUL','AUG','SEP','OCT','NOV','DEC'];
    
    const [studentDetailsClass, setStudentDetailsClass] = useState('d-none');
    const [feesDetailsClass, setFeesDetailsClass] = useState('d-none');
    const [stuId, setStuId] = useState('');
    const [contact, setContact] = useState('');
    const [singleStudentData, setSingleStudentData] = useState({});
    const [loaderClasses, setLoaderClasses] = useState('loader-container d-none');
    const [paidFeesLists, setPaidFeesLists] = useState([]);
    const [lastPaymentDetails, setLastPaymentDetails] = useState({});

    useEffect(()=>{
        setStuId(param.stu_id);
        setContact(param.contact);
    }, [param]);
   
    const getPaidFeesList = (e) => {
        e.preventDefault();
        setLoaderClasses('loader-container');
        setStudentDetailsClass('d-none');
        setFeesDetailsClass('d-none');

        fetch(`${props.hostName}/redsky_api/student/get_paid_fees_list_student.php?stu_id=${stuId}&contact=${contact}`)
        .then(resp => resp.json()).then(data => {
    
            if(data.code === 5){
                setSingleStudentData(data.data.stu_data);
                setPaidFeesLists(data.data.paid_fees_data);
                setLastPaymentDetails(...data.data.paid_fees_data.slice(0));
                
                // setTotalPaidAmount(data.data.total_paid_amount);
                setStudentDetailsClass('');
                setFeesDetailsClass('');
            }else{
                alert(data.data);
            }
            setLoaderClasses('loader-container d-none');
            
        }).catch(err => console.log(err));
    }
  return (
    <div style={{paddingTop: "6rem"}} className='student-panel'>
        <div className='d-flex flex-col'>
            <form action="#"  onSubmit={getPaidFeesList} >
                
                <h3>Fees Installment Details</h3>

                <label htmlFor="Student-Id">Student Id</label>
                <input type="number" id='Student-Id' placeholder="Enter Student Id" value={stuId} onChange={(e)=>setStuId(e.target.value)} required />

                <label htmlFor="contact">Contact</label>
                <input type="number" id='contact' placeholder="Enter Phone No" value={contact} onChange={(e)=>setContact(e.target.value)} required />

                <input type="submit" value="Search" />
            </form>

            <form className={studentDetailsClass} action='#' >
                <h3 id='last-payment-details-heading'>Last Payment Details</h3>
                { Object.keys(paidFeesLists).length > 0 ?
                    <div className='d-oflex flex-col paid-fees-list__last-payment-container'>
                        <p>Installment No: <b>{lastPaymentDetails.installment_no}</b></p>
                        <p>For Month: <b>{monthNames[lastPaymentDetails.for_month]}, {lastPaymentDetails.for_year}</b></p>
                        <p>Paid Amount: <b>&#8377;{lastPaymentDetails.course_fees}/-</b></p>
                        <p>Payment Date: <b>{lastPaymentDetails.date}</b></p>
                        <p>Payment Time: <b>{lastPaymentDetails.time}</b></p>
                        <p>Payment Mode: <b>{lastPaymentDetails.payment_mode}</b></p>
                    </div>
                :
                    <p className='mb-0 mt-4 text-center'>No Last Payment Details Found</p>
                }
                
            </form>
            <br></br>
            <form className={studentDetailsClass} action='#' >
                <h3>Basic Details</h3>

                <div className="input-col">
                    <div>
                        <label htmlFor="stu_fname">First Name</label>
                        <input type="text" value={singleStudentData.first_name} disabled />
                    </div>
                    <div>
                        <label htmlFor="stu_lname">Last Name</label>
                        <input type="text" value={singleStudentData.last_name} disabled />
                    </div>
                </div>

                <div className="input-col">
                    <div>
                        <label htmlFor="guardian-name">Guardian Name</label>
                        <input type="text" value={singleStudentData.guardian_name} disabled /> 
                    </div>
                    <div>
                        <label htmlFor="contact">Contact</label>
                        <input type="text" value={singleStudentData.contact} disabled /> 
                    </div>
                </div>

                <label htmlFor="course-name">Course</label>
                <input type="text" value={singleStudentData.course_name} disabled />
            </form>

            <table className={'paid-table '+feesDetailsClass}>
                    <thead>
                        <tr>
                            <th scope="col">No</th>
                            <th scope="col">For Month</th>
                            <th scope="col">Date</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            (paidFeesLists.length > 0) ? (
                                paidFeesLists.map((element) => {
                                    return(
                                            <PaidFeesTableRowStudent key={element.installment_no} monthNames={monthNames} stu_id={props.stu_id} element={element} />
                                        );
                                })
                            ) 
                            :
                            <tr>
                                <td colSpan='5'>
                                <center>No Payment Details Found</center>
                                </td>
                            </tr>
                        }

                    </tbody>
                </table>
        </div>
        <Loader loaderClasses={loaderClasses}/>
    </div>
  )
}

export default PaidFeesListStudent;
