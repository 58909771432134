import React, { useEffect, useState } from "react";
import "./TaskStudentBox.css";
import MarkTaskStatus from "./MarkTaskStatus";

const TaskStudentBox = (props) => {
  const [taskData, setTaskData] = useState([]);
  const [backUpTaskData, setBackUpTaskData] = useState([]);

  useEffect(() => {
    if (props.studentTaskData !== null) {
      setTaskData(props.studentTaskData);
      setBackUpTaskData(props.studentTaskData);
    }
  }, [props.studentTaskData]);

  const typeOfTaskHandler = (e) => {
    if (e.target.value === "A") {
      setTaskData(backUpTaskData);
    } else if (e.target.value === "T") {
      setTaskData(
        backUpTaskData.filter((elem) => {
          return elem.task_type === "2";
        })
      );
    } else if (e.target.value === "P") {
      setTaskData(
        backUpTaskData.filter((elem) => {
          return elem.task_type === "1";
        })
      );
    } else if (e.target.value === "Test") {
      setTaskData(
        backUpTaskData.filter((elem) => {
          return elem.task_type === "3";
        })
      );
    } else {
      alert("Invalid Option");
      return;
    }
  };

  return (
    <div className="task-student-box-container">
      <div className="task-details">
        <div className="select-box-container">
          <select className="select-box" onChange={typeOfTaskHandler}>
            <option value="A">All</option>
            <option value="T">Theory</option>
            <option value="P">Practical</option>
            <option value="Test">Test</option>
          </select>
        </div>
        <header>Task Details</header>
        <p className="d-oflex align-center">
          <span
            className="material-icons material-symbols-outlined"
            style={{
              marginRight: "0.3rem",
              fontSize: "1.5rem",
              color: "var(--task-primary-color)",
            }}
          >
            bar_chart
          </span>
          No of Task: {taskData.length}
        </p>
        {taskData.map((elem) => {
          return (
            <MarkTaskStatus
              key={elem.task_id}
              taskData={elem}
              setTaskData={setTaskData}
              setBackUpTaskData={setBackUpTaskData}
              studentTaskData={props.studentTaskData}
              setTaskAsComplete={props.setTaskAsComplete}
              setTaskAsIncomplete={props.setTaskAsIncomplete}
              setTaskAsCancel={props.setTaskAsCancel}
              setResumeTask={props.setResumeTask}
              deleteTask={props.deleteTask}
            />
          );
        })}
      </div>
    </div>
  );
};

export default TaskStudentBox;
