import React, { useEffect, useRef, useState } from 'react';
// import Chart from 'react-google-charts';
import { useNavigate } from 'react-router-dom';
import ActiveAllStudentGraph from './ActiveAllStudentGraph';
import CourseStudentGraph from './CourseStudentGraph';
import GenderRatio from './GenderRatio';
import './Dashboard.css';
import FeesCollectionFeesDue from './FeesCollectionFeesDue';
import ChartFeesCollectionPerDay from './ChartFeesCollectionPerDay';
import Loader from './Loader';

const Dashboard = (props) => {
    const navigate = useNavigate('');
    useEffect(()=>{
        if(props.isHeadAdmin === '0'){
            navigate('../all-students');
        }
    });
    const [totalNoOfActiveStudent, setTotalNoOfActiveStudent] = useState(0);
    const [totalFees, setTotalFees] = useState(0);
    const [newAdmission, setNewAdmission] = useState(0);
    // const [totalDueFees, setTotalDueFees] = useState(0);
    const [avgFeesAllMonth, setAvgFeesAllMonth] = useState(0);
    const [birthDayData, setBirthDayData] = useState([]);
    // const [birthdayCount, setBirthdayCount] = useState(0);
    const [loaderClasses, setLoaderClasses] = useState('loader-container d-none');

    const birthDayPopUpContainer = useRef();

    useEffect(()=> {props.setTitle("DashBoard | Admin Panel | RedSky Educations | Educations for future")}, [props]);

    useEffect(()=>{
        // file name for ALL total student -> getTotalNoOfStudent.php
        fetch(`${props.hostName}/redsky_api/getTotalNoOfActiveStudents.php?token=${props.token}`)
        .then(resp => resp.json()).then(data => {
            if (data.code === 5) {
                setTotalNoOfActiveStudent(data.data.total_student);
            }

        }).catch(err => console.log(err));
    },[setTotalNoOfActiveStudent,props.token,props.hostName]);

    useEffect(()=>{
        fetch(`${props.hostName}/redsky_api/getTotalFees.php?token=${props.token}`)
        .then(resp => resp.json()).then(data => {
            if (data.code === 5) {
                setTotalFees(data.data.total_fees);
            }

        }).catch(err => console.log(err));
    },[setTotalFees,props.token,props.hostName]);

    useEffect(()=>{
        fetch(`${props.hostName}/redsky_api/getTotalNewAdmission.php?token=${props.token}`)
        .then(resp => resp.json()).then(data => {
            
            if (data.code === 5) {
                setNewAdmission(data.data.new_admission);
            }

        }).catch(err => console.log(err));
    },[setTotalFees,props.token,props.hostName]);

    // useEffect(()=>{
    //     fetch(`${props.hostName}/redsky_api/getTotalDueFees.php?token=${props.token}`)
    //     .then(resp => resp.json()).then(data => {

    //         if (data.code === 5) {
    //             setTotalDueFees(data.data.total_due);
    //         }

    //     }).catch(err => console.log(err));
    // },[props.token,props.hostName]);

    useEffect(()=>{
        fetch(`${props.hostName}/redsky_api/get_avg_fees_count_all_month.php?token=${props.token}`)
        .then(resp => resp.json()).then(data => {

            if (data.code === 5) {
                setAvgFeesAllMonth(data.data.avg_fees_all_month);
            }

        }).catch(err => console.log(err));
    },[props.token,props.hostName]);

    useEffect(()=>{
        fetch(`${props.hostName}/redsky_api/get_has_birthday.php?token=${props.token}`)
        .then(resp => resp.json()).then(data => {
            if (data.code === 5) {
                setBirthDayData(data.data);
                // setBirthdayCount(data.data.student_count);
            }

        }).catch(err => console.log(err));
    },[setTotalFees,props.token,props.hostName]);

    const sendBirthdayWish = (subID, index) => {
        setLoaderClasses('loader-container');
        fetch(`${props.hostName}/redsky_api/send_birthday_wish.php?token=${props.token}&sub_id=${subID}`)
        .then(resp => resp.json()).then(data => {
            if (data.code === 5) {
                birthDayData.splice(index, 1);
            }else{
                alert(data.data);
            }
            setLoaderClasses('loader-container d-none');

        }).catch(err => console.log(err));
    }

    const closeBirthdayPopUp = (index) => {
        // const elem = birthDayPopUpContainer.current.children[index];
        // elem.classList.remove('active');
        // OR 
        birthDayPopUpContainer.current.children[index].classList.remove('active');
    }

    return (
        <>
    <section className="dashboard-container">
        <div ref={birthDayPopUpContainer}>
        {
            birthDayData.map((item, index)=>{
                return(
                    <div className={`action-pop-up d-flex active`} title={`ID: ${item.reg_no} || ${item.course_name}`} key={index} style={{bottom:`${1.5+index}rem`, zIndex:`${100-index}`}}>
                        <p style={{whiteSpace:"nowrap", marginBottom:0}}>Birthday: {item.first_name} {item.last_name}</p>
                        <span className="material-icons material-symbols-outlined send-sms text-white prevent-select" onClick={()=>sendBirthdayWish(item.sub_id, index)} >send</span>
                        <span className="material-icons material-symbols-outlined prevent-select " style={{cursor:"pointer"}} onClick={()=>closeBirthdayPopUp(index)} title='Close'>close</span>
                    </div>
                );
            })
        }
        </div>
        

        <div className="dash-content">
            <div className="overview">
                <div className="title">
                    <span className="material-icons material-symbols-outlined">speed</span>
                    <span className="text">Overview</span>
                </div>

                <div className="boxes">
                    <div className="box box1">
                        <span className="material-icons material-symbols-outlined">person_4</span>
                        <span className="text">Active Student</span>
                        <span className="number">{totalNoOfActiveStudent}</span>
                        <span className="sub-text">Life Time</span>
                    </div>
                    <div className="box box2">
                        <span className="material-icons material-symbols-outlined">person_add</span>
                        <span className="text">New Admission</span>
                        <span className="number">{newAdmission}</span>
                        <span className="sub-text">This Month</span>
                    </div>
                    <div className="box box3">
                        <span className="material-icons material-symbols-outlined">account_balance_wallet</span>
                        <span className="text">Total Fees</span>
                        <span className="number">&#8377;{totalFees}</span>
                        <span className="sub-text">This Month</span>
                    </div>
                    {/* <div className="box box4">
                        <span className="material-icons material-symbols-outlined">speed</span>
                        <span className="text">Total Due</span>
                        <span className="number">&#8377;{totalDueFees}</span>
                        <span className="sub-text">Till Now</span>
                    </div> */}
                    <div className="box box4">
                        <span className="material-icons material-symbols-outlined">speed</span>
                        <span className="text">Avg. Fees</span>
                        <span className="number">&#8377;{avgFeesAllMonth}</span>
                        <span className="sub-text">Per Month</span>
                    </div>
                </div>
            </div>

            <div className='chart-container'>
                <ChartFeesCollectionPerDay className='charts'  hostName={props.hostName} token={props.token} />
            </div>
            <div className='chart-container'>
                <ActiveAllStudentGraph className='charts' hostName={props.hostName} token={props.token}/>
            </div>
           
            <hr/>
            <div className='chart-group'>
                <GenderRatio className='charts' hostName={props.hostName} token={props.token}/>
                <CourseStudentGraph className='charts' hostName={props.hostName} token={props.token}/>
            </div>
            <hr/>
            <div className='chart-container'>
                <FeesCollectionFeesDue className='charts' hostName={props.hostName} token={props.token}/>
            </div>
        </div>
    </section>
    <Loader loaderClasses={loaderClasses} />
    </>
    )
};

export default Dashboard;