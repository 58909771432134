import React, { useEffect, useState } from "react";
import DueFeesTable from "../../components/admin_components/DueFeesTable";
import "./DueFeesList.css";
import Loader from "../../components/admin_components/Loader";
import { useNavigate } from "react-router-dom";

const DueFeesList = (props) => {
  const navigate = useNavigate();

  useEffect(() => {
    props.setTitle(
      "Due Fees List | Admin Panel | RedSky Educations | Educations for future"
    );
  }, [props]);

  useEffect(() => {
    if (props.isHeadAdmin === "0") {
      navigate("../all-students");
    }
  });
  let d = new Date();
  let year = d.getFullYear();
  let month = d.getMonth() < 9 ? `0${d.getMonth() + 1}` : d.getMonth() + 1;

  const [dueFeesListData, setDueFeesListData] = useState([]);
  const [isVisibleDueTable, setIsVisibleDueTable] = useState(
    "form-container d-none"
  );
  const [loaderClasses, setLoaderClasses] = useState("loader-container d-none");
  const [totalDueAmount, setTotalDueAmount] = useState(0);

  const fetchDueList = (searchMonth = `${year}-${month}`) => {
    let fetchYear = searchMonth.slice(0, 4);
    // let fetchYear = 2023;
    let fetchMonth = searchMonth.slice(5, 7);
    // let fetchMonth = 7;
    setLoaderClasses("loader-container");
    setIsVisibleDueTable("form-container d-none");
    setDueFeesListData([]);
    setTotalDueAmount([]);

    // console.log(`${props.hostName}/redsky_api/getDueFeesListPerMonth.php?token=${props.token}&month=${fetchMonth}&year=${fetchYear}`);

    fetch(
      `${props.hostName}/redsky_api/getDueFeesListPerMonth.php?token=${props.token}&month=${fetchMonth}&year=${fetchYear}`
    )
      .then((resp) => resp.json())
      .then((data) => {
        if (data.code === 5) {
          setDueFeesListData(data.data["due_list"]);
          setTotalDueAmount(data.data["total_due_amount"]);
          setIsVisibleDueTable("form-container");
        } else {
          alert(data.data);
        }
        setLoaderClasses("loader-container d-none");
      })
      .catch((err) => console.log(err));
  };

  const sendPaymentDueReminder = (contact) => {
    setLoaderClasses("loader-container");
    fetch(
      `${props.hostName}/redsky_api/send_payment_due_reminder.php?token=${props.token}&contact=${contact}`
    )
      .then((resp) => resp.json())
      .then((data) => {
        if (data.code === 5) {
          console.log(data);
          alert(data.data.msg);
        } else {
          alert(data.data);
        }
        setLoaderClasses("loader-container d-none");
      })
      .catch((err) => console.log(err));
  };

  useEffect(fetchDueList, [props.token, props.hostName, year, month]);

  return (
    <>
      <section className="due-fees-list-form-container">
        <header>Due Fees List</header>
        <form className="form">
          <div className="input-box">
            <label>Searching Month</label>
            <input
              type="month"
              placeholder="Enter Starting Month"
              defaultValue={`${year}-${month}`}
              onChange={(e) => {
                fetchDueList(e.target.value);
              }}
              required
            />
          </div>
        </form>
      </section>

      <DueFeesTable
        dueFeesListData={dueFeesListData}
        totalDueAmount={totalDueAmount}
        isVisibleDueTable={isVisibleDueTable}
        sendPaymentDueReminder={sendPaymentDueReminder}
      />
      <Loader loaderClasses={loaderClasses} />
    </>
  );
};

export default DueFeesList;
