import React, { forwardRef, useImperativeHandle, useState } from 'react';
import './FilterStudent.css';


const FilterOtherPayments = (props, ref) => {
    const [filterId, setfilterId] = useState('');
    const [filterName, setfilterName] = useState('');
    const [filterGender, setfilterGender] = useState('');
    const [filterPurpose, setPurpose] = useState('');

    const filterFormHandler = (e) => {
        e.preventDefault();
        let filterObj = {
            filterId: filterId,
            filterName:filterName,
            filterGender:filterGender,
            filterPurpose:filterPurpose
        }
        props.getFilteredData(filterObj);
    };

    const resetFilterForm = () => {
        let filterForm = document.querySelector('form.filter-box');
        filterForm.querySelector(`.gender-option input[value='']`).checked = true;
        filterForm.querySelector(`.purpose-options option[value='']`).selected = true;
        setfilterId('');
        setfilterName('');
        setfilterGender('');
        setPurpose('');
    };

    useImperativeHandle(ref, ()=>({
        resetFilterForm
    }));

    return (
        <form className='filter-box d-none' onSubmit={filterFormHandler}>
            
            <abbr title='Reset Filter'><span className="material-icons material-symbols-outlined reset-filter-btn" onClick={resetFilterForm}>filter_list_off</span></abbr>
        
            <div className="input-box">
                <label className='field'>ID :</label>
                <input type="number" placeholder='Filter By ID' value={filterId} onChange={(e)=>setfilterId(e.target.value)}/>
            </div>

            <div className="input-box">
                <label className='field'>Name :</label>
                <input type="text" placeholder='Filter By Name' value={filterName} onChange={(e)=>setfilterName(e.target.value)}/>
            </div>

            <div className="input-box">
                <label className='field'>Gender :</label>
                <div className="gender-option">
                    <div className="gender">
                        <label><input type="radio" name="gender" value='' onChange={(e)=>setfilterGender(e.target.value)} /> ALL</label>
                    </div>
                    <div className="gender">
                        <label><input type="radio" name="gender" value='Male' onChange={(e)=>setfilterGender(e.target.value)}/> Male</label>
                    </div>
                    <div className="gender">
                        <label><input type="radio" name="gender" value='Female' onChange={(e)=>setfilterGender(e.target.value)} /> Female</label>
                    </div>
                    <div className="gender">
                        <label><input type="radio" name="gender" value='Others' onChange={(e)=>setfilterGender(e.target.value)} /> Prefer not to say</label>
                    </div>
                    
                </div>
            </div>

            <div className="input-box">
                <label className='field'>Purpose :</label>
                <div className="select-box">
                    <select onChange={(e) => { setPurpose(e.target.value) }} className="purpose-options">
                        <option value=''>All Purpose</option>
                        <option value='RFID Card'>RFID Card</option>
                        <option value='Notebook'>Notebook</option>
                        <option value='Monthly Card'>Monthly Card</option>
                        <option value='Olympiad Course'>Olympiad Course</option>
                    </select>
                </div>
            </div>

            {/* <div className="input-box">
                <label className='field'>Date Of Birth :</label>
                <div className='dob-filter'>
                    <label>From : <input type="date" value={filterDOBFrom} onChange={(e)=>setfilterDOBFrom(e.target.value)} /></label>
                    <label>To : <input type="date" value={filterDOBTo} onChange={(e)=>setfilterDOBTo(e.target.value)} /></label>
                </div>
            </div> */}

            <div className="input-box d-flex justify-center">
                <input type="submit" className='apply-filter-btn' value="Apply Filter" onClick={()=> props.filterHandler()}/>
            </div>

        </form>

    )
}

export default forwardRef(FilterOtherPayments);

