import React, { useEffect, useState } from "react";
import "./AddNewStudentForm.css";
import "./OtherPaymentForm.css";
import "./Loader.css";
import Loader from "./Loader";
import { Link } from "react-router-dom";

const OtherPaymentForm = (props) => {
  const [isVisible, setIsVisible] = useState("");
  useEffect(() => {
    if (props.isHeadAdmin === "0") {
      setIsVisible("d-none");
    }
  }, [props.isHeadAdmin]);
  const [stuRegNo, setStuRegNo] = useState("");
  const [amount, setAmount] = useState("");
  const [purpose, setPurpose] = useState("");
  const [payerName, setPayerName] = useState("");

  const fetStudentName = () => {
    fetch(
      `${props.hostName}/redsky_api/getSingleStudentName.php?token=${props.token}&stu_reg_no=${stuRegNo}`
    )
      .then((resp) => resp.json())
      .then((data) => {
        if (data.code === 5) {
          setPayerName(data.data);
        } else {
          alert(data.data);
        }
      })
      .catch((err) => console.log(err));
  };
  const checkKeyPressHandler = (e) => {
    if (e.key === "Enter") {
      fetStudentName();
    }
  };
  const onBlurHandler = (e) => {
    if (e.target.value.trim() !== "") {
      fetStudentName();
    }
  };
  const resetFormFields = () => {
    setStuRegNo("");
    setAmount("");
    setPurpose("");
    document.querySelectorAll(".placeHolder")[0].selected = true;
  };

  const formValidate = (e) => {
    e.preventDefault();
    if (
      stuRegNo.trim() === "" ||
      amount.trim() === "" ||
      purpose.trim() === ""
    ) {
      alert("Please Fill Out All Fields!");
      return;
    }

    let formData = {
      stu_reg_no: stuRegNo,
      amount: amount,
      purpose: purpose,
    };

    // console.log(JSON.stringify(formData));
    props.setFormData(formData);
    resetFormFields();
  };

  return (
    <>
      <section
        className={`add-student-form-container other-payment ${props.isShowForm}`}
      >
        <header>
          Other Payments{" "}
          <Link
            to="../view-all-others-payments"
            className={isVisible}
            style={{ position: "absolute", right: "0", top: "2px" }}
          >
            <span className="material-icons material-symbols-outlined">
              apps
            </span>
          </Link>
        </header>
        <form action="#" className="form" onSubmit={formValidate}>
          <div className="input-box">
            <label>
              ID:<span className="color-red">*</span>
            </label>
            <input
              type="text"
              placeholder="Enter student ID"
              value={stuRegNo}
              onChange={(e) => {
                setStuRegNo(e.target.value);
              }}
              onKeyPress={checkKeyPressHandler}
              onBlur={onBlurHandler}
              required
            />
            <p className="payer-name">&nbsp;{payerName}</p>
            {/* <button className='payer-verify-btn'>Verify Student</button> */}
          </div>

          <div className="input-box">
            <label>
              Amount<span className="color-red">*</span>
            </label>
            <input
              type="number"
              placeholder="Enter Amount in Rupees"
              value={amount}
              onChange={(e) => {
                setAmount(e.target.value);
              }}
              required
            />
          </div>

          <div className="input-box">
            <label>
              Purpose<span className="color-red">*</span>
            </label>
            <div className="select-box">
              <select
                onChange={(e) => {
                  setPurpose(e.target.value);
                }}
                className="purpose-options"
              >
                <option hidden className="placeHolder" value="">
                  Select a purpose
                </option>
                <option value="RFID Card">RFID Card</option>
                <option value="Notebook">Notebook</option>
                <option value="Monthly Card">Monthly Card</option>
                <option value="Olympiad Course">Olympiad Course</option>
                <option value="ID Card">ID Card</option>
              </select>
            </div>
          </div>

          <button type="submit">Submit</button>
        </form>
      </section>

      <Loader loaderClasses={props.loaderClasses} />
      {/* <div className={loaderClasses}>
        <div className="box">
          <div className="circle-container">
            <span className="circle"></span>
            <span className="circle"></span>
            <span className="circle"></span>
            <span className="circle"></span>
          </div>
          <br />
          <span>Loading</span>
        </div>
      </div> */}
    </>
  );
};

export default OtherPaymentForm;
