import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/admin_components/Loader";
import logo from "../../assets/img/redsky logo.jpeg";
import "./LogBook.css";

const LogBook = (props) => {
  //   props.setTitle("RedSky Digital Passbook | Student Panel | RedSky Educations");
  const param = useParams();
  const assignmentTable = useRef(null);
  const navigate = useNavigate();

  const [loaderClasses, setLoaderClasses] = useState("loader-container d-none");
  const [studentData, setStudentData] = useState({});
  const [assignmentList, setAssignmentList] = useState([]);
  const [assignmentBackUpList, setAssignmentBackUpList] = useState([]);

  useEffect(() => {
    setLoaderClasses("loader-container");

    fetch(
      `${props.hostName}/redsky_api/student/get_log_book_details.php?stu_reg_no=${param.reg_no}&contact=${param.contact}`
    )
      .then((resp) => resp.json())
      .then((data) => {
        if (data.code === 5) {
          setStudentData(data.data.stu_data);
          setAssignmentList(data.data.assignment_data);
          setAssignmentBackUpList(data.data.assignment_data);
        } else {
          alert(data.data);
          navigate("../");
        }
        setLoaderClasses("loader-container d-none");
      })
      .catch((err) => console.log(err));
  }, [param, props.hostName, navigate]);

  const filterAssignmentData = (filterType = "All") => {
    if (filterType === "All") {
      setAssignmentList(assignmentBackUpList);
    } else {
      setAssignmentList(
        assignmentBackUpList.filter((elem) => elem.task_type === filterType)
      );
    }

    if (assignmentTable.current) {
      assignmentTable.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <Helmet>
        {/* <meta
          name="description"
          content="RedSky Digital Passbook | Student Panel | RedSky Educations"
        /> */}
        <title>Class Log details | Student Panel | RedSky Educations</title>
      </Helmet>
      <div className="container-fluid py-4" id="class-log-details">
        <div className="row">
          <div className="col-12 text-center">
            <img src={logo} alt="Logo" height="150" />
            <p className="text-uppercase fs-5 mt-3 fw-medium">
              {studentData.s_fname} {studentData.s_lname}
            </p>
            <p className="text-uppercase fs-6 mt-3 fw-medium">
              {studentData.course_name}
            </p>

            {/* <h4 className="mb-3">RedSky Digital Passbook</h4> */}
            <h5 className="mb-2 mt-3">Log Book</h5>
            <hr className="border border-secondary border-1 opacity-75 mt-3" />
          </div>
        </div>

        <div className="row gy-3 boxes pb-2">
          <div className="col-12 col-md-6 col-xl-3">
            <div
              className="box"
              style={{ backgroundColor: "#76d7c4", cursor: "pointer" }}
              onClick={() => filterAssignmentData()}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="36px"
                viewBox="0 -960 960 960"
                width="36px"
                fill="#000"
              >
                <path d="M360-280q-33 0-56.5-23.5T280-360v-440q0-33 23.5-56.5T360-880h235q-8 19-11.5 39t-3.5 41H360v408q45-42 101.5-65T580-480q61 0 118 23t102 65q1 1 0 0v-188q21 0 41-3.5t39-11.5v235q0 33-23.5 56.5T800-280H360Zm220-350ZM224-81q-33 4-58.5-16T135-150L80-587q-5-33 16-59t54-30l50-6v80l-40 5 54 437 298-40h218q0 26-21 43.5T661-135L224-81Zm-10-79Zm493-540 71-200h44l72 200h-43l-15-44h-72l-15 44h-42Zm67-74h52l-26-82-26 82ZM580-400q-35 0-69 10t-63 30h264q-29-20-63-30t-69-10Zm0-340q46 0 78 32t32 78q0 46-32 78t-78 32q-46 0-78-32t-32-78q0-46 32-78t78-32Zm0 80q-13 0-21.5 8.5T550-630q0 13 8.5 21.5T580-600q13 0 21.5-8.5T610-630q0-13-8.5-21.5T580-660Z" />
              </svg>
              <span className="text my-2">All</span>
              {/* <span className="number">{100}</span> */}
              <span className="sub-text">(Theory + Practical + Test)</span>
            </div>
          </div>
          <div className="col col-12 col-md-6 col-xl-3">
            <div
              className="box"
              style={{
                backgroundColor: "#f8c471",
                cursor: "pointer",
              }}
              onClick={() => filterAssignmentData("Theory")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="36px"
                viewBox="0 -960 960 960"
                width="36px"
                fill="#000"
              >
                <path d="M240-80v-172q-57-52-88.5-121.5T120-520q0-150 105-255t255-105q125 0 221.5 73.5T827-615l54 213.67q4.33 15.66-5.67 28.5-10 12.83-26.66 12.83H760v133.33q0 27.5-19.58 47.09Q720.83-160 693.33-160H600v80h-66.67v-146.67h160v-200h112l-42.66-171.66q-23.67-95-102.67-155t-180-60q-122 0-207.67 84.66-85.66 84.67-85.66 206.36 0 62.95 25.71 119.6Q238.1-346.06 285.33-302l21.34 20v202H240Zm256-366.67Zm-50 77.34h66.67l4-46q10.66-2.34 19.81-7.98 9.15-5.64 16.19-12.69L596-420l32-54.67-33.33-26q4-12 4-24.66 0-12.67-4-24.67L628-576l-32-54.67-43.33 16q-7.23-6.79-16.61-12.23-9.39-5.43-19.39-8.43l-4-46H446l-4 46q-10 3-19.39 8.43-9.39 5.44-16.61 12.23l-43.33-16-32 54.67L364-550q-4 12-4 24.67 0 12.66 4 24.66l-33.33 26 32 54.67L406-436q7.04 7.05 16.19 12.69 9.14 5.64 19.81 7.98l4 46Zm33.41-89.34q-27.74 0-47.24-19.42-19.5-19.42-19.5-47.17 0-27.74 19.42-47.24 19.42-19.5 47.16-19.5 27.75 0 47.25 19.42t19.5 47.17q0 27.74-19.42 47.24-19.42 19.5-47.17 19.5Z" />
              </svg>
              <span className="text my-2">Theory</span>
              {/* <span className="number">{90}</span> */}
              <span className="sub-text">All Time</span>
            </div>
          </div>
          <div className="col col-12 col-md-6 col-xl-3">
            <div
              className="box"
              style={{ backgroundColor: "#d7bde2", cursor: "pointer" }}
              onClick={() => filterAssignmentData("Practical")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="36px"
                viewBox="0 -960 960 960"
                width="36px"
                fill="#000"
              >
                <path d="m280-620 80-80-80-80-80 80 80 80Zm200-40ZM160-400q-33 0-56.5-23.5T80-480v-360q0-33 23.5-56.5T160-920h640q33 0 56.5 23.5T880-840v360q0 33-23.5 56.5T800-400H671q6-20 8-40t0-40h121v-360H160v360h121q-2 20 0 40t8 40H160Zm500-280q25 0 42.5-17.5T720-740q0-25-17.5-42.5T660-800q-25 0-42.5 17.5T600-740q0 25 17.5 42.5T660-680ZM200-40v-84q0-35 19.5-65t51.5-45q49-23 102-34.5T480-280q54 0 107 11.5T689-234q32 15 51.5 45t19.5 65v84H200Zm80-80h400v-4q0-12-7-22t-18-15q-42-19-86-29t-89-10q-45 0-89 10t-86 29q-11 5-18 15t-7 22v4Zm200-200q-58 0-99-41t-41-99q0-58 41-99t99-41q58 0 99 41t41 99q0 58-41 99t-99 41Zm0-80q25 0 42.5-17.5T540-460q0-25-17.5-42.5T480-520q-25 0-42.5 17.5T420-460q0 25 17.5 42.5T480-400Zm0-60Zm0 340Z" />
              </svg>

              <span className="text my-2">Practical</span>
              {/* <span className="number">&#8377;{80}</span> */}
              <span className="sub-text">All Time</span>
            </div>
          </div>

          <div className="col col-12 col-md-6 col-xl-3">
            <div
              className="box"
              style={{ backgroundColor: "#b3b6b7", cursor: "pointer" }}
              onClick={() => filterAssignmentData("Test")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="36px"
                viewBox="0 -960 960 960"
                width="36px"
                fill="#000"
              >
                <path d="M480-40q-186 0-313-69.5T40-280q0-69 64-126.5T280-494v82q-73 23-116.5 59T120-280q0 64 108 112t252 48q144 0 252-48t108-112q0-37-43.5-73T680-412v-82q112 30 176 87.5T920-280q0 101-127 170.5T480-40ZM360-200v-440H160v-80h640v80H600v440h-80v-200h-80v200h-80Zm120-560q-33 0-56.5-23.5T400-840q0-33 23.5-56.5T480-920q33 0 56.5 23.5T560-840q0 33-23.5 56.5T480-760Z" />
              </svg>
              <span className="text my-2">Test</span>
              {/* <span className="number">&#8377;{70}</span> */}
              <span className="sub-text">All Time</span>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div
              className="accordion"
              id="accordionLogBook"
              ref={assignmentTable}
            >
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button text-white"
                    style={{ backgroundColor: "#0a2558" }}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    Assignment Details
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse show"
                  data-bs-parent="#accordionLogBook"
                >
                  <div className="accordion-body">
                    {assignmentList.length > 0 ? (
                      <>
                        <div className="table-responsive">
                          <table className="table table-bordered align-middle">
                            <thead>
                              <tr>
                                <th scope="col" className="fit">
                                  Sl. No.
                                </th>
                                <th scope="col">Assignment Date</th>
                                <th scope="col">Class Type</th>
                                <th scope="col">Class Details</th>
                                <th scope="col">Attained</th>
                              </tr>
                            </thead>
                            <tbody>
                              {assignmentList.map((elem, index) => {
                                return (
                                  <tr key={index} className="fit">
                                    <td data-label="Sl. No.">{index + 1}</td>
                                    <td data-label="Assignment Date">
                                      {elem.for_date || "N/A"}
                                    </td>
                                    <td data-label="Class Type">
                                      {elem.task_type}
                                    </td>
                                    <td
                                      data-label="Class Details"
                                      className="p-2"
                                    >
                                      <p className="mb-0 fw-semibold">
                                        {elem.task_heading}
                                      </p>
                                      <p
                                        className="my-2 text-wrap"
                                        title={elem.task_details}
                                      >
                                        {elem.task_details}
                                      </p>
                                    </td>
                                    <td
                                      data-label="Attained"
                                      className={
                                        elem.present_status === "Absent"
                                          ? "table-danger bordered border-1"
                                          : ""
                                      }
                                    >
                                      {" "}
                                      {elem.present_status}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </>
                    ) : (
                      <p className="mb-0 mt-4 text-center">
                        No assignment Details Found
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Loader loaderClasses={loaderClasses} />
    </>
  );
};

export default LogBook;
