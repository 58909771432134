import React, { useRef, useState } from 'react';

const MarksModifyFormRow = (props) => {
  const fixValue = item => item ? item : '';
  const [dateOfExam, setDateOfExam] = useState(fixValue(props.elem.date_of_exam));
  const [theoryObtain, setTheoryObtain] = useState(fixValue(props.elem.th_ob));
  const [theoryTotal, setTheoryTotal] = useState(fixValue(props.elem.th_total));
  const [practicalObtain, setPracticalObtain] = useState(fixValue(props.elem.prac_ob));
  const [practicalTotal, setPracticalTotal] = useState(fixValue(props.elem.prc_total));
  const [vivaObtain, setVivaObtain] = useState(fixValue(props.elem.viva_ob));
  const [vivaTotal, setVivaTotal] = useState(fixValue(props.elem.viva_total));
  const [semRank, setSemRank] = useState(fixValue(props.elem.sem_rank));
  const dateOfExamElem = useRef();
  const thOb = useRef();
  const thTot = useRef();
  const prcOb = useRef();
  const prcTot = useRef();
  const vivaOb = useRef();
  const vivaTot = useRef();
  const [btnType, setBtnType] = useState('edit');
  const [isDisabled, setIsDisabled] = useState(true);
  
  
  const setError = (elem) => {
    elem.current.classList.add('border-red');
  }
  const removeError = (elem) => {
    elem.current.classList.remove('border-red');
  }

  function isAllEmpty(){
    if(dateOfExam.trim() === '' && theoryObtain.trim() === '' && theoryTotal.trim() === '' && practicalObtain.trim() === '' &&  practicalTotal.trim() === '' &&  vivaObtain.trim() === '' &&  vivaTotal.trim() === ''){
      setError(dateOfExamElem);
      setError(thOb);
      setError(thTot);
      setError(prcOb);
      setError(prcTot);
      setError(vivaOb);
      setError(vivaTot);
      return true;
    }else{
      removeError(dateOfExamElem);
      removeError(thOb);
      removeError(thTot);
      removeError(prcOb);
      removeError(prcTot);
      removeError(vivaOb);
      removeError(vivaTot);
    }
    return false;
  }

  function checkIsNumberAndNegative(){
    
    if( (isNaN(theoryObtain)) || (parseInt(theoryObtain) < 0)){
      setError(thOb);
      return true;
    }else{
      removeError(thOb);
    }

    if( (isNaN(theoryTotal)) || (parseInt(theoryTotal) < 0)){
      setError(thTot);
      return true;
    }else{
      removeError(thTot);
    }

    if( (isNaN(practicalObtain)) || (parseInt(practicalObtain) < 0)){
      setError(prcOb);
      return true;
    }else{
      removeError(prcOb);
    }

    if( (isNaN(practicalTotal)) || (parseInt(practicalTotal) < 0)){
      setError(prcTot);
      return true;
    }else{
      removeError(prcTot);
    }

    if( (isNaN(vivaObtain)) || (parseInt(vivaObtain) < 0)){
      setError(vivaOb);
      return true;
    }else{
      removeError(vivaOb);
    }

    if( (isNaN(vivaTotal)) || (parseInt(vivaTotal) < 0)){
      setError(vivaTot);
      return true;
    }else{
      removeError(vivaTot);
    }

    return false;
  }

  function checkBothSet(){
    if((theoryObtain && !theoryTotal) || (!theoryObtain && theoryTotal)){
      if(!theoryObtain){
        setError(thOb);
        return true;
      }
      else{
        removeError(thOb);
      }
      if(!theoryTotal){
        setError(thTot);
        return true;
      }
      else{
        removeError(thTot);
      }
    }

    if((practicalObtain && !practicalTotal) || (!practicalObtain && practicalTotal)){
      if(!practicalObtain){
        setError(prcOb);
        return true;
      }
      else{
        removeError(prcOb);
      }
      if(!practicalTotal){
        setError(prcTot);
        return true;
      }
      else{
        removeError(prcTot);
      }
    }

    if((vivaObtain && !vivaTotal) || (!vivaObtain && vivaTotal)){
      if(!vivaObtain){
        setError(vivaOb);
        return true;
      }
      else{
        removeError(vivaOb);
      }
      if(!vivaTotal){
        setError(vivaTot);
        return true;
      }
      else{
        removeError(vivaTot);
      }
    }

    return false;
  }

  function checkIsLesser(){
    if(parseInt(theoryObtain) > parseInt(theoryTotal)){
      setError(thOb)
      return true;
    }else{
      removeError(thOb);
    }

    if(parseInt(practicalObtain) > parseInt(practicalTotal)){
      setError(prcOb)
      return true;
    }else{
      removeError(prcOb);
    }
    if(parseInt(vivaObtain) > parseInt(vivaTotal)){
      setError(vivaOb);
      return true;
    }else{
      removeError(vivaOb);
    }

    return false;
  }


  const validate = (marksId) => {
        
    if(isAllEmpty()){return;}
    if(checkIsNumberAndNegative()){return;}
    if(checkBothSet()){return;}
    if(checkIsLesser()){return;}
          
    let marksData = {
      marks_id: marksId,
      date_of_exam: dateOfExam,
      theory_obtain: theoryObtain,
      theory_total: theoryTotal,
      practical_obtain: practicalObtain,
      practical_total: practicalTotal,
      viva_obtain: vivaObtain,
      viva_total: vivaTotal,
      sem_rank: semRank
    };
    props.modifyMarksData(marksData, setIsDisabled, setBtnType);
  }
 
  const modify = (marksId) => {
    if(btnType === 'edit'){
        setBtnType('done');
        setIsDisabled(false);
        return;
    }
    validate(marksId);  
  }

  const deleteMarks = (marksId) => {
    props.setVerifyPinClasses('verify-pin-container');
    props.setDeleteMarksId(marksId);
  }

  return (
    <tr>
        <td className='printable'>{props.elem.id}</td>
        <td className='no-wrap printable'>{props.elem.fname} {props.elem.lname}</td>
        <td className='no-wrap'><input type='date' ref={dateOfExamElem} value={dateOfExam} onChange={(e) => setDateOfExam(e.target.value)} disabled={isDisabled} /></td>
        <td><input type='text' ref={thOb} placeholder='Obtained' value={theoryObtain} onChange={(e)=>setTheoryObtain(e.target.value)}  disabled={isDisabled} /></td>
        <td><input type='text' ref={thTot} placeholder='Out Of' value={theoryTotal} onChange={(e)=>setTheoryTotal(e.target.value)} disabled={isDisabled} /></td>
        <td><input type='text' ref={prcOb} placeholder='Obtained' value={practicalObtain} onChange={(e)=>setPracticalObtain(e.target.value)} disabled={isDisabled} /></td>
        <td><input type='text' ref={prcTot} placeholder='Out Of' value={practicalTotal} onChange={(e)=>setPracticalTotal(e.target.value)} disabled={isDisabled} /></td>
        <td><input type='text' ref={vivaOb} placeholder='Obtained' value={vivaObtain} onChange={(e)=>setVivaObtain(e.target.value)} disabled={isDisabled} /></td>
        <td><input type='text' ref={vivaTot} placeholder='Out Of' value={vivaTotal} onChange={(e)=>setVivaTotal(e.target.value)} disabled={isDisabled} /></td>
        <td><input type='text' ref={vivaTot} placeholder='Rank' value={semRank} onChange={(e)=>setSemRank(e.target.value)} disabled={isDisabled} /></td>
        <td><button className='d-flex' onClick={()=>modify(props.elem.marks_id)}><span className="material-icons material-symbols-outlined submit">{btnType}</span></button></td>
        <td><button className='d-flex' onClick={()=>deleteMarks(props.elem.marks_id)}><span className={`material-icons material-symbols-outlined ${!isDisabled ? 'disabled' : ''}`} disabled={isDisabled}>delete</span></button></td>
    
        <td className='printable d-none'>{theoryObtain}/{theoryTotal}</td>
        <td className='printable d-none'>{practicalObtain}/{practicalTotal}</td>
        <td className='printable d-none'>{vivaObtain}/{vivaTotal}</td>
        <td className='printable d-none'>{parseInt(theoryObtain)+parseInt(practicalObtain)+parseInt(vivaObtain)}/{parseInt(theoryTotal)+parseInt(practicalTotal)+parseInt(vivaTotal)}</td>
    </tr>
  )
}

export default MarksModifyFormRow;

