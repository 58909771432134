import React, { useEffect, useRef, useState } from 'react';
import Loader from '../../components/admin_components/Loader';
import RecordAttendance from '../../components/admin_components/RecordAttendance';
import attn_success from '../../assets/audio/attendance_success.mpeg';
import attn_error from '../../assets/audio/attendance_error.mpeg';

const AddAttendace = (props) => {
    const [loaderClasses, setLoaderClasses] = useState('loader-container d-none');
    const attendanceSuccessAudio = useRef();
    const attendanceErrorAudio = useRef();

    useEffect(()=> {props.setTitle(`Record Attendance | ${parseInt(props.isHeadAdmin) ? "Admin Panel" : "User Panel"} | RedSky Educations | Educations for future`)}, [props]);

    const storeAttendaceData = (stuRegNo) => {
        setLoaderClasses('loader-container');
        fetch(`${props.hostName}/redsky_api/recordAttendace.php?token=${props.token}`,
          {
            method: "POST",
            body: JSON.stringify({stu_reg_no : stuRegNo}),
            headers: {
              'Content-Type': 'application/json;charset=UTF-8'
            }
          })
          .then(resp => resp.json()).then(data => {
            setLoaderClasses('loader-container d-none');
            if(data.code === 8){
              attendanceSuccessAudio.current.play(); 
            }else{
              attendanceErrorAudio.current.play();
            }
          }).catch(err => console.log(err));
      };

  return (
    <>
        <RecordAttendance storeAttendaceData={storeAttendaceData}/>
        <audio controls style={{visibility:'hidden'}} ref={attendanceSuccessAudio}>
          <source src={attn_success} type="audio/mpeg" />
        </audio>
        <audio controls style={{visibility:'hidden'}} ref={attendanceErrorAudio}>
          <source src={attn_error} type="audio/mpeg" />
        </audio>

        <Loader loaderClasses={loaderClasses}/>
    </>
  )
}

export default AddAttendace;
