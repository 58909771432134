import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from '../../components/admin_components/Loader';
import ViewAllOthersPaymentsTable from '../../components/admin_components/ViewAllOthersPaymentsTable';

const ViewAllOthersPayments = (props) => {
    const navigate = useNavigate();

    useEffect(()=> {props.setTitle("View Other Payment List | Admin Panel | RedSky Educations | Educations for future")}, [props]);

    useEffect(()=>{
      if(props.isHeadAdmin === '0'){
        navigate('../all-students');
      }
    });
    let tableCols = ['Receipt No', 'ID', 'Name', 'Purpose', 'Amount', 'Date', 'Edit', 'Delete', 'Print'];
    let [rowsData, setRowsData] = useState([]);
    const [loaderClasses, setLoaderClasses] = useState('loader-container d-none');
    const [tableClasses, setTableClasses] = useState('table-container');
    const [msgClasses, setMsgClasses] = useState('msg d-none');
    const [msgData, setMsgData] = useState('');
    const [rowLimit, setRowLimit] = useState(15);
    const [LimitRowsCountClasses, setLimitRowsCountClasses] = useState('row-limit-container d-none');   
    
    const fetchAllOthersPayment = () => {

        setLoaderClasses('loader-container');
        setRowsData([]);
        fetch(`${props.hostName}/redsky_api/getAllOthersPayment.php?token=${props.token}`)
            .then(resp => resp.json()).then(data => {
                setLoaderClasses('loader-container d-none');
              
                if(data.code === 5){
                    setRowsData(data.data);
                    setTableClasses('table-container');
                    setMsgClasses('msg d-none');
                    setLimitRowsCountClasses('row-limit-container');
                }
                else{
                    setTableClasses('table-container d-none');
                    setMsgData(data.data);
                    setMsgClasses('msg');
                    setLimitRowsCountClasses('row-limit-container d-none');
                }
                
            }).catch(err => console.log(err));
    };
    useEffect(fetchAllOthersPayment,[props.token,props.hostName,rowLimit]);
    
    const getFilteredData = (filterObj) => {
        setLoaderClasses('loader-container');
        setRowsData([]);

        fetch(`${props.hostName}/redsky_api/getFilteredOthersPayments.php?token=${props.token}`,
        {
            method: "POST",
            body: JSON.stringify(filterObj),
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            }
        })
            .then(resp => resp.json()).then(data => {
              if(data.code === 8){
                setRowsData(data.data);
                setTableClasses('table-container');
                setMsgClasses('msg d-none');
                setLimitRowsCountClasses('row-limit-container');
              }
              else{
                setTableClasses('table-container d-none');
                setMsgData(data.data);
                setMsgClasses('msg');
                setLimitRowsCountClasses('row-limit-container d-none');
              }
              setLoaderClasses('loader-container d-none');
                
            }).catch(err => console.log(err));
    };    
      
    const setDeleteOthersPayment = (deleteData) => {

      setLoaderClasses('loader-container');
        
      //deletion code will go here
      fetch(`${props.hostName}/redsky_api/deleteOtherPayment.php?token=${props.token}`,
      {
        method: "POST",
        body: JSON.stringify(deleteData),
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        }
      })
      .then(resp => resp.json()).then(data => {
        if(data.code === 5){
          fetchAllOthersPayment();
        }
        alert(data.data);
        setLoaderClasses('loader-container d-none');
        
      }).catch(err => console.log(err));
      };

      const getRowLimit = (limitVal) => {
        setRowLimit(limitVal);
        fetchAllOthersPayment();
      };
    
    return (
        <>
            <ViewAllOthersPaymentsTable tableClasses={tableClasses} tableCols={tableCols} rowsData={rowsData} token={props.token} fetchAllOthersPayment={fetchAllOthersPayment} setDeleteOthersPayment={setDeleteOthersPayment} getFilteredData={getFilteredData} getRowLimit={getRowLimit} LimitRowsCountClasses={LimitRowsCountClasses} msgClasses={msgClasses} msgData={msgData}   />

            <Loader loaderClasses={loaderClasses}/>
        </>
    );
}

export default ViewAllOthersPayments;
