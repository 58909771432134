import React, { useEffect, useState } from 'react';
import Chart from 'react-google-charts';
import Loader from './Loader';

const FeesCollectionFeesDue = (props) => {
  const [graphData, setGraphData] = useState('');
  const [feesCollectedCountData, setFeesCollectedCountData] = useState([]);
  // const [feesDueCountData, setFeesDueCountData] = useState([]);
  const [loaderClasses, setLoaderClasses] = useState('loader-container');

  useEffect(() => {

    setLoaderClasses("loader-container");

    fetch(`${props.hostName}/redsky_api/getFeesCollectionCountPerMonth.php?token=${props.token}`)
      .then(resp => resp.json()).then(data => {
        
        if (data.code === 5) {
            // setFeesCollectedCountData(data.data.reverse());
            setFeesCollectedCountData(data.data);
            setLoaderClasses("loader-container d-none");
        }

      }).catch(err => console.log(err));

      // setLoaderClasses("loader-container");
      // fetch(`${props.hostName}/redsky_api/getFeesDueCountPerMonth.php?token=${props.token}`)
      // .then(resp => resp.json()).then(data => {

      //   if (data.code === 5) {
      //       setFeesDueCountData(data.data.reverse());
      //       setLoaderClasses("loader-container d-none");
      //   }

      // }).catch(err => console.log(err));

  }, [props.token,props.hostName]);



  useEffect(() => {
    // const monthArr = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
    // // if (feesCollectedCountData.length !== 0 && feesDueCountData.length !== 0) {
    // if (feesCollectedCountData.length !== 0) {
    //   let data = [];
    //   let year;
    //   // let feesDue;
    //   let feesCollection, monthYear;

    //   feesCollectedCountData.forEach((elem, index) => {
    //     year = elem.year.toString().slice(2, 4);
    //     monthYear = `${monthArr[elem.month - 1]}, ${year}`;
    //     feesCollection = parseInt(elem.total_fees);
    //     // feesDue = parseInt(feesDueCountData[index].total_due_fees);

    //     // data[index] = [monthYear, feesCollection, feesDue];
    //     data[index] = [monthYear, feesCollection];
    //   });

    //   setGraphData([
    //     // ["Months", "Collected", "Due"], ...data
    //     ["Months", "Collected"], ...data
    //   ]);

    // }


    if (feesCollectedCountData.length !== 0 ){
      let data = [];
      feesCollectedCountData.forEach((elem, index) => {
        data[index] = [
                        elem.month_name, 
                        elem.total_payment
                      ];
      });
  
      setGraphData([
        // ["Months", "Collected", "Due"], ...data
        ["Months", "Collected"], ...data
      ]);
    }
  }, [feesCollectedCountData]);


  return (
    <>
      <Chart className={props.className} chartType="Bar" data={graphData}
        height="400px" width='1200px'
        options={{
            title: "Fees Details",
            curveType: "function",
            legend: { position: "top" },
            responsive: true,
            colors: ['#ff9900', '#1e88e5'],  
            chart: {
                title: 'Fees Data',
                subtitle: 'Paid',
                
              },       
        }} />

        <Loader loaderClasses={loaderClasses}/>
    </>
  )
}

export default FeesCollectionFeesDue;
