import React from 'react';
import './PaidFeesTableRow.css';
import { Link } from 'react-router-dom';

const PaidFeesTableRow = (props) => {

	const deleteHandler = (receiptNo) =>{
		props.setVerifyPinClasses('verify-pin-container');
		props.setReceiptNo(receiptNo);
	}
	return (
		<tr>
			<td data-label="No">{props.element.installment_no} Installment</td>
			{/* <!-- <td data-label="Receipt No">101</td> --> */}
			<td data-label="For Month">{props.element.for_month_year_text}</td>
			<td data-label="Date">{props.element.date}</td>
			<td data-label="Amount">{props.element.course_fees}/-</td>
			<td data-label="Edit" className={props.isExtraContentShow}>
				<Link to={`../modify-paid-fees/${props.element.receipt_no}`}>
                  <span className="material-icons material-symbols-rounded edit-btn" >edit</span>
                </Link>
			</td>
			<td data-label="Delete" className={props.isExtraContentShow}><span className="material-icons material-symbols-rounded edit-btn" onClick={()=>{deleteHandler(props.element.receipt_no)}} >delete</span></td>
			<td data-label="Action">
				<Link to={`../Print-paid-Fees/${props.element.receipt_no}`} className="print-btn">Print</Link>
			</td>
		</tr>
	)
};

export default PaidFeesTableRow;
