import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';

const Navbar = (props) => {

    const openAbleNav = useRef();

    const openMenuHandler = () => {
            openAbleNav.current.style.right = "0";
    };
    const closeMenuHandler = () => {
        openAbleNav.current.style.right = "-300px";
    }

  return (
    <>
        <div id="nav-bar">
            <div id="top-nav">
                <div id="logo" className="d-flex">
                    <span className="material-icons material-icons-round prevent-select" >add_task</span>
                    <h1>Task</h1>
                </div>
                
                <div className="d-flex">
                    <div id="nav_user_details" className="d-flex">
                        <span className="material-icons material-icons-round" style={{color: "var(--task-primary-color)", fontSize: "1.7rem"}} >account_circle</span>
                        <h3>{props.taskUserName}</h3>
                    </div>
                    <span className="material-icons material-icons-round prevent-select" onClick={openMenuHandler} id='open-menu-btn' >menu</span>
                </div>

            </div>
        </div>

        <div id="m-side-nav" ref={openAbleNav}>
            <div>
                <div id="user_details" className="d-flex">
                    <span className="material-icons material-icons-round" style={{color: "var(--task-primary-color)", fontSize: "2.5rem" }} >account_circle</span>
                    <h3 style={{fontWeight: 500, marginTop: "0.4rem", fontSize: "1.4rem" }} >{props.taskUserName}</h3>
                    
                </div>
                <div id="nav-links" style={{marginTop: "2rem"}}>
                
                    <Link to="./add-task" onClick={closeMenuHandler}>
                        <span className="material-icons material-icons-round" >add</span>Add Task
                    </Link>
                    <Link to="./all-task" onClick={closeMenuHandler}>
                        <span className="material-icons material-icons-round" >list_alt</span>&nbsp;All Task
                    </Link>
                    <Link to="./view-task" onClick={closeMenuHandler}>
                        <span className="material-icons material-icons-round" >checklist_rtl</span> &nbsp;View Task
                    </Link>
                    <Link to="./time-table" onClick={closeMenuHandler}>
                        <span className="material-icons material-icons-round" >today</span> &nbsp;Time Table
                    </Link>
                    <Link to="./daily-class" onClick={closeMenuHandler}>
                        <span className="material-icons material-icons-round" >calendar_month</span> &nbsp;Daily Class
                    </Link>
        
                </div>
            </div>
            <div>
                <Link to='/task_panel/login' className="btn">Log Out</Link>
            </div>
            <span className="material-icons material-icons-round prevent-select" onClick={closeMenuHandler} id='menu-close-btn'>close</span>
        </div>


    </>

  )
}

export default Navbar;
