import React from 'react';
import './AllStudentTableRow.css';
import { Link } from 'react-router-dom';

const DailyRegisterOtherPaymentTableRow = (props) => {

  const deleteOthersPayment = (receiptNo) => {
    props.setReceiptNo(receiptNo);
    props.setTypeOfDeletion('O');
    props.setVerifyPinClasses('verify-pin-container');
  };

  const sharePaymentData = (fName, lName, receiptNo, purpose, amount, date, contact) => { 
    let wp_msg = `✅ *PAYMENT SUCCESSFUL*%0a%0aHello, ${fName} ${lName}%0aThank you for making payment for ${purpose.toUpperCase()}.%0a%0a*PAYMENT DETAILS* %0a%0a*Receipt No*: ${receiptNo}%0a*Amount*: Rs. ${amount}/-%0a*For*: ${purpose}%0a*Date*: ${date}%0a%0aFor any query, feel free to contact us...%0a📞 9474897192%0a%0aBest Wishes,%0aTeam RedSky`;

    if(props.deviceType === 'desktop'){
       window.open(`https://web.whatsapp.com/send?phone=+91 ${contact}&text=${wp_msg}&app_absent=0`, "blank");
    }else{
       window.open(` https://wa.me/+91 ${contact}?text=${wp_msg}`, "blank");
    }
  }

  return (
    <>
      {
        props.otherPaymentRowData.map((element) => {

         
          return (

            <tr key={element.id}>
              <td data-label="ID">{`${element.reg_no}`}</td>
              <td data-label="Name">{element.first_name + ' ' + element.last_name}</td>
              <td data-label="For">{element.purpose}</td>
              <td data-label="Amount">{element.amount}</td>
              <td data-label="Date">{element.date}</td>
              <td data-label="Edit">
                <Link to={`../edit-other-payment/${element.receipt_no}`}>
                  <span className="material-icons material-symbols-rounded edit-btn" >edit</span>
                </Link>
              </td>
              <td data-label="Delete"><span className="material-icons material-symbols-rounded del-btn" onClick={ ()=> deleteOthersPayment(element.receipt_no) } >delete</span></td>

              <td data-label="Print">
                <Link to={`../Print-Other-Payment/${element.receipt_no}`}>
                  <span className="material-icons material-symbols-rounded print-btn">print</span>
                </Link>
              </td>

              <td data-label="Share"><span className="material-icons material-symbols-outlined prevent-select" onClick={()=>{
                sharePaymentData(element.first_name, element.last_name, element.receipt_no, element.purpose, element.amount, element.date, element.contact)
              }}>share</span></td>
              
            </tr>
          )
        })
      }
    </>

  )
}

export default DailyRegisterOtherPaymentTableRow;
