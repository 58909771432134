import React, { useEffect, useRef, useState } from 'react';
import './AddNewStudentForm.css';
import './Loader.css';
import UploadStudentImage from './UploadStudentImage';
// import FileUpload from './FileUpload';

const AddNewStudentForm = (props) => {
  const [stuId, setStuId] = useState('');
  const [stuRegNo, setStuRegNo] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [guardianName, setGuardianName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNo, setPhoneNo] = useState('');
  const [altPhoneNo, setAltPhoneNo] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [gender, setGender] = useState('');
  const [course, setCourse] = useState('');
  const [feesDiscount, setFeesDiscount] = useState(0);
  const [caste, setCaste] = useState('');
  const [schoolUniversity, setSchoolUniversity] = useState('');
  const [board, setBoard] = useState('');
  const [classStu, setClassStu] = useState('');
  const [courseStartingMonth, setCourseStartingMonth] = useState('');
  const [courseEndingMonth, setCourseEndingMonth] = useState('');
  const [streetAddress, setStreetAddress] = useState('');
  const [country, setCountry] = useState('India');
  const [city, setCity] = useState('');
  const [state, setState] = useState('West Bengal');
  const [pinCode, setPinCode] = useState('');
  const [psychology, setPsychology] = useState('');

  const casteElem = useRef();
  const genderElem = useRef();
  const boardElem = useRef();
  const classElem = useRef();

  const minStartingDate = new Date().toJSON().slice(0, 7);
  const [minEndingDate, setMinEndingDate] = useState(minStartingDate);
  const [maxEndingDate, setMaxEndingDate] = useState(minStartingDate);

  useEffect(()=>{
    setStuRegNo(props.nextStuRegNo);
  },[props.nextStuRegNo]);

  const resetFormFields = () => {
    setStuId('');
    setStuRegNo('');
    setFirstName('');
    setLastName('');
    setGuardianName('');
    setEmail('');
    setPhoneNo('');
    setAltPhoneNo('');
    setDateOfBirth('');
    setGender('');
    setCaste('');
    setBoard('');
    setClassStu('');
    setSchoolUniversity('');
    setCourse('');
    setFeesDiscount(0);
    setCourseStartingMonth('');
    setCourseEndingMonth('');
    setStreetAddress('');
    // setCountry('');
    setCity('');
    // setState('');
    setPinCode('');
    setPsychology('');
    document.querySelector("input[name=gender]:checked").checked = false;
    document.querySelectorAll(".placeHolder")[0].selected = true;
    document.querySelectorAll(".placeHolder")[1].selected = true;
    document.querySelectorAll(".placeHolder")[2].selected = true;
    document.querySelectorAll(".placeHolder")[3].selected = true;
  }

  const formValidate = (e) => {
    e.preventDefault();
    if (firstName.trim() === '' || lastName.trim() === '' || guardianName.trim() === '' || phoneNo.trim() === '' || dateOfBirth.trim() === '' || gender.trim() === '' || caste.trim() === '' || course.trim() === '' || courseStartingMonth.trim() === '' || courseEndingMonth.trim() === '' ) {
      alert("Please Fill Out All Fields!");
      return;
    }

    // phoneNo validation
    if (phoneNo.length !== 10) {
      alert("Phone No is Invalid!");
      return;
    }
    // // PIN validation
    // if (pinCode.length !== 6) {
    //   alert("PIN Code is Invalid!");
    //   return;
    // }
    let formData = {
      stu_id: stuId,
      s_reg_no: stuRegNo,
      f_name: firstName,
      l_name: lastName,
      guardian_name: guardianName,
      email: email,
      phone_no: phoneNo,
      alt_phone_no: altPhoneNo,
      date_of_birth: dateOfBirth,
      gender: gender,
      caste: caste,
      board: board,
      class: classStu,
      school_university: schoolUniversity,
      course: course,
      fees_discount: feesDiscount,
      course_starting_month: courseStartingMonth,
      course_ending_month: courseEndingMonth,
      address: streetAddress,
      country: country,
      city: city,
      state: state,
      pin_code: pinCode,
      psychology: psychology
    };

    props.setFormData(formData, resetFormFields);
  }

  const courseHandler = (e) => {
    setCourse(e.target.value);
    setCourseStartingMonth('');
    setCourseEndingMonth('');
    setMaxEndingDate('');
    props.getSingleCourseData(e.target.value);
  }
  const courseStartingMonthChangeHandler = (e) => { 
    setCourseStartingMonth(e.target.value);
    setMinEndingDate(e.target.value);
    let year = parseInt(e.target.value.slice(0,4));
    let month = parseInt(e.target.value.slice(5,7)) + parseInt(props.courseTotalInstallment) - 1;
    
    year = (month > 12) ? year + 1 : year;
    month = (month > 12) ? month - 12 : month;
    month = (month < 10) ? '0'+month : month;
    setMaxEndingDate(`${year}-${month}`);
    setCourseEndingMonth(`${year}-${month}`);
  }

  const fetStudentName = () => {
    fetch(`${props.hostName}/redsky_api/fetchSingleStudent.php?token=${props.token}`,
    {
      method: "POST",
      body: JSON.stringify({stu_reg_no: stuRegNo}),
      headers: {
        'Content-Type': 'application/json;charset=UTF-8'
      }
    })
    .then(resp => resp.json()).then(data => {
      if(data.code === 5){
        // setStuRegNo(data.data.stu_data.reg_no);
        setFirstName(data.data.stu_data.first_name);
        setLastName(data.data.stu_data.last_name);
        setGuardianName(data.data.stu_data.guardian_name);
        setEmail(data.data.stu_data.email_id);
        setPhoneNo(data.data.stu_data.contact);
        setAltPhoneNo(data.data.stu_data.alt_contact);
        setDateOfBirth(data.data.stu_data.dob);
        setGender(data.data.stu_data.gender);
        setCaste(data.data.stu_data.caste);
        setBoard(data.data.stu_data.board);
        setClassStu(data.data.stu_data.class);
        setSchoolUniversity(data.data.stu_data.school_university);
        // setCourse(data.data.stu_data.);
        setFeesDiscount(data.data.stu_data.fees_discount);
        // setCourseStartingMonth(data.data.stu_data.course_start_date);
        // setCourseEndingMonth(data.data.stu_data.course_end_date);
        setStreetAddress(data.data.stu_data.address);
        setCountry(data.data.stu_data.country);
        setCity(data.data.stu_data.city);
        setState(data.data.stu_data.state);
        setPinCode(data.data.stu_data.pin);
        setPsychology(data.data.stu_data.psychology);

        genderElem.current.querySelector(`input[value="${data.data.stu_data.gender}"]`).checked = true;
        boardElem.current.querySelector(`option[value="${data.data.stu_data.board}"]`).selected = true;
        classElem.current.querySelector(`option[value="${data.data.stu_data.class}"]`).selected = true;
        casteElem.current.querySelector(`option[value="${data.data.stu_data.caste}"]`).selected = true;
      }else if(data.code !== 4){
        alert(data.data);
      }
    }).catch(err => console.log(err));
  }
  const checkKeyPressHandler = (e) => {
    if(e.key === "Enter"){
      fetStudentName();
    }
  }
  const onBlurHandler = (e) => {
    if(e.target.value.trim() !== ""){
      fetStudentName();
    }
  }

  return (
      <section className='add-student-form-container'>
        <header>Registration Form</header>
        <form action="#" className="form" onSubmit={formValidate} >
          <div className="column">
            <div className="input-box field-disabled">
              <label> Sub ID.<span className='color-red'>*</span></label>
              <input type="text" placeholder="Enter Student ID" value={props.nextStuId} onChange={(e) => setStuId(e.target.value)} required disabled />
            </div>
            <div className="input-box">
                <label>ID<span className='color-red'>*</span></label>
                <input type="text" placeholder="Enter Main Id" value={stuRegNo} onChange={(e) => setStuRegNo(e.target.value)}  onKeyPress={checkKeyPressHandler} onBlur={onBlurHandler} required />
            </div>
          </div>
          {/* <div className="input-box field-disabled">
              <label>Reg No.<span className='color-red'>*</span></label>
              <input type="text" placeholder="Enter Student ID" value={regNo} onChange={(e)=>setStuId(e.target.value)} required />
          </div> */}
          <div className="column">
            <div className="input-box">
              <label>First Name<span className='color-red'>*</span></label>
              <input type="text" placeholder="Enter first name" value={firstName} onChange={(e) => setFirstName(e.target.value)} required />
            </div>
            <div className="input-box">
              <label>Last Name<span className='color-red'>*</span></label>
              <input type="text" placeholder="Enter last name" value={lastName} onChange={(e) => setLastName(e.target.value)} required />
            </div>
          </div>

          <div className="input-box">
            <label>Guardian Name<span className='color-red'>*</span></label>
            <input type="text" placeholder="Enter Guardian Name" value={guardianName} onChange={(e) => setGuardianName(e.target.value)} required />
          </div>

          
          <div className="column">
            <div className="input-box">
              <label>Email Address</label>
              <input type="email" placeholder="Enter email address (optional)" value={email} onChange={(e) => setEmail(e.target.value)} />
            </div>
            <div className="input-box">
              <label>Birth Date<span className='color-red'>*</span></label>
              <input type="date" placeholder="Enter birth date" value={dateOfBirth} onChange={(e) => setDateOfBirth(e.target.value)} required />
            </div>
          </div>

          <div className="column">
            <div className="input-box">
              <label>Phone Number<span className='color-red'>*</span></label>
              <input type="number" placeholder="Enter phone number" value={phoneNo} onChange={(e) => setPhoneNo(e.target.value)} required />
            </div>
            <div className="input-box">
              <label>Alternate Number</label>
              <input type="number" placeholder="Enter Alternate Phone Number" value={altPhoneNo} onChange={(e) => setAltPhoneNo(e.target.value)} />
            </div>
          </div>
          <div className="gender-box" ref={genderElem}>
            <h3>Gender<span className='color-red'>*</span></h3>
            <div className="gender-option">
              <div className="gender">
                <label><input type="radio" name="gender" value='Male' onClick={(e) => setGender(e.target.value)} required /> Male</label>
              </div>
              <div className="gender">
                <label><input type="radio" name="gender" value='Female' onClick={(e) => setGender(e.target.value)} required /> Female</label>
              </div>
              <div className="gender">
                <label><input type="radio" name="gender" value='Others' onClick={(e) => setGender(e.target.value)} required /> Prefer not to say</label>
              </div>
            </div>
          </div>
          <div className="column">
            <div className="input-box">
              <label>Caste<span className='color-red'>*</span></label>
              <div className="select-box">
                <select onChange={(e) => setCaste(e.target.value)} defaultValue={caste} className="course-options" ref={casteElem}>
                  <option hidden className='placeHolder' value=''>Caste</option>
                  <option value='General'>General</option>
                  <option value='OBC-A'>OBC-A</option>
                  <option value='OBC-B'>OBC-B</option>
                  <option value='SC'>SC</option>
                  <option value='ST'>ST</option>
                </select>
              </div>
            </div>

            <div className="input-box" ref={boardElem}>
              <label>Board</label>
              <div className="select-box">
                <select onChange={(e) => setBoard(e.target.value)} className="board-options">
                  <option hidden className='placeHolder' value=''>Board</option>
                  <option value='ICSE'>ICSE</option>
                  <option value='CBSE'>CBSE</option>
                  <option value='WBBSE'>WBBSE</option>
                  <option value='WBCHSE'>WBCHSE</option>
                  <option value='No Board'>No Board</option>
                </select>
              </div>
            </div>

          </div>

          <div className="column">
            
            <div className="input-box">
              <label>School / University</label>
              <input type="text" placeholder="Enter School / University (Optional)" value={schoolUniversity} onChange={(e) => setSchoolUniversity(e.target.value)} />
            </div>

            <div className="input-box" ref={classElem}>
              <label>Class</label>
              <div className="select-box">
                <select onChange={(e) => setClassStu(e.target.value)} className="board-options">
                  <option hidden className='placeHolder' value=''>Class</option>
                  <option value='nursery'>Nursery</option>
                  <option value='lkg'>LKG</option>
                  <option value='ukg'>UKG</option>
                  {
                    Array.from({length: 12}, (_, i) => i + 1).map((item) => {
                      return(
                        <option key={item} value={item}>{item}</option>
                      )
                    })
                  }
                </select>
              </div>
            </div>

          </div>
          <div className="column">
            <div className="input-box">
                <label>Course<span className='color-red'>*</span></label>
                <div className="select-box">
                  <select onChange={courseHandler} className="course-options">
                    <option hidden className='placeHolder'>Course</option>
                    {
                      props.allCourses.map((element) => {
                        return (<option key={element.c_id} value={element.c_id}>{element.c_name}</option>);
                      })
                    }
                  </select>
                </div>
            </div>
            <div className="input-box">
              <label>Discount<span className='color-red'>*</span></label>
              <input type="number" placeholder="Enter Discount per installment (eg: 20/-)" value={feesDiscount} onChange={(e)=>{setFeesDiscount(e.target.value)}} min='0' required />
            </div>
          </div>
          <div className="column">
            <div className="input-box">
              <label>Starting Month<span className='color-red'>*</span></label>
              <input type="month" placeholder="Enter Starting Month" value={courseStartingMonth} onChange={courseStartingMonthChangeHandler} /*min={minStartingDate}*/ required />
            </div>
            <div className="input-box">
                <label>Ending Month<span className='color-red'>*</span></label>
                <input type="month" placeholder="Enter Ending Month" value={courseEndingMonth} onChange={(e) => { setCourseEndingMonth(e.target.value) }} /*min={minEndingDate} max={maxEndingDate}*/ required />
              </div>
            </div>
          <div className="input-box address">
            <label>Address</label>
            <input type="text" placeholder="Enter street address" value={streetAddress} onChange={(e) => setStreetAddress(e.target.value)} />
            {/* <input type="text" placeholder="Enter street address 2 (Optional)" /> */}
            <div className="column">
              <div className="select-box">
                <select onChange={(e) => setCountry(e.target.value)} className="country-options" defaultValue={'India'}>
                  <option hidden className='placeHolder'>Country</option>
                  <option value='India' defaultValue>India</option>
                </select>
              </div>
              <input type="text" placeholder="Enter your city" value={city} onChange={(e) => setCity(e.target.value)} />
            </div>
            <div className="column">
              <input type="text" placeholder="Enter your State" value={state} onChange={(e) => setState(e.target.value)} />
              <input type="number" placeholder="Enter postal code" value={pinCode} onChange={(e) => setPinCode(e.target.value)} />
            </div>
          </div>

          <div className="input-box">
            <label>Psychology</label>
            <textarea placeholder='Enter Student Psychology (Seperated By ; [Semi-Colon])' value={psychology} onChange={(e)=>setPsychology(e.target.value)}></textarea>
          </div>
          <UploadStudentImage stu_id={props.nextStuId} isModifyForm={undefined} setLoaderClasses={props.setLoaderClasses} hostName={props.hostName} token={props.token} />

          {/* <FileUpload /> */}
          <button type='submit'>Add Student</button>
        </form>
      </section>
  )
}

export default AddNewStudentForm;
