import React, { useEffect, useState } from 'react';
import Loader from './Loader';
import Chart from 'react-google-charts';

const ClassCourseGraph = (props) => {
  const [graphData, setGraphData] = useState('');
  const [courseStudentCountData, setCourseStudentCountData] = useState([]);
  const [loaderClasses, setLoaderClasses] = useState('loader-container');

  useEffect(() => {

    setLoaderClasses("loader-container");

    fetch(`${props.hostName}/redsky_api/getClassCountPerCourse.php?token=${props.token}`)
      .then(resp => resp.json()).then(data => {

        if (data.code === 5) {
            setCourseStudentCountData(data.data);
          }else{
            setCourseStudentCountData([{}]);
          }
          setLoaderClasses("loader-container d-none");

      }).catch(err => console.log(err));

  }, [props.token,props.hostName]);



  useEffect(() => {
    if (courseStudentCountData.length !== 0) {
        let data = [];
        let courseName, coursePerStudent;

      courseStudentCountData.forEach((elem, index) => {
        courseName = elem.course_name;
        coursePerStudent = parseInt(elem.total_class);

        data[index] = [courseName, coursePerStudent];
      });


      setGraphData([
        ["Course", "No"], ...data
      ]);

    }
  }, [courseStudentCountData]);


  return (
    <>
      <Chart className={props.className} chartType="PieChart" data={graphData}
        height="400px" width="100%"
        options={{
            title: 'Classes Per Courses (Current Month)',
            responsive: true,
            legend: { position: "bottom" },
            titleTextStyle: {
                color: 'black',  
                fontSize: 16,
                bold: false,  
                italic: false, 
            }
        }} />
        <Loader loaderClasses={loaderClasses}/>
    </>
  )
}

export default ClassCourseGraph;

