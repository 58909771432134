import React from 'react';
import UnpaidFeesTableRow from './UnpaidFeesTableRow';
import './UnpaidPaymentBox.css';
import DeferralFeesTableRow from './DeferralFeesTableRow';

const UnpaidPaymentBox = (props) => {
	
	return (
		<>
			<section className="form-container " >
				<header>Unpaid Fees Details</header>
				
				<table className='unpaid-table'>
					<thead>
						<tr>
							<th scope="col" className='fit'>No</th>
							<th scope="col">Due Month</th>
							<th scope="col">Amount</th>
							<th scope="col">Date</th>
							<th scope="col">Action</th>
						</tr>
					</thead>
					<tbody>
						{

							(props.unpaidFeesLists.length > 0 || props.deferralFeesLists.length > 0) ? (
								props.unpaidFeesLists.map((element) => {
							
									return (
										<UnpaidFeesTableRow key={element.installment_no} element={element} saveNewPaymentDetails={props.saveNewPaymentDetails} />
									);
								})
							)
							:
							<tr>
								<td colSpan='5'>
									<center>No Unpaid Payment Details Found</center>
								</td>
							</tr>
						}
						{
							
							props.deferralFeesLists.map((element) => {
								return (
									<DeferralFeesTableRow key={element.installment_no} element={element} />
								);
							})
						
						}

					</tbody>
				</table>
				{/* <div className={`payment-btn-container ${isShowPaymentBtn}`}>
					<button  className='payment-btn online-btn' onClick={()=>submitNewPaymentHandler('online')}>Online</button>
					<button className='payment-btn cash-btn' onClick={()=>submitNewPaymentHandler('cash')}>Cash</button>
				</div> */}
				
			</section>

			{/* <VerifyPinContainer verifyPinClasses={verifyPinClasses} enteredPin={[enteredPin, setEnteredPin]}  proceedPinHandler={proceedPinHandler} cancelPinHandler={cancelPinHandler} /> */}
		</>
	)
}

export default UnpaidPaymentBox;
