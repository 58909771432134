import React, { useState } from 'react';
import './Table.css';
import EnqueryAllListTableRow from './EnqueryAllListTableRow';
import './EnqueryAllListTable.css';
import VerifyPinContainer from './VerifyPinContainer';
// import LimitRowsCount from './LimitRowsCount';

const EnqueryAllListTable = (props) => {
    // const resestFilterRef = useRef();
    const [enqueryId, setEnqueryId] = useState('');
    const [enteredPin, setEnteredPin] = useState('');
	const [verifyPinClasses, setVerifyPinClasses] = useState('verify-pin-container d-none');

    const cancelPinHandler = () => {
        setVerifyPinClasses('verify-pin-container d-none');
        setEnteredPin('');
      };
    
    const proceedPinHandler = () => {
        if (enteredPin.length !== 0) {
          const enqueryDeleteData = {
            enquery_id: enqueryId,
            entered_verify_pin: enteredPin
          };
    
          setEnteredPin('');
          // setEnteredCatpcha('');
          setVerifyPinClasses('verify-pin-container d-none');
          props.deleteEnquery(enqueryDeleteData);
    
        } else {
          alert("Please Enter Pin");
        }
      };
    
    const printAll = () => {
        window.print();
    };
    // const filterHandler = () => {
    //     document.querySelector(".filter-box").classList.toggle('d-none');
    // };

    return (
        <div>
            <header className='table-header'>
                <span>Enquiry List</span>
                <div className='pos-rel'>
                    <div className='d-flex align-center'>
                        {/* <span className="material-icons material-symbols-outlined" onClick={filterHandler}>tune</span> */}
                        <span className="material-icons material-symbols-rounded" onClick={printAll}>print</span>
                    </div>

                    {/* <FilterOtherPayments ref={resestFilterRef} getFilteredData={props.getFilteredData} filterHandler={filterHandler} allCourses={props.allCourses} /> */}
                </div>
            </header>

            <div className={props.tableClasses}>
                <div className='enquery-counting-container'>
                    <span>Total Enquiry : <b>{props.totalEnqueryCount}</b></span>
                    <span>Total Admitted : <b>{props.totalAdmittedEnqueryCount}</b></span>
                </div>
                <table>
                    <thead>
                        <tr>
                            {
                                props.tableCols.map((element) => (<th scope="col" id={element} key={element}>{element}</th>) )
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {props.rowsData.map((elem, index) => 
                            <EnqueryAllListTableRow elem={elem} key={index} slNo={index+1} updateAdmittedStatus={props.updateAdmittedStatus} setVerifyPinClasses={setVerifyPinClasses} setEnqueryId={setEnqueryId} />
                        )}
                        
                    </tbody>

                </table>

            </div>
            <center className={props.msgClasses}>{props.msgData}</center> 
            <VerifyPinContainer verifyPinClasses={verifyPinClasses} enteredPin={[enteredPin, setEnteredPin]} proceedPinHandler={proceedPinHandler}  cancelPinHandler={cancelPinHandler} />
        </div>
    )
}

export default EnqueryAllListTable;


