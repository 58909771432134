import React, { useEffect, useState } from 'react';
import './StudentLogin.css';
import Loader from '../../components/admin_components/Loader';
import { useNavigate, useParams } from 'react-router-dom';
import Alert from '../../components/Alert';
// import climbKids from '../../assets/img/climbing_kids.png';
// import jumpKids from '../../assets/img/jumping_kids.png';

const StudentLogin = (props) => {
    const param = useParams();
    const navigate = useNavigate(null);
    const [loaderClasses, setLoaderClasses] = useState('loader-container d-none');
    const [stuRegNo, setStuRegNo] = useState('');
    const [contact, setContact] = useState('');
    props.setTitle("Login Student | Student Panel | RedSky Educations");

    localStorage.removeItem("redsky_stu_welcome_msg");

    
    useEffect(()=>{
        setStuRegNo(param.reg_no);
        setContact(param.contact);
        // window.addEventListener('load', ()=> {
            // if (document.exitFullscreen) {
            //     document.exitFullscreen();
            // } else if (document.webkitExitFullscreen) {
            //     document.webkitExitFullscreen();
            // } else if (document.mozCancelFullScreen) {
            //     document.mozCancelFullScreen();
            // } else if (document.msExitFullscreen) {
            //     document.msExitFullscreen();
            // }  
        // })
        // document.getElementById('confirmModel').style.display ='none';
    }, [param.reg_no, param.contact]);

    const veifyStudentLogIn = (e) =>{
        e.preventDefault();
        setLoaderClasses('loader-container');
        fetch(`${props.hostName}/redsky_api/student/verify_student.php?stu_reg_no=${stuRegNo}&contact=${contact}`)
        .then(resp => resp.json()).then(data => {
            setLoaderClasses('loader-container d-none');
            if(data.code === 5){
                navigate(`../panel_choice/${stuRegNo}/${contact}`);
            }else{
                alert(data.data);
            }
        }).catch(err => console.log(err));
    };

  return (
    <div style={{paddingTop: "6rem"}} className='student-panel'>
        <div className='d-flex '>
            {/* <img src={climbKids} alt='kids_img' id='img-top' /> */}
            <form className='student-form' onSubmit={veifyStudentLogIn}>
                <h2 className='heading'>Get Started</h2>
                <div className='input-box'>
                    <input type='text' placeholder='Student ID' value={stuRegNo} onChange={(e)=>setStuRegNo(e.target.value)} required />
                </div>
                <div className='input-box'>
                    <input type='number' placeholder='Contact Number' value={contact} onChange={(e)=>setContact(e.target.value)} required/>
                </div>

                <input type='submit'  />

                {param.alert === 'true'
                ?
                <p className='mb-0 mt-3 text-center'>
                    <span className='text-success'>Submission Successful.</span>
                    <br/>
                    <span className='text-danger'>Press <b>ESC</b> (Escape) key & Close the Tab</span>
                </p>
                :
                ''
                }
                
                {/* <img src={jumpKids} alt='kids_img' id='img-bottom' /> */}
            </form>
        </div>
        <Loader loaderClasses={loaderClasses} />
        <Alert alert={param.alert} strongMsg={param.alert === 'true' ? "Thank You!": "Warning!"} msg={param.alert === 'true' ? "Your Answer is Submiited." : "Something Went Wrong"} time={5000} pos='bottom' />
    </div>
  )
}

export default StudentLogin;
