import React, {useState} from 'react';

const DeferralFeesTableRow = (props) => {

  return (
      <tr className='deferral-row'>
          <td data-label="No">{props.element.installment_no}</td>
          <td data-label="Due Month">{props.element.for_month_year_text}</td>
          <td data-label="Amount">
            <div className="input-group">
                <span className="input-group-text" id="basic-addon1">₹</span>
                <input type="number" className="form-control amount_input" placeholder="Amount" value={props.element.course_fees}  disabled ></input>
            </div>
        </td>
          <td data-label="Date"><input type="date" className="form-control py-2" value={props.element.date} disabled/></td>
        <td></td>
      </tr>
  );
}

export default DeferralFeesTableRow;
