import React, { useState } from 'react';
import './AddNewStudentForm.css';
import './Loader.css';

const InstantBillForm = (props) => {
  const [name, setName] = useState('');
  const [course, setCourse] = useState('');
  const [advanceFees, setAdvanceFees] = useState('');

  const bookAdvanceFormValidate = (e) => {
    e.preventDefault();
    if (name.trim() === '' || course.trim() === '' || advanceFees.trim() === '') {
      alert("Please Fill Out All Fields!");
      return;
    }

    let bookAdvanceFormData = {
      name: name,
      course: course,
      advance_fees: advanceFees,
    };

    // resetFormFields();
    props.storeAdvancedBooking(bookAdvanceFormData);

  };

  const courseHandler = (e) => {
    setCourse(e.target.value);
  }


  return (
    <>
      <section className="add-student-form-container">
        <header>Instant Bill</header>
        <form action="#" className="form" >


          <div className="input-box">
            <label>Name:<span className='color-red'>*</span></label>
            <input type="text" placeholder="Enter student Name" value={name} onChange={(e) => { setName(e.target.value) }} required />
          </div>

          <div className='column'>
            <div className="input-box">
              <label>Course<span className='color-red'>*</span></label>
              <div className="select-box">
                <select onChange={courseHandler} className="course-options" required>
                  <option hidden className='placeHolder'>Course</option>
                  {
                    props.allCourses.map((element) => {
                      return (<option key={element.c_id} value={element.c_id} >{element.c_name}</option>);
                    })
                  }
                </select>
              </div>
            </div>
            <div className="input-box">
              <label>Amount<span className='color-red'>*</span></label>
              <input type="number" placeholder="Enter Fees Amount" value={advanceFees} onChange={(e) => { setAdvanceFees(e.target.value) }} min="0" required />
            </div>
          </div>

          <button onClick={bookAdvanceFormValidate}>Book Advance</button>
        </form>
      </section>

    </>
  )
}

export default InstantBillForm;
