import React, { useState } from 'react';
import './PaidPaymentBox.css';
import PaidFeesTableRow from './PaidFeesTableRow';
import VerifyPinContainer from './VerifyPinContainer';

const PaidPaymentBox = (props) => {
	const [enteredPin, setEnteredPin] = useState('');
	const [verifyPinClasses, setVerifyPinClasses] = useState('verify-pin-container d-none');
	const [receiptNo, setReceiptNo] = useState('');

	const cancelPinHandler = () => {
		setVerifyPinClasses('verify-pin-container d-none');
		setEnteredPin('');
	};

    const proceedPinHandler = () => {
		if (enteredPin.length !== 0) {
			let paymentDeleteData = {
				receipt_no: receiptNo,
                entered_verify_pin: enteredPin
			};

			setEnteredPin('');
			// setEnteredCatpcha('');
			setVerifyPinClasses('verify-pin-container d-none');
			props.deletePaymentHandler(paymentDeleteData);

		} else {
			alert("Please Enter Pin");
		}
	};

    return (
        
			<section className="form-container" >
				<header>Paid Fees Details</header>
				<p className='all-total'>Total: {props.totalPaidAmount}/-</p>
				<table className='paid-table'>
					<thead>
						<tr>
							<th scope="col">No</th>
							<th scope="col">For Month</th>
							<th scope="col">Date</th>
							<th scope="col">Amount</th>
							<th scope="col" className={props.isExtraContentShow}>Edit</th>
							<th scope="col" className={props.isExtraContentShow}>Delete</th>
							<th scope="col">Action</th>
							{/* <th scope="col">Time</th> */}
						</tr>
					</thead>
					<tbody>
						{
							(props.paidFeesLists.length > 0)? (
								props.paidFeesLists.map((element) => {
									return(
											<PaidFeesTableRow key={element.installment_no} setReceiptNo={setReceiptNo} element={element} setVerifyPinClasses={setVerifyPinClasses} isExtraContentShow={props.isExtraContentShow} />
										);
								})
							) 
							:
							<tr>
								<td colSpan='7'>
								<center>No Payment Details Found</center>
								</td>
							</tr>
						}

					</tbody>
				</table>
				<VerifyPinContainer verifyPinClasses={verifyPinClasses} enteredPin={[enteredPin, setEnteredPin]} proceedPinHandler={proceedPinHandler}  cancelPinHandler={cancelPinHandler} />
			</section>
    )
}

export default PaidPaymentBox;
