import React, { useEffect, useState } from 'react';
import Chart from 'react-google-charts';
import Loader from './Loader';

const GenderRatio = (props) => {
  const [graphData, setGraphData] = useState('');
  const [genderRatioData, setGenderRatioData] = useState([]);
  const [loaderClasses, setLoaderClasses] = useState('loader-container');

  useEffect(() => {
    setLoaderClasses("loader-container");
    fetch(`${props.hostName}/redsky_api/getGenderRatio.php?token=${props.token}`)
      .then(resp => resp.json()).then(data => {

        if (data.code === 5) {
          setGenderRatioData(data.data);
        }else{
          setGenderRatioData([{}]);
        }
        setLoaderClasses("loader-container d-none");

      }).catch(err => console.log(err));

  }, [props.token, props.hostName]);



  useEffect(() => {
    if (genderRatioData.length !== 0) {
        let data = [];
        let genderName, genderCount;

      genderRatioData.forEach((elem, index) => {
        genderName = elem.gender;
        genderCount = parseInt(elem.count);

        data[index] = [genderName, genderCount];
      });


      setGraphData([
        ["Course", "No"], ...data
      ]);

    }
  }, [genderRatioData]);


  return (
    <>
      <Chart className={props.className} chartType="PieChart" data={graphData}
        height="400px" width="100%"
        options={{
            title: 'Gender Ratio',
            responsive: true,
            legend: { position: "bottom" },
            pieHole: 0.4,
            titleTextStyle: {
                color: 'black',  
                // fontName: <string>,
                fontSize: 16,
                bold: false,  
                italic: false, 
                // underline: true
            }
        }} />
        <Loader loaderClasses={loaderClasses}/>
    </>
  )
}

export default GenderRatio;
