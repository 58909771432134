import React, { useState, useEffect } from 'react';
import UpgradeCourseForm from '../../components/admin_components/UpgradeCourseForm';
import Loader from '../../components/admin_components/Loader';
import { useNavigate } from 'react-router-dom';

const UpgradeCourse = (props) => {
  const navigate = useNavigate();
    useEffect(()=>{
      if(props.isHeadAdmin === '0'){
        navigate('../all-students');
      }
    });
  const [allCourses, setAllCourses] = useState([]);
  const [isShowForm, setIsShowForm] = useState('d-none');
  const [loaderClasses, setLoaderClasses] = useState('loader-container d-none');
  const [courseTotalInstallment, setCourseTotalInstallment] = useState(1);

  const [singleStudentData, setSingleStudentData] = useState([]);

  useEffect(()=> {props.setTitle("Upgrade Student | Admin Panel | RedSky Educations | Educations for future")}, [props]);

  const getSearchData = (searchVal) => {
    setLoaderClasses('loader-container ');
    setIsShowForm('d-none');
  
    fetch(`${props.hostName}/redsky_api/getSingleStudent.php?token=${props.token}`,
      {
        method: "POST",
        body: JSON.stringify(searchVal),
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        }
      })
      .then(resp => resp.json()).then(data => {
        setLoaderClasses('loader-container d-none');

        if(data.code === 5){
          let stu_data = data.data['stu_data'];
          setSingleStudentData(stu_data);
          setIsShowForm('');
        }else{
          alert(data.data);
        }
        
      }).catch(err => console.log(err));
  };

  const getFormData = (formData) => {
    setLoaderClasses('loader-container ');
    fetch(`${props.hostName}/redsky_api/UpgradeCourse.php?token=${props.token}`,
      {
        method: "POST",
        body: JSON.stringify(formData),
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        }
      })
      .then(resp => resp.json()).then(data => {
        setLoaderClasses('loader-container d-none');
        if(data.code === '8'){
          setIsShowForm('d-none');
          alert(data.data);
        }else{
          alert(data.data);
        }
      }).catch(err => console.log(err));
  };

  const fetchAllCourses = () => {
    setLoaderClasses('loader-container ');
    fetch(`${props.hostName}/redsky_api/getAllCourses.php?token=${props.token}`)
      .then(resp => resp.json()).then(data => {
        setLoaderClasses('loader-container d-none');
        if (data.code === 5) {
          setAllCourses(data.data);
        }
        else {
            document.querySelector('.add-student-form-container .form').innerHTML = `<center>${data.data} <br> Please Try again!</center>`;
        }

      }).catch(err => console.log(err));
    };
  useEffect(fetchAllCourses, [props.token,props.hostName]);

  const getSingleCourseData = (cId) => {
    setLoaderClasses('loader-container');
    fetch(`${props.hostName}/redsky_api/getSingleCourseData.php?token=${props.token}&course_id=${cId}`)
      .then(resp => resp.json()).then(data => {
        
        if (data.code === 5) {
          setCourseTotalInstallment(data.data.total_installment);
        }else{
          alert(data.data);
        }
        setLoaderClasses('loader-container d-none');
      }).catch(err => console.log(err));
  }

  return (
    <>
      <UpgradeCourseForm getSearchData={getSearchData} studentData={singleStudentData} allCourses={allCourses} setFormData={getFormData} isShowForm={isShowForm} getSingleCourseData={getSingleCourseData} courseTotalInstallment={courseTotalInstallment} />
      <Loader loaderClasses={loaderClasses} />
    </>
  )
}

export default UpgradeCourse;
