import React, {useEffect} from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import AddTask from './AddTask';
import PageNotFound from '../PageNotFound';
import Navbar from '../../components/task_components/Navbar';
import ViewTask from './ViewTask';
import StudentTaskData from './StudentTaskData';
import BatchTimeTable from './BatchTimeTable';
import AllTask from './AllTask';
import ModifyTask from './ModifyTask';
import DailyClass from './DailyClass';

const TaskIndex = (props) => {
    const navigate = useNavigate();
    const isLoggedIn = JSON.parse(localStorage.getItem("task_user_data_redsky"));

    useEffect(()=>{
        if(isLoggedIn === null){
            navigate('/task_panel/login');
        }
    });

    let token = '';
    if(isLoggedIn != null){
        token=isLoggedIn.task_token;
    }

  return (
    <div style={{paddingTop: "6rem"}} className='task'>
        <Navbar taskUserName={ (isLoggedIn) ? isLoggedIn.task_fname : "User" } />
        <Routes>
            <Route path='/' element={<ViewTask hostName={props.hostName} token={token} setTitle={props.setTitle} />} />
            <Route path='add-task' element={<AddTask hostName={props.hostName} token={token} setTitle={props.setTitle} />} />
            <Route path='modify-task/:task_id' element={<ModifyTask hostName={props.hostName} token={token} setTitle={props.setTitle} />} />
            <Route path='all-task' element={<AllTask hostName={props.hostName} token={token} setTitle={props.setTitle} />} />
            <Route path='view-task' element={<ViewTask hostName={props.hostName} token={token} setTitle={props.setTitle} />} />
            <Route path='student-task-data/:stu_id' element={<StudentTaskData hostName={props.hostName} token={token} setTitle={props.setTitle} />} />
            <Route path='time-table' element={<BatchTimeTable hostName={props.hostName} token={token} setTitle={props.setTitle} />} />
            <Route path='daily-class' element={<DailyClass hostName={props.hostName} token={token} setTitle={props.setTitle} />} />

            <Route path='*' element={<PageNotFound setTitle={props.setTitle} />}/>  
        </Routes>
    </div>
  );
};

export default TaskIndex;