import React from 'react';
import './AllStudentTableRow.css';
import './AttendanceViewManualRow.css';
import ToggleBtnWith3Option from './ToggleBtnWith3Option';

const AttendanceViewManualRow = (props) => {
   
    return (
        
        <tr key={props.i+props.element.sub_id} data={props.i+props.element.sub_id}>
            <td data-label="SL No.">{props.i}</td>
            <td data-label="ID">{`${props.element.reg_no}`}</td>
            {/* <td data-label="RFID">{props.element.rfid_no}</td> */}
            <td data-label="Name">{props.element.f_name + ' ' + props.element.l_name}</td>
            <td data-label="Course">{props.element.course_name}</td>
            <td data-label="Action">
                <ToggleBtnWith3Option modifyManualAttendanceHandler={props.modifyManualAttendanceHandler} sub_id={props.element.sub_id} is_present={props.element.is_present} is_absent_known={props.element.is_absent_known} />
            </td>
        </tr>
    )    
}

export default AttendanceViewManualRow;

