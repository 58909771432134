import React from 'react';
import './StudentShortDetails.css';

const StudentShortDetails = (props) => {


  return (
    <>
      <section className="form-container ">
        <header>Student Details</header>
        <form action="#" className="student-short-details-form" >

            <div className="input-box field-disabled">
              <label>Student Id</label>
              <input type="text" placeholder="Enter student id" value={`${props.studentData.reg_no}`} onChange={()=>{}} disabled  />
            </div>
          <div className="column">
            <div className="input-box field-disabled">
              <label>First Name</label>
              <input type="text" placeholder="Enter first name" value={props.studentData.first_name} onChange={()=>{}} disabled />
            </div>
            <div className="input-box field-disabled">
              <label>Last Name</label>
              <input type="text" placeholder="Enter last name" value={props.studentData.last_name} onChange={()=>{}} disabled />
            </div>
          </div>

          <div className="column">
            <div className="input-box field-disabled">
              <label>Phone Number</label>
              <input type="number" placeholder="Enter phone number" value={props.studentData.contact} onChange={()=>{}} disabled/>
            </div>
            <div className="input-box field-disabled">
              <label>Birth Date</label>
              <input type="date" placeholder="Enter birth date" value={props.studentData.dob} onChange={()=>{}} disabled/>
            </div>
          </div>

          <div className="column">
            <div className="input-box field-disabled">
              <label>Gender</label>
              <input type="text" placeholder="Enter Gender" value={props.studentData.gender} onChange={()=>{}} disabled />
            </div>
            <div className="input-box field-disabled">
              <label>Course</label>
              <input type="text" placeholder="Enter last name" value={props.studentData.course_name} onChange={()=>{}} disabled />
            </div>
          </div>
        </form>
      </section>

    </>
  )
}

export default StudentShortDetails;