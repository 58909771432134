import React, {useEffect, useState} from "react";
import { useParams, useNavigate } from 'react-router-dom';
import Loader from '../../components/admin_components/Loader';
import "./QuotationPrint.css";
import QuotationPrintForm from "../../components/admin_components/QuotationPrintForm";

const QuotationPrint = (props) => {
  const param = useParams();
  const navigate = useNavigate(null);
  const [loaderClasses, setLoaderClasses] = useState('loader-container d-none');
  const [enqueryPrintData, setEnqueryPrintData] = useState({});

  props.setTitle(`Print Quotation | ${parseInt(props.isHeadAdmin) ? "Admin Panel" : "User Panel"} | RedSky Educations | Educations for future`);

  const fetchSingleEnquery = () => {
    setLoaderClasses('loader-container');
  
    fetch(`${props.hostName}/redsky_api/get_enquery_print_data.php?token=${props.token}&enquery_id=${param.enquery_id}`)
        .then(resp => resp.json()).then(data => {
         
          if(data.code === 5){
            setEnqueryPrintData(data.data);
          }
          else{
            alert(data.data);
            navigate('../all-enquery-list');
          }
          setLoaderClasses('loader-container d-none');
            
        }).catch(err => console.log(err));
    };

  useEffect(fetchSingleEnquery, [props.hostName, props.token, param.enquery_id, navigate]);

  return (
    <>
      <QuotationPrintForm enqueryPrintData={enqueryPrintData} />
      <Loader loaderClasses={loaderClasses}/>
    </>
  );
};

export default QuotationPrint;
