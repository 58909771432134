import React, { useEffect, useState } from 'react';
import './StudentCommon.css';
import './UpdateContact.css';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../../components/admin_components/Loader';

const UpdateContact = (props) => {
    props.setTitle("Update Contact | Student Panel | RedSky Educations");
    const param = useParams();
    const navigate = useNavigate();
    const stuRegNo = param.reg_no;
    const oldNumber = param.old_number;
    const [detailsFormClass, setDetailsFormClass] = useState('d-none');

    // const [oldContact, setOldContact] = useState('');
    const [dob, setDOB] = useState('');
    const [newContact, setNewContact] = useState('');
    const [formDetailsData, setFormDetailsData] = useState({});
    const [loaderClasses, setLoaderClasses] = useState('loader-container d-none');

    useEffect(()=>{
        // setOldContact(param.old_number);
        setDOB(param.dob);
    }, [param]);
    
    const getUpdateContactFormData = (e) =>{
        e.preventDefault();
        setLoaderClasses('loader-container');
        fetch(`${props.hostName}/redsky_api/student/get_update_contact_data.php?stu_reg_no=${stuRegNo}&old_contact=${oldNumber}&dob=${dob}`)
        .then(resp => resp.json()).then(data => {
    
            if(data.code === 5){
                setFormDetailsData(data.data);
                setNewContact('');
                setDetailsFormClass('');
            }else{
                alert(data.data);
            }
            setLoaderClasses('loader-container d-none');
        }).catch(err => console.log(err));
    };

    const saveUpdateContact = (e) => {
        e.preventDefault();

        if(newContact.toString().length !== 10 ){
            alert("New Number Must Contain 10 digits");
            return;
        }
        if(oldNumber.toString() === newContact.toString()){
            alert("New Number & Old Number Cann't be same");
            return;
        }

        setLoaderClasses('loader-container');
        fetch(`${props.hostName}/redsky_api/student/update_student_contact.php`,
        {
            method: "POST",
            body: JSON.stringify({stu_reg_no: stuRegNo, old_number: oldNumber, dob:dob, new_contact: newContact}),
            headers: {
              'Content-Type': 'application/json;charset=UTF-8'
            }
        })
        .then(resp => resp.json()).then(data => {
            if(data.code === 5){
                alert(data.data);
                setDetailsFormClass('d-none');
                navigate(`../panel_choice/${stuRegNo}/${newContact}`);
                
            }else{
                alert(data.data);
            }
            setLoaderClasses('loader-container d-none');
        }).catch(err => console.log(err));
    };

  return (
    <div style={{paddingTop: "6rem"}} className='student-panel'>
        <div className='d-flex flex-col'>
            <form action="#" onSubmit={getUpdateContactFormData} >
                
                <h3>Update Contact</h3>

                {/* <label htmlFor="old-number">Old Number</label>
                <input type="number" placeholder="Old Number (eg. 9282726252)" value={oldContact} onChange={(e)=>setOldContact(e.target.value)} required /> */}

                <label htmlFor="dob">D.O.B</label>
                <input type="date" placeholder="Enter DOB" value={dob} onChange={(e)=>setDOB(e.target.value)}  required />

                <input type="submit" value="Validate" />
            </form>

            <form className={detailsFormClass} action='#' onSubmit={saveUpdateContact} >
                <h3>Basic Details</h3>

                <div className="input-col">
                    <div>
                        <label htmlFor="stu_fname">First Name</label>
                        <input type="text" value={formDetailsData.fname} disabled />
                    </div>
                    <div>
                        <label htmlFor="stu_lname">Last Name</label>
                        <input type="text" value={formDetailsData.lname} disabled />
                    </div>
                </div>

                <label htmlFor="guardian-name">Guardian Name</label>
                <input type="text" value={formDetailsData.guardian_name} disabled /> 

                <label htmlFor="course-name">Course</label>
                <input type="text" value={formDetailsData.course_name} disabled />

                <div className="input-col">
                    <div>
                        <label htmlFor="text-old-number">Old Number</label>
                        <input type="number" value={formDetailsData.contact} disabled />
                    </div>
                    <div>
                        <label htmlFor="new-number">New Number</label>
                        <input type="number" placeholder="Enter New Contact Number" value={newContact} onChange={(e) => setNewContact(e.target.value)} required />
                    </div>
                </div>

                <input type="submit" value="Update" />
            </form>
        </div>
        <Loader loaderClasses={loaderClasses}/>
    </div>
  );
}

export default UpdateContact;
