import React from 'react';
import '../admin_components/Table.css';
import TaskTableRow from './TaskTableRow';

const TaskTable = (props) => {    
    const printAll = () => {
        window.print();
    };
    return (
        <div>
            <header className='table-header'>
                <span>Task Lists</span>
                <div className='pos-rel'>
                    <div className='d-flex align-center'>
                        <span className="material-icons material-symbols-rounded prevent-select" onClick={printAll}>print</span>
                    </div>
                </div>
            </header>

            <div className={props.tableClasses}>
                <table>
                    <thead>
                        <tr>
                            {
                                props.tableCols.map((element) => {
                                    return (<th scope="col" id={element} key={element}>{element}</th>);
                                })
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            props.rowsData.map((element)=>{
                                return (<TaskTableRow key={element.task_id} element={element} deleteStudentHandler={props.deleteStudentHandler} />);
                            })
                        }
                        
                    </tbody>

                </table>
            </div>
        </div>
    )
}

export default TaskTable;
