import React, { useEffect, useRef, useState } from 'react';
import AllStudentTableRow from './AllStudentTableRow';
import './Table.css';
import FilterStudent from './FilterStudent';
import VerifyPinContainer from './VerifyPinContainer';
// import LimitRowsCount from './LimitRowsCount';

const Table = (props) => {
    const resestFilterRef = useRef();
    const [enteredPin, setEnteredPin] = useState('');
	const [verifyPinClasses, setVerifyPinClasses] = useState('verify-pin-container d-none');
    const [deleteStuId, setDeleteStuId] = useState('');
    const [deleteStuCourseId, setDeleteStuCourseId] = useState('');

    const [deviceType, setDeviceType] = useState(null);
    
    const printAll = () => {
        window.print();
    };
    const filterHandler = () => {
        document.querySelector(".filter-box").classList.toggle('d-none');
    };
    const cancelPinHandler = () => {
		setVerifyPinClasses('verify-pin-container d-none');
		setEnteredPin('');
	};

    useEffect(() => {
        // checkScreenSize
          /* Storing user's device details in a variable*/
             let details = navigator.userAgent;
       
             /* Creating a regular expression containing some mobile devices keywords to search it in details string*/
             let regexp = /android|iphone|kindle|ipad/i;
       
             /* Using test() method to search regexp in detail it returns boolean value*/
             let isMobileDevice = regexp.test(details);
           
             isMobileDevice ? setDeviceType("mobile") : setDeviceType("desktop");
      }, []);

    const proceedPinHandler = () => {
		if (enteredPin.length !== 0) {
			let stuDeletionData = {
				stu_id: deleteStuId,
                course_id: deleteStuCourseId,
                entered_verify_pin: enteredPin
			};

			setEnteredPin('');
			// setEnteredCatpcha('');
			setVerifyPinClasses('verify-pin-container d-none');
			props.deleteStudentHandeler(stuDeletionData);

		} else {
			alert("Please Enter Pin");
		}
	};

    const typeOfStudentListHandler = (e) => {
        resestFilterRef.current.resetFilterForm();
        props.setStuListType(e.target.value);
        props.fetchAllStudent(e.target.value);
    };

    return (
        <div>
            <header className='table-header'>
                <span>Student Lists</span>
                <div className='pos-rel'>
                    <div className='d-flex align-center'>
                        <span className="material-icons material-symbols-outlined prevent-select" onClick={filterHandler}>tune</span>
                        <span className="material-icons material-symbols-rounded prevent-select" onClick={printAll}>print</span>
                    </div>

                    <FilterStudent ref={resestFilterRef} getFilteredData={props.getFilteredData} filterHandler={filterHandler} allCourses={props.allCourses} />
                </div>
            </header>

            <div className='select-box-container'>
                <select className='select-box' onChange={typeOfStudentListHandler} defaultValue={2}>
                    <option value='1'>All</option>
                    <option value='2'>Active</option>
                    <option value='3'>Discontinue</option>
                    <option value='4'>Pass Out</option>
                </select>
            </div>

            <div className={props.tableClasses}>
                <table>
                    <thead>
                        <tr>
                            {
                                props.tableCols.map((element) => {
                                    return (<th scope="col" id={element} key={element}>{element}</th>);
                                })
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            props.rowsData.map((element)=>{
                                return (<AllStudentTableRow key={element.s_id} element={element} fetchAllStudent={props.fetchAllStudent} setEditStuId={props.setEditStuId} setVerifyPinClasses={setVerifyPinClasses} setDeleteStuId={setDeleteStuId} setDeleteStuCourseId={setDeleteStuCourseId} deviceType={deviceType} token={props.token} hostName={props.hostName} setLoaderClasses={props.setLoaderClasses}  />);
                            })
                        }
                        
                    </tbody>

                </table>
                {/* <LimitRowsCount getRowLimit={props.getRowLimit} LimitRowsCountClasses={props.LimitRowsCountClasses} resestFilterRef={resestFilterRef}/> */}
            </div>
            <center className={props.msgClasses}>{props.msgData}</center> 
            
            <VerifyPinContainer verifyPinClasses={verifyPinClasses} enteredPin={[enteredPin, setEnteredPin]} proceedPinHandler={proceedPinHandler}  cancelPinHandler={cancelPinHandler} />
        </div>
    )
}

export default Table;
