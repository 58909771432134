import React, { useEffect, useRef, useState } from 'react';
import AttendanceViewManualRow from '../../components/admin_components/AttendanceViewManualRow';
import Loader from '../../components/admin_components/Loader';
import './AttendanceViewManual.css';
import AttendanceFilterBox from '../../components/admin_components/AttendanceFilterBox';

let filterObj = {filter_date:'',filter_id:"",filter_name:'',filter_gender:"",filter_course:""};

const AttendanceViewManual = (props) => {
    
    const attendanceTableContainer = useRef();
    const resestFilterRef = useRef();
    const attendanceTableCols = ['Sl', 'ID', 'Name', 'Course', 'Action'];
    const [fromDate, setFromDate] = useState(new Date().toJSON().slice(0, 10));
    const [nameSearch, setNameSearch] = useState('');

    // const [toDate, setToDate] = useState(new Date().toJSON().slice(0, 10));
    const [attendanceTableClasses, setAttendanceTableClasses] = useState('attendance_list_container d-none');
    const [loaderClasses, setLoaderClasses] = useState('loader-container d-none');
    const [attendanceManualData, setAttendanceManualData] = useState([]);
    const [searchedDate, setSearchedDate] = useState(null);
    const [allCourses, setAllCourses] = useState([]);

    const [countPresent, setCountPresent] = useState(0);
    const [countAbsentKnown, setCountAbsentKnown] = useState(0);
    const [countAbsent, setCountAbsent] = useState(0);

    useEffect(()=> {props.setTitle(`Manual Attendance | ${parseInt(props.isHeadAdmin) ? "Admin Panel" : "User Panel"} | RedSky Educations | Educations for future`)}, [props]);

    const printAll = () => {
        attendanceTableContainer.current.classList.remove('overflowY-scroll');
        window.print();
        attendanceTableContainer.current.classList.add('overflowY-scroll');
    };


    const fetchAttendaceData = (e) => {
        e.preventDefault();
        resestFilterRef.current.resetFilterForm();
        fetchAttendanceManualData();
        
    };

    const fetchAllCourses = () => {
        setLoaderClasses('loader-container');

        fetch(`${props.hostName}/redsky_api/getAllCourses.php?token=${props.token}`)
          .then(resp => resp.json()).then(data => {
            setLoaderClasses('loader-container d-none');
            if (data.code === 5) {
              setAllCourses(data.data);
            }
            else {
                document.querySelector('.add-student-form-container .form').innerHTML = `<center>${data.data} <br> Please Try again!</center>`;
            }
    
          }).catch(err => console.log(err));
        };

      useEffect(fetchAllCourses, [props.token,props.hostName]);

    //   const fetchAttendanceManualCount = () => {
    //     setLoaderClasses('loader-container');

    //     fetch(`${props.hostName}/redsky_api/getAttendanceManualCountData.php?token=${props.token}&date=${fromDate}&name=${nameSearch}`)
    //       .then(resp => resp.json()).then(data => {
    //         setLoaderClasses('loader-container d-none');
    //         if (data.code === 5) {
    //           setCountPresent(data.data.present_count);
    //           setCountAbsent(data.data.absent_count);
    //           setCountAbsentKnown(data.data.absent_known_count);
    //         }
    //         else {
    //             alert(data.data);
    //         }
    
    //       }).catch(err => console.log(err));
    //     };
    


    const modifyManualAttendanceHandler = (filePath, subId) => {
        setLoaderClasses('loader-container');
        
        fetch(`${props.hostName}/redsky_api/${filePath}.php?token=${props.token}`,
            {
                method: "POST",
                body: JSON.stringify({ date: searchedDate, sub_id : subId}),
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8'
                }
            })
            .then(resp => resp.json()).then(data => {
            
                if (data.code === 5) {
                    // fetchAttendanceManualCount();
                    getAttendanceManualFilteredCountData();
                    
                } else {
                    alert(data.data);
                }
                setLoaderClasses('loader-container d-none');

            }).catch(err => console.log(err));
    }

    const filterHandler = () => document.querySelector(".filter-box").classList.toggle('d-none');

    const getAttendanceManualFilteredCountData = () => {
        setLoaderClasses('loader-container');
        fetch(`${props.hostName}/redsky_api/getAttendanceManualFilterCountData.php?token=${props.token}`,
        {

            method: "POST",
            body: JSON.stringify(filterObj),
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            }
        })
            .then(resp => resp.json()).then(data => {
                if(data.code === 5){
                    setCountPresent(data.data.present_count);
                    setCountAbsent(data.data.absent_count);
                    setCountAbsentKnown(data.data.absent_known_count);                
                }
                else{
                    alert(data.data);
                }
                setLoaderClasses('loader-container d-none');
                
            }).catch(err => console.log(err));

    };

    const getFilteredData = (filterData) => {
        setLoaderClasses('loader-container');
        
        setSearchedDate(null);
        setAttendanceManualData([]);
        setNameSearch('');

        filterObj = {filter_date: fromDate, ...filterData};

        fetch(`${props.hostName}/redsky_api/get_filtered_manual_attendance.php?token=${props.token}`,
        {

            method: "POST",
            body: JSON.stringify(filterObj),
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            }
        })
            .then(resp => resp.json()).then(data => {
                if(data.code === 5){
                    setAttendanceManualData(data.data['attn_manual_data']);
                    setSearchedDate(data.data['date']);
                    setAttendanceTableClasses('attendance_list_container'); 
                    getAttendanceManualFilteredCountData();                 
                }
                else{
                    setAttendanceTableClasses('attendance_list_container d-none');
                    alert(data.data);
                }
                setLoaderClasses('loader-container d-none');
                
            }).catch(err => console.log(err));

    };

    const fetchAttendanceManualData = () => {
        filterObj = {filter_date:fromDate,filter_id:"",filter_name:nameSearch,filter_gender:"",filter_course:""};
        setLoaderClasses('loader-container');
        setSearchedDate(null);
        setAttendanceManualData([]);

        fetch(`${props.hostName}/redsky_api/getAttendanceManualData.php?token=${props.token}&date=${fromDate}&name=${nameSearch}`)
            .then(resp => resp.json()).then(data => {
                setLoaderClasses('loader-container d-none');
            
                if(data.code === 5){
                    // fetchAttendanceManualCount();
                    getAttendanceManualFilteredCountData(); 
                    setAttendanceManualData(data.data['attn_manual_data']);
                    setSearchedDate(data.data['date']);
                    setAttendanceTableClasses('attendance_list_container');    
                                
                }
                else{
                    setAttendanceTableClasses('attendance_list_container d-none');
                    alert(data.data);
                }
                
            }).catch(err => console.log(err));
    };



  return (
    <>
        <div className='daily-register manual-attendance-container'>
            <header className='table-header'>
                <span>Attendance</span>
                <div className='pos-rel'>
                    <div className='d-flex align-center'>
                        <span className="material-icons material-symbols-outlined prevent-select" onClick={filterHandler}>tune</span>
                        <span className="material-icons material-symbols-rounded prevent-select" onClick={printAll}>print</span>
                    </div>
                    <AttendanceFilterBox ref={resestFilterRef} getFilteredData={getFilteredData} filterHandler={filterHandler} allCourses={allCourses} />
                </div>
            </header>
            <p className='date-range-msg d-none'>Searching For: {fromDate}</p>

            <form className='daily-register-range-form' onSubmit={fetchAttendaceData}>
            
                <div className="column">
                    <div className="input-box">
                        <label style={{marginTop: 0}}>Searching Date<span className='color-red'>*</span></label>
                        <input type="date" placeholder="Enter search date" value={fromDate} onChange={(e) => setFromDate(e.target.value)} required />
                    </div>
                </div>
                <div className="input-box" style={{marginTop:"0.8rem"}}>
                    <label>Search By Name<span className='color-red'>*</span></label>
                    <input type="text" placeholder="Enter Keyword(s)" value={nameSearch} onChange={(e)=>setNameSearch(e.target.value)} />
                </div>
                <input type='submit' className='submit-button' value='Search' />
                {/* <p className='text-or'>OR</p> */}
            </form>

            <form className={ attendanceTableClasses + ' form'} >      
                <div ref={attendanceTableContainer} className='table-container overflowY-scroll'>
                    <div className='table-captions'>
                        <h2 className='table-caption'>Attendance Details</h2>
                    </div>
                    <div className='attendance-meta-data d-oflex align-center justify-between'>
                            <p>Present: {countPresent}</p>
                            <p>Date: {searchedDate}</p>
                            <p>Absent: {countAbsent}[{countAbsentKnown}]</p>
                        </div>
                    <table>
                        <thead>
                            <tr>
                                {
                                    attendanceTableCols.map((element) => {
                                        return (<th scope="col" key={element} data={element}>{element}</th>);
                                    })
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                (attendanceManualData.length > 0) ?
                                    attendanceManualData.map((element, i) => {                                      
                                        
                                        return(<AttendanceViewManualRow key={i} element={element} i={++i} modifyManualAttendanceHandler={modifyManualAttendanceHandler} />);
                                    })
                                :
                                <tr>
                                    <td colSpan='8'>
                                    <center>No Record Found</center>
                                    </td>
                                </tr>
                                
                            }
                                
                        </tbody>
                    </table>
                </div>
                {/* <div className='bottom-hang-btn d-flex'>
                    <button onClick={modifyAttendanceHandler}>Modify</button>
                </div>  */}
            </form> 
            
                {/* payment table end*/}
        </div>
        <Loader loaderClasses={loaderClasses}/>
    </>
  )
}

export default AttendanceViewManual;
