import React, { useEffect } from 'react';
import './AllStudents.css';
import './DailyRegister.css';
import DailyRegisterTable from '../../components/admin_components/DailyRegisterTable';
import { useNavigate } from 'react-router-dom';

const DailyRegister = (props) => {  
    const navigate = useNavigate();
    useEffect(()=>{
      if(props.isHeadAdmin === '0'){
        navigate('../all-students');
      }
    });
    useEffect(()=> {props.setTitle(`Daily Register | ${parseInt(props.isHeadAdmin) ? "Admin Panel" : "User Panel"} | RedSky Educations | Educations for future`)}, [props]);
    
    return (
        <>
            <DailyRegisterTable hostName={props.hostName} token={props.token}  />
        </>
    );
};

export default DailyRegister;
