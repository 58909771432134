import React, { useEffect, useState } from 'react'

const Alert = (props) => {
    
    const [clsName, setClsName] = useState(props.alert ? 'active' : '');

    useEffect(() =>{
        setTimeout(() => {
            setClsName('');
            if(props.isWelcomeMsg)
                localStorage.setItem("redsky_stu_welcome_msg", true);
        }, props.time);
    },[props.time, props.isWelcomeMsg]);
  return (
    <div className={`alert-container ${props.pos} ` + clsName }>
        <div className={"alert alert-dismissible fade show " + ( props.alert === 'true' ? "alert-success" : "alert-danger")} role="alert">
        <strong>{props.strongMsg}</strong> {props.msg}
            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
    </div>
  )
}

export default Alert
