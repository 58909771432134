import React, { useEffect } from 'react';

const BatchTimeTable = (props) => {
  useEffect(()=> {props.setTitle("Batch Time Table | Task Panel | RedSky Educations | Educations for future")}, [props]);
  return (
    <div className='helolo' style={{overflow:"scroll", maxHeight:"100vh", height:"100%", minHeight: "90vh"}}>
      <iframe title='Batch-Time-Table' src='https://docs.google.com/spreadsheets/d/e/2PACX-1vTj16VggHA86Sa0qVbz883-hcjHTvLOvYHgQ_njBmk9LGChDagy8SOrDLuzUh-65hAyn_00838ouFkN/pubhtml' style={{ minHeight:"90vh", width: "100%"}}></iframe>
    </div>
  );
};

export default BatchTimeTable;
