import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './PrintRegistration.css';
import Loader from '../../components/admin_components/Loader';
import mcaImg from '../../assets/img/m_c_a.png';
import classplus from '../../assets/img/Classplus.png';
import logo from '../../assets/img/redsky logo.jpeg';
// import airtel from '../../assets/img/Airtel_Logo.png';
import tataTele from '../../assets/img/tata_tele.jpeg';
import ScrollToTopAndPrint from '../../components/admin_components/ScrollToTopAndPrint ';

const PrintRegistration = (props) => {
	const param = useParams();
	const [stuRegData, setStuRegDataData] = useState([]);
	const [loaderClasses, setLoaderClasses] = useState('loader-container d-none');
	
	useEffect(()=> {props.setTitle(`Print Registration | ${parseInt(props.isHeadAdmin) ? "Admin Panel" : "User Panel"} | RedSky Educations | Educations for future`)}, [props]);

	useEffect(() => {
		setLoaderClasses('loader-container');
		fetch(`${props.hostName}/redsky_api/getRegistrationDetails.php?token=${props.token}&id=${param.id}`)
			.then(resp => resp.json()).then(data => {
				if (data.code === 5) {
					setStuRegDataData(data.data);
				}else{
					alert(data.data);
				}
				setLoaderClasses('loader-container d-none');
			}).catch(err => console.log(err));
	}, [props.token, props.hostName, param, stuRegData.has_next_due]);
	
	return (
		<>
			<div style={{overflowX:"scroll"}} className='outer-container'>
				<div id="reg-container">
					<div className='d-flex'>
						<img src={logo} alt='logo' height={220}/>
					</div>

					<div className='reg-tag'>
						<h2>Registration Certificate</h2>
						<p>Session&nbsp;{stuRegData.year_of_admission}</p>
					</div>

					<div className="student-reg-data">
						<p style={{textAlign:'justify'}}>
							This is to certify that Mr/Mrs/Miss <span className='capital'><b>{stuRegData.full_name}</b></span> c/o <span className='capital'>{stuRegData.guardian_name}</span>, Address: {stuRegData.address} has been registered under training program of RedSky Educations (P) Ltd. dated on {stuRegData.date_of_admission}.
						</p>
						<p style={{margin:"1rem 0"}}>Your ID is <b>{stuRegData.reg_no}</b>.</p>
						<p style={{marginBottom:0}}>You can verify your details on below link:</p>
						{/* <p>Student profile link: redskyeducations.co.in/profile/{stuRegData.reg_no}</p> */}
						<p>Student profile link: redskyeducations.co.in/student/</p>
					</div>
					<div className='website-helpdesk-container'>
						<p>Helpdesk: +91 9474897192</p>
						<p>Website: https://redskyeducations.in</p>
						<p>Mail ID: redskyeducations@gmail.com</p>
						<img src={`https://api.qrserver.com/v1/create-qr-code/?size=125x125&data=https://redskyeducations.co.in/student/${stuRegData.reg_no}/${stuRegData.contact}`} id='qr-img' alt='qr_code'/>
					</div>
					<div className="reg-seal-container">
						<div>
							<p>Director</p>
							<p>RedSky Educations (P) Ltd.</p>
						</div>
					</div>
					{/* <div id='accreditation'>
						<p>Accreditation: <br/>Institute registered under MCA</p>
					</div> */}
					<div className="accreditation-container" style={{padding:"0 2rem", marginTop:"5.9rem"}} >
						<h3 className="details-heading">ACCREDITATION:</h3>
						<div className="accreditation-container__details">
							{/* 1 */}
							<div className='accreditation-container__details__div'>
								<small>Institute Registered Under</small>
								<img src={mcaImg} alt="MCA" id="mca-img" />
							</div>
							{/* 2 */}
							<div className='accreditation-container__details__div'>
								<small>DLT Registered Under</small>
								<img src={tataTele} alt="tata_tele" style={{height:"50px", marginTop:"10px"}}  />
							</div>
							{/* 3 */}
							<div className='accreditation-container__details__div'>
								<small>Trusted Partner</small>
								<div className='d-flex' style={{gridGap:"0.7rem", justifyContent:"flex-end"}}>
								<img src={classplus} alt="classplus" id="classplus-img" />
								{/* <img src={airtel} alt="airtel" id="airtel-img" /> */}
								</div>
							</div>
						</div>
					</div>
				</div>
				<ScrollToTopAndPrint btnText="Print" icon="print" />
			</div>


			<Loader loaderClasses={loaderClasses}/>
		</>
	)
}

export default PrintRegistration;
