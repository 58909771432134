import React, { useState } from 'react';
import './RecordAttendance.css';

const RecordAttendance = (props) => {
  const [stuRegNo, setStuRegNo] = useState('');

  const inputValidate = (e) => {
    e.preventDefault();
    if(stuRegNo.trim() === ''){
      alert("Please Enter a Valid Student ID!");
      return;
    }
    
    props.storeAttendaceData(stuRegNo);
    setStuRegNo('');
  };


  return (
    <div>
      <section className="record-attendance-container">
        <header>{props.searchTitle}</header>
        <form className="form" onSubmit={inputValidate}>
          
            <div className="input-box">
              <label>Enter Student ID:</label>
              <input type="text" placeholder="Enter Student ID" value={stuRegNo} onChange={(e)=>setStuRegNo(e.target.value)} autoFocus required />
            </div>   

          <button type='submit'>Record</button>
        </form>
        
      </section>
    </div>
  )
}

export default RecordAttendance;
