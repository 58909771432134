import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import "./PaidFeesListStudent.css";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/admin_components/Loader";
import logo from "../../assets/img/redsky logo.jpeg";
// import PaidFeesTableRowStudent from '../../components/student_components/PaidFeesTableRowStudent';

const PaymentDetails = (props) => {
  //   props.setTitle("RedSky Digital Passbook | Student Panel | RedSky Educations");
  const param = useParams();
  const navigate = useNavigate();

  const [loaderClasses, setLoaderClasses] = useState("loader-container d-none");
  const [paidFeesLists, setPaidFeesLists] = useState([]);
  const [lastPaymentDetails, setLastPaymentDetails] = useState({});

  useEffect(() => {
    setLoaderClasses("loader-container");

    fetch(
      `${props.hostName}/redsky_api/student/get_paid_fees_list_student.php?stu_reg_no=${param.reg_no}&contact=${param.contact}`
    )
      .then((resp) => resp.json())
      .then((data) => {
        if (data.code === 5) {
          setPaidFeesLists(data.data);
          //   console.log(data.data);

          setLastPaymentDetails(data.data[0]);
        } else {
          alert(data.data);
          navigate("../");
        }
        setLoaderClasses("loader-container d-none");
      })
      .catch((err) => console.log(err));
  }, [param, props.hostName, navigate]);

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="RedSky Digital Passbook | Student Panel | RedSky Educations"
        />
        <title>
          RedSky Digital Passbook | Student Panel | RedSky Educations
        </title>
      </Helmet>
      <div className="container-fluid py-4" id="profile">
        <div className="row">
          <div className="col-12 text-center">
            <h4 className="mb-3">RedSky Digital Passbook</h4>
            <img src={logo} alt="Logo" height="150" />
            <h5 className="mb-2 mt-3">Payments Details</h5>
            <p className="text-uppercase">
              <b>
                {lastPaymentDetails.stu_fname} {lastPaymentDetails.stu_lname}
              </b>
            </p>
            <hr className="border border-secondary border-1 opacity-75 mt-3" />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="accordion" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button text-white"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    Last Payment Data
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse show"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    {paidFeesLists.length > 0 ? (
                      <>
                        <p className="text-center fw-medium mb-4">
                          Course: {lastPaymentDetails.course_name}
                        </p>
                        {"payment_data" in lastPaymentDetails ? (
                          <div className="table-responsive mt-3">
                            <table className="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col">Installment No</th>
                                  <th scope="col">For Month</th>
                                  <th scope="col">Amount</th>
                                  <th scope="col">Date</th>
                                  <th scope="col">Time</th>
                                  <th scope="col">Mode</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  key={
                                    lastPaymentDetails.payment_data[0]
                                      .installment_no
                                  }
                                >
                                  <td data-label="Installment No">
                                    {
                                      lastPaymentDetails.payment_data[0]
                                        .installment_no
                                    }
                                  </td>
                                  <td data-label="For Month">
                                    {
                                      lastPaymentDetails.payment_data[0]
                                        .for_month_year
                                    }
                                  </td>
                                  <td data-label="Amount">
                                    {
                                      lastPaymentDetails.payment_data[0]
                                        .paid_amount
                                    }
                                    /-
                                  </td>
                                  <td data-label="Date">
                                    {lastPaymentDetails.payment_data[0].date}
                                  </td>
                                  <td data-label="Time">
                                    {lastPaymentDetails.payment_data[0].time}
                                  </td>
                                  <td data-label="Mode">
                                    {
                                      lastPaymentDetails.payment_data[0]
                                        .payment_mode
                                    }
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        ) : (
                          <p className="mb-0 mt-4 text-center">
                            No Last Payment Details Found
                          </p>
                        )}
                      </>
                    ) : (
                      <p className="mb-0 mt-4 text-center">
                        No Last Payment Details Found
                      </p>
                    )}
                  </div>
                </div>
              </div>

              {paidFeesLists.map((elem, i) => {
                return (
                  <div className="accordion-item" key={i}>
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button text-white collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#collapse${i}`}
                        aria-expanded="false"
                        aria-controls={`collapse${i}`}
                      >
                        {elem.course_name}
                      </button>
                    </h2>
                    <div
                      id={`collapse${i}`}
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        {"payment_data" in elem ? (
                          <div className="table-responsive mt-3">
                            <table className="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col">No.</th>
                                  <th scope="col">Reciept No</th>
                                  <th scope="col">For Month</th>
                                  <th scope="col">Date</th>
                                </tr>
                              </thead>
                              <tbody>
                                {elem.payment_data.map((paymentRow) => {
                                  return (
                                    <tr
                                      className={
                                        paymentRow.receipt_no === "0"
                                          ? "deferral-row"
                                          : ""
                                      }
                                      key={paymentRow.installment_no}
                                    >
                                      <td data-label="No.">
                                        {paymentRow.installment_no}
                                      </td>
                                      <td data-label="Reciept No">
                                        {paymentRow.receipt_no === "0"
                                          ? "N/A"
                                          : paymentRow.receipt_no}
                                      </td>
                                      <td data-label="For Month">
                                        {paymentRow.for_month_year}
                                      </td>
                                      <td data-label="Date">
                                        {paymentRow.date}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        ) : (
                          <p className="mb-0 mt-4 text-center">
                            No Payment Details Found
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <Loader loaderClasses={loaderClasses} />
    </>
  );
  //   return (
  //     <div className='student-panel pt-5'>
  //         <div className='d-flex flex-col'>
  //             <form action='#' >
  //                 <h3 id='last-payment-details-heading'>Last Payment Details</h3>
  //                 { Object.keys(paidFeesLists).length > 0 ?
  //                     <div className='d-oflex flex-col paid-fees-list__last-payment-container'>
  //                         <p>Installment No: <b>{lastPaymentDetails.installment_no}</b></p>
  //                         <p>For Month: <b>{lastPaymentDetails.for_month_year}</b></p>
  //                         <p>Paid Amount: <b>&#8377;{lastPaymentDetails.paid_amount}/-</b></p>
  //                         <p>Payment Date: <b>{lastPaymentDetails.date}</b></p>
  //                         <p>Payment Time: <b>{lastPaymentDetails.time}</b></p>
  //                         <p>Payment Mode: <b>{lastPaymentDetails.payment_mode}</b></p>
  //                     </div>
  //                 :
  //                     <p className='mb-0 mt-4 text-center'>No Last Payment Details Found</p>
  //                 }

  //             </form>
  //             <table className='paid-table '>
  //                     <thead>
  //                         <tr>
  //                             <th scope="col">No</th>
  //                             <th scope="col">For Month</th>
  //                             <th scope="col">Date</th>
  //                             <th scope="col">Amount</th>
  //                             <th scope="col">Status</th>
  //                         </tr>
  //                     </thead>
  //                     <tbody>
  //                         {
  //                             (paidFeesLists.length > 0) ? (
  //                                 paidFeesLists.map((element, i) => {
  //                                     return(
  //                                             <PaidFeesTableRowStudent key={i} element={element} />
  //                                         );
  //                                 })
  //                             )
  //                             :
  //                             <tr>
  //                                 <td colSpan='5'>
  //                                 <center>No Payment Details Found</center>
  //                                 </td>
  //                             </tr>
  //                         }

  //                     </tbody>
  //                 </table>
  //         </div>
  //         <Loader loaderClasses={loaderClasses}/>
  //     </div>
  //   )
};

export default PaymentDetails;
