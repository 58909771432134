import React from 'react';
import './Table.css';
import AllCoursesTableRow from './AllCoursesTableRow';


const AllCoursesTable = (props) => {

    const printAll = ()=>{
        window.print();
    };

    return (
        <div>
            <header className='table-header'>
                <span>{(props.tableName) ? props.tableName :'Student Lists'}</span>
                <div className='pos-rel'>
                    <div className='d-flex align-center'>
                        <span className="material-icons material-symbols-rounded" onClick={printAll}>print</span>
                    </div>

                </div>
            </header>

            <div className={props.tableClasses}>
                <table>
                    <thead>
                        <tr>
                            {
                                props.tableCols.map((element)=>{
                                    return(<th scope="col" id={element} key={element}>{element}</th>);
                                })
                            }
                        </tr>
                    </thead>
                    <tbody>                    
                        <AllCoursesTableRow allCourses={props.allCourses}  deleteCourseHandeler={props.deleteCourseHandeler} token={props.token}/>    
                    </tbody>
                    
                </table>
            </div>
                <center className={props.msgClasses}>{props.msgData}</center>
        </div>
    )
}

export default AllCoursesTable;
