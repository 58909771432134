import React, { useEffect, useState} from 'react';
import Loader from '../../components/admin_components/Loader';
import MarksModifyForm from '../../components/admin_components/MarksModifyForm';
import VerifyPinContainer from '../../components/admin_components/VerifyPinContainer';

const ViewMarksList = (props) => {
  const [allCourses, setAllCourses] = useState([]);
  const [marksData, setMarksData] = useState([]);
  const [courseId, setCourseId] = useState('');
  const [courseName, setCourseName] = useState('');
  const [yearOfExam, setYearOfExam] = useState('');
  const [isShowForm, setIsShowForm] = useState('d-none');
  const [loaderClasses, setLoaderClasses] = useState('loader-container d-none');
  const [enteredPin, setEnteredPin] = useState('');
	const [verifyPinClasses, setVerifyPinClasses] = useState('verify-pin-container d-none');
  const [deleteMarksId, setDeleteMarksId] = useState(null);

  useEffect(()=> {props.setTitle(`View Marks | ${parseInt(props.isHeadAdmin) ? "Admin Panel" : "User Panel"} | RedSky Educations | Educations for future`)}, [props]);

  const cancelPinHandler = () => {
		setVerifyPinClasses('verify-pin-container d-none');
		setEnteredPin('');
	};

  const getMarksData = () => {

    setIsShowForm('d-none');
    setLoaderClasses('loader-container');
    setMarksData([]);
    fetch(`${props.hostName}/redsky_api/get_all_marks_data.php?token=${props.token}&course_id=${courseId}&year_of_exam=${yearOfExam}`)
      .then(resp => resp.json()).then(data => {
        
        if(data.code === 5){
          setMarksData(data.data['marks_data']);
          setIsShowForm('');
        }else{
          alert(data.data);
        }
        setLoaderClasses('loader-container d-none');
        
      }).catch(err => console.log(err));

  };
  const formValidate = (e) =>{
    e.preventDefault();
    if(!courseId){
      alert("Please Select Course Id");
      return;
    }
    if(!yearOfExam){
      alert("Please Enter Date of Exam");
      return;
    }
    getMarksData();
  }

  const modifyMarksData = (marksData, setIsDisabled, setBtnType) => {
   
    setLoaderClasses('loader-container');
    // stu_id = searchVal.student_id;
    fetch(`${props.hostName}/redsky_api/modify_marks.php?token=${props.token}`,
    {
        method: "POST",
        body: JSON.stringify(marksData),
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        }
    })
    .then(resp => resp.json()).then(data => {
        
      if(data.code === 5){
        setBtnType('edit');
        setIsDisabled(true);
        alert(data.data);
      }else{
        alert(data.data);
      }
      setLoaderClasses('loader-container d-none');
        
    }).catch(err => console.log(err));
  };

  const fetchAllCourses = () => {
    setLoaderClasses('loader-container');
    fetch(`${props.hostName}/redsky_api/getAllCoursesWithOutProfCourse.php?token=${props.token}`)
      .then(resp => resp.json()).then(data => {

        if (data.code === 5) {
          setAllCourses(data.data);
        }
        else {
            document.querySelector('.add-student-form-container .form').innerHTML = `<center>${data.data} <br> Please Try again!</center>`;
        }
        setLoaderClasses('loader-container d-none');

      }).catch(err => console.log(err));
    };
  useEffect(fetchAllCourses, [props.token, props.hostName]);

  const deleteMarks = (deleteData) => {
    setLoaderClasses('loader-container');
        
    //deletion code will go here
    fetch(`${props.hostName}/redsky_api/delete_marks.php?token=${props.token}`,
    {
      method: "POST",
      body: JSON.stringify(deleteData),
      headers: {
        'Content-Type': 'application/json;charset=UTF-8'
      }
    })
    .then(resp => resp.json()).then(data => {
      if(data.code === 12){
        getMarksData();
        alert(data.data);
      }else{
        alert(data.data);
      }
      setLoaderClasses('loader-container d-none');
      
    }).catch(err => console.log(err));
  }
  const proceedPinHandler = () => {
		if (enteredPin.length !== 0) {
			let marksDeleteData = {
				marks_id: deleteMarksId,
        entered_verify_pin: enteredPin
			};

			setEnteredPin('');
			// setEnteredCatpcha('');
			setVerifyPinClasses('verify-pin-container d-none');
			deleteMarks(marksDeleteData);

		} else {
			alert("Please Enter Pin");
		}
	};

  return (
    <>
      <div>
        <section className="bg-white py-4 pb-5 px-5 mx-4 my-2 rounded mb-3 non-printable">
            <header className='h4 text-center mt-2 mb-0'>View Marks</header>
            <form className="form" onSubmit={formValidate}>
            
                <div className='column'>
                  <div className="input-box">
                      <label>Exam Year:<span className='color-red'>*</span></label>
                      <input type='text' value={yearOfExam} onChange={(e)=>setYearOfExam(e.target.value)} placeholder='Enter Exam Year (eg. 2023)' required />
                  </div>

                  <div className="input-box">
                      <label>Select Course:<span className='color-red'>*</span></label>
                      <div className="select-box">
                          <select onChange={(e)=>{setCourseId(e.target.value);setCourseName(e.target.options[e.target.selectedIndex].text)}} className="course-options" required>
                              <option hidden className='placeHolder'>--Select Course--</option>
                              {
                                allCourses.map((element) => {
                                  return (<option key={element.c_id} value={element.c_id} data={5}>{element.c_name}</option>);
                                })
                              }
                          </select>
                      </div>
                  </div>   
                </div>
                <button type='submit'>Search</button>
            </form>
        </section>
      </div>

      <div className={isShowForm}>
        {/* <StudentShortDetails studentData={StudentData}/> */}
        <MarksModifyForm modifyMarksData={modifyMarksData} marksData={marksData} setVerifyPinClasses={setVerifyPinClasses} setDeleteMarksId={setDeleteMarksId} courseName={courseName} yearOfExam={yearOfExam} />
      </div>
      <Loader loaderClasses={loaderClasses} />
      <VerifyPinContainer verifyPinClasses={verifyPinClasses} enteredPin={[enteredPin, setEnteredPin]} proceedPinHandler={proceedPinHandler}  cancelPinHandler={cancelPinHandler} />
    </>
  );
}

export default ViewMarksList;

