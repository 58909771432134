import React from 'react';
import SearchStudentBox from './SearchStudentBox';
import StudentForm from './StudentForm';

const ModifyStudentForm = (props) => {

  return (
    <>
      <SearchStudentBox searchTitle="Search Student" searchStuId={props.searchStuId} setSearchData={props.getSearchData} />
      <StudentForm hostName={props.hostName} token={props.token} studentData={props.studentData} modType={props.modType} allCourses={props.allCourses} isShowForm={props.isShowForm}  setFormData={props.setFormData} setLoaderClasses={props.setLoaderClasses} />
    </>
  )
}

export default ModifyStudentForm;
