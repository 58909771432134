import React, { useEffect, useState } from 'react';
import Loader from '../../components/admin_components/Loader';
import './AssignOnlineClass.css';

const AssignOnlineClass = (props) => {

  const [course, setCourse] = useState('');
  const [mentor, setMentor] = useState('');
  const [topic, setTopic] = useState('');
  const [classDate, setClassDate] = useState('');
  const [strTime, setStrTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [totalNoOfStudent, setTotalNoOfStudent] = useState('');


  const [loaderClasses, setLoaderClasses] = useState('loader-container d-none');
  const [allCourses, setAllCourses] = useState([]);

  useEffect(()=> {props.setTitle(`Assign Class | ${parseInt(props.isHeadAdmin) ? "Admin Panel" : "User Panel"} | RedSky Educations | Educations for future`)}, [props]);
  
  const resetForm = () => {
    setCourse('');
    setMentor('');
    setTopic('');
    setClassDate('');
    setStrTime('');
    setEndTime('');
    setTotalNoOfStudent('');
    document.querySelectorAll(".placeHolder")[0].selected = true;
    document.querySelectorAll(".placeHolder")[1].selected = true;
  }
  const formValidate = (e) =>{
    e.preventDefault();

    if(course === ''){
      alert("Please Select Course");
      return;
    }
    
    setLoaderClasses('loader-container');
    fetch(`${props.hostName}/redsky_api/assign_class.php?token=${props.token}`,
      {
        method: "POST",
        body: JSON.stringify({course_id: course, mentor: mentor, topic: topic, date: classDate, str_time: strTime, end_time: endTime, total_student: totalNoOfStudent}),
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        }
      })
      .then(resp => resp.json()).then(data => {
  
        if(data.code === 5){
          alert(data.data);
          resetForm();
        }else{
          alert(data.data);
        }
        setLoaderClasses('loader-container d-none');
      }).catch(err => console.log(err));
  }

  const fetchAllCourses = () => {
    setLoaderClasses('loader-container');
    fetch(`${props.hostName}/redsky_api/getAllCourses.php?token=${props.token}`)
      .then(resp => resp.json()).then(data => {

        if (data.code === 5) {
          setAllCourses(data.data);
        }
        else {
            document.querySelector('.assign-class-form-container .form').innerHTML = `<center>${data.data} <br> Please Try again!</center>`;
        }
        setLoaderClasses('loader-container d-none');

      }).catch(err => console.log(err));
    };
  useEffect(fetchAllCourses, [props.token, props.hostName]);

  return (
    <>
        <section className='assign-class-form-container'>
            <header>Assign Class</header>

            <form action="#" className="form" onSubmit={formValidate} >

                <div className="column">
                    <div className="input-box">
                        <label>Course<span className='color-red'>*</span></label>
                        <div className="select-box">
                          <select onChange={(e)=>setCourse(e.target.value)} className="course-options" required>
                              <option hidden className='placeHolder'>--Select Course--</option>
                              {
                              allCourses.map((element) => {
                                  return (<option key={element.c_id} value={element.c_id}>{element.c_name}</option>);
                              })
                              }
                          </select>
                        </div>
                    </div>
                    <div className="input-box">
                        <label>Mentor<span className='color-red'>*</span></label>
                        <div className="select-box">
                            <select onChange={(e) => setMentor(e.target.value)} className="course-options" required>
                                <option hidden className='placeHolder' value=''>--Select Mentor--</option>
                                <option value='Arpi Saha'>Arpi Saha</option>
                                <option value='Gourab Talukdar'>Gourab Talukdar</option>
                                <option value='Rahul Saha'>Rahul Saha</option>
                            </select>
                        </div>
                    </div>
                </div>
                
                <div className="input-box">
                    <label>Class Topic<span className='color-red'>*</span></label>
                    <input type="text" placeholder="Enter Class Topic" value={topic} onChange={(e) => setTopic(e.target.value)} required />
                </div>

                <div className="input-box">
                    <label>Class Date<span className='color-red'>*</span></label>
                    <input type="date" placeholder="Enter class date" value={classDate} onChange={(e) => setClassDate(e.target.value)} required />
                </div>

                <div className="column">
                    <div className="input-box">
                        <label>Starting Time<span className='color-red'>*</span></label>
                        <input type="time" placeholder="EnterStarting Time" value={strTime} onChange={(e) => setStrTime(e.target.value)} required />
                    </div>
                    <div className="input-box">
                        <label>Ending Time<span className='color-red'>*</span></label>
                        <input type="time" placeholder="Enter Ending Time" value={endTime} onChange={(e) => setEndTime(e.target.value)} required />
                    </div>
                </div>

                <div className="input-box">
                    <label>Total No of Students<span className='color-red'>*</span></label>
                    <input type="text" placeholder="Enter Total No of Studens" value={totalNoOfStudent} onChange={(e) => setTotalNoOfStudent(e.target.value)} required />
                </div>

                <button type='submit'>Assign Class</button>
            </form>
        </section>

        <Loader loaderClasses={loaderClasses} />
    </>
  )
}

export default AssignOnlineClass;
