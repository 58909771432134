import React, { useEffect, useState} from 'react';
import Loader from '../../components/admin_components/Loader';
import './PaymentStudent.css';
import MarksUploadForm from '../../components/admin_components/MarksUploadForm';

const MarksUpload = (props) => {
  const [allCourses, setAllCourses] = useState([]);
  const [studentData, setStudentData] = useState([]);
  const [isShowForm, setIsShowForm] = useState('d-none');
  const [loaderClasses, setLoaderClasses] = useState('loader-container d-none');

  useEffect(()=> {props.setTitle(`Upload Marks | ${parseInt(props.isHeadAdmin) ? "Admin Panel" : "User Panel"} | RedSky Educations | Educations for future`)}, [props]);

  const getSearchData = (c_id) => {

    setIsShowForm('d-none');
    setLoaderClasses('loader-container');
   
    fetch(`${props.hostName}/redsky_api/get_marks_upload_student_data.php?token=${props.token}&course_id=${c_id}`)
      .then(resp => resp.json()).then(data => {
        
        if(data.code === 5){
          setStudentData(data.data['stu_data']);
          setIsShowForm('');
        }else{
          alert(data.data);
        }
        setLoaderClasses('loader-container d-none');
        
      }).catch(err => console.log(err));

  };
  const courseHandler = (e) =>{
    getSearchData(e.target.value);
  }

  const storeMarks = (marksData, row) => {
    
    setLoaderClasses('loader-container');
    fetch(`${props.hostName}/redsky_api/add_marks.php?token=${props.token}`,
    {
        method: "POST",
        body: JSON.stringify(marksData),
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        }
    })
    .then(resp => resp.json()).then(data => {
        
      if(data.code === 5){
        row.current.classList.add('d-none');
        alert(data.data);
      }else{
        alert(data.data);
      }
      setLoaderClasses('loader-container d-none');
        
    }).catch(err => console.log(err));
  };

  const fetchAllCourses = () => {
    setLoaderClasses('loader-container');
    fetch(`${props.hostName}/redsky_api/getAllCoursesWithOutProfCourse.php?token=${props.token}`)
      .then(resp => resp.json()).then(data => {

        if (data.code === 5) {
          setAllCourses(data.data);
        }
        else {
            document.querySelector('.add-student-form-container .form').innerHTML = `<center>${data.data} <br> Please Try again!</center>`;
        }
        setLoaderClasses('loader-container d-none');

      }).catch(err => console.log(err));
    };
  useEffect(fetchAllCourses, [props.token, props.hostName]);

  return (
    <>
      <div>
        <section className="search-container">
          <header>Upload Marks</header>
          <form className="form" style={{marginBottom:"1.5rem"}}>
            
              <div className="input-box">
                <label>Select Course:<span className='color-red'>*</span></label>
                <div className="select-box">
                  <select onChange={courseHandler} className="course-options">
                    <option hidden className='placeHolder'>--Select Course--</option>
                    {
                      allCourses.map((element) => {
                        return (<option key={element.c_id} value={element.c_id}>{element.c_name}</option>);
                      })
                    }
                  </select>
                </div>
              </div>   

            {/* <button type='submit'>Search</button> */}
          </form>
        </section>
      </div>

      <div className={isShowForm}>
        <MarksUploadForm storeMarks={storeMarks} studentData={studentData} />
      </div>
      <Loader loaderClasses={loaderClasses} />
    </>
  );
}

export default MarksUpload;
