import React, { useState, useEffect } from 'react';
import Loader from '../../components/admin_components/Loader';
// import PrintEnqueryBill from '../../components/admin_components/PrintEnqueryBill';
import { Route, Routes, useNavigate } from 'react-router-dom';
import PrintBookAdvanceBill from '../../components/admin_components/PrintBookAdvanceBill';
import PageNotFound from './PageNotFound';
import InstantBillForm from '../../components/admin_components/InstantBillForm';


const InstantBill = (props) => {
  const navigate = useNavigate();
  const [loaderClasses, setLoaderClasses] = useState('loader-container d-none');
  const [allCourses, setAllCourses] = useState([]);

  useEffect(()=> {props.setTitle(`Instant Bill | ${parseInt(props.isHeadAdmin) ? "Admin Panel" : "User Panel"} | RedSky Educations | Educations for future`)}, [props]);

  const fetchAllCourses = () => {
    setLoaderClasses('loader-container');
    fetch(`${props.hostName}/redsky_api/getAllCourses.php?token=${props.token}`)
      .then(resp => resp.json()).then(data => {
        setLoaderClasses('d-none');
        if (data.code === 5) {
          setAllCourses(data.data);
        }
        else {
          document.querySelector('.add-student-form-container .form').innerHTML = `<center>${data.data} <br> Please Try again!</center>`;
        }

      }).catch(err => console.log(err));
  };
  useEffect(fetchAllCourses, [props.token, props.hostName]);

  const storeAdvancedBooking = (bookAdvanceFormData) => {
		setLoaderClasses('loader-container');
		fetch(`${props.hostName}/redsky_api/addAdvanceBooking.php?token=${props.token}`,
		{
			method: "POST",
			body: JSON.stringify(bookAdvanceFormData),
			headers: {
			'Content-Type': 'application/json;charset=UTF-8'
			}
		})
		.then(resp => resp.json()).then(data => {
			if(data.code === 8){
				navigate('./print-book-advance', {state:{bookAdvanceFormData: bookAdvanceFormData}} );
			}else{
				alert(data.data);
			}
			setLoaderClasses('loader-container d-none');
		}).catch(err => console.log(err));

  };

  return (
    <div>
      <Routes>
          <Route path='/' element={<InstantBillForm setTitle={props.setTitle} allCourses={allCourses} storeAdvancedBooking={storeAdvancedBooking}/>} />
          <Route path='/print-book-advance' element={<PrintBookAdvanceBill setTitle={props.setTitle} hostName={props.hostName} token={props.token} isHeadAdmin={props.isHeadAdmin} />} />
          <Route path='/*' element={<PageNotFound setTitle={props.setTitle} />} />
      </Routes>
      
      <Loader loaderClasses={loaderClasses}/>
      
    </div>
  )
}

export default InstantBill;
