import React, { useState } from 'react';
import './SearchStudentBox.css';

const SearchStudentBox = (props) => {
  const [searchVal, setSearchVal] = useState(props.searchStuId);

  // const resetSearchField = () => {
  //   setSearchVal('');
  // };

  const searchValidate = (e) => {
    e.preventDefault();
    if(searchVal.trim() === ''){
      alert("Please Enter a Search value!");
      return;
    }
    let searchData = {
      stu_reg_no : searchVal
    };
    

    props.setSearchData(searchData);
    // resetSearchField();
  };


  return (
    <div>
      <section className="search-container">
        <header>{props.searchTitle}</header>
        <form className="form" onSubmit={searchValidate}>
          
            <div className="input-box">
              <label>Enter Student ID</label>
              <input type="text" placeholder="Enter Student ID" value={searchVal} onChange={(e)=>setSearchVal(e.target.value)} autoFocus required />
            </div>   

          <button type='submit'>Search</button>
        </form>
      </section>
    </div>
  )
}

export default SearchStudentBox
