import React, { useState, useRef, useEffect } from "react";
import "./AddTask.css";
import Loader from "../../components/admin_components/Loader";
import FilterStudentTask from "../../components/task_components/FilterStudentTask";

let checkedStudentData = [];
const AddTask = (props) => {
  const resestFilterRef = useRef();
  const [taskHeading, setTaskHeading] = useState("");
  const [taskForDate, setTaskForDate] = useState("");
  const [taskDetails, setTaskDetails] = useState("");
  const [taskType, setTaskType] = useState("");
  const [loaderClasses, setLoaderClasses] = useState("loader-container d-none");
  const [allCourses, setAllCourses] = useState([]);
  const [allStudentData, setAllStudentData] = useState([]);

  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);

  const [noOfSelection, setNoOfSelection] = useState(0);
  // const [checkedStudentData, setCheckedStudentData] = useState([]);

  useEffect(() => {
    props.setTitle(
      "Add Task | Task Panel | RedSky Educations | Educations for future"
    );
  }, [props]);

  const resetFormFields = () => {
    setTaskHeading("");
    setTaskDetails("");
    setTaskForDate("");
    setTaskType("");
    checkedStudentData = [];
    setNoOfSelection(0);

    document.querySelectorAll(".placeHolder")[0].selected = true;
    let checkBoxes = document.querySelectorAll(".student input[type=checkbox]");
    checkBoxes.forEach((elem) => (elem.checked = false));
  };

  const validateAddTaskHandler = (e) => {
    e.preventDefault();

    if (
      taskHeading.trim() === "" ||
      taskDetails.trim() === "" ||
      taskType.trim() === ""
    ) {
      alert("Please Fill All The Field");
      return;
    }
    if (checkedStudentData.length === 0) {
      alert("Please Select Min 1 Student");
      return;
    }

    if (!window.confirm("Are You sure to ADD TASK?")) return;
    setLoaderClasses("loader-container");
    fetch(
      `${props.hostName}/redsky_api/task_api/add_task.php?token=${props.token}`,
      {
        method: "POST",
        body: JSON.stringify({
          task_type: taskType,
          task_heading: taskHeading,
          task_for_date: taskForDate,
          task_details: taskDetails,
          checked_student_data: checkedStudentData,
        }),
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    )
      .then((resp) => resp.json())
      .then((data) => {
        setLoaderClasses("loader-container d-none");
        alert(data.data);
        resetFormFields();
      })
      .catch((err) => console.log(err));
  };

  const filterHandler = () => {
    document.querySelector(".filter-box-task").classList.toggle("d-none");
  };

  const fetchAllCourses = () => {
    setLoaderClasses("loader-container");

    fetch(
      `${props.hostName}/redsky_api/task_api/task_get_all_courses.php?token=${props.token}`
    )
      .then((resp) => resp.json())
      .then((data) => {
        setLoaderClasses("loader-container d-none");
        if (data.code === 5) {
          setAllCourses(data.data);
        } else {
          document.querySelector(
            ".add-task-form"
          ).innerHTML = `<center>${data.data} <br> Please Try again!</center>`;
        }
      })
      .catch((err) => console.log(err));
  };

  const fetchAllStudents = () => {
    setLoaderClasses("loader-container");

    fetch(
      `${props.hostName}/redsky_api/task_api/task_get_all_stu_list.php?token=${props.token}`
    )
      .then((resp) => resp.json())
      .then((data) => {
        setLoaderClasses("loader-container d-none");
        if (data.code === 5) {
          setAllStudentData(data.data);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(fetchAllCourses, [props.token, props.hostName]);
  useEffect(fetchAllStudents, [props.token, props.hostName]);

  const getFilteredData = (filterObj) => {
    setLoaderClasses("loader-container");

    fetch(
      `${props.hostName}/redsky_api/task_api/task_get_filtered_active_student.php?token=${props.token}`,
      {
        method: "POST",
        body: JSON.stringify(filterObj),
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    )
      .then((resp) => resp.json())
      .then((data) => {
        setLoaderClasses("loader-container d-none");
        if (data.code === 8) {
          setAllStudentData(data.data);
        } else {
          setAllStudentData([]);
          alert(data.data);
        }
      })
      .catch((err) => console.log(err));
  };

  const getCheckedStudent = (e) => {
    if (e.target.checked) {
      checkedStudentData.push(e.target.value);
      setNoOfSelection(noOfSelection + 1);
    } else {
      let index = checkedStudentData.indexOf(e.target.value);
      checkedStudentData.splice(index, 1);
      setNoOfSelection(noOfSelection - 1);
    }
  };

  const expandTaskBox = (e) => {
    e.target.parentNode.parentNode.style.maxHeight = "initial";
    e.target.style.display = "none";
    e.target.nextSibling.style.display = "block";
  };
  const closeTaskBox = (e) => {
    e.target.parentNode.parentNode.style.maxHeight = "52px";
    e.target.style.display = "none";
    e.target.previousSibling.style.display = "block";
  };

  return (
    <>
      <div className="d-flex">
        <section className="add-task-form">
          <header>Add Task Form</header>
          <form action="#" className="form" onSubmit={validateAddTaskHandler}>
            <div className="input-box">
              <label>
                Task Type:<span className="color-red">*</span>
              </label>
              <div className="select-box">
                <select onChange={(e) => setTaskType(e.target.value)} required>
                  <option hidden className="placeHolder" value="">
                    Practical / Theory
                  </option>
                  <option value="1">Practical</option>
                  <option value="2">Theory</option>
                  <option value="3">Test</option>
                </select>
              </div>
            </div>
            <div className="input-box">
              <label>
                Task Heading:<span className="color-red">*</span>
              </label>
              <input
                type="text"
                placeholder="Enter Task Headline"
                value={taskHeading}
                onChange={(e) => setTaskHeading(e.target.value)}
                required
              />
            </div>

            <div className="input-box">
              <label>
                Task For Date:<span className="color-red">*</span>
              </label>
              <input
                type="date"
                placeholder="Enter Task Headline"
                value={taskForDate}
                onChange={(e) => setTaskForDate(e.target.value)}
                min={tomorrow.toJSON().slice(0, 10)}
                required
              />
            </div>

            <div className="input-box">
              <label>
                Task Details:<span className="color-red">*</span>
              </label>
              <textarea
                placeholder="Enter Task Details"
                value={taskDetails}
                onChange={(e) => setTaskDetails(e.target.value)}
                required
              ></textarea>
            </div>

            <div className="student-selection-container">
              <h1>Student List</h1>

              <div className="pos-rel" style={{ position: "relative" }}>
                <div className="d-oflex justify-end">
                  <span
                    className="material-icons material-symbols-outlined"
                    style={{
                      cursor: "pointer",
                      fontSize: "1.8rem",
                      marginBottom: "1rem",
                    }}
                    onClick={filterHandler}
                  >
                    tune
                  </span>
                </div>

                <FilterStudentTask
                  ref={resestFilterRef}
                  getFilteredData={getFilteredData}
                  filterHandler={filterHandler}
                  allCourses={allCourses}
                />
              </div>
              <div>
                <p style={{ fontSize: "1.05rem", fontWeight: 500 }}>
                  No of Selection : {noOfSelection}
                </p>
              </div>
              <div className="task__student_list">
                {allStudentData.map((elem) => {
                  let arr = elem.s_psychology.split(";");

                  return (
                    <div className="student" key={elem.s_id}>
                      <div
                        className="d-oflex align-center"
                        style={{ width: "100%" }}
                      >
                        <span className="material-icons material-symbols-outlined person-logo">
                          person_4
                        </span>
                        <h4>
                          {elem.s_reg_no} - {elem.s_fname} {elem.s_lname}
                        </h4>
                        <input
                          type="checkbox"
                          name="checked_students[]"
                          onClick={getCheckedStudent}
                          value={elem.s_id}
                        />
                        <span
                          className="material-icons material-symbols-outlined down-arrow"
                          onClick={expandTaskBox}
                        >
                          keyboard_arrow_down
                        </span>
                        <span
                          className="material-icons material-symbols-outlined up-arrow"
                          onClick={closeTaskBox}
                        >
                          keyboard_arrow_up
                        </span>
                      </div>

                      <div className="student-extra-details">
                        <p>Course : {elem.c_name}</p>
                        <p>Psychology :</p>
                        <div className="status-text-container">
                          {arr.map((statusText) => {
                            return (
                              <p className="status-text" key={statusText}>
                                {statusText}
                              </p>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            <input type="submit" value="Add Task" />
          </form>
        </section>
      </div>

      <Loader loaderClasses={loaderClasses} />
    </>
  );
};

export default AddTask;
