import React, { useEffect, useState } from 'react';
import './Table.css';
import './EnqueryAllListTable.css';
import AssignedClassListTableRow from './AssignedClassListTableRow';
// import LimitRowsCount from './LimitRowsCount';

const AssignedClassListTable = (props) => {
    // const resestFilterRef = useRef();
    const [deviceType, setDeviceType] = useState(null);

    useEffect(() => {
        // checkScreenSize
          /* Storing user's device details in a variable*/
             let details = navigator.userAgent;
       
             /* Creating a regular expression containing some mobile devices keywords to search it in details string*/
             let regexp = /android|iphone|kindle|ipad/i;
       
             /* Using test() method to search regexp in detail it returns boolean value*/
             let isMobileDevice = regexp.test(details);
           
             isMobileDevice ? setDeviceType("mobile") : setDeviceType("desktop");
      }, []);
    
    const printAll = () => {
        window.print();
    };
    // const filterHandler = () => {
    //     document.querySelector(".filter-box").classList.toggle('d-none');
    // };


    return (
        <div>
            <header className='table-header'>
                <span>Class List</span>
                <div className='pos-rel'>
                    <div className='d-flex align-center'>
                        {/* <span className="material-icons material-symbols-outlined" onClick={filterHandler}>tune</span> */}
                        <div className='d-flex align-items-center justify-content-center'>
                            <input type='Checkbox' onChange={(e)=>{e.target.checked ? props.setIsAllDataShow(true) : props.setIsAllDataShow(false)}} />
                            <label htmlFor='all-attendance-check' className='ms-1 me-3 fs-6 fw-normal'>All</label>
                        
                        </div>
                        <span className="material-icons material-symbols-rounded" onClick={printAll}>print</span>
                    </div>

                    {/* <FilterOtherPayments ref={resestFilterRef} getFilteredData={props.getFilteredData} filterHandler={filterHandler} allCourses={props.allCourses} /> */}
                </div>
            </header>

            <div className={props.tableClasses}>
                <div className='enquery-counting-container'>
                    <span>Total Class Done : <b>{props.totalClassDone}</b></span>
                </div>
                <table>
                    <thead>
                        <tr>
                            {
                                props.tableCols.map((element) => (<th scope="col" id={element} key={element}>{element}</th>) )
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {props.rowsData.map((elem, index) => 
                            <AssignedClassListTableRow elem={elem} key={index+1} slNo={index+1} deviceType={deviceType} updateIsDone={props.updateIsDone} />
                        )}
                        
                    </tbody>

                </table>

            </div>
            <center className={props.msgClasses}>{props.msgData}</center> 
        </div>
    )
}

export default AssignedClassListTable;