import React, {useState, useEffect} from 'react';
import './ViewAllAttendace.css';
import Loader from '../../components/admin_components/Loader';
import ViewAttendanceTable from '../../components/admin_components/ViewAttendanceTable';

const ViewAllAttendace = (props) => {
  let tableCols = ['ID', 'Name', 'Course', 'Date', 'Delete'];
  let [rowsData, setRowsData] = useState([]);
  const [loaderClasses, setLoaderClasses] = useState('loader-container d-none');
  const [tableClasses, setTableClasses] = useState('table-container');
  const [allCourses, setAllCourses] = useState([]);
  const [msgClasses, setMsgClasses] = useState('msg d-none');
  const [msgData, setMsgData] = useState('');
  const [isAllDataShow, setIsAllDataShow] = useState(false);

  useEffect(()=> {props.setTitle(`View Attendance | ${parseInt(props.isHeadAdmin) ? "Admin Panel" : "User Panel"} | RedSky Educations | Educations for future`)}, [props]);

  const fetchAllStudentAttendance = () => {
      setLoaderClasses('loader-container');
      setRowsData([]);
      fetch(`${props.hostName}/redsky_api/getAllStudentsAttendanceList.php?token=${props.token}&is_all_data_show=${isAllDataShow}`)
          .then(resp => resp.json()).then(data => {
              if(data.code === 5){
                  setRowsData(data.data);
                  setTableClasses('table-container');
                  setMsgClasses('msg d-none');
              }
              else{
                  setTableClasses('table-container d-none');
                  setMsgData(data.data);
                  setMsgClasses('msg');
              }
              setLoaderClasses('loader-container d-none');
              
          }).catch(err => console.log(err));
  };
  useEffect(fetchAllStudentAttendance,[props.token,props.hostName, isAllDataShow]);
  
  const getFilteredAttendanceData = (filterObj) => {
      setLoaderClasses('loader-container');
      setRowsData([]);

      fetch(`${props.hostName}/redsky_api/getFilteredAttendanceData.php?token=${props.token}`,
      {
          method: "POST",
          body: JSON.stringify(filterObj),
          headers: {
              'Content-Type': 'application/json;charset=UTF-8'
          }
      })
          .then(resp => resp.json()).then(data => {
              setLoaderClasses('loader-container d-none');
              if(data.code === 5){
                  setRowsData(data.data);
                  setTableClasses('table-container');
                  setMsgClasses('msg d-none');
              }
              else{
                  setTableClasses('table-container d-none');
                  setMsgData(data.data);
                  setMsgClasses('msg');
              }
              
          }).catch(err => console.log(err));
  };

  const fetchAllCourses = () => {
    setLoaderClasses('loader-container');

    fetch(`${props.hostName}/redsky_api/getAllCourses.php?token=${props.token}`)
      .then(resp => resp.json()).then(data => {
        setLoaderClasses('loader-container d-none');
        if (data.code === 5) {
          setAllCourses(data.data);
        }
        else {
            document.querySelector('.add-student-form-container .form').innerHTML = `<center>${data.data} <br> Please Try again!</center>`;
        }

      }).catch(err => console.log(err));
    };

  useEffect(fetchAllCourses, [props.token,props.hostName]);
  
    
  const setDeleteAttedance = (stu_attn_data) => {
    setLoaderClasses('loader-container');
    const confirmBox = window.confirm("Are you sure to Delete? ID = " + stu_attn_data.sub_id + " & Date = " + stu_attn_data.date);
    if (confirmBox === true) {
      //deletion code will go here
      fetch(`${props.hostName}/redsky_api/delete_attendance.php?token=${props.token}`,
      {
        method: "POST",
        body: JSON.stringify(stu_attn_data),
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        }
      })
      .then(resp => resp.json()).then(data => {
        if(data.code === 5){
          fetchAllStudentAttendance();
          alert(data.data);
        }else{
          alert(data.data);
        }
        setLoaderClasses('loader-container d-none'); 
      }).catch(err => console.log(err));
    }else{
      setLoaderClasses('loader-container d-none'); 
    }
  };
  
  return (
      <>
          <ViewAttendanceTable tableClasses={tableClasses} tableCols={tableCols} rowsData={rowsData} setEditStuId={props.setEditStuId} token={props.token} fetchAllStudentAttendance={fetchAllStudentAttendance} setDeleteAttedance={setDeleteAttedance} getFilteredAttendanceData={getFilteredAttendanceData} msgClasses={msgClasses} msgData={msgData} allCourses={allCourses} setIsAllDataShow={setIsAllDataShow} />
          <Loader loaderClasses={loaderClasses}/>
      </>
  );
}

export default ViewAllAttendace;
