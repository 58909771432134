import React, { useEffect, useState } from 'react';
import './AllStudents.css';
import './AllCourses.css';
import Loader from '../../components/admin_components/Loader';
import AllCoursesTable from '../../components/admin_components/AllCoursesTable';
import { useNavigate } from 'react-router-dom';

const AllCourses = (props) => {
  const navigate = useNavigate();
    useEffect(()=>{
      if(props.isHeadAdmin === '0'){
        navigate('../all-students');
      }
    });
    let tableCols = ['ID', 'Name', 'Duration', 'Installment', 'Fees', 'Enrolled', 'Edit', 'Delete'];
    const [loaderClasses, setLoaderClasses] = useState('loader-container d-none');
    const [allCourses, setAllCourses] = useState([]);
    const [tableClasses, setTableClasses] = useState('table-container');
    const [msgClasses, setMsgClasses] = useState('msg d-none');
    const [msgData, setMsgData] = useState('');
   
    useEffect(()=> {props.setTitle("All Courses | Admin Panel | RedSky Educations | Educations for future")}, [props]);
    
    const fetchAllCourses = () => {
        setLoaderClasses('loader-container');

        fetch(`${props.hostName}/redsky_api/getAllCoursesDetailedInfo.php?token=${props.token}`)
          .then(resp => resp.json()).then(data => {
            setLoaderClasses('loader-container d-none');
            if (data.code === 5) {
              setAllCourses(data.data);
              setTableClasses('table-container');
                setMsgClasses('msg d-none');
            }
            else {
                setTableClasses('table-container d-none');
                setMsgData(data.data);
                setMsgClasses('msg');
            }
    
          }).catch(err => console.log(err));
        };

      useEffect(fetchAllCourses, [props.token,props.hostName]);
    
      
      const deleteCourseHandeler = (course_id) => {
        setLoaderClasses('loader-container');
        const confirmBox = window.confirm("Are you sure to Delete the Course data? ID=" + course_id);
        if (confirmBox === true) {
          //deletion code will go here
          fetch(`${props.hostName}/redsky_api/deleteCourse.php?course_id=${course_id}&token=${props.token}`)
          .then(resp => resp.json()).then(data => {
            setLoaderClasses('loader-container d-none');
            if(data.code === 12){
                fetchAllCourses();
              alert(data.data);
            }else{
              alert(data.data);
            }
          }).catch(err => console.log(err));
        }else{
          setLoaderClasses('loader-container d-none');
        }
      };

    
    return (
        <>
            <AllCoursesTable tableName='All Courses List' tableClasses={tableClasses} tableCols={tableCols} allCourses={allCourses}  token={props.token}  deleteCourseHandeler={deleteCourseHandeler} msgClasses={msgClasses} msgData={msgData}/>
            <Loader loaderClasses={loaderClasses}/>
        </>
    );
};

export default AllCourses;
