// ScrollToTopAndPrint.js
import React from 'react';

const ScrollToTopAndPrint = (props) => {

  const scrollToTopAndPrint = () => {
    console.log("inside");
    function checkScrollAndPrint() {
        console.log("inside");
      if (window.scrollY === 0) {
        window.print();
      } else {
        setTimeout(checkScrollAndPrint, 50);
      }
    }

    window.scrollTo(0, 0);
    checkScrollAndPrint();
  };

  return (
    <button className='btn btn-primary print-btn' onClick={()=>scrollToTopAndPrint()} >
      {props.btnText ? props.btnText : "Print"} 
      <span className='material-icons'>{props.icon ? props.icon : "print"}</span>
    </button>
  );
};

export default ScrollToTopAndPrint;
