import React, { useState } from 'react';
import './StudentForm.css';

const UpgradeCourseBox = (props) => {  
    
  let studentData = props.studentData;
  let optionHide = '';
  const [newCourse, setNewCourse] = useState('');
  // const [courseStartingMonth, setCourseStartingMonth] = useState('');
  // const [courseEndingMonth, setCourseEndingMonth] = useState('');

  // const minStartingDate = new Date().toJSON().slice(0, 7);
  // const [minEndingDate, setMinEndingDate] = useState(minStartingDate);
  // const [maxEndingDate, setMaxEndingDate] = useState(minStartingDate);
  
  const formValidate = (e) =>{
    e.preventDefault();
    if(newCourse === ''){
        alert("Please Fill Out All the fields");
    }else{
        let formData = {
          stu_id: studentData.id,
          old_course_id: studentData.course_id,
          new_course_id: newCourse,
          course_starting_date : studentData.course_start_date,
          // course_ending_month: courseEndingMonth
        };

          //reseting form fields
          setNewCourse('');
          // setCourseStartingMonth('');
          document.querySelector(".new-course-options-placeHolder").selected = true;
          props.setFormData(formData);
    }
    
  }
  const courseHandler = (e) => {
    setNewCourse(e.target.value);
    // setCourseStartingMonth('');
    // setCourseEndingMonth('');
    // setMaxEndingDate('');
    props.getSingleCourseData(e.target.value);
  }

  // const courseStartingMonthChangeHandler = (e) => { 
  //   setCourseStartingMonth(e.target.value);
  //   setMinEndingDate(e.target.value);
  //   let year = parseInt(e.target.value.slice(0,4));
  //   let month = parseInt(e.target.value.slice(5,7)) + parseInt(props.courseTotalInstallment) - 1;
    
  //   year = (month > 12) ? year + 1 : year;
  //   month = (month > 12) ? month - 12 : month;
  //   month = (month < 10) ? '0'+month : month;
  //   setMaxEndingDate(`${year}-${month}`);
  //   setCourseEndingMonth(`${year}-${month}`);
  // }

  return (
    <>
      <section className={`form-container ${props.isShowForm}`}>
        <header>Upgrade Course Form</header>
        <form action="#" className="form" onSubmit={formValidate} >
        
          <div className="column ">
            <div className="input-box field-disabled">
              <label>First Name</label>
              <input type="text" placeholder="Enter first name" value={studentData.first_name} required disabled  />
            </div>
            <div className="input-box field-disabled">
              <label>Last Name</label>
              <input type="text" placeholder="Enter last name" value={studentData.last_name} required disabled/>
            </div>
          </div>

          <div className="input-box field-disabled">
            <label>Guardian Name</label>
            <input type="text" placeholder="Enter Guardian Name" value={studentData.guardian_name}  required disabled/>
          </div>


          <div className="column">
            <div className="input-box field-disabled">
              <label>Phone Number</label>
              <input type="number" placeholder="Enter phone number" value={studentData.contact}  required disabled/>
            </div>
            <div className="input-box field-disabled">
              <label>Birth Date</label>
              <input type="date" placeholder="Enter birth date" value={studentData.dob}  required disabled/>
            </div>
          </div>
          
          <div className="column">

            <div className="input-box">
                <label>Old Course</label>
                <div className="select-box field-disabled">
                    <select className="old-course-options" disabled>
                        <option  value={studentData.course_id} >{studentData.course_name}</option>
                    </select>
                    </div>
                </div>

                <div className="input-box">
                <label>Upgrade Course</label>
                <div className="select-box">
                    <select onChange={courseHandler}  className="new-course-options">
                        <option hidden className='new-course-options-placeHolder'>Course</option>
                        {
                            props.allCourses.map((element) => {
                                optionHide = (element.c_id === studentData.course_id) ? "d-none" : '';
                                    
                                return(<option key={element.c_id} value={element.c_id} className={optionHide}>{element.c_name}</option>);
                            })
                        }
                    </select>
                    </div>
                </div>

            </div>
            {/* <div className="column">
              <div className="input-box">
                  <label>Starting Month<span className='color-red'>*</span></label>
                  <input type="month" placeholder="Enter Starting Month" value={courseStartingMonth} onChange={courseStartingMonthChangeHandler} min={minStartingDate} required />
                </div>
                <div className="input-box">
                  <label>Ending Month<span className='color-red'>*</span></label>
                  <input type="month" placeholder="Enter Ending Month" value={courseEndingMonth} onChange={(e) => { setCourseEndingMonth(e.target.value) }} min={minEndingDate} max={maxEndingDate} required />
                </div>
            </div> */}

          <button type='submit'>Upgrade</button>
        </form>
      </section>

    </>
  )
}

export default UpgradeCourseBox;
