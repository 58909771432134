import React, { useState, useEffect } from "react";
import AddNewStudentForm from "../../components/admin_components/AddNewStudentForm";
import Loader from "../../components/admin_components/Loader";

const AddNewStudent = (props) => {
  const [allCourses, setAllCourses] = useState([]);
  const [nextStuId, setNextStuId] = useState("");
  const [nextStuRegNo, setNextStuRegNo] = useState("");
  const [loaderClasses, setLoaderClasses] = useState("loader-container d-none");
  const [courseTotalInstallment, setCourseTotalInstallment] = useState(1);

  useEffect(() => {
    props.setTitle(
      `Add Student | ${
        parseInt(props.isHeadAdmin) ? "Admin Panel" : "User Panel"
      } | RedSky Educations | Educations for future`
    );
  }, [props]);

  const getFormData = (formData, resetFormFields) => {
    setLoaderClasses("loader-container");
    fetch(
      `${props.hostName}/redsky_api/insertNewStudent.php?token=${props.token}`,
      {
        method: "POST",
        body: JSON.stringify(formData),
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    )
      .then((resp) => resp.json())
      .then((data) => {
        if (data.code === 8) {
          console.log(data);

          alert(data.data.msg);
          resetFormFields();
          getNextStudentId();
        } else if (data.code === 11) {
          alert(data.data.msg);
        } else {
          alert(data.data);
        }
        setLoaderClasses("loader-container d-none");
      })
      .catch((err) => console.log(err));
  };

  const fetchAllCourses = () => {
    setLoaderClasses("loader-container");
    fetch(`${props.hostName}/redsky_api/getAllCourses.php?token=${props.token}`)
      .then((resp) => resp.json())
      .then((data) => {
        if (data.code === 5) {
          setAllCourses(data.data);
        } else {
          document.querySelector(
            ".add-student-form-container .form"
          ).innerHTML = `<center>${data.data} <br> Please Try again!</center>`;
        }
        setLoaderClasses("loader-container d-none");
      })
      .catch((err) => console.log(err));
  };
  useEffect(fetchAllCourses, [props.token, props.hostName]);

  const getNextStudentId = () => {
    setLoaderClasses("loader-container");
    fetch(
      `${props.hostName}/redsky_api/getNextStudentId.php?token=${props.token}`
    )
      .then((resp) => resp.json())
      .then((data) => {
        if (data.code === 5) {
          setNextStuId(data.data.next_s_id);
          setNextStuRegNo(data.data.next_reg_no);
        } else {
          alert(data.data);
        }
        setLoaderClasses("loader-container d-none");
      })
      .catch((err) => console.log(err));
  };
  useEffect(getNextStudentId, [props.token, props.hostName]);

  const getSingleCourseData = (cId) => {
    setLoaderClasses("loader-container");
    fetch(
      `${props.hostName}/redsky_api/getSingleCourseData.php?token=${props.token}&course_id=${cId}`
    )
      .then((resp) => resp.json())
      .then((data) => {
        if (data.code === 5) {
          setCourseTotalInstallment(data.data.total_installment);
        } else {
          alert(data.data);
        }
        setLoaderClasses("loader-container d-none");
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <AddNewStudentForm
        setFormData={getFormData}
        allCourses={allCourses}
        nextStuId={nextStuId}
        nextStuRegNo={nextStuRegNo}
        courseTotalInstallment={courseTotalInstallment}
        getSingleCourseData={getSingleCourseData}
        setLoaderClasses={setLoaderClasses}
        hostName={props.hostName}
        token={props.token}
      />
      <Loader loaderClasses={loaderClasses} />
    </>
  );
};

export default AddNewStudent;
