import React, { useState } from 'react';
import UnpaidFeesTableRow from './UnpaidFeesTableRow';
import './UnpaidPaymentBox.css';
import VerifyPinContainer from './VerifyPinContainer';

const DefferalBox = (props) => {
	// const [currentTime, setCurrentTime] = useState(new Date().toJSON().slice(11, 16));
	// const [updateBtnClasses, setUpdateBtnClasses] = useState('payment-btn hidden');

	const [enteredPin, setEnteredPin] = useState('');
	const [verifyPinClasses, setVerifyPinClasses] = useState('verify-pin-container d-none');
	const [defPaymentData, setDefPaymentData] = useState({});

	// const [captchaText, setCaptchaText] = useState('');
	// const [enteredCatpcha, setEnteredCatpcha] = useState('');

	// const getCheckBoxData = (e, date, forMonth, forYear, courseFees) => {
	// 	const respectiveAmountElem = e.target.parentNode.parentNode.querySelector('input.amount_input');
	// 	const respectiveDateElem = e.target.parentNode.parentNode.querySelector('input[type=date]');
	// 	if (e.target.checked) {
	// 		respectiveAmountElem.setAttribute("disabled", "true");
	// 		respectiveDateElem.setAttribute("disabled", "true");
	// 		checkedBoxArr.push(parseInt(e.target.value));
	// 		selectedDateArr.push(date);
	// 		forMonthArr.push(forMonth);
	// 		forYearArr.push(forYear);
	// 	} else {
	// 		respectiveAmountElem.removeAttribute("disabled");
	// 		respectiveDateElem.removeAttribute("disabled");
	// 		let index = checkedBoxArr.indexOf(parseInt(e.target.value));
	// 		checkedBoxArr.splice(index, 1);
	// 		selectedDateArr.splice(index, 1);
	// 		forMonthArr.splice(index, 1);
	// 		forYearArr.splice(index, 1);
	// 	}

	// 	(checkedBoxArr.length > 0) ? setUpdateBtnClasses('payment-btn') : setUpdateBtnClasses('payment-btn hidden');
	// };


	const proceedPinHandler = () => {


		if (enteredPin.length !== 0) {

			setEnteredPin('');
			setVerifyPinClasses('verify-pin-container d-none');

			props.setNewDefferalPaymentDetails({payment_pin: enteredPin,  ...defPaymentData});

		} else {
			alert("Please Enter Pin");
		}


	}
	const cancelPinHandler = () => {
		setVerifyPinClasses('verify-pin-container d-none');
		setDefPaymentData({});
		setEnteredPin('');
	}

	const saveDefPayment = (defPaymentData) => {
		
		// if (checkedBoxArr.length > 0) {
			setDefPaymentData(defPaymentData);
			setVerifyPinClasses('verify-pin-container');
			// setEnteredCatpcha('');
			// getCaptcha();
		// }
	};


	return (
		<>
			<section className="form-container" >
				<header>To Be Deferral Fees Details</header>
				<table className='unpaid-table'>
					<thead>
						<tr>
							<th scope="col">No</th>
							<th scope="col">Due Month</th>
							<th scope="col">Amount</th>
							<th scope="col">Date</th>
							<th scope="col">Action</th>
						</tr>
					</thead>
					<tbody>
						{

							(props.unpaidFeesLists.length > 0 ) ? (
								props.unpaidFeesLists.map((element) => {
									return (
										<UnpaidFeesTableRow key={element.installment_no} element={element} type="def" saveDefPayment={saveDefPayment} />
									);
								})

							)
							:
							<tr>
								<td colSpan='5'>
									<center>No Unpaid Payment Details Found</center>
								</td>
							</tr>
						}

					</tbody>
				</table>

			</section>

			<VerifyPinContainer verifyPinClasses={verifyPinClasses} enteredPin={[enteredPin, setEnteredPin]}  proceedPinHandler={proceedPinHandler} cancelPinHandler={cancelPinHandler} />
		</>
	)
}

export default DefferalBox;
