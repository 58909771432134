import React, { useEffect } from 'react';
import './PrintOtherPaymentForm.css';

const PrintOtherPaymentForm = (props) => {

	useEffect(()=> {props.setTitle(`Print Other Payment | ${parseInt(props.isHeadAdmin) ? "Admin Panel" : "User Panel"} | RedSky Educations | Educations for future`)}, [props]);

	return (
		<div className={props.isShowBill}>
			<div id="Other-bill-container">
				<div id="top-border">
					<h3>MONEY RECEIPT</h3>
				</div>
				
				<div className='company-details-container'>
						<h2 className="company-name">Redsky educations</h2>
						<p>CIN: U80903WB2021PTC248059</p>
						<div className='company-meta-details-container'>
							<div>
								<p>Krishnanagar, WB, IN</p>
								<p>Helpdesk: 94 74 89 71 92</p>
							</div>
							<div>
								<p>Receipt No. {props.otherPaymentBillData.receipt_no}</p>
								<p>Date. {props.otherPaymentBillData.payment_day}</p>
							</div>
						</div>
					</div>

				<div className="student-data">
					<div className='student-short-data'>
						<div className='divide-horion d-flex-us'>
							<div className='divide-vertical'>
								<p>ID</p>
								<p>Name</p>
								<p>Amount</p>
								<p>Purpose</p>
							</div>
							<div>
								<p>&nbsp;{props.otherPaymentBillData.id}</p>
								<p>&nbsp;{props.otherPaymentBillData.full_name}</p>
								<p>&nbsp;{props.otherPaymentBillData.amount}/-</p>
								<p>&nbsp;<b>{props.otherPaymentBillData.purpose}</b></p>
						
							</div>
						</div>
					</div>
				</div>
				<div className="seal-container">
					<div>
						<p>For,</p>
						<p>RedSky Educations</p>
					</div>
				</div>
				<div className='website-helpdesk-container'>
					<p>Website: https://www.redskyeducations.in</p>
					<p>Help Desk: +91 9474897192 (Available on WhatsApp)</p>
				</div>
				
				<div className='note-container'>
					<p className='text-note'>Note:</p>
					<p>1. Please bring monthly card during payment</p>
					<p>2. If attendace card lost fine Rs. 30/-</p>
				</div>
				<div className='printing-info'>
					<p className='heading-text'>Printing Info :</p>
					<div>
						<p>[{props.otherPaymentBillData.printing_info_date}]-[{props.otherPaymentBillData.printing_info_time}]</p>
					</div>
				</div>
			</div>
			<button className='btn btn-primary print-btn' onClick={() => window.print()} autoFocus>Print
				<span className="material-icons" >print</span>
			</button>
		</div>
	)
}


export default PrintOtherPaymentForm;
