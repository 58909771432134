import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './PrintPaidFees.css';
import Loader from '../../components/admin_components/Loader';
import ScrollToTopAndPrint from '../../components/admin_components/ScrollToTopAndPrint ';

const PrintPaidFees = (props) => {
    const param = useParams();
	const receiptNo = param.receipt_no;
	const navigate = useNavigate();
	const [printData, setPrintData] = useState([]);
	const [lastPaymentData, setLastPaymentData] = useState([]);
	// const [nextDueDate, setNextDueDate] = useState([]);
	const [amountInWrods, setAmountInWords] = useState('');
	// const [printingInfo, setPrintingInfo] = useState('');
	// const [isNextDueBoxShow, setIsNextDueBoxShow] = useState('');
	const [loaderClasses, setLoaderClasses] = useState('loader-container d-none');
	const monthsArr = ['', 'January', 'February', 'March','April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

	useEffect(()=> {props.setTitle(`Print Paid Fees | ${parseInt(props.isHeadAdmin) ? "Admin Panel" : "User Panel"} | RedSky Educations | Educations for future`)}, [props]);

	useEffect(()=>{
		window.onpopstate = ()=>{
			
			if(printData.reg_no){
				navigate(`../paid-fees-list-no-search/${printData.reg_no}`);
			}	
		}
	},[navigate, printData.reg_no]);
	
	useEffect(() => {
		function numberToWords(n){
			let limit = 1000000000000, t = 0;
			n = parseInt(n);
	
			// If zero console.log zero
			if (n === 0)
			{
				console.log("zero")
				return
			}
			// Array to store the powers of 10
			let multiplier = ["", "Trillion", "Billion", "Million", "Thousand"]
		
			// Array to store numbers till 20
			let first_twenty = ["", "One", "Two",
							"Three", "Four", "Five",
							"Six", "Seven", "Eight",
							"Nine", "Ten", "Eleven",
							"Twelve", "Thirteen", "Fourteen",
							"Fifteen", "Sixteen", "Seventeen",
							"Eighteen", "Nineteen"]
		
			// Array to store multiples of ten
			let tens = ["", "Twenty", "Thirty", "Forty", "Fifty",
					"Sixty", "Seventy", "Eighty", "Ninety"]
		
			// If number is less than 20, console.log without any
			if (n < 20)
			{
				// console.log(first_twenty[n])
				return
			}
			let answer = ""
			let i = n
			while(i > 0)
			{
				/*
				Store the value in multiplier[t], i.e n = 1000000,
				then r = 1, for multiplier(million), 0 for multipliers(trillion and billion)
				multiplier here refers to the current accessible limit
				*/
				let curr_hun = Math.floor(i / limit)
		
				// It might be possible that the current multiplier is bigger than your number
				while (curr_hun === 0)
				{
					// Set i as the remainder obtained when n was divided by the limit
					i %= limit
		
					// Divide the limit by 1000, shifts the multiplier
					limit /= 1000
		
					// Get the current value in hundreds, as English system works in hundreds
					curr_hun = Math.floor(i / limit)
		
					// Shift the multiplier
					t += 1
				}
		
				let flr = Math.floor(curr_hun / 100);
		
				// If current hundred is greater than 99, Add the hundreds' place
				if (curr_hun > 99)
					answer += (first_twenty[flr] + " Hundred ")
		
				// Bring the current hundred to tens
				curr_hun = curr_hun % 100
		
				// If the value in tens belongs to [1,19], add using the first_twenty
				if (curr_hun > 0 && curr_hun < 20)
					answer += (first_twenty[curr_hun] + " ")
		
				// If curr_hun is now a multiple of 10, but not 0
				// Add the tens' value using the tens array
				else if (curr_hun % 10 === 0  &&  curr_hun !== 0){
					flr = Math.floor(curr_hun / 10);
					answer += (tens[flr - 1] + " ")
				}
		
				// If the value belongs to [21,99], excluding the multiples of 10
				// Get the ten's place and one's place, and console.log using the first_twenty array
				else if (curr_hun > 19  &&  curr_hun < 100){
					flr = Math.floor(curr_hun / 10);
					answer += (tens[flr - 1] + " " +
							first_twenty[curr_hun % 10] + " ")
				}
		
				// If Multiplier has not become less than 1000, shift it
				if (t < 4)
					answer += (multiplier[t] + " ")
					
				i = i % limit
				limit = Math.floor(limit / 1000)
			}
		
			// console.log(answer);
			return answer;
		}


		setLoaderClasses('loader-container');
		fetch(`${props.hostName}/redsky_api/getDetailsForPrintingPaidFee.php?token=${props.token}&receipt_no=${receiptNo}`)
			.then(resp => resp.json()).then(data => {
				console.log(data);
				if (data.code === 5) {
					setPrintData(data.data['print_data']);
					setLastPaymentData(data.data['last_3_payment_data']);
					
					// setPrintingInfo(data.data['print_info']);
					// setNextDueDate(data.data['next_due_date']);
					// setIsNextDueBoxShow((data.data['next_due_date']['is_visible']) ? '' : 'd-none');
					setAmountInWords(numberToWords(data.data['print_data'].paid_amount));
				} else {
					alert(data.data.msg);
				}
				setLoaderClasses('loader-container d-none');
			}).catch(err => console.log(err));
	}, [props.token, props.hostName, receiptNo, printData.has_next_due]);


	return (
		<>
			<div>
				<div id="paid-bill-container" >
					<div id="top-border">
						<h3>MONEY RECEIPT</h3>
					</div>
					<div className='company-details-container'>
						<h2 className="company-name">Redsky educations</h2>
						<p>CIN: U80903WB2021PTC248059</p>
						<div className='company-meta-details-container'>
							<div>
								<p>Krishnanagar, WB, IN</p>
								<p>Helpdesk: 94 74 89 71 92</p>
							</div>
							<div>
								<p>Receipt No. {printData.receipt_no}-{printData.installment_no}</p>
								<p>Date. {printData.date}</p>
							</div>
						</div>
					</div>

					<div className="student-data">
						<div className='student-short-data'>
							<div className='divide-horion d-flex-us'>
								<div className='divide-vertical'>
									<p>ID</p>
									<p>Name</p>
									<p>Course</p>
								</div>
								<div>
									<p>{printData.reg_no}</p>
									<p>{printData.f_name} {printData.l_name}</p>
									<p>{printData.course_name}</p>
								</div>
							</div>
							
							<div className='divide-horion d-flex-us'>
								<div className='divide-vertical'>
									<p>Particular</p>
									<p>Fees</p>
									<p>For Month</p>
								</div>
								<div>
									<p>Tution fee</p>
									<p>Rs. {printData.paid_amount}.00 [{printData.payment_mode}]</p>
									<p><b>{monthsArr[printData.for_month]}, {[printData.for_year]}</b></p>
								</div>
							</div>
						</div>
						<p>Amount in Words:</p>
						<p>{amountInWrods} Rupees Only.</p>
					</div>
					<div id='payment-prev-data-container'>
						<span>Last {lastPaymentData.length ? lastPaymentData.length : ''} Payment details:</span>
						{
							lastPaymentData.length > 0 
							?
								<table id='payment-prev-table'>
									<thead>
										<tr>
											<th style={{whiteSpace:"nowrap"}}>Sl. No.</th>
											<th>Date</th>
											<th>Month</th>
											<th>Amount</th>
										</tr>
									</thead>
									<tbody>
									{
										lastPaymentData.map((elem, index) => {
											return(
												<tr key={index}>
													<td>{index+1}</td>
													<td>{elem.date}</td>
													<td style={{whiteSpace:"nowrap"}}>{monthsArr[elem.for_month]}, {elem.for_year.slice(2,4)}</td>
													<td>{elem.paid_amount}/-</td>
												</tr>
											)
										})
									}
									</tbody>
								</table>
							:
							' N/A'
						}
					</div>
					<div style={{marginTop:"22px"}}>
						<p style={{fontSize:"12px"}}>This is system generated bill and no signature is required.</p>
					</div>
				</div>
				<ScrollToTopAndPrint btnText="Print" icon="print" />
			</div>
			
			<Loader loaderClasses={loaderClasses}/>
		</>
	)
}

export default PrintPaidFees;
