import React from 'react';
import { useNavigate } from 'react-router-dom';

const ViewTaskTableRow = (props) => {
  const navigate = useNavigate('');

  return (
    <>
      {
        props.rowsData.map((element) => {

          return (

            <tr key={element.s_id}>
              <td data-label="ID">&nbsp;{element.s_reg_no}</td>
              <td data-label="Name">&nbsp;{element.s_fname + ' ' + element.s_lname}</td>
              <td data-label="Guardian">&nbsp;{element.guardian_name}</td>
              <td data-label="Course">&nbsp;{element.c_name}</td>
              <td data-label="DOA">&nbsp;{element.date_admission}</td>

              <td data-label="View">
                <span className="material-icons material-symbols-rounded task-proceed-btn" onClick={() => navigate(`../student-task-data/${element.s_id}`) } >navigate_next</span>
              </td>
            </tr>
          )
        })
      }
    </>

  )
}

export default ViewTaskTableRow;
