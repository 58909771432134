import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './LoginAdmin.css';
import Loader from '../../components/admin_components/Loader';
// import CaptchaBox from '../../components/admin_components/CaptchaBox';

const LoginAdmin = (props) => {
  const navigate = useNavigate();
  localStorage.removeItem("admin_data_redsky");

  const [adminId, setAdminId] = useState('');
  const [adminPassword, setAdminPassword] = useState('');
  const [isShowPassword, setIsShowPassword] = useState('password');
  const [isShowStatus, setIsShowStatus] = useState('hidden');
  const [statusMsg, setStatusMsg] = useState('');
  const [loaderClasses, setLoaderClasses] = useState('loader-container d-none');

  // const [captchaText, setCaptchaText] = useState('');
  // const [enteredCatpcha, setEnteredCatpcha] = useState('');

  useEffect(()=> {props.setTitle("Login | Admin Panel | RedSky Educations | Educations for future")}, [props]);

  const resetFormField = () => {
    setAdminId('');
    setAdminPassword('');
    setIsShowPassword('password');
  };

  const validateAdmin = (adminLoginDetails) => {
    fetch(`${props.hostName}/redsky_api/validateAdmin.php`,
      {
        method: "POST",
        body: JSON.stringify(adminLoginDetails),
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        }
      })
      .then(resp => resp.json()).then(data => {
        setLoaderClasses('loader-container d-none');
        if (data.code === '8') {
          let localStorageData = { admin_token: data.data.admin_token, first_name: data.data.first_name, last_name: data.data.last_name, is_head: data.data.head_admin };
          localStorage.setItem('admin_data_redsky', JSON.stringify(localStorageData));
          if(data.data.head_admin === '1'){
            navigate('/admin_panel/verify/Dashboard');
          }else{
            navigate('/admin_panel/verify/user-dashboard');
          }
        } else if (data.code === '11') {
          setStatusMsg('Invalid Login Details!');
          setIsShowStatus('');
          setAdminId('');
          setAdminPassword('');
          // reloadCaptcha();
          localStorage.clear();
        } else {
          alert(data.data);
        }
        resetFormField();
      }).catch(err => console.log(err));
  };

  const adminLoginFormSubmit = (e) => {

    e.preventDefault();
    //when not using CAPTCHA
      setIsShowStatus('hidden');
      setLoaderClasses('loader-container');
      if (adminId === '' && adminPassword === '') {
        alert('Please Fill All Fields!');
      } else {
        let adminLoginDetails = {
          adminId: adminId,
          adminPassword: adminPassword
        };

        validateAdmin(adminLoginDetails);
      }


    //when using CAPTCHA
    // if(checkCaptcha()) {
    //   setIsShowStatus('hidden');
    //   setLoaderClasses('loader-container');
    //   if (adminId === '' && adminPassword === '') {
    //     alert('Please Fill All Fields!');
    //   } else {
    //     let adminLoginDetails = {
    //       adminId: adminId,
    //       adminPassword: adminPassword
    //     };

    //     validateAdmin(adminLoginDetails);
    //   }
    // } else {
    //   setStatusMsg('Invalid Captcha!');
    //   setIsShowStatus('');
    // }

  };

  //------------captcha code start
  


  // const getCaptcha = useCallback(() => {
  //   // const allCharacters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O',
  //   // 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'a', 'b', 'c', 'd',
  //   // 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's',
  //   // 't', 'u', 'v', 'w', 'x', 'y', 'z', 0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
  //   const allCharacters = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

  //   let len = allCharacters.length;
  //   let generatedCaptchaText = '';
    
  //   for (let i = 0; i < 6; i++) { //getting 6 random characters from the array
  //     let randomCharacter = allCharacters[Math.floor(Math.random() * len)];
  //     generatedCaptchaText = generatedCaptchaText + ' ' + randomCharacter;
  //   }
  //   setCaptchaText(generatedCaptchaText);
  // },[]);
  


  // const reloadCaptcha = () => {
  //   resetEnteredCaptcha();
  //   getCaptcha();
  // };

  // const resetEnteredCaptcha = () => {
  //   setCaptchaText('');
  //   setEnteredCatpcha('');
  // };


  // const checkCaptcha = () => {
  //   let inputVal = ' ' + enteredCatpcha.split('').join(' ');
  //   return (captchaText === inputVal) ? 1 : 0;
  // };
  //------------captcha code end

  // useEffect(()=>{
  //   getCaptcha();
  // },[getCaptcha]);

  return (
    <>
      <div className="admin-login-form-container">
        <div className="admin-login-form">
          <span className="title">Login</span>

          <form onSubmit={adminLoginFormSubmit} >
            <div className="input-field">
              <input type="text" placeholder="Enter your Admin Id" value={adminId} onChange={(e) => setAdminId(e.target.value)} autoFocus={true} required />
              <span className="material-icons material-symbols-outlined">person</span>
            </div>
            <div className="input-field">
              <input type={isShowPassword} className="password" placeholder="Enter your password" value={adminPassword} onChange={(e) => setAdminPassword(e.target.value)} required />
              <span className="material-icons material-symbols-outlined">lock</span>
              {
                (isShowPassword === 'password') ?
                  <span className="material-icons material-symbols-outlined showHidePw" onClick={() => setIsShowPassword('text')}>visibility</span>
                  :
                  <span className="material-icons material-symbols-outlined showHidePw" onClick={() => setIsShowPassword('password')}>visibility_off</span>
              }
            </div>
            {/* <CaptchaBox captchaText={captchaText} enteredCatpcha={[enteredCatpcha, setEnteredCatpcha]} reloadCaptcha={reloadCaptcha} /> */}
            <div className="input-field button">
              <input type="submit" value="Login" id="admin-login-btn"/>
            </div>
            <div className='status-box'>
              <p className={isShowStatus}>{statusMsg}</p>
            </div>
          </form>
        </div>
      </div>

      <Loader loaderClasses={loaderClasses} />
    </>

  )
}

export default LoginAdmin;
