import React, { useState, useEffect } from 'react';
import EnqueryForm from '../../components/admin_components/EnqueryForm';
import Loader from '../../components/admin_components/Loader';
import { Route, Routes, useNavigate } from 'react-router-dom';
import PageNotFound from './PageNotFound';
import QuotationPrint from './QuotationPrint';
import EnqueryAllList from '../../components/admin_components/EnqueryAllList';
import EnqueryModify from './EnqueryModify';

const Enquery = (props) => {
  const navigate = useNavigate();
  const [loaderClasses, setLoaderClasses] = useState('loader-container d-none');
  const [allCourses, setAllCourses] = useState([]);
  const [courseTotalInstallment, setCourseTotalInstallment] = useState(1);

  const fetchAllCourses = () => {
    setLoaderClasses('loader-container');
    fetch(`${props.hostName}/redsky_api/getAllCourses.php?token=${props.token}`)
      .then(resp => resp.json()).then(data => {
        setLoaderClasses('d-none');
        if (data.code === 5) {
          setAllCourses(data.data);
        }
        else {
          document.querySelector('.add-student-form-container .form').innerHTML = `<center>${data.data} <br> Please Try again!</center>`;
        }

      }).catch(err => console.log(err));
  };
  useEffect(fetchAllCourses, [props.token, props.hostName]);

  const getSingleCourseData = (cId) => {
    setLoaderClasses('loader-container');
    fetch(`${props.hostName}/redsky_api/getSingleCourseData.php?token=${props.token}&course_id=${cId}`)
      .then(resp => resp.json()).then(data => {
        
        if (data.code === 5) {
          setCourseTotalInstallment(data.data.total_installment);
        }else{
          alert(data.data);
        }
        setLoaderClasses('loader-container d-none');
      }).catch(err => console.log(err));
  };

  const storeEnquery = (enqueryFormData) => {
		setLoaderClasses('loader-container');
		fetch(`${props.hostName}/redsky_api/addEnquery.php?token=${props.token}`,
		{
			method: "POST",
			body: JSON.stringify(enqueryFormData),
			headers: {
			'Content-Type': 'application/json;charset=UTF-8'
			}
		})
		.then(resp => resp.json()).then(data => {
			if(data.code === 8){
				navigate('./print-enquery/_MAX_');
			}else{
				alert(data.data);
			}
			setLoaderClasses('loader-container d-none');
		}).catch(err => console.log(err));

  };


  return (
    <div>
      <Routes>
          <Route path='/' element={<EnqueryForm allCourses={allCourses} getSingleCourseData={getSingleCourseData} courseTotalInstallment={courseTotalInstallment} storeEnquery={storeEnquery} isHeadAdmin={props.isHeadAdmin} setTitle={props.setTitle} />} />

          <Route path='/enquiry-modify/:enquery_id' element={<EnqueryModify token={props.token} hostName={props.hostName} allCourses={allCourses} getSingleCourseData={getSingleCourseData} courseTotalInstallment={courseTotalInstallment} storeEnquery={storeEnquery} isHeadAdmin={props.isHeadAdmin} setTitle={props.setTitle} />} />

          <Route path='/all-enquery-list' element={<EnqueryAllList hostName={props.hostName} token={props.token} isHeadAdmin={props.isHeadAdmin} setTitle={props.setTitle} />} />

          <Route path='/print-enquery/:enquery_id' element={<QuotationPrint setTitle={props.setTitle} hostName={props.hostName} token={props.token} isHeadAdmin={props.isHeadAdmin} />} />
          <Route path='/*'  element={<PageNotFound setTitle={props.setTitle} />} />
      </Routes>
      
      <Loader loaderClasses={loaderClasses}/>
      
    </div>
  )
}

export default Enquery;
