import React, { useEffect, useState } from 'react';
import Chart from 'react-google-charts';
import Loader from './Loader';

const ActiveAllStudentGraph = (props) => {
  const [graphData, setGraphData] = useState('');
  const [activeStudentCountData, setActiveStudentCountData] = useState([]);
  const [discontinueStudentCountData, setDiscontinueStudentCountData] = useState([]);
  const [passOutStudentCountData, setPassOutStudentCountData] = useState([]);
  // const [allStudentCountData, setAllStudentCountData] = useState([]);
  const [loaderClasses, setLoaderClasses] = useState('loader-container');

  useEffect(() => {

    setLoaderClasses("loader-container");
    fetch(`${props.hostName}/redsky_api/getActiveStudentCountPerMonth.php?token=${props.token}`)
      .then(resp => resp.json()).then(data => {
    
        if (data.code === 5) {
          // setActiveStudentCountData(data.data.reverse());
          setActiveStudentCountData(data.data);
          setLoaderClasses("loader-container d-none");
        }

      }).catch(err => console.log(err));

    setLoaderClasses("loader-container");
    fetch(`${props.hostName}/redsky_api/getDiscontinueStudentCountPerMonth.php?token=${props.token}`)
      .then(resp => resp.json()).then(data => {
    
        if (data.code === 5) {
          // setActiveStudentCountData(data.data.reverse());
          setDiscontinueStudentCountData(data.data);
          setLoaderClasses("loader-container d-none");
        }

      }).catch(err => console.log(err));

    setLoaderClasses("loader-container");
    fetch(`${props.hostName}/redsky_api/getPassoutStudentCountPerMonth.php?token=${props.token}`)
      .then(resp => resp.json()).then(data => {
    
        if (data.code === 5) {
          // setActiveStudentCountData(data.data.reverse());
          setPassOutStudentCountData(data.data);
          setLoaderClasses("loader-container d-none");
        }

      }).catch(err => console.log(err));

      // setLoaderClasses("loader-container");
      // fetch(`${props.hostName}/redsky_api/getALLStudentCountPerMonth.php?token=${props.token}`)
      // .then(resp => resp.json()).then(data => {

      //   if (data.code === 5) {
      //     // setAllStudentCountData(data.data.reverse());
      //     setAllStudentCountData(data.data);
      //     setLoaderClasses("loader-container d-none");
      //   }

      // }).catch(err => console.log(err));

  }, [props.token, props.hostName]);



  useEffect(() => {

    if (activeStudentCountData.length !== 0 && discontinueStudentCountData.length !== 0 && passOutStudentCountData.length !== 0){
      let data = [];
      activeStudentCountData.forEach((elem, index) => {
        data[index] = [
                        elem.month_name, 
                        elem.new_admission, 
                        discontinueStudentCountData[index].discontinue_student,
                        passOutStudentCountData[index].passout_student
                      ];
      });
  
      setGraphData([
        ["Months", "Active", "Discontinue", "Passout"], ...data
      ]);
    }
    
  }, [activeStudentCountData,discontinueStudentCountData, passOutStudentCountData]);


  return (
    <>
      <Chart className={props.className} chartType="Bar" data={graphData}
        height="400px" width='1200px'
        options={{
          chart: {
            title: 'Students',
            subtitle: 'Active, All (Passout, Discontinue, Active)',
            
          },
          responsive: true,
          colors: ['#1e88e5', '#f44336', '#388e3c'],         
          legend: { position: "top"},
        }} />

        <Loader loaderClasses={loaderClasses}/>
    </>
  )
}

export default ActiveAllStudentGraph;
