import React,{useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
// import { useLocation } from 'react-router-dom';
import TaskStudentShortData from '../../components/task_components/TaskStudentShortData';
import Loader from '../../components/admin_components/Loader';
import TaskStudentBox from '../../components/task_components/TaskStudentBox';

const StudentTaskData = (props) => {
    // const location = useLocation();
    // const {stuId} = location.state;
    const param = useParams();
    const stuId = param.stu_id;
    const [singleStudentData, setSingleStudentData] = useState([]);
    const [studentTaskData, setStudentTaskData] = useState([]);
    const [loaderClasses, setLoaderClasses] = useState('loader-container d-none');

    useEffect(()=> {props.setTitle("Student Task Data | Task Panel | RedSky Educations | Educations for future")}, [props]);

    const fetchTaskDataWithStudentShortData = () => {
        setLoaderClasses("loader-container");
        fetch(`${props.hostName}/redsky_api/task_api/task_data_with_student_short_details.php?token=${props.token}&stu_id=${stuId}`)
        .then(resp => resp.json()).then(data => {
            
            setLoaderClasses("loader-container d-none");
            
            if(data.code === 5){
                setSingleStudentData(data.data['stu_data']);
                setStudentTaskData(data.data['stu_task_data']);
            
            }
            else if(data.code === 13){
                setSingleStudentData(data.data['stu_data']);
                document.querySelector('.task-details').innerHTML = "<center>No Task Found</center>";
            }
            else{
                alert(data.data);
            }
            
        }).catch(err => console.log(err));
    };


    useEffect(fetchTaskDataWithStudentShortData, [props.hostName, props.token, stuId]);

    const setTaskAsComplete = (obj) => {
        
        setLoaderClasses('loader-container');
        fetch(`${props.hostName}/redsky_api/task_api/task_set_as_complete.php?token=${props.token}`,
        {
        method: "POST",
        body: JSON.stringify(obj),
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
        })
        .then(resp => resp.json()).then(data => {
                
            setLoaderClasses('loader-container d-none');
            alert(data.data);
            fetchTaskDataWithStudentShortData();
            
        }).catch(err => console.log(err));
    };
    
    const setTaskAsIncomplete = (obj) => {
        setLoaderClasses('loader-container');
        fetch(`${props.hostName}/redsky_api/task_api/task_set_as_incomplete.php?token=${props.token}`,
        {
        method: "POST",
        body: JSON.stringify(obj),
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
        })
        .then(resp => resp.json()).then(data => {
                
            setLoaderClasses('loader-container d-none');
            // alert(data.data);
            fetchTaskDataWithStudentShortData();
            
        }).catch(err => console.log(err));
    };

    const setTaskAsCancel = (obj) => {
        setLoaderClasses('loader-container');
        fetch(`${props.hostName}/redsky_api/task_api/task_set_as_cancel.php?token=${props.token}`,
        {
        method: "POST",
        body: JSON.stringify(obj),
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
        })
        .then(resp => resp.json()).then(data => {
                
            setLoaderClasses('loader-container d-none');
            alert(data.data);
            fetchTaskDataWithStudentShortData();
            
        }).catch(err => console.log(err));
    };

    const setResumeTask = (obj) => {
        setLoaderClasses('loader-container');
        fetch(`${props.hostName}/redsky_api/task_api/task_set_as_resume.php?token=${props.token}`,
        {
        method: "POST",
        body: JSON.stringify(obj),
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
        })
        .then(resp => resp.json()).then(data => {
                
            setLoaderClasses('loader-container d-none');
            alert(data.data);
            fetchTaskDataWithStudentShortData();
            
        }).catch(err => console.log(err));
    };

    const deleteTask = (taskIdObj) => {
        setLoaderClasses('loader-container');
        fetch(`${props.hostName}/redsky_api/task_api/task_delete_single.php?token=${props.token}`,
        {
        method: "POST",
        body: JSON.stringify(taskIdObj),
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
        })
        .then(resp => resp.json()).then(data => {
                
            setLoaderClasses('loader-container d-none');
            alert(data.data);
            fetchTaskDataWithStudentShortData();
            
        }).catch(err => console.log(err));
    }

  return (
    <>
        <TaskStudentShortData studentData={singleStudentData} />
        <TaskStudentBox studentTaskData={studentTaskData} setTaskAsComplete={setTaskAsComplete} setTaskAsIncomplete={setTaskAsIncomplete} setTaskAsCancel={setTaskAsCancel} setResumeTask={setResumeTask} deleteTask={deleteTask} />
        <Loader loaderClasses={loaderClasses} />
    </>
  )
}

export default StudentTaskData;
