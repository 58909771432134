import React, { useEffect, useState } from 'react';
import './AddNewStudentForm.css';
import './Loader.css';

const EnqueryForm = (props) => {
  const [name, setName] = useState('');
  const [course, setCourse] = useState('');
  const [feesDiscount, setFeesDiscount] = useState(0);
  const [contact, setContact] = useState('');
  const [courseStartingMonth, setCourseStartingMonth] = useState('');
  const [courseEndingMonth, setCourseEndingMonth] = useState('');
  const [day1, setDay1] = useState('#N/A');
  const [time1, setTime1] = useState('#N/A');
  const [day2, setDay2] = useState('#N/A');
  const [time2, setTime2] = useState('#N/A');

  const minStartingDate = new Date().toJSON().slice(0, 7);
  const [minEndingDate, setMinEndingDate] = useState(minStartingDate);
  const [maxEndingDate, setMaxEndingDate] = useState(minStartingDate);

  const dayOptions = ['#N/A', 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const timeOptions = ['#N/A', '06:45 AM - 08:00 AM', '08:00 AM - 09:15 AM', '09:15 AM - 10:30 AM', '10:30 AM - 11:45 AM', '11:45 AM - 01:00 PM', '01:00 PM - 02:15 PM', '03:00 PM - 04:15 PM', '04:15 PM - 05:30 PM', '05:30 PM - 06:45 PM', '06:45 PM - 08:00 PM', '08:00 PM - 09:15 PM', '10:00 PM - 11:15 PM'];

  useEffect(()=> {props.setTitle(`Enquiry Form | ${parseInt(props.isHeadAdmin) ? "Admin Panel" : "User Panel"} | RedSky Educations | Educations for future`)}, [props]);
  /*const resetFormFields = () => {
    setName('');
    setCourse('');
    setFeesDiscount(0);
    setSchool('');
    setContact('');
    setDay1('');
    setTime1('');
    setDay2('');
    setTime2('');
    setAdvanceFees('');
    // document.querySelectorAll(".placeHolder")[0].selected = true;
    // document.querySelectorAll(".placeHolder")[1].selected = true;
    // document.querySelectorAll(".placeHolder")[2].selected = true;
    // document.querySelectorAll(".placeHolder")[3].selected = true;
    // document.querySelectorAll(".placeHolder")[4].selected = true;
  };*/

  const enqueryFormValidate = (e) => {
    e.preventDefault();
    if (name.trim() === '' || course.trim() === '' || contact.trim() === '' || day1.trim() === '' || time1.trim() === '' || day2.trim() === '' || time2.trim() === '') {
        alert("Please Fill Out All Fields!");
        return;
      }
      
      let enqueryFormData = {
        name: name,
        course: course,
        fees_discount: feesDiscount,
        contact: contact,
        course_starting_date: courseStartingMonth,
        course_ending_date: courseEndingMonth,
        day1: day1,
        time1: time1,
        day2: day2,
        time2: time2
      };
      
      props.storeEnquery(enqueryFormData);
    // resetFormFields();
  };

  const courseHandler = (e) => {
    setCourse(e.target.value);
    setCourseStartingMonth('');
    setCourseEndingMonth('');
    setMaxEndingDate('');
    props.getSingleCourseData(e.target.value);
  }

  const courseStartingMonthChangeHandler = (e) => { 
   
    setCourseStartingMonth(e.target.value);
    setMinEndingDate(e.target.value);
    let year = parseInt(e.target.value.slice(0,4));
    let month = parseInt(e.target.value.slice(5,7)) + parseInt(props.courseTotalInstallment) - 1;
    
    while(month>12){
      year = (month > 12) ? year + 1 : year;
      month = (month > 12) ? month - 12 : month;
    }
    month = (month < 10) ? '0'+month : month;
    setMaxEndingDate(`${year}-${month}`);
    setCourseEndingMonth(`${year}-${month}`);
  }

  return (
    <>
      <section className="add-student-form-container">
        <header>Enquiry Form</header>
        <form className="form" onSubmit={enqueryFormValidate} >


          <div className="input-box">
            <label>Name:<span className='color-red'>*</span></label>
            <input type="text" placeholder="Enter student Name" value={name} onChange={(e) => { setName(e.target.value) }} required />
          </div>

          <div className='column'>
            
            <div className="input-box">
              <label>Contact<span className='color-red'>*</span></label>
              <input type="text" placeholder="Enter Phone No" value={contact} onChange={(e) => { setContact(e.target.value) }} required/>
            </div>
          </div>

          <div className='column'>
            <div className="input-box">
              <label>Course<span className='color-red'>*</span></label>
              <div className="select-box">
                <select onChange={courseHandler} className="course-options" required>
                  <option hidden className='placeHolder'>Course</option>
                  {
                    props.allCourses.map((element) => {
                      return (<option key={element.c_id} value={element.c_id} >{element.c_name}</option>);
                    })
                  }
                </select>
              </div>
            </div>
            <div className="input-box">
              <label>Discount<span className='color-red'>*</span></label>
              <input type="text" placeholder="Enter Fees Discount" value={feesDiscount} onChange={(e) => { setFeesDiscount(e.target.value) }} min="0" required />
            </div>
          </div>

          <div className='column'>
            <div className="input-box">
              <label>Starting Month:<span className='color-red'>*</span></label>
              <input type="month" placeholder="Enter Starting Month" value={courseStartingMonth} onChange={courseStartingMonthChangeHandler} min={minStartingDate} required />
            </div>
            <div className="input-box">
              <label>Ending Month<span className='color-red'>*</span></label>
              <input type="month" placeholder="Enter Ending Month" value={courseEndingMonth} onChange={(e) => { setCourseEndingMonth(e.target.value) }} min={minEndingDate} max={maxEndingDate} required />
            </div>
          </div>

          <div className='column'>
            <div className="input-box">
              <label>Day 1</label>
              <div className="select-box">
                <select onChange={(e) => { setDay1(e.target.value) }} className="purpose-options">
                  {/* <option hidden className='placeHolder' value=''>Select Day 1</option> */}
                  {
                    dayOptions.map((dayOpt)=>{
                      return(<option key={dayOpt} value={dayOpt}>{dayOpt}</option>);
                    })
                  }
                </select>
              </div>
            </div>
            <div className="input-box">
              <label>Time 1</label>
              <div className="select-box">
                <select onChange={(e) => { setTime1(e.target.value) }} className="purpose-options">
                  {/* <option hidden className='placeHolder' value=''>Select Time Slot 1</option> */}
                  {
                    timeOptions.map((timeOpt)=>{
                      return(<option key={timeOpt} value={timeOpt}>{timeOpt}</option>);
                    })
                  }
                </select>
              </div>
            </div>
          </div>

          <div className='column'>
            <div className="input-box">
              <label>Day 2</label>
              <div className="select-box">
                <select onChange={(e) => { setDay2(e.target.value) }}  className="purpose-options">
                  {/* <option hidden className='placeHolder' value=''>Select Day 2</option> */}
                  {
                    dayOptions.map((dayOpt)=>{
                      return(<option key={dayOpt} value={dayOpt}>{dayOpt}</option>);
                    })
                  }
                </select>
              </div>
            </div>
            <div className="input-box">
              <label>Time 2</label>
              <div className="select-box">
                <select onChange={(e) => { setTime2(e.target.value) }} className="purpose-options">
                  {/* <option hidden className='placeHolder' value=''>Select Time Slot 2</option> */}
                  {
                    timeOptions.map((timeOpt)=>{
                      return(<option key={timeOpt} value={timeOpt}>{timeOpt}</option>);
                    })
                  }
                </select>
              </div>
            </div>
          </div>
          

          <input type='submit' value='Print Enquiry' className='submit-btn' />
        </form>
      </section>

    </>
  )
}

export default EnqueryForm;
