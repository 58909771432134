import React, { useEffect, useRef, useState } from 'react';
import './Certificate.css';
import logo from '../../assets/img/logo_png.png';
import mdSign from '../../assets/img/md_sign-png.png';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../../components/admin_components/Loader';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import QRCode from 'react-qr-code';

const Certificate = (props) => {
    const param = useParams();
    const certificate = useRef();
    const navigate = useNavigate();
    const [loaderClasses, setLoaderClasses] = useState('loader-container d-none');
    const [certificateData, setCertificateData] = useState({});

    const downloadCertificate = () =>{
        // const input = document.getElementById('certificate');
        html2canvas(certificate.current, {scale: 2.5})
          .then((canvas) => {
           
            const imgData = canvas.toDataURL('image/jpeg,1.0');
            const pdf = new jsPDF('p','mm', 'a4');
            // const height = pdf.internal.pageSize.height;
            // const aspectWidth1 = height*0.707;
            //          imgBase64, type, marginX, marginY, width, height
            pdf.addImage(imgData, 'png', 0, -1, 210, 298);
            pdf.save(`${param.id}_certificate.pdf`);
          })
        ;
      }
    useEffect(() => {
		setLoaderClasses('loader-container');
		fetch(`${props.hostName}/redsky_api/get_certificate_details.php?token=${props.token}&id=${param.id}`)
			.then(resp => resp.json()).then(data => {
				if (data.code === 5) {
					setCertificateData(data.data);
				}else{
					alert(data.data);
                    navigate('../all-students');
				}
				setLoaderClasses('loader-container d-none');
			}).catch(err => console.log(err));
	}, [props.token, props.hostName, param.id]);

  return (
    <>
    {
        Object.keys(certificateData).length > 0
        ?
            <div className="container-fluid certificate-container" style={{overflowX:"scroll"}}>
                <div className="row d-flex justify-content-center align-items-center flex-column">
                    <div id="certificate" className="col-12 py-5 px-5" ref={certificate}>
                        <div className="c-header d-flex justify-content-between align-items-start">
                            <div className="c-header-left">
                                <img src={logo} alt="logo" />
                            </div>
                            <div className="c-header-right">
                                <h4>Redsky educations private limited</h4>
                                <h4>CIN: U80903WB2021PTC248059</h4>
                            </div>
                        </div>
                        <div className="c-middle-text mt-4 d-flex justify-content-center align-items-center flex-column">
                            <p>This is to certify that</p>
                            <h1 className="stu-name">{certificateData.fname} {certificateData.lname}</h1>
                            <p>has successfully completed training program of {certificateData.course_duration} {certificateData.course_duration_unit}s on</p>
                            <p className="course-name">{certificateData.course_name}</p>
                            <p>at RedSky Educations study center, Krishnanagar, Nadia WB IN <br /> based on following module:</p>
                        </div>
                        <div className={`c-module d-flex justify-content-center align-items-start flex-column ${certificateData.course_module.length > 11 ? 'large-no-module' : ''}`}>
                            {
                                certificateData.course_module.map((item, indx) =>{
                                    return(
                                        <p key={indx}>{item.trim()}</p>
                                    )
                                })
                            }
                            
                        </div>
                        <div className="c-footer">
                            <div className="c-footer-upper d-flex justify-content-between align-items-start">
                                <div>
                                    <p>Krishnanagar, WB IN</p>
                                    <img src={mdSign} alt="sign" id="md-sign" />
                                    <p>Managing Director</p>
                                    <p>Dated: {certificateData.passout_date}</p>
                                    <p>Certificate no: {certificateData.sub_id}/{certificateData.m_id}</p>
                                </div>
                                <div>
                                    <p className="mb-3">Marks details:</p>
                                    <p><span>Marks obtained:</span> {certificateData.marks_obtain}</p>
                                    <p><span>Full marks:</span> {certificateData.total_marks}</p>
                                    <p><span>Grade obtained:</span> {certificateData.grade}</p>
                                    <p><span>Percentage:</span> {certificateData.marks_percentage}%</p>
                                </div>
                            </div>

                            <div className="c-footer-bottom d-flex justify-content-start align-items-center mt-3 w-100">
                                <QRCode
                                    title="RedSky"
                                    value={`https://redskyeducations.co.in/certificate/${param.id}/${certificateData.m_id}`}
                                    alt="qr" id="c-qr" className="me-3"
                                />
                                <p className="m-0">The authencity of this document may be verified at <br/> https://redskyeducations.co.in/certificate/{param.id}/{certificateData.m_id}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <button className='btn btn-primary print-btn' autoFocus onClick={()=>window.print()}>Print<span className='material-icons'>print</span></button>
                <button className='btn btn-success print-btn' style={{bottom: '4.5rem'}} onClick={()=>downloadCertificate()}>Download<span className='material-icons'>download</span></button>
            </div>
        :
        <></>
    }
    <Loader loaderClasses={loaderClasses} />
    </>
  )
}

export default Certificate;
