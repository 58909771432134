import React from 'react';
import './Loader.css';

//loader-container
const Loader = (props) => {
  return (
    <div className={props.loaderClasses}>
        <div className="box">
          <div className="circle-container">
            <span className="circle"></span>
            <span className="circle"></span>
            <span className="circle"></span>
            <span className="circle"></span>
          </div>
          <br/>
          <span>Loading</span>
        </div>
      </div>
  )
}

export default Loader;
