import React, { useEffect, useState} from 'react';
import StudentShortDetails from '../../components/admin_components/StudentShortDetails';
import UnpaidPaymentBox from '../../components/admin_components/UnpaidPaymentBox';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../../components/admin_components/Loader';
import './PaymentStudent.css';
import SearchPaymentBox from './SearchPaymentBox';


const PaymentStudent = (props) => {
  const navigate = useNavigate();
  const param = useParams();
  const stu_reg_no = param.reg_no;

  const [singleStudentData, setSingleStudentData] = useState({});
  const [unpaidFeesLists, setUnpaidFeesLists] = useState([]);
  const [deferralFeesLists, setDeferralFeesLists] = useState([]);
  const [loaderClasses, setLoaderClasses] = useState('loader-container d-none');

  useEffect(()=> {props.setTitle(`New Payment | ${parseInt(props.isHeadAdmin) ? "Admin Panel" : "User Panel"} | RedSky Educations | Educations for future`)}, [props]);

  useEffect(() => {

    setLoaderClasses('loader-container');
    setSingleStudentData({});
    setUnpaidFeesLists([]);
    setDeferralFeesLists([]);
   
    fetch(`${props.hostName}/redsky_api/unpaidFeesListWithStudentShortData.php?token=${props.token}`,
      {
        method: "POST",
        body: JSON.stringify({stu_reg_no: stu_reg_no}),
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        }
      })
      .then(resp => resp.json()).then(data => {
        
        
        if(data.code === 5){
          // console.log(data);
          setSingleStudentData(data.data['stu_data']);
          setUnpaidFeesLists(data.data['unpaid_fees_data']);
          setDeferralFeesLists(data.data['deferral_fees_data']);
          
        }else{
          alert(data.data);
        }
        setLoaderClasses('loader-container d-none');
        
      }).catch(err => console.log(err));

  },[props.hostName, props.token, stu_reg_no]);

  
  const saveNewPaymentDetails = (paymentObj) => {
    setLoaderClasses('loader-container');
      fetch(`${props.hostName}/redsky_api/addNewPaymentDetails.php?token=${props.token}`,
      {
        method: "POST",
        body: JSON.stringify({...paymentObj, reg_no: stu_reg_no}),
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        }
      })
      .then(resp => resp.json()).then(data => {

      
        if(data.code === 8){
          // sendPaymentConfirmation(singleStudentData.id, singleStudentData.course_fees, singleStudentData.contact);
          //if want to print the reciept
          // navigate('../Print-paid-Fees', {state:{stuId:newPaymentArr.stu_reg_no, installmentNo:newPaymentArr.new_selected_installment_arr.slice(-1)}});

          navigate(`../paid-fees-list-no-search/${stu_reg_no}`);
          // alert(data.data);

        }else{
          alert(data.data);
        }

        setLoaderClasses('loader-container d-none');
      }).catch(err => console.log(err));
  };

  
  return (
    <>
      <SearchPaymentBox setTitle={props.setTitle} heading="Payment Student" path="../payment-student" />
      {
        Object.keys(singleStudentData).length > 0 ?
          <StudentShortDetails studentData={singleStudentData} />
        :
        <></>
      }
    
      <UnpaidPaymentBox unpaidFeesLists={unpaidFeesLists} deferralFeesLists={deferralFeesLists} saveNewPaymentDetails={saveNewPaymentDetails} />
       

      <Loader loaderClasses={loaderClasses} />
    </>
  );
}

export default PaymentStudent;
