import React, {useState, useEffect} from 'react';
import '../../components/admin_components/StudentForm.css';
import './PrintSingleStudent.css';

const PrintSingleStudent = (props) => {
  const [isLoading, setIsLoading] = useState('loading-container');
  const [isFormVisible, setIsFormVisible] = useState('form d-none');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNo, setPhoneNo] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [streetAddress, setStreetAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [pinCode, setPinCode] = useState('');

  useEffect(()=> {props.setTitle(`Print Student | ${parseInt(props.isHeadAdmin) ? "Admin Panel" : "User Panel"}  | RedSky Educations | Educations for future`)}, [props]);

  useEffect(()=>{

    fetch(`${props.hostName}/redsky_api/getSingleStudent.php?token=${props.token}&id=${props.searchStuId}`,
      {
        method: "POST",
        body: JSON.stringify({
          student_id : props.searchStuId
        }),
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        }
      })
      .then(resp => resp.json()).then(data => {
        console.log(data);
        let studentDetails = data.data.stu_data;
        setIsLoading("loading-container d-none");
        if(data.code === 5){
          setFirstName(studentDetails.s_fname);
          setLastName(studentDetails.s_lname);
          setEmail(studentDetails.s_email);
          setPhoneNo(studentDetails.s_contact);
          setDateOfBirth(studentDetails.s_dob);
          document.querySelector(`.gender-option input[value='${studentDetails.s_gender}']`).checked = true;
          document.querySelector('.course-options').innerHTML = `<option>${studentDetails.c_name}</option>`;
          setStreetAddress(studentDetails.s_address);
          document.querySelector(`.country-options option[value='${studentDetails.s_country}']`).selected = true;
          setCity(studentDetails.s_city);
          setState(studentDetails.s_state);
          setPinCode(studentDetails.s_pin);
          //showing the form
          setIsFormVisible('form');
        }
      }).catch(err => console.log(err));
  });

  const studentPrintHandler = (e) => {
    e.preventDefault();
    window.print();
  };
  
  return (
    <section className='form-container'>
        <header>Print Student</header>
        <div className={isLoading}>
          <span className="material-icons material-symbols-outlined">sync</span> Loading...
        </div>
        <form className={isFormVisible}>
          <div className="column">
            <div className="input-box">
              <label>First Name</label>
              <input type="text" placeholder="Enter first name" value={firstName} required disabled/>
            </div>
            <div className="input-box">
              <label>Last Name</label>
              <input type="text" placeholder="Enter last name" value={lastName} required disabled/>
            </div>
          </div>

          <div className="input-box">
            <label>Email Address</label>
            <input type="email" placeholder="Enter email address" value={email} required disabled/>
          </div>

          <div className="column">
            <div className="input-box">
              <label>Phone Number</label>
              <input type="number" placeholder="Enter phone number" value={phoneNo} required disabled/>
            </div>
            <div className="input-box">
              <label>Birth Date</label>
              <input type="date" placeholder="Enter birth date" value={dateOfBirth} required disabled/>
            </div>
          </div>
          <div className="gender-box">
            <h3>Gender</h3>
            <div className="gender-option">
              <div className="gender">
                <label><input type="radio"  name="gender" value='Male' required disabled/> Male</label>
              </div>
              <div className="gender">
                <label><input type="radio" name="gender" value='Female' required disabled/> Female</label>
              </div>
              <div className="gender">
                <label><input type="radio" name="gender" value='Others' required disabled/> Prefer not to say</label>
              </div>
            </div>
          </div>
          <div className="input-box">
            <label>Course</label>
            <div className="select-box">
                  <select  className="course-options" disabled>
                    <option hidden className='placeHolder'>Course</option>
                  </select>
                </div>
            </div>
          <div className="input-box address">
            <label>Address</label>
            <input type="text" placeholder="Enter street address" value={streetAddress}  required disabled/>
            {/* <input type="text" placeholder="Enter street address 2 (Optional)" /> */}
            <div className="column">
              <div className="select-box">
                <select className="country-options" disabled>
                  <option hidden className='placeHolder'>Country</option>
                  <option value='India'>India</option>
                  <option value='America'>America</option>
                  <option value='Japan'>Japan</option>
                  <option value='Nepal'>Nepal</option>
                </select>
              </div>
              <input type="text" placeholder="Enter your city" value={city} required disabled/>
            </div>
            <div className="column">
              <input type="text" placeholder="Enter your State"  value={state} required disabled/>
              <input type="number" placeholder="Enter postal code" value={pinCode} required disabled/>
            </div>
          </div>

          <button type='submit' className='print-btn' onClick={studentPrintHandler}>Print</button>
        </form>
      </section>
  )
}

export default PrintSingleStudent;
