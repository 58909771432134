import React from 'react';
import './AllStudentTableRow.css';

const ViewAttendanceTableRow = (props) => {
  const element = props.element;
  const deleteAttendanceHandler = (sub_id, date) => {
    props.setDeleteAttedance({sub_id: sub_id, date : date});
  }
  return (
    <>
      <tr className={parseInt(element.is_present) ? '' : (parseInt(element.is_absent_known) ? 'absent-but-known' : 'absent')}>
        <td data-label="ID">&nbsp;{`${element.reg_no}`}</td>
        <td data-label="Name">&nbsp;{element.fname + ' ' + element.lname}</td>
        <td data-label="Course">&nbsp;{element.course_name}</td>
        <td data-label="Date">&nbsp;{element.date}</td>
        <td data-label="Delete"><span className="material-icons material-symbols-rounded del-btn" onClick={ ()=> deleteAttendanceHandler(element.sub_id, element.date) } >delete</span></td>
      </tr>   
    </>

  )
}

export default ViewAttendanceTableRow;
