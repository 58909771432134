import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './Profile.css';
import Loader from '../../components/admin_components/Loader';

const ProfileCertificate = (props) => {
  const param = useParams();
  const [certificateData, setCertificateData] = useState([]);
  const [loaderClasses, setLoaderClasses] = useState('loader-container');
  
  useEffect(()=>{
    setLoaderClasses('loader-container');
    fetch(`${props.hostName}/redsky_api/student/getProfileCertificateData.php?sub_id=${param.sub_id}&marks_id=${param.marks_id}`)
      .then(resp => resp.json()).then(data => {
            if(data.code === 5){
              setCertificateData(data.data);
            }else{
                alert(data.data);
            }
            setLoaderClasses('loader-container d-none');
      }).catch(err => console.log(err));
  }, [param, props]);

  return (
    <>
      <div className='container-fluid py-4' id='profile'>
        <div className='row'>
          <div className='col-12'>
            <h4 className='text-center'>Certificate Verify</h4>
            <hr className="border border-secondary border-1 opacity-75 mt-3" />
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>

            <div className="accordion" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button text-white" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                    Certificate Details
                  </button>
                </h2>
                <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                  <div className="accordion-body">

                    <div className='row'>
                      <div className='col-md-6'>
                        <div className='row d-flex align-items-center h-100 bg-body-secondary p-2 border border-secondary-subtle border-bottom-0'>
                          <div className='col-6 fw-medium'>First Name:</div>
                          <div className='col-6'>{certificateData.fname}</div>
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className='row d-flex align-items-center h-100 bg-body-secondary bg-alt-white p-2 border border-secondary-subtle border-bottom-0 '>
                          <div className='col-6 fw-medium'>Last Name:</div>
                          <div className='col-6'>{certificateData.lname}</div>
                        </div>
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col-md-6'>
                        <div className='row d-flex align-items-center h-100 p-2 border border-secondary-subtle border-bottom-0 bg-alt-gray'>
                          <div className='col-6 fw-medium'>Guardian:</div>
                          <div className='col-6'>{certificateData.guardian_name}</div>
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className='row d-flex align-items-center h-100 p-2 border border-secondary-subtle border-bottom-0'>
                          <div className='col-6 fw-medium'>D.O.B:</div>
                          <div className='col-6'>{certificateData.dob}</div>
                        </div>
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col-md-6'>
                        <div className='row d-flex align-items-center h-100 bg-body-secondary p-2 border border-secondary-subtle border-bottom-0'>
                          <div className='col-6 fw-medium'>Gender:</div>
                          <div className='col-6'>{certificateData.gender}</div>
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className='row d-flex align-items-center h-100 bg-body-secondary bg-alt-white p-2 border border-secondary-subtle border-bottom-0'>
                          <div className='col-6 fw-medium'>Admission Date:</div>
                          <div className='col-6'>{certificateData.admission_date}</div>
                        </div>
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col-md-6'>
                        <div className='row d-flex align-items-center h-100 p-2 border border-secondary-subtle border-bottom-0 bg-alt-gray'>
                          <div className='col-6 fw-medium'>Course:</div>
                          <div className='col-6'>{certificateData.course_name}</div>
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className='row d-flex align-items-center h-100 p-2 border border-secondary-subtle border-bottom-0'>
                          <div className='col-6 fw-medium'>Status:</div>
                          <div className='col-6'>{certificateData.is_passout}</div>
                        </div>
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col-md-6'>
                        <div className='row d-flex align-items-center h-100 bg-body-secondary p-2 border border-secondary-subtle border-bottom-0'>
                          <div className='col-6 fw-medium'>State:</div>
                          <div className='col-6'>{certificateData.state}</div>
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className='row d-flex align-items-center h-100 bg-body-secondary bg-alt-white p-2 border border-secondary-subtle border-bottom-0'>
                          <div className='col-6 fw-medium'>Country:</div>
                          <div className='col-6'>{certificateData.country}</div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button text-white collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                    Exam Marks
                  </button>
                </h2>
                <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                    <div className="table-responsive mt-3">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th scope="col">Exam Date</th>
                            <th scope="col">Theory</th>
                            <th scope="col">Practical</th>
                            <th scope="col">Viva</th>
                            <th scope="col">Total</th>
                            <th scope="col">Grade</th>
                          </tr>
                        </thead>
                        <tbody>
                            <tr key={certificateData.exam_marks_id}>
                                <td data-label="Exam Date">{certificateData.date_of_exam}</td>
                                <td data-label="Theory">{certificateData.th_obtain} / {certificateData.th_total}</td>
                                <td data-label="Practical">{certificateData.prac_obtain} / {certificateData.prac_total}</td>
                                <td data-label="Viva">{certificateData.viva_obtain} / {certificateData.viva_total}</td>
                                <td data-label="Total">{certificateData.marks_obtain} / {certificateData.total_marks}</td>
                                <td data-label="Grade">{certificateData.grade}</td>
                            </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>

          </div>
        </div>
      </div>
      <Loader loaderClasses={loaderClasses} />
    </>
  )
}

export default ProfileCertificate;
