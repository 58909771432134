import React, { useEffect, useState } from 'react';

const EnqueryModifyForm = (props) => {
  const [name, setName] = useState();
  const [course, setCourse] = useState();
  const [feesDiscount, setFeesDiscount] = useState();
  const [contact, setContact] = useState();
  const [courseStartingMonth, setCourseStartingMonth] = useState();
  const [courseEndingMonth, setCourseEndingMonth] = useState();
  const [day1, setDay1] = useState();
  const [time1, setTime1] = useState();
  const [day2, setDay2] = useState();
  const [time2, setTime2] = useState();
  const minStartingDate = new Date().toJSON().slice(0, 7);
  const dayOptions = ['#N/A', 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const timeOptions = ['#N/A', '06:45 AM - 08:00 AM', '08:00 AM - 09:15 AM', '09:15 AM - 10:30 AM', '10:30 AM - 11:45 AM', '11:45 AM - 01:00 PM', '01:00 PM - 02:15 PM', '03:00 PM - 04:15 PM', '04:15 PM - 05:30 PM', '05:30 PM - 06:45 PM', '06:45 PM - 08:00 PM', '08:00 PM - 09:15 PM', '10:00 PM - 11:15 PM'];

  useEffect(() => {
    setName(props.enqueryData.name);
    setContact(props.enqueryData.contact);
    setCourse(props.enqueryData.course_id);
    setFeesDiscount(props.enqueryData.fees_discount);
    setCourseStartingMonth(props.enqueryData.course_start_month);
    setCourseEndingMonth(props.enqueryData.course_end_month);
    setDay1(props.enqueryData.day1);
    setTime1(props.enqueryData.time1);
    setDay2(props.enqueryData.day2);
    setTime2(props.enqueryData.time2);
  }, [props.enqueryData]);

  const formValidate = (e) =>{
    e.preventDefault();
    if (name.trim() === '' || course.trim() === '' || contact.trim() === '' || feesDiscount.trim() === '' || day1.trim() === '' || time1.trim() === '' || day2.trim() === '' || time2.trim() === '') {
        alert("Please Fill Out All Fields!");
        return;
      }

      let enqueryFormDataObj = {
        enquery_id: props.enqueryData.enquery_id,
        name: name,
        course: course,
        fees_discount: feesDiscount,
        contact: contact,
        course_starting_date: courseStartingMonth,
        course_ending_date: courseEndingMonth,
        day1: day1,
        time1: time1,
        day2: day2,
        time2: time2
      };
      props.enqueryModify(enqueryFormDataObj);
  }

  return (
    <section className="add-student-form-container">
        <header>Modify Enquiry Form</header>
        <form action="#" className="form" onSubmit={formValidate} >

          <div className="input-box">
            <label>Name:<span className='color-red'>*</span></label>
            <input type="text" placeholder="Enter student Name" value={name} onChange={(e) => { setName(e.target.value) }} required />
          </div>

          <div className='column'>
            
            <div className="input-box">
              <label>Contact<span className='color-red'>*</span></label>
              <input type="text" placeholder="Enter Phone No" value={contact} onChange={(e) => { setContact(e.target.value) }} required/>
            </div>
          </div>

          <div className='column'>
            <div className="input-box">
              <label>Course<span className='color-red'>*</span></label>
              <div className="select-box">
                <select onChange={(e)=>setCourse(e.target.value)} className="course-options" value={course} required>
                  <option hidden className='placeHolder'>Course</option>
                  {
                    props.allCourses.map((element) => {
                      return (<option key={element.c_id} value={element.c_id} >{element.c_name}</option>);
                    })
                  }
                </select>
              </div>
            </div>
            <div className="input-box">
              <label>Discount<span className='color-red'>*</span></label>
              <input type="text" placeholder="Enter Fees Discount" value={feesDiscount} onChange={(e) => { setFeesDiscount(e.target.value) }} min="0" required />
            </div>
          </div>

          <div className='column'>
            <div className="input-box">
              <label>Starting Month:<span className='color-red'>*</span></label>
              <input type="month" placeholder="Enter Starting Month" value={courseStartingMonth} onChange={(e) =>setCourseStartingMonth(e.target.value)} min={minStartingDate} required />
            </div>
            <div className="input-box">
              <label>Ending Month<span className='color-red'>*</span></label>
              <input type="month" placeholder="Enter Ending Month" value={courseEndingMonth} onChange={(e) => { setCourseEndingMonth(e.target.value) }} /*min={minEndingDate} max={maxEndingDate} */ required />
            </div>
          </div>

          <div className='column'>
            <div className="input-box">
              <label>Day 1</label>
              <div className="select-box">
                <select onChange={(e) => { setDay1(e.target.value) }} className="purpose-options" value={day1}>
                  {/* <option hidden className='placeHolder' value=''>Select Day 1</option> */}
                  {
                    dayOptions.map((dayOpt)=>{
                      return(<option key={dayOpt} value={dayOpt}>{dayOpt}</option>);
                    })
                  }
                </select>
              </div>
            </div>
            <div className="input-box">
              <label>Time 1</label>
              <div className="select-box">
                <select onChange={(e) => { setTime1(e.target.value) }} className="purpose-options" value={time1}>
                  {/* <option hidden className='placeHolder' value=''>Select Time Slot 1</option> */}
                  {
                    timeOptions.map((timeOpt)=>{
                      return(<option key={timeOpt} value={timeOpt} >{timeOpt}</option>);
                    })
                  }
                </select>
              </div>
            </div>
          </div>

          <div className='column'>
            <div className="input-box">
              <label>Day 2</label>
              <div className="select-box">
                <select onChange={(e) => { setDay2(e.target.value) }}  className="purpose-options" value={day2}>
                  {/* <option hidden className='placeHolder' value=''>Select Day 2</option> */}
                  {
                    dayOptions.map((dayOpt)=>{
                      return(<option key={dayOpt} value={dayOpt} >{dayOpt}</option>);
                    })
                  }
                </select>
              </div>
            </div>
            <div className="input-box">
              <label>Time 2</label>
              <div className="select-box">
                <select onChange={(e) => { setTime2(e.target.value) }} className="purpose-options" value={time2}>
                  {/* <option hidden className='placeHolder' value=''>Select Time Slot 2</option> */}
                  {
                    timeOptions.map((timeOpt)=>{
                      return(<option key={timeOpt} value={timeOpt} >{timeOpt}</option>);
                    })
                  }
                </select>
              </div>
            </div>
          </div>
          <input type='submit' value='Modify' className='submit-btn'/>
        </form>
      </section>
  )
}

export default EnqueryModifyForm
