import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
import './AllStudentTableRow.css';

const AssignedClassListTableRow = (props) => { 
    const [isDone, setIsDone] = useState(parseInt(props.elem.is_done) ? true : false);
    const sharePaymentData = (data) =>{
       
        let wp_msg = `*Class Details for ${data.date}*%0aTotal Nos of Student: ${data.total_student}%0aMentor: ${data.mentor_name}%0aCourse: ${data.course_name} %0aStart Time: ${data.str_time}%0aEnd Time: ${data.end_time}%0aHost: RedSky%0a*Topic: ${data.topic}*%0aLanguage: English/Bengali`;

        if(props.deviceType === 'desktop'){
           window.open(`https://web.whatsapp.com/send?phone=+91 7001634925&text=${wp_msg}&app_absent=0`, "blank");
        }else{
           window.open(` https://wa.me/+91 7001634925?text=${wp_msg}`, "blank");
        }
    }
    const setUpdateIsDone = (id) => {
      props.updateIsDone(id, !isDone);
      setIsDone(!isDone);
    }

  return (
      <tr>
        <td data-label="Sl.">&nbsp;{props.slNo}</td>
        <td data-label="Mentor">&nbsp;{props.elem.mentor_name}</td>
        <td data-label="Course">&nbsp;{props.elem.course_name} {props.elem.is_prof_course}</td>
        <td data-label="Topic">&nbsp;{props.elem.topic}</td>
        <td data-label="Date">&nbsp;{props.elem.date}</td>
        <td data-label="Done"><input type="checkbox" value={props.elem.id} checked={isDone} onChange={()=>{setUpdateIsDone(props.elem.id)}} /></td>
        {/* <td data-label="Edit">
          <Link to="../enquiry-modify" state={{id:props.elem.id}}>
            <span className="material-icons material-symbols-rounded edit-btn" >edit</span>
          </Link>
        </td> */}
         {/* <td data-label="Delete"><span className="material-icons material-symbols-rounded del-btn" onClick={ ()=> deleteOthersPayment(props.elem.receipt_no) } >delete</span></td> */}
         <td data-label="Share"><span className="material-icons material-symbols-outlined prevent-select" onClick={()=>{
                sharePaymentData(props.elem)
              }}>share</span></td>
      </tr>

  )
}

export default AssignedClassListTableRow;