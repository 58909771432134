import React, { useEffect, useRef, useState} from 'react';
import './ToggleBtnWith3Option.css';

const ToggleBtnWith3Option = (props) => {
    const toggle3BtnContainer = useRef('');

    const [isPresent, setIsPresent] = useState(false);
    const [isAbsent, setIsAbsent] =  useState(false);
    const [isAbsentKnown, setIsAbsentKnown] = useState(false);

    useEffect(()=>{
        if(props.is_present !== null || props.is_absent_known !== null ){
            setIsPresent(parseInt(props.is_present) ? true : false);
            setIsAbsent(parseInt(props.is_present) ? false : ( parseInt(props.is_absent_known) ? false : true));
            setIsAbsentKnown(parseInt(props.is_absent_known) ? true : false);
        }
    }, [props.is_present, props.is_absent_known]);
    

    const switchToggle = (index) => {
        let filePath = '';
        if(index === 2){
            filePath = (isPresent) ? 'manual_attendance_delete' : 'manual_attendance_set_present';

            setIsPresent(!isPresent);
            setIsAbsent(false);
            setIsAbsentKnown(false);
        }else if(index === 1){
            filePath = (isAbsent) ? 'manual_attendance_delete' : 'manual_attendance_set_absent';
            
            setIsPresent(false);
            setIsAbsent(!isAbsent);
            setIsAbsentKnown(false);
        }else{
            filePath = (isAbsentKnown) ? 'manual_attendance_delete' : 'manual_attendance_set_absent_known';
            
            setIsPresent(false);
            setIsAbsent(false);
            setIsAbsentKnown(!isAbsentKnown);
        }
        props.modifyManualAttendanceHandler(filePath, props.sub_id);
        
    };

    const setClass = (variable) => variable ? 'active' : '';

  return (
    <div className='toggle3-btn-options-container' ref={toggle3BtnContainer}>
        <span className={"material-icons material-symbols-outlined toggle3-btn toggle-default prevent-select "+setClass(isAbsentKnown)} onClick={()=>switchToggle(0)} >radio_button_unchecked
            <small>Known Absent</small>
        </span>
        <span className={"material-icons material-symbols-outlined toggle3-btn toggle-off prevent-select "+setClass(isAbsent)} onClick={()=>switchToggle(1)}>close
            <small>Absent</small>
        </span>
        
        <span className={"material-icons material-symbols-outlined toggle3-btn toggle-on prevent-select "+ setClass(isPresent)} onClick={()=>switchToggle(2)} >check
            <small>Present</small>
        </span>
    </div>

  )
}

export default ToggleBtnWith3Option;
