import React from 'react';
import SearchStudentBox from './SearchStudentBox';
import UpgradeCourseBox from './UpgradeCourseBox';

const UpgradeCourseForm = (props) => {

  return (
    <>
      <SearchStudentBox searchTitle="Upgrade Student" setSearchData={props.getSearchData} />
      <UpgradeCourseBox studentData={props.studentData} allCourses={props.allCourses} isShowForm={props.isShowForm}  setFormData={props.setFormData} getSingleCourseData={props.getSingleCourseData} courseTotalInstallment={props.courseTotalInstallment} />
    </>
  )
}

export default UpgradeCourseForm;
