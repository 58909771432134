import React from 'react';

const PaidFeesTableRowStudent = (props) => {

	return (
		<tr>
			<td data-label="No">{props.element.installment_no} Installment</td>
			{/* <!-- <td data-label="Receipt No">101</td> --> */}
			<td data-label="For Month">{props.element.for_month_year}</td>
			<td data-label="Date">{props.element.date}</td>
			<td data-label="Amount">{props.element.paid_amount}/-</td>
			<td data-label="Status" >
                <span className='status success'>Paid</span>
            </td>
		</tr>
	)
};

export default PaidFeesTableRowStudent;

