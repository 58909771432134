import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../components/admin_components/Dashboard.css';
import ClassDailyGraph from '../../components/admin_components/ClassDailyGraph';
import ClassCourseGraph from '../../components/admin_components/ClassCourseGraph';

const ClassGraph = (props) => {
    const navigate = useNavigate('');
    useEffect(()=>{
        if(props.isHeadAdmin === '0'){
            navigate('../all-students');
        }
    });

    useEffect(()=> {props.setTitle("Class Graph | Admin Panel | RedSky Educations | Educations for future")}, [props]);

    return (
    <>
        <section className="dashboard-container">
            <div className="dash-content">
                {/* <div className="overview">
                    <div className="title">
                        <span className="material-icons material-symbols-outlined">speed</span>
                        <span className="text">Overview</span>
                    </div>

                    <div className="boxes">
                        <div className="box box1">
                            <span className="material-icons material-symbols-outlined">person_4</span>
                            <span className="text">Active Student</span>
                            <span className="number">{totalNoOfActiveStudent}</span>
                            <span className="sub-text">Life Time</span>
                        </div>
                        <div className="box box2">
                            <span className="material-icons material-symbols-outlined">person_add</span>
                            <span className="text">New Admission</span>
                            <span className="number">{newAdmission}</span>
                            <span className="sub-text">This Month</span>
                        </div>
                        <div className="box box3">
                            <span className="material-icons material-symbols-outlined">account_balance_wallet</span>
                            <span className="text">Total Fees</span>
                            <span className="number">&#8377;{totalFees}</span>
                            <span className="sub-text">This Month</span>
                        </div>
                        <div className="box box4">
                            <span className="material-icons material-symbols-outlined">speed</span>
                            <span className="text">Avg. Fees</span>
                            <span className="number">&#8377;{avgFeesAllMonth}</span>
                            <span className="sub-text">Per Month</span>
                        </div>
                    </div>
                </div> */}

                <div className='chart-container'>
                    <ClassDailyGraph className='charts'  hostName={props.hostName} token={props.token} />
                </div>
                <div className='chart-group'>
                    {/* <GenderRatio className='charts' hostName={props.hostName} token={props.token}/> */}
                    <ClassCourseGraph className='charts' hostName={props.hostName} token={props.token}/>
                </div>
            </div>
        </section>
    </>
    )
};

export default ClassGraph;