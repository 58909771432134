import React from 'react';
import { Link } from 'react-router-dom';
import './AllStudentTableRow.css';

const AllOthersPaymentTableRow = (props) => {
  
  const deleteOthersPayment = (receiptNo) => {
    props.setReceiptNo(receiptNo);
    props.setVerifyPinClasses('verify-pin-container');
  }

  return (
    <>
      {
        props.rowsData.map((element) => {

          return (

            <tr key={element.receipt_no}>
              <td data-label="Receipt No">&nbsp;{element.receipt_no}</td>
              <td data-label="ID">&nbsp;{`${element.reg_no}`}</td>
              <td data-label="Name">&nbsp;{element.fname + ' ' + element.lname}</td>
              {/* <td data-label="Course">&nbsp;{element.course_name}</td> */}
              <td data-label="Purpose">&nbsp;{element.purpose}</td>
              <td data-label="Amount">&nbsp;{element.amount}/-</td>
              <td data-label="Date">&nbsp;{element.date}</td>

              <td data-label="Edit">
                <Link to={`../edit-other-payment/${element.receipt_no}`} >
                  <span className="material-icons material-symbols-rounded edit-btn" >edit</span>
                </Link>
              </td>
              <td data-label="Delete"><span className="material-icons material-symbols-rounded del-btn" onClick={ ()=> deleteOthersPayment(element.receipt_no) } >delete</span></td>

              <td data-label="Print">
                <Link to={`../Print-Other-Payment/${element.receipt_no}`}>
                  <span className="material-icons material-symbols-rounded print-btn">print</span>
                </Link>
              </td>
            </tr>
          )
        })
      }
    </>

  )
}

export default AllOthersPaymentTableRow;
