import React, {useEffect, useState} from 'react';
import Loader from '../../components/admin_components/Loader';
import { useNavigate, useParams } from 'react-router-dom';

const ModifyPaidFees = (props) => {
  const param = useParams();
  const receiptNo = param.receipt_no;
  const navigate = useNavigate();

  useEffect(()=> {props.setTitle("Edit Paid Fees List | Admin Panel | RedSky Educations | Educations for future")}, [props]);

    useEffect(()=>{
      if(props.isHeadAdmin === '0'){
        navigate('../paid-fees');
      }
    });
  
  const [stuSubId, setStuSubId] = useState('');
  const [stuRegNo, setStuRegNo] = useState('');
  const [installmentNo, setInstallmentNo] = useState('');
  const [receiptNumber, setReceiptNumber] = useState('');
  const [forMonth, setForMonth] = useState('');
  const [paymentDate, setPaymentDate] = useState('');
  const [paidAmount, setPaidAmount] = useState('');
  const [paymentMode, setPaymentMode] = useState('');
  const [loaderClasses, setLoaderClasses] = useState('loader-container d-none');

  const fetchSinglePaidFeesDataWithReceiptNo = () => {
    setLoaderClasses('loader-container');
       
    fetch(`${props.hostName}/redsky_api/fetchSinglePaidFeesDataWithReceiptNo.php?token=${props.token}&receipt_no=${receiptNo}`)
      .then(resp => resp.json()).then(data => {
       
        if(data.code === 5){
            let paymentData = data.data;
            setStuSubId(paymentData.sub_id);
            setStuRegNo(paymentData.reg_no);
            setInstallmentNo(paymentData.installment_no);
            setReceiptNumber(paymentData.receipt_no);
            setForMonth(`${paymentData.for_year}-${paymentData.for_month < 10 ? '0'+paymentData.for_month : paymentData.for_month }`);
            setPaymentDate(paymentData.date);
            setPaidAmount(paymentData.paid_amount);
            setPaymentMode(paymentData.payment_mode);
      
        }else{
          alert(data.data);
        }
        setLoaderClasses('loader-container d-none');
        
      }).catch(err => console.log(err));
  };

  useEffect(fetchSinglePaidFeesDataWithReceiptNo,[props.hostName, props.token, receiptNo]);

  const validateFormData = (e) =>{
    e.preventDefault();
    const formObj = {
        receipt_no : receiptNo,
        date: paymentDate,
        paid_amount: paidAmount,
        payment_mode : paymentMode
    };

    fetch(`${props.hostName}/redsky_api/modifyPaidFees.php?token=${props.token}`,
      {
        method: "POST",
        body: JSON.stringify(formObj),
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        }
      })
      .then(resp => resp.json()).then(data => {
    
  
        if(data.code === 5){
            alert(data.data);
            navigate(`../paid-fees-list-no-search/${stuRegNo}`);
        }else{
          alert(data.data);
        }
        
      }).catch(err => console.log(err));
  }

  return (
    <>
      <section className='form-container'>
        <header>Payment Details</header>
        <form action="#" className="student-short-details-form" onSubmit={validateFormData} >

            <div className="input-box field-disabled">
              <label>Student Id</label>
              <input type="text" placeholder="Enter student id" value={`${stuRegNo}-[${stuSubId}]`} onChange={()=>{}} disabled  />
            </div>
          <div className="column">
            <div className="input-box field-disabled">
              <label>Installment No</label>
              <input type="number" placeholder="Enter Installment No" value={installmentNo} onChange={()=>{}} disabled />
            </div>
            <div className="input-box field-disabled">
              <label>Receipt No</label>
              <input type="number" placeholder="Enter Receipt No" value={receiptNumber} onChange={()=>{}} disabled />
            </div>
          </div>

          <div className="column">
            <div className="input-box ">
              <label>For Month</label>
              <input type="month" placeholder="Enter For Month" value={forMonth} onChange={(e)=>{setForMonth(e.target.value)}} disabled/>
            </div>
            <div className="input-box ">
              <label>Paid Date</label>
              <input type="date" placeholder="Enter Paid date" value={paymentDate} onChange={(e)=>{setPaymentDate(e.target.value)}}/>
            </div>
          </div>

          <div className="input-box ">
            <label>Amount</label>
            <input type="number" placeholder="Enter Amount" value={paidAmount} onChange={(e)=>{setPaidAmount(e.target.value)}} style={{cursor:"text"}}/>
          </div>

          <div className="input-box">
            <label>Payment Mode</label>
            <div className="select-box" >
              <select onChange={(e)=>setPaymentMode(e.target.value)}>
                  <option hidden value=''>Select Payment Mode</option>
                  <option value='Cash' selected={(paymentMode.toLowerCase() === 'cash')}>Cash</option>
                  <option value='Online' selected={(paymentMode.toLowerCase() === 'online')}>Online</option>
              </select>
            </div>
          </div>

            <input type='submit' value="Modify" className='submit-btn' style={{background:"rgb(130, 106, 251)"}}/>
        </form>
      </section>
      <Loader loaderClasses={loaderClasses} />
    </>
  );
}

export default ModifyPaidFees;



