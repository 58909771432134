import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './Profile.css';
import Calender from '../../components/student_components/Calender';
import Loader from '../../components/admin_components/Loader';

const Profile = (props) => {
  const param = useParams();
  const [basicData, setBasicData] = useState([]);
  const [PaidFeesData, setPaidFeesData] = useState([]);
  const [examMarksData, setExamMarksData] = useState([]);
  const [attnData, setAttnData] = useState([]);
  const [loaderClasses, setLoaderClasses] = useState('loader-container');
  
  useEffect(()=>{
    setLoaderClasses('loader-container');
    fetch(`${props.hostName}/redsky_api/student/getProfileData.php?sub_id=${param.sub_id}`)
      .then(resp => resp.json()).then(data => {
        setLoaderClasses('loader-container d-none');
        if(data.code === 5){
          setBasicData(data.data.stu_data);
          setExamMarksData(data.data.exam_marks_data);
          setPaidFeesData(data.data.paid_fees_data);
          setAttnData(data.data.attn_data);
        }else{
          alert(data.data);
        }
      }).catch(err => console.log(err));
  }, [param, props]);

  return (
    <>
      <div className='container-fluid py-4' id='profile'>
        <div className='row'>
          <div className='col-12'>
            <h4 className='text-center'>Student Profile</h4>
            <hr className="border border-secondary border-1 opacity-75 mt-3" />
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>

            <div className="accordion" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button text-white" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                    Basic Details
                  </button>
                </h2>
                <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                  <div className="accordion-body">

                    <div className='row'>
                      <div className='col-md-6'>
                        <div className='row d-flex align-items-center h-100 bg-body-secondary p-2 border border-secondary-subtle border-bottom-0'>
                          <div className='col-6 fw-medium'>First Name:</div>
                          <div className='col-6'>{basicData.first_name}</div>
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className='row d-flex align-items-center h-100 bg-body-secondary bg-alt-white p-2 border border-secondary-subtle border-bottom-0 '>
                          <div className='col-6 fw-medium'>Last Name:</div>
                          <div className='col-6'>{basicData.last_name}</div>
                        </div>
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col-md-6'>
                        <div className='row d-flex align-items-center h-100 p-2 border border-secondary-subtle border-bottom-0 bg-alt-gray'>
                          <div className='col-6 fw-medium'>Guardian:</div>
                          <div className='col-6'>{basicData.guardian_name}</div>
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className='row d-flex align-items-center h-100 p-2 border border-secondary-subtle border-bottom-0'>
                          <div className='col-6 fw-medium'>D.O.B:</div>
                          <div className='col-6'>{basicData.dob}</div>
                        </div>
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col-md-6'>
                        <div className='row d-flex align-items-center h-100 bg-body-secondary p-2 border border-secondary-subtle border-bottom-0'>
                          <div className='col-6 fw-medium'>Gender:</div>
                          <div className='col-6'>{basicData.gender}</div>
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className='row d-flex align-items-center h-100 bg-body-secondary bg-alt-white p-2 border border-secondary-subtle border-bottom-0'>
                          <div className='col-6 fw-medium'>Caste:</div>
                          <div className='col-6'>{basicData.caste}</div>
                        </div>
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col-md-6'>
                        <div className='row d-flex align-items-center h-100 p-2 border border-secondary-subtle border-bottom-0 bg-alt-gray'>
                          <div className='col-6 fw-medium'>Course:</div>
                          <div className='col-6'>{basicData.course_name}</div>
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className='row d-flex align-items-center h-100 p-2 border border-secondary-subtle border-bottom-0'>
                          <div className='col-6 fw-medium'>Admission Date:</div>
                          <div className='col-6'>{basicData.admission_date}</div>
                        </div>
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col-md-6'>
                        <div className='row d-flex align-items-center h-100 bg-body-secondary p-2 border border-secondary-subtle border-bottom-0'>
                          <div className='col-6 fw-medium'>Starting Month:</div>
                          <div className='col-6'>{basicData.course_start_date}</div>
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className='row d-flex align-items-center h-100 bg-body-secondary bg-alt-white p-2 border border-secondary-subtle border-bottom-0'>
                          <div className='col-6 fw-medium'>Ending Month:</div>
                          <div className='col-6'>{basicData.course_end_date}</div>
                        </div>
                      </div>
                    </div>
                    
                    <div className='row'>
                      <div className='col-md-6'>
                        <div className='row d-flex align-items-center h-100 p-2 border border-secondary-subtle border-bottom-0 bg-alt-gray'>
                          <div className='col-6 fw-medium'>Status:</div>
                          <div className='col-6'>{basicData.status}</div>
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className='row d-flex align-items-center h-100 p-2 border border-secondary-subtle border-bottom-0'>
                          <div className='col-6 fw-medium'>Area:</div>
                          <div className='col-6'>{basicData.city}</div>
                        </div>
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col-md-6'>
                        <div className='row d-flex align-items-center h-100 bg-body-secondary p-2 border border-secondary-subtle border-bottom-0'>
                          <div className='col-6 fw-medium'>State:</div>
                          <div className='col-6'>{basicData.state}</div>
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className='row d-flex align-items-center h-100 bg-body-secondary bg-alt-white p-2 border border-secondary-subtle border-bottom-0'>
                          <div className='col-6 fw-medium'>Country:</div>
                          <div className='col-6'>{basicData.country}</div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button text-white collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                    Exam Marks
                  </button>
                </h2>
                <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                    <div className="table-responsive mt-3">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th scope="col">Exam Date</th>
                            <th scope="col">Theory</th>
                            <th scope="col">Practical</th>
                            <th scope="col">Viva</th>
                            <th scope="col">Rank</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            examMarksData.map((item)=>{
                              return(
                                <tr key={item.exam_marks_id}>
                                  <td data-label="Exam Date">{item.date_of_exam}</td>
                                  <td data-label="Theory">{item.th_obtain} / {item.th_total}</td>
                                  <td data-label="Practical">{item.prac_obtain} / {item.prac_total}</td>
                                  <td data-label="Viva">{item.viva_obtain} / {item.viva_total}</td>
                                  <td data-label="Rank">{parseInt(item.sem_rank) === 0 ? 'N/A' : item.sem_rank}</td>
                                </tr>
                                )
                            })
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button text-white collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                    Attendance Data
                  </button>
                </h2>
                <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                    <div className='d-sm-flex justify-content-between'>

                      <p className='fw-medium d-flex align-items-center'><span className='color-box present-box'></span>Present : { attnData.present_data ? attnData.present_data.length : 'N/A'}</p>

                      <p className='fw-medium d-flex align-items-center'><span className='color-box known-absent-box'></span>Known Absent : { attnData.known_absent_data ? attnData.known_absent_data.length : 'N/A'}</p>

                      <p className='fw-medium d-flex align-items-center'><span className='color-box absent-box'></span>Absent : { attnData.absent_data ? attnData.absent_data.length : 'N/A'}</p>

                    </div>
                    <div className='d-flex justify-content-center'>
                      <Calender attnData={attnData} basicData={basicData} />
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button text-white collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                    Payment Details
                  </button>
                </h2>
                <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                    <div className="table-responsive mt-3">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th scope="col">No.</th>
                            <th scope="col">Reciept No</th>
                            <th scope="col">For Month</th>
                            <th scope="col">Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            PaidFeesData.map((item)=>{
                              return(
                                <tr className={item.receipt_no === '0' ? 'deferral-row' : ""} key={item.installment_no}>
                                  <td data-label="No.">{item.installment_no}</td>
                                  <td data-label="Reciept No">{item.receipt_no === '0' ? "N/A" : item.receipt_no}</td>
                                  <td data-label="For Month">{item.for_month}</td>
                                  <td data-label="Date">{item.date}</td>
                                </tr>
                                )
                            })
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>

          </div>
        </div>
      </div>
      <Loader loaderClasses={loaderClasses} />
    </>
  )
}

export default Profile;
