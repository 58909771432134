import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './AllStudentTableRow.css';
import { saveAs } from 'file-saver';

const TableRow = (props) => {
  const [isActionULShow, setIsActionULShow] = useState(false);
  const deleteStudentHandler = (stuId, courseId) => {
    props.setVerifyPinClasses('verify-pin-container');
    props.setDeleteStuId(stuId);
    props.setDeleteStuCourseId(courseId);
    setIsActionULShow(false);
  }
  const shareStudentRegistration = (fName, lName, stuId, contact) => { 
    
    let wp_msg = `Hello,%0a${fName} ${lName} %0aID: ${stuId}%0a%0a*Welcome to RedSky Educations (P) Ltd.* %0a%0aYour name has been registered.
    %0a%0aPlease save our number to get Notices and other %0aupdates on our WhatsApp status. %0a%0aWebsite: %0ahttp://www.redskyeducations.in %0a%0aDownload the App: %0ahttps://play.google.com/store/apps/details?id=co.amy.yodqb `;

    if(props.deviceType === 'desktop'){
      window.open(`https://web.whatsapp.com/send?phone=+91 ${contact}&text=${wp_msg}&app_absent=0`, "blank");
    }else{
      window.open(` https://wa.me/+91 ${contact}?text=${wp_msg}`, "blank");
    }
    setIsActionULShow(false);
  }
  let status = null;
  if (props.element.is_passout === '1') {
    status = 'done_all';
  } else if (props.element.is_discontinue === '1') {
    status = 'close';
  } else {
    status = 'directions_run';
  }

  const fetchImage = () =>{
    props.setLoaderClasses('loader-container');
    fetch(`${props.hostName}/redsky_api/upload/get_student_image.php?token=${props.token}`,
    {
      method: "POST",
      body: JSON.stringify({stu_id: props.element.s_id}),
      headers: {
        'Content-Type': 'application/json;charset=UTF-8'
      }
    })
    .then(resp => resp.json()).then(data => {
      // console.log(data);
      if(data.code === 5){
        saveAs(data.data.file_name, props.element.s_id+'.jpg');
        // or 
        // const downloadLink = document.createElement("a");
        // downloadLink.href = `${data.data.file_name}`;
        // downloadLink.download = props.element.s_id+'.png';
        // downloadLink.setAttribute('download', true);
        // console.log(downloadLink);
        // downloadLink.click();
      }
      props.setLoaderClasses('loader-container d-none');
    }).catch(err => console.log(err));
  };

  return (
    <>
      <tr>
        <td data-label="ID">{props.element.s_reg_no}</td>
        <td data-label="Name">{props.element.s_fname + ' ' + props.element.s_lname}</td>
        <td data-label="Guardian">{props.element.guardian_name}</td>
        <td data-label="Course">{props.element.c_name}</td>
        <td data-label="DOB">{props.element.s_dob}</td>
        <td data-label="Contact">{props.element.s_contact} <br/> {props.element.s_alt_contact}</td>
        <td data-label="Board">&nbsp;{(props.element.s_board === '') ? '-' : props.element.s_board}</td>
        <td data-label="Class">&nbsp;{(props.element.s_class === '') ? '-' : props.element.s_class}</td>
        <td data-label="DOA">{props.element.date_admission}</td>
        <td data-label="Status">
          <span className={`material-icons material-symbols-rounded status_student ${status}`}>{status}</span>
        </td>
        
        <td data-label="ACTION" className='action-more-btn'>
          <span className="material-icons material-symbols-rounded prevent-select" onClick={()=>setIsActionULShow(!isActionULShow)}>more_vert</span>
            <ul className={`action-more-ul ${isActionULShow ? '' : 'd-none'} `}>
              <li data-label="Edit">
                <Link to={`../Modify-Student/${props.element.s_id}`}>
                  <span className="material-icons material-symbols-rounded edit-btn" >edit</span>
                </Link>
              </li>
              <li data-label="Delete"><span className="material-icons material-symbols-rounded del-btn" onClick={ ()=> deleteStudentHandler(props.element.s_id, props.element.c_id) } >delete</span></li>

              <li data-label="Print">
                <Link to={`../Print-Registration/${props.element.s_id}`} >
                  <span className="material-icons material-symbols-rounded print-btn" >print</span>
                </Link>
              </li>
              {/* {
                parseInt(props.element.is_passout) === 1 
                
                ? */}
              <li data-label="Certificate">
                <Link to={`../certificate/${props.element.s_id}`} >
                  <span className="material-icons material-symbols-outlined" >badge</span>
                </Link>
              </li>
              {/* :
              <></> */}

              {/* } */}

              <li data-label="Share">
                <span className="material-icons material-symbols-outlined prevent-select" onClick={()=>{
                  shareStudentRegistration(props.element.s_fname, props.element.s_lname, props.element.s_id, props.element.s_contact)
                  }}>share</span>
              </li>
              <li data-label="IMG">
                <span className="material-icons material-symbols-outlined prevent-select"  onClick={fetchImage} >download</span>
              </li>
            </ul>
        </td>
      </tr>     
    </>
  )
}

export default TableRow;
