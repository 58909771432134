import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';

const SearchPaymentBox = (props) => {
    const navigate = useNavigate();
    const param = useParams();
    const [searchVal, setSearchVal] = useState(param.reg_no ? param.reg_no : '');

    useEffect(()=> {props.setTitle(`New Payment | ${parseInt(props.isHeadAdmin) ? "Admin Panel" : "User Panel"} | RedSky Educations | Educations for future`)}, [props]);
  return (
    <div>
      <section className="search-container">
        <header>{props.heading}</header>
        <form className="form" onSubmit={(e)=>{e.preventDefault(); navigate(`${props.path}/${searchVal}`)}}>
          
            <div className="input-box">
              <label>Enter Student ID</label>
              <input type="text" placeholder="Enter Student ID" value={searchVal} onChange={(e)=>setSearchVal(e.target.value)} autoFocus required />
            </div>   

          <button type='submit'>Search</button>
        </form>
      </section>
    </div>
  )
}

export default SearchPaymentBox
