import React from "react";
// import StudentNav from '../../components/student_components/StudentNav';
import "./StudentCommon.css";
import PageNotFound from "../PageNotFound";
import UpdateContact from "./UpdateContact";
import { Route, Routes } from "react-router-dom";
import PaidFeesListStudent from "./PaidFeesListStudent";
import StudentLogin from "./StudentLogin";
// import PointDashboard from "./PointDashboard";
import PanelChoice from "./PanelChoice";
import BasicStudentDetails from "./BasicStudentDetails";
import PaymentDetails from "./PaymentDetails";
import AttendanceDetails from "./AttendanceDetails";
// import MarksData from "./MarksData";
import LogBook from "./LogBook";

const StudentIndex = (props) => {
  return (
    <>
      {/* <StudentNav /> */}
      <Routes>
        <Route
          path="/"
          element={
            <StudentLogin hostName={props.hostName} setTitle={props.setTitle} />
          }
        />
        <Route
          path="/:reg_no/:contact"
          element={
            <StudentLogin hostName={props.hostName} setTitle={props.setTitle} />
          }
        />
        <Route
          path="/:alert"
          element={
            <StudentLogin hostName={props.hostName} setTitle={props.setTitle} />
          }
        />
        <Route
          path="/panel_choice/:reg_no/:contact"
          element={
            <PanelChoice hostName={props.hostName} setTitle={props.setTitle} />
          }
        />
        {/* <Route
          path="/point_dashboard/:stu_id/:contact"
          element={
            <PointDashboard
              hostName={props.hostName}
              setTitle={props.setTitle}
            />
          }
        /> */}
        {/* <Route path='/exam/:stu_id/:contact/:f_q_id' element={<ExamPage hostName={props.hostName} setTitle={props.setTitle} />} /> */}
        <Route
          path="/basic_details/:reg_no/:contact"
          element={
            <BasicStudentDetails
              hostName={props.hostName}
              setTitle={props.setTitle}
            />
          }
        />
        <Route
          path="/payment-details/:reg_no/:contact"
          element={
            <PaymentDetails
              hostName={props.hostName}
              setTitle={props.setTitle}
            />
          }
        />
        <Route
          path="/log-book/:reg_no/:contact"
          element={<LogBook hostName={props.hostName} />}
        />
        <Route
          path="/attendace-details/:reg_no/:contact"
          element={
            <AttendanceDetails
              hostName={props.hostName}
              setTitle={props.setTitle}
            />
          }
        />
        {/* <Route
          path="/marks-data/:stu_id/:contact"
          element={
            <MarksData hostName={props.hostName} setTitle={props.setTitle} />
          }
        /> */}

        <Route
          path="/update-contact"
          element={
            <UpdateContact
              hostName={props.hostName}
              setTitle={props.setTitle}
            />
          }
        />
        <Route
          path="/update-contact/:reg_no/:old_number/:dob"
          element={
            <UpdateContact
              hostName={props.hostName}
              setTitle={props.setTitle}
            />
          }
        />
        <Route
          path="/paid-fees-list"
          element={
            <PaidFeesListStudent
              hostName={props.hostName}
              setTitle={props.setTitle}
            />
          }
        />
        <Route
          path="/paid-fees-list/:stu_id/:contact"
          element={
            <PaidFeesListStudent
              hostName={props.hostName}
              setTitle={props.setTitle}
            />
          }
        />
        <Route path="*" element={<PageNotFound setTitle={props.setTitle} />} />
      </Routes>
    </>
  );
};

export default StudentIndex;
