import React, {useState, useEffect, useRef} from 'react';
import './StudentForm.css';
import UploadStudentImage from './UploadStudentImage';

const StudentForm = (props) => {
  const [modificationType, setModificationType] = useState(0);
  const [stuSubId, setStuSubId] = useState('');
  const [stuRegNo, setStuRegNo] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [guardianName, setGuardianName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNo, setPhoneNo] = useState('');
  const [altPhone, setAltPhone] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [gender, setGender] = useState('');
  const [caste, setCaste] = useState('');
  const [board, setBoard] = useState('');
  const [classStu, setClassStu] = useState('');
  const [schoolUniversity, setSchoolUniversity] = useState('');
  const [course, setCourse] = useState('');
  const [discount, setDiscount] = useState('');
  const [courseStartDate, setCourseStartDate] = useState('');
  const [courseEndDate, setCourseEndDate] = useState('');
  const [streetAddress, setStreetAddress] = useState('');
  const [country, setCountry] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [pinCode, setPinCode] = useState('');
  const [psychology, setPsychology] = useState('');

  const modTypeElem = useRef();
  
  // this form is also used for MODIFYING STUDENTS.... so if we get any student data from the parent component (API), then this code will run
  
  useEffect(() => {
    
    if(props.modType !== null){
      modTypeElem.current.querySelector(`option[value='${props.modType}']`).selected = true;
      setModificationType(props.modType);
    }

    if(props.studentData.length !== 0){
      
      setStuSubId(props.studentData.sub_id);
      setStuRegNo(props.studentData.reg_no);
      setFirstName(props.studentData.first_name);
      setLastName(props.studentData.last_name);
      setGuardianName(props.studentData.guardian_name);
      setEmail(props.studentData.email_id);
      setPhoneNo(props.studentData.contact);
      setAltPhone(props.studentData.alt_contact);
      setDateOfBirth(props.studentData.dob);
      setGender(props.studentData.gender);
      document.querySelector(`.gender-option input[value='${props.studentData.gender}']`).checked = true;
      setCaste(props.studentData.caste);
      document.querySelector(`.caste-options option[value='${props.studentData.caste}']`).selected = true;
      setBoard(props.studentData.board);
      document.querySelector(`.board-options option[value='${props.studentData.board}']`).selected = true;
      setClassStu(props.studentData.class);
      document.querySelector(`.class-options option[value='${props.studentData.class}']`).selected = true;
      setSchoolUniversity(props.studentData.school_university);
      setCourse(props.studentData.course_id);
      document.querySelector(`.course-options option[value='${props.studentData.course_id}']`).selected = true;
      setDiscount(props.studentData.fees_discount);
      setCourseStartDate(props.studentData.course_start_date);
      setCourseEndDate(props.studentData.course_end_date);
      setStreetAddress(props.studentData.address);
      setCountry(props.studentData.country);
      document.querySelector(`.country-options option[value='${props.studentData.country}']`).selected = true;
      setCity(props.studentData.city);
      setState(props.studentData.state);
      setPinCode(props.studentData.pin);
      setPsychology(props.studentData.psychology);
      
    }
  }, [props.studentData, props.modType]);
  
  
  const resetFormFields = () =>{
    
    setStuRegNo('');
    setFirstName('');
    setLastName('');
    setGuardianName('');
    setEmail('');
    setPhoneNo('');
    setAltPhone('');
    setDateOfBirth('');
    setGender('');
    setCaste('');
    setBoard('');
    setClassStu('');
    setSchoolUniversity('');
    setCourse('');
    setCourseEndDate('');
    setStreetAddress('');
    setCountry('');
    setCity('');
    setState('');
    setPinCode('');
    setPsychology('');
    document.querySelector("input[name=gender]:checked").checked = false;
    document.querySelectorAll(".placeHolder")[0].selected = true;
    document.querySelectorAll(".placeHolder")[1].selected = true;
    document.querySelectorAll(".placeHolder")[2].selected = true;
    document.querySelectorAll(".placeHolder")[3].selected = true;
  }

  const formValidate = (e) =>{
   
    e.preventDefault();
    if(stuRegNo.trim() === '' || firstName.trim() === '' || lastName.trim() === ''  || guardianName.trim() === '' || phoneNo.trim() === '' || dateOfBirth.trim() === '' || gender.trim() ==='' || caste.trim() === '' || course.trim() === '' || courseEndDate.trim() === '' ){
      alert("Please Fill Out All Data");
      return;
    }
    
    // phoneNo validation
    if(phoneNo.length !== 10){
      alert("Phone No is Invalid!");
      return;
    }
    // PIN validation
    // if (pinCode.length !== 6) {
    //   alert("PIN Code is Invalid!");
    //   return;
    // }
    let formData = {
      modification_type: modificationType,
      sub_id: stuSubId,
      reg_no: stuRegNo,
      f_name: firstName,
      l_name: lastName,
      guardian_name: guardianName,
      email: email,
      phone_no: phoneNo,
      alt_phone: altPhone,
      date_of_birth: dateOfBirth,
      gender: gender,
      caste: caste,
      board: board,
      class: classStu,
      school_university: schoolUniversity,
      course:course,
      discount: discount,
      course_start_date: courseStartDate,
      course_end_date: courseEndDate,
      address:streetAddress,
      country: country,
      city: city,
      state: state,
      pin_code: pinCode,
      psychology: psychology
    };
    
    props.setFormData(formData);
    resetFormFields();
  }
  const modificationTypeHandler = (e) => {
    if(e.target.value !== '0'){
      fetch(`${props.hostName}/redsky_api/isAllPaymentClear.php?token=${props.token}&student_id=${props.studentData.sub_id}`)
      .then(resp => resp.json()).then(data => {
        
        if (data.code === 5) {
          if(data.data.has_active_payment)
            alert(data.data.msg + "!... \nDo You Still want to proceed?");
        }else{
          alert(data.data);
        }
      }).catch(err => console.log(err));
    }
    setModificationType(e.target.value);
  };

  let classes = "form-container "+props.isShowForm;

  return (
    <>
      <section className={classes}>
        <header>Modify Student Form</header>
        <form action="#" className="form" onSubmit={formValidate} >
        
        <div className="input-box">
            <label>Modification Type<span className='color-red'>*</span></label>
            <div className="select-box">
                  <select onChange={modificationTypeHandler} ref={modTypeElem}>
                      <option value='0' >None</option>
                      <option value='1'>Discontinue</option>
                      <option value='2'>Passed Out</option>
                  </select>
                </div>
          </div>
          <div className='column'>
            <div className="input-box field-disabled">
                <label>Sub ID<span className='color-red'>*</span></label>
                <input type="text" placeholder="Enter Registration No" value={stuSubId}  disabled/>
            </div>
            <div className="input-box">
              <label>Reg No<span className='color-red'>*</span></label>
              <input type="text" placeholder="Enter Registration No" value={stuRegNo} onChange={(e)=>setStuRegNo(e.target.value)} />
            </div>
          </div>
          {/* <div className="input-box">
              <label>Reg. No<span className='color-red'>*</span></label>
              <input type="text" placeholder="Enter Registration No" value={regNo} onChange={(e)=>setRegNo(e.target.value)} required />
          </div> */}
          <div className="column">
            <div className="input-box">
              <label>First Name<span className='color-red'>*</span></label>
              <input type="text" placeholder="Enter first name" value={firstName} onChange={(e)=>setFirstName(e.target.value)} required />
            </div>
            <div className="input-box">
              <label>Last Name<span className='color-red'>*</span></label>
              <input type="text" placeholder="Enter last name" value={lastName} onChange={(e)=>setLastName(e.target.value)} required />
            </div>
          </div>

          <div className="input-box">
            <label>Guardian Name<span className='color-red'>*</span></label>
            <input type="text" placeholder="Enter Guardian Name" value={guardianName} onChange={(e)=>setGuardianName(e.target.value)}  required/>
          </div>

          <div className="column">
            <div className="input-box">
              <label>Email Address</label>
              <input type="email" placeholder="Enter email Id (Optional)" value={email} onChange={(e)=>setEmail(e.target.value)} />
            </div>
            <div className="input-box">
              <label>Birth Date<span className='color-red'>*</span></label>
              <input type="date" placeholder="Enter birth date" value={dateOfBirth} onChange={(e)=>setDateOfBirth(e.target.value)}  required/>
            </div>
          </div>

          <div className="column">
            <div className="input-box">
              <label>Phone Number<span className='color-red'>*</span></label>
              <input type="number" placeholder="Enter phone number" value={phoneNo} onChange={(e)=>setPhoneNo(e.target.value)}  required/>
            </div>
            <div className="input-box">
              <label>Alt Phone No</label>
              <input type="number" placeholder="Enter Alternate Phone Number" value={altPhone} onChange={(e)=>setAltPhone(e.target.value)} />
            </div>
          </div>
          <div className="gender-box">
            <h3>Gender<span className='color-red'>*</span></h3>
            <div className="gender-option">
              <div className="gender">
                <label><input type="radio"  name="gender" value='Male' onClick={(e)=>setGender(e.target.value)} required/> Male</label>
              </div>
              <div className="gender">
                <label><input type="radio" name="gender" value='Female' onClick={(e)=>setGender(e.target.value)} required/> Female</label>
              </div>
              <div className="gender">
                <label><input type="radio" name="gender" value='Others' onClick={(e)=>setGender(e.target.value)} required/> Prefer not to say</label>
              </div>
            </div>
          </div>
          <div className="column">
            <div className="input-box">
              <label>Caste<span className='color-red'>*</span></label>
              <div className="select-box">
                <select onChange={(e)=>setCaste(e.target.value)} className="caste-options">
                  <option hidden className='placeHolder' value=''>Caste</option>
                  <option value='General'>General</option>
                  <option value='OBC-A'>OBC-A</option>
                  <option value='OBC-B'>OBC-B</option>
                  <option value='SC'>SC</option>
                  <option value='ST'>ST</option>
                </select>
              </div>
            </div>

            <div className="input-box">
              <label>Board</label>
              <div className="select-box">
                <select onChange={(e) => setBoard(e.target.value)} className="board-options">
                  <option hidden className='placeHolder' value=''>Board</option>
                  <option value='ICSE'>ICSE</option>
                  <option value='CBSE'>CBSE</option>
                  <option value='WBBSE'>WBBSE</option>
                  <option value='WBCHSE'>WBCHSE</option>
                  <option value='No Board'>No Board</option>
                </select>
              </div>
            </div>

          </div>
          <div className="column">
            <div className="input-box">
              <label>School / University</label>
              <input type="text" placeholder="Enter School / University (Optional)" value={schoolUniversity} onChange={(e)=>setSchoolUniversity(e.target.value)} />
            </div>

            <div className="input-box">
              <label>Class</label>
              <div className="select-box">
                <select onChange={(e) => setClassStu(e.target.value)} className="class-options">
                  <option hidden className='placeHolder' value=''>Class</option>
                  <option value='nursery'>Nursery</option>
                  <option value='lkg'>LKG</option>
                  <option value='ukg'>UKG</option>
                  {
                    Array.from({length: 12}, (_, i) => i + 1).map((item) => {
                      return(
                        <option key={item} value={item}>{item}</option>
                      )
                    })
                  }
                </select>
              </div>
            </div>

          </div>
            
          <div className="column">
            
            <div className="input-box">
              <label>Course<span className='color-red'>*</span></label>
              <div className="select-box field-disabled">
                  <select onChange={(e)=>setCourse(e.target.value)} className="course-options" disabled>
                        <option hidden className='placeHolder'>Course</option>
                        {
                          props.allCourses.map((element) => {
                            return(<option key={element.c_id} value={element.c_id}>{element.c_name}</option>);
                          })
                        }
                  </select>
                </div>
            </div>
            <div className="input-box">
                <label>Discount<span className='color-red'>*</span></label>
                <input type="number" placeholder="Enter Discount Value" value={discount} onChange={(e)=>setDiscount(e.target.value)} />
            </div>
          </div>

          <div className='column'>
            <div className="input-box">
              <label>Start Date<span className='color-red'>*</span></label>
              <input type="month" placeholder="Enter End Date" value={courseStartDate} onChange={(e)=>setCourseStartDate(e.target.value)} />
            </div>
            <div className="input-box">
              <label>End Date<span className='color-red'>*</span></label>
              <input type="month" placeholder="Enter End Date" value={courseEndDate} onChange={(e)=>setCourseEndDate(e.target.value)} />
            </div>
          </div>
          <div className="input-box address">
            <label>Address</label>
            <input type="text" placeholder="Enter street address" value={streetAddress} onChange={(e)=>setStreetAddress(e.target.value)} />
            {/* <input type="text" placeholder="Enter street address 2 (Optional)" /> */}
            <div className="column">
              <div className="select-box">
                <select onChange={(e)=>setCountry(e.target.value)} className="country-options">
                  <option hidden className='placeHolder'>Country</option>
                  <option value='India'>India</option>
                  <option value='America'>America</option>
                  <option value='Japan'>Japan</option>
                  <option value='Nepal'>Nepal</option>
                </select>
              </div>
              <input type="text" placeholder="Enter your city" value={city} onChange={(e)=>setCity(e.target.value)}  />
            </div>
            <div className="column">
              <input type="text" placeholder="Enter your State"  value={state} onChange={(e)=>setState(e.target.value)}  />
              <input type="number" placeholder="Enter postal code" value={pinCode} onChange={(e)=>setPinCode(e.target.value)}  />
            </div>
          </div>
          <div className="input-box">
            <label>Psychology</label>
            <textarea placeholder='Enter Student Psychology (Seperated By ; [Semi-Colon])' value={psychology} onChange={(e)=>setPsychology(e.target.value)}></textarea>
          </div>
          {
            stuSubId ?
              <UploadStudentImage isModifyForm={true} stu_id={stuSubId} setLoaderClasses={props.setLoaderClasses} hostName={props.hostName} token={props.token} />
              :
              ''
          }

          <button type='submit'>Update</button>
        </form>
      </section>
    </>
  )
}

export default StudentForm;
