import React, { useEffect, useState } from 'react';
import OtherPaymentForm from '../../components/admin_components/OtherPaymentForm';
import PrintOtherPaymentForm from '../../components/admin_components/PrintOtherPaymentForm';

const OtherPayment = (props) => {
    const [loaderClass, setLoaderClass] = useState('loader-container d-none');
    const [isShowBill, setIsShowBill] = useState('d-none');
    const [isShowForm, setIsShowForm] = useState('');
    const [otherPaymentBillData, setOtherPaymentBillData] = useState([]);

    useEffect(()=> {props.setTitle(`Other Payment | ${parseInt(props.isHeadAdmin) ? "Admin Panel" : "User Panel"} | RedSky Educations | Educations for future`)}, [props]);

    const getFormData = (formData) => {
      setLoaderClass('loader-container');
      fetch(`${props.hostName}/redsky_api/AddOtherPayments.php?token=${props.token}`,
      {
        method: "POST",
        body: JSON.stringify(formData),
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        }
      })
      .then(resp => resp.json()).then(data => {
        setLoaderClass('loader-container d-none');
        if(data.code === 8){ 
          getStudentDataForOtherPaymentBill(data.data.stu_reg_no);
          setIsShowForm('d-none');
          setIsShowBill('');
        }else{
          alert(data.data);
        }
      }).catch(err => console.log(err));
    };
    
    const getStudentDataForOtherPaymentBill = (stuRegNo) => {
      setLoaderClass('loader-container');
      fetch(`${props.hostName}/redsky_api/getStudentDataForOtherPaymentBill.php?token=${props.token}&stu_reg_no=${stuRegNo}`)
        .then(resp => resp.json()).then(data => {
          
          setLoaderClass('loader-container d-none');
          if(data.code === 5){
            setOtherPaymentBillData(data.data);
          }else{
            alert(data.data);
          }
        }).catch(err => console.log(err));
    };

  return (
    <div>
      <OtherPaymentForm  hostName={props.hostName} token={props.token} isHeadAdmin={props.isHeadAdmin} setFormData={getFormData} loaderClasses={loaderClass} isShowForm={isShowForm}/>
      <PrintOtherPaymentForm isShowBill={isShowBill} otherPaymentBillData={otherPaymentBillData} setTitle={props.setTitle} isHeadAdmin={props.isHeadAdmin} />
    </div>
  )
}

export default OtherPayment;
