import React, { useEffect, useState } from 'react';
import Loader from './Loader';
import EnqueryAllListTable from './EnqueryAllListTable';

const EnqueryAllList = (props) => {
  let tableCols = ['Sl.', 'Name', 'Course', 'Contact', 'Day', 'Date', 'Admitted', 'Edit', 'Del', 'Print'];
  let [rowsData, setRowsData] = useState([]);
  const [loaderClasses, setLoaderClasses] = useState('loader-container d-none');
  const [tableClasses, setTableClasses] = useState('table-container');
  const [msgClasses, setMsgClasses] = useState('msg d-none');
  const [msgData, setMsgData] = useState('');

  const [totalEnqueryCount, setTotalEnqueryCount] = useState(0);
  const [totalAdmittedEnqueryCount, setAdmittedTotalEnqueryCount] = useState(rowsData.filter((elem)=> parseInt(elem.is_admitted) === true ).length);

  useEffect(()=> {props.setTitle(`Enquiry List | ${parseInt(props.isHeadAdmin) ? "Admin Panel" : "User Panel"} | RedSky Educations | Educations for future`)}, [props]);
  const fetchEnqueryDetails = () => {

    setLoaderClasses('loader-container');
    setRowsData([]);
    fetch(`${props.hostName}/redsky_api/getAllEnqueryDetails.php?token=${props.token}`)
        .then(resp => resp.json()).then(data => {
            setLoaderClasses('loader-container d-none');
            if(data.code === 5){
                setRowsData(data.data);
                setTableClasses('table-container');
                setMsgClasses('msg d-none');
                setTotalEnqueryCount(data.data.length);
                setAdmittedTotalEnqueryCount(data.data.filter((elem)=>{ return parseInt(elem.is_admitted) === 1 } ).length);
            }
            else{
                setTableClasses('table-container d-none');
                setMsgData(data.data);
                setMsgClasses('msg');
            }
            
        }).catch(err => console.log(err));
    };

  useEffect(fetchEnqueryDetails, [props.hostName, props.token]);

  const updateAdmittedStatus = (enquery_id, isAdmittedStatus) => {
      setLoaderClasses('loader-container');
      fetch(`${props.hostName}/redsky_api/modify_enquery_is_admitted.php?token=${props.token}&enquery_id=${enquery_id}&admit_status=${isAdmittedStatus}`)
      .then(resp => resp.json()).then(data => {
          
          if (data.code === 5) {
            if(isAdmittedStatus){
              setAdmittedTotalEnqueryCount(totalAdmittedEnqueryCount+1);
            }else{
              setAdmittedTotalEnqueryCount(totalAdmittedEnqueryCount-1);
            }
          }else{
            alert(data.data);
          }
          setLoaderClasses('loader-container d-none');
      }).catch(err => console.log(err));
  }

  const deleteEnquery = (enqueryDeleteData) => {

    setLoaderClasses('loader-container');
      
    //deletion code will go here
    fetch(`${props.hostName}/redsky_api/deleteEnquery.php?token=${props.token}`,
    {
      method: "POST",
      body: JSON.stringify(enqueryDeleteData),
      headers: {
        'Content-Type': 'application/json;charset=UTF-8'
      }
    })
    .then(resp => resp.json()).then(data => {
      if(data.code === 5){
        fetchEnqueryDetails();
        alert(data.data);
      }else{
        alert(data.data);
      }
      setLoaderClasses('loader-container d-none');
      
    }).catch(err => console.log(err));
    };

  return (
    <>
      <EnqueryAllListTable tableClasses={tableClasses} tableCols={tableCols} rowsData={rowsData} token={props.token}  msgClasses={msgClasses} msgData={msgData} setLoaderClasses={setLoaderClasses} updateAdmittedStatus={updateAdmittedStatus} totalEnqueryCount={totalEnqueryCount} totalAdmittedEnqueryCount={totalAdmittedEnqueryCount} deleteEnquery={deleteEnquery} />

      <Loader loaderClasses={loaderClasses}  />
    </>
  )

}

export default EnqueryAllList
